// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accents from "remove-accents";

// Css
import classes from "./FuncionariosScreen.module.css";

// Actions
import * as funcionariosActions from "../../store/actions/funcionarios";

// Components
import BarraPesquisa from "../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../components/UI/Button/Button";
import Funcionario from "../../components/Funcionarios/Funcionario/Funcionario";
import ModalFuncionario from "../../components/Funcionarios/Funcionario/ModalFuncionario/ModalFuncionario";
import Table from "../../components/UI/Table/Table";
import Spinner from "../../components/UI/Spinner/Spinner";

const FuncionariosScreen = props => {
    const dispatch = useDispatch();

    const funcionarios = useSelector((state) => state.funcionarios.funcionarios);

    const [isLoading, setIsLoading] = useState(false);
    const [copiaFuncionarios, setCopiaFuncionarios] = useState([]);
    const [funcionarioNoModal, setFuncionarioNoModal] = useState(false);
    const [busca, setBusca] = useState("");
    const [showModal, setShowModal] = useState(false);

    // Requisição dos funcionários
    useEffect(() => {
        const fetchFuncionarios = async () => {
            try {
                setIsLoading(true);
                await dispatch(funcionariosActions.fetchFuncionarios());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        fetchFuncionarios();
    }, [dispatch]);

    // Setando copiaFuncionarios
    useEffect(() => {
        setCopiaFuncionarios([...funcionarios]);
    }, [funcionarios]);

    const buscar = () => {
        let funcionariosFiltrados = [];
        if (busca) {
            funcionariosFiltrados = funcionarios.filter(funcionario =>
                Accents.remove(funcionario.nome.toLowerCase()).includes(busca)
            );
        } else {
            funcionariosFiltrados = [...funcionarios];
        };
        setCopiaFuncionarios(funcionariosFiltrados);
    };

    const handleModalFuncionario = (funcionario) => {
        if (!funcionario)
            setFuncionarioNoModal(false);
        else
            setFuncionarioNoModal({ ...funcionario });

        setShowModal(true);
    };

    return isLoading ? <Spinner /> : (
        <div>
            {showModal ?
                <ModalFuncionario
                    funcionario={funcionarioNoModal ? { ...funcionarioNoModal } : null}
                    onClose={() => setShowModal(false)} />
                : null}

            {funcionarios.length ? (
                <div>
                    <div className="row mb-2">
                        <div className="col-9">
                            <BarraPesquisa
                                name="funcionarios"
                                placeholder="Buscar por um Funcionario"
                                busca={busca}
                                onChange={(novaBusca) => {
                                    setBusca(Accents.remove(novaBusca));
                                    buscar();
                                }}
                                onBuscar={() => buscar()}
                            />
                        </div>
                        <div className="col-3 text-center">
                            <Button
                                color="green"
                                onClick={() => {
                                    setFuncionarioNoModal(false);
                                    setShowModal(true);
                                }}
                            >Cadastrar Funcionário</Button>
                        </div>
                    </div>
                    <div className="row" style={{ height: "84vh" }}>
                        <Table
                            header={["Código", "Nome", "Cargo", "Qnt. vendas este mês", "Valor de vendas este mês", "Média dos últimos 3 meses", "Qnt. total de vendas", "Valor total de vendas"]}
                            hoverble
                            bordered
                            fixed
                        >
                            {copiaFuncionarios.map(funcionario =>
                                <Funcionario
                                    key={funcionario.id}
                                    funcionario={funcionario}
                                    onClick={() => handleModalFuncionario(funcionario)} />)}
                        </Table>
                    </div>
                </div>
            ) : (
                <div className="row" style={{ height: "83vh" }}>
                    <div style={{ position: "relative", width: "100%", height: "90%" }}>
                        <div className={classes.Center}>
                            <h2>Você ainda não tem nenhum funcionário registrado!</h2>
                            <br />
                            <h4>Você pode cadastrar um funcionário clicando <Button onClick={() => setShowModal(true)}>Aqui</Button>.</h4>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default FuncionariosScreen;