import {
    FETCH_FUNCIONARIOS,
    FETCH_FUNCIONARIO,
    CREATE_FUNCIONARIO,
    UPDATE_FUNCIONARIO,
    DELETE_FUNCIONARIO
} from "../actions/funcionarios";

const initialState = {
    funcionarios: [],
    funcionario: {}
};

const funcionariosReducer = (state = initialState, action) => {
    let copiaFuncionarios = [...state.funcionarios];

    switch (action.type) {
        case FETCH_FUNCIONARIOS:
            return { ...state, funcionarios: action.funcionarios };
        case FETCH_FUNCIONARIO:
            return { ...state, funcionario: action.funcionario };
        case CREATE_FUNCIONARIO:
            copiaFuncionarios.push(action.funcionario);
            return { ...state, funcionarios: copiaFuncionarios }
        case UPDATE_FUNCIONARIO:
            copiaFuncionarios = copiaFuncionarios.map(funcionario =>
                funcionario.id === action.funcionario.id ? action.funcionario : funcionario);

            return { ...state, funcionarios: copiaFuncionarios }
        case DELETE_FUNCIONARIO:
            copiaFuncionarios = copiaFuncionarios.filter(funcionario => funcionario.id !== action.id)

            return { ...state, funcionarios: copiaFuncionarios }
        default:
            return state;
    }
};

export default funcionariosReducer;
