// Dependências
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../store/actions/anuncios";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Plataforma from "./Plataforma/Plataforma";
import Spinner from "../../UI/Spinner/Spinner";
import Titulo from "../../UI/Titulo/Titulo";

const SelecaoTiposAnuncio = props => {
    const dispatch = useDispatch();

    const plataformas = useSelector(state => state.anuncios.plataformas);

    const [isLoading, setIsLoading] = useState(false);
    const [copiaPlataformas, setCopiaPlataformas] = useState([]);

    // Requisição das plataformas
    useEffect(() => {
        const fetchPlataformas = async () => {
            setIsLoading(true);
            try {
                await dispatch(anunciosActions.fetchPlataformas());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        if (!plataformas.length)
            fetchPlataformas();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // Setando copiaPlataformas
    useEffect(() => {
        setCopiaPlataformas([...plataformas.map(plataforma => {
            return {
                ...plataforma,
                tiposAnuncio: plataforma.tiposAnuncio.map(tipoAnuncio => {
                    let tipoAnuncioSalvo = null;
                    // Busca pelos tiposAnuncio correspondente nas props para carregar os valores salvos anteriormente
                    props.plataformas.forEach(plataforma => {
                        tipoAnuncioSalvo = plataforma.tiposAnuncio.find(tipoAnuncioSalvo => tipoAnuncioSalvo.id === tipoAnuncio.id);
                    });

                    return {
                        ...tipoAnuncio,
                        selecionado: tipoAnuncio.obrigatorio ? true : tipoAnuncioSalvo ? tipoAnuncioSalvo.selecionado : false,
                        porcentagem: tipoAnuncioSalvo ? tipoAnuncioSalvo.porcentagem : tipoAnuncio.porcentagem,
                    };
                })
            };
        })]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plataformas]);

    return isLoading ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <Titulo>Selecione os anúncios que deseja gerar</Titulo>

            <div className="row">
                <div className="col-12">
                    {copiaPlataformas.filter(plataforma => plataforma.tiposAnuncio.length).map(plataforma =>
                        <Plataforma
                            key={plataforma.id}
                            plataforma={plataforma}
                            onChange={novaPlataforma => {
                                let plataformasAtualizadas = [...copiaPlataformas];
                                plataformasAtualizadas = plataformasAtualizadas.map(plataformaAtualizada =>
                                    plataformaAtualizada.id === novaPlataforma.id ? novaPlataforma : plataformaAtualizada);
                                setCopiaPlataformas(plataformasAtualizadas);
                            }} />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-5">
                    <span><strong>Conversão de preço:</strong> Caso deseje aumentar/diminuir o valor de cada peça de acordo com a modalidade do anúncio, ajuste acima.</span>
                    <p>Ex: Se você inseriu o preço R$ 50 para determinada peça na etapa anterior, ao selecionar a conversão de preço 100% no Clássico e 110% no Premium, os anúncios serão criados com preço de R$ 50 e R$ 55, respectivamente. Caso tenha alguma dúvida, clique no ícone de WhatsApp no menu esquerdo e contate nossa equipe. <span role="img" aria-label="">🙂</span></p>
                </div>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={() => props.onProxClick([...copiaPlataformas])} />
        </div>
    );
};

export default SelecaoTiposAnuncio;