import {
    SWITCH_DARK_MODE,
} from "../actions/color-palette.js";

const getPrefColorScheme = () => {
    if (JSON.parse(localStorage.getItem("darkMode")) !== true && JSON.parse(localStorage.getItem("darkMode")) !== false) {
        if (!window.matchMedia) {
            localStorage.setItem("darkMode", JSON.stringify(false));
            return false;
        };

        localStorage.setItem("darkMode", JSON.stringify(window.matchMedia("(prefers-color-scheme: dark)").matches));
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    } else
        return JSON.parse(localStorage.getItem("darkMode"));
};

const initialState = {
    darkMode: getPrefColorScheme()
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_DARK_MODE:
            return { ...state, darkMode: action.darkMode };
        default:
            return state;
    }
};
