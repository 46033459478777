// Dependências
import React from "react";
import { useDispatch } from "react-redux";
import { FaInfoCircle, FaTrash } from "react-icons/fa";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Input from "../../../UI/Input/Input";

// Css
import classes from "./Peca.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const Peca = props => {
    const dispatch = useDispatch();

    const deletePeca = async idPeca => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta peça? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(pecasActions.deletePeca(props.peca.id));
                    exibirMensagem.success("Peça excluída com sucesso.");
                    props.onDelete();
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    return (
        <tr className={!props.isValid ? classes.Invalid : ""}>
            <td className={classes.NomeArquetipo}>
                {props.peca.observacao?.length ? <FaInfoCircle className={classes.WarningIcon} /> : null}
                {props.peca.arquetipo.nome} {props.showSucata ? `- ${props.peca.sucata}` : ""}

                {props.peca.preco ?
                    <span
                        className={classes.TaxasButton}
                        onClick={props.onTaxasClick} >
                        <AiOutlineDollarCircle
                            size="18px"
                            className="mr-1" />
                        Calcular taxas
                    </span>
                    : null}
            </td>
            <td style={{ position: "relative" }}>
                <Input
                    light
                    onlyMoney
                    size="150px"
                    placeholder="Preço (R$)"
                    value={props.peca.preco}
                    onBlur={event => {
                        let pecaAtualizada = { ...props.peca };
                        pecaAtualizada.preco = parseInt(event.target.value);
                        props.onChange(pecaAtualizada);
                    }}
                />
                <BiCommentDetail
                    size="25px"
                    title="Adicionar uma observação"
                    className={classes.ObservacaoIcon}
                    onClick={() => {
                        exibirMensagem.exibir({
                            title: 'Digite uma observação',
                            text: 'Esta observação será incluída na descrição desta peça.',
                            input: 'textarea',
                            inputValue: props.peca.observacao,
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Salvar'
                        }).then(value => {
                            if (value.value || value.value === "")
                                props.onChange({ ...props.peca, observacao: value.value });
                        });
                    }} />

                <button className={classes.DeleteButton} onClick={deletePeca}>
                    <FaTrash title="Deletar peça" />
                </button>
            </td>
        </tr>
    );
};

export default Peca;