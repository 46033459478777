// Dependências
import React from "react";

// Css
import classes from "./TutorialScreen.module.css";

// Components
import BotaoWhatsapp from "../../components/Conhecer/BotaoWhatsapp/BotaoWhatsapp";
import Navbar from "../../components/Conhecer/Navbar/Navbar";
import Titulo from '../../components/UI/Titulo/Titulo';
const TutorialScreen = props => {
    return (
        <div className={classes.Conhecer}>
            <Navbar />
            <BotaoWhatsapp />

            <div className="row">
                <div className={`col-12 ${classes.Container1}`}>
                    <div>
                        <h1>Cadastro realizado!</h1>

                        <h2 className="h3 my-4">Recomendamos que assista aos tutoriais abaixo antes de iniciar :)</h2>
                        <p className="h5">Assim você vai dominar TODOS os recursos do sistema UnifiCAR. Em breve nossa equipe também entrará em contato para liberar seu acesso!</p>
                    </div>
                </div>
                <div className={`col-12 ${classes.Container2}`}>
                    <svg viewBox="0 0 1440 150">
                        <path
                            fill="#c34858"
                            d="M 10, 108
                            L 120, 99.975
                            C 240, 91.5, 480, 75.75, 720, 75.75
                            C 960, 75.75, 1200, 91.5, 1320, 99.975
                            L 1440, 108
                            L 1440, 0
                            L 1320, 0
                            C 1200, 0, 960, 0, 720, 0
                            C 480, 0, 240, 0, 120, 0
                            L 0,0
                            Z"
                        />
                    </svg>
                </div>
            </div>
            <div className={`col-12 ${classes.Container3}`}>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Tutorial #1 - Apresentação Geral</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/WkmDCHX7nVk"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Cadastrando até 250 peças no Mercado Livre de uma só vez - Aba "Cadastrar Sucata"</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/koH7QsP7aaM"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Financeiro - Saiba quais peças e sucatas estão te dando mais lucro!</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/5J0QhB_jYq0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Quando e como usar o "Cadastrar Sucata" e o "Grupo de Peças"</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/iu0jNEcCDzk"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Tutorial #2 - Recursos da aba "Início"</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/BlzkwJjSjWI"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Tutorial #3 - Utilizando as Respostas Padronizadas</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/E1LNrBMxLAw"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Tutorial #4 - Consultando seu Estoque de peças da maneira correta</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/eygbEdOgcVM"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Como vincular sua conta do Mercado Livre</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/h35Zz9ZlX0Q"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Tutorial Completo UnifiCAR</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/AJyMSzHvORY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorial"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TutorialScreen;