// Dependências
import React from "react";

// Components
import TipoAnuncio from "./TipoAnuncio/TipoAnuncio";
import Titulo from "../../../UI/Titulo/Titulo";

// Css
import classes from "./Plataforma.module.css";

const Plataforma = props => {
    return (
        <div className="mb-5">
            <Titulo className={classes.Titulo}>
                <img
                    className={classes.Imagem}
                    src={props.plataforma.imagem}
                    alt="Imagem do plataforma" />

                {props.plataforma.nome}
            </Titulo>

            {props.plataforma.tiposAnuncio.map(tipoAnuncio =>
                <TipoAnuncio
                    key={tipoAnuncio.id}
                    cor={props.plataforma.cor}
                    tipoAnuncio={tipoAnuncio}
                    onChange={novoTipoAnuncio => {
                        let tiposAnuncioAtualizados = [...props.plataforma.tiposAnuncio];
                        tiposAnuncioAtualizados = tiposAnuncioAtualizados.map(tipoAnuncioAtualizado =>
                            tipoAnuncioAtualizado.id === novoTipoAnuncio.id ? novoTipoAnuncio : tipoAnuncioAtualizado);
                        props.onChange({ ...props.plataforma, tiposAnuncio: [...tiposAnuncioAtualizados] });
                    }}
                />)}
        </div>
    );
};

export default Plataforma;