// Dependências
import React from "react";

// Css
import classes from "./Conta.module.css";

const Conta = props => {
    return (
        <tr className={props.highlight ? classes.Selected : null} onClick={props.onClick}>
            <td>{props.conta.id}</td>
            <td>{props.conta.nome}</td>
            <td>{props.conta.tipo.nome}</td>
        </tr>
    );
};

export default Conta;