// Dependências
import React from "react";

export const MercadoLivre = props => {
    return (
        <svg width="48.0pt" height="33.5pt" viewBox="0 0 630.000000 419.000000">
            <g transform="translate(0.000000, 400.000000) scale(0.100000,-0.100000)" fill={props.color || "#fff"}>
                <path d="M1510 4081 c0 -5 -16 -12 -35 -15 -19 -4 -35 -11 -35 -16 0 -6 -6
                    -10 -13 -10 -7 0 -19 -9 -27 -20 -8 -11 -20 -20 -27 -20 -7 0 -22 -8 -34 -17
                    -11 -10 -30 -26 -41 -35 -12 -10 -27 -18 -35 -18 -7 0 -13 -6 -13 -14 0 -8 -9
                    -18 -20 -21 -11 -3 -20 -11 -20 -16 0 -5 -6 -9 -14 -9 -8 0 -18 -9 -21 -20 -3
                    -11 -14 -27 -23 -37 -13 -13 -17 -33 -15 -95 1 -62 -1 -78 -13 -78 -8 0 -14
                    -4 -14 -10 0 -5 -7 -10 -15 -10 -9 0 -25 -9 -37 -20 -18 -17 -20 -27 -16 -92
                    3 -40 11 -74 17 -76 27 -9 -10 -72 -43 -72 -8 0 -18 -9 -21 -20 -3 -11 -13
                    -20 -21 -20 -8 0 -14 -4 -14 -10 0 -5 -5 -10 -10 -10 -6 0 -26 -16 -46 -35
                    -19 -19 -41 -35 -49 -35 -8 0 -15 -5 -15 -11 0 -6 -22 -33 -50 -60 -27 -27
                    -50 -52 -50 -58 0 -5 -9 -11 -20 -14 -11 -3 -20 -12 -20 -20 0 -9 -8 -18 -18
                    -21 -10 -3 -24 -15 -32 -27 -8 -12 -27 -35 -42 -51 -16 -17 -28 -39 -28 -49 0
                    -10 -6 -19 -14 -19 -12 0 -35 -34 -70 -107 -6 -13 -14 -23 -18 -23 -5 0 -8 -9
                    -8 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 -3 -15 -8 -15 -7 0 -27
                    -51 -39 -97 -3 -13 -9 -23 -14 -23 -5 0 -9 -11 -9 -24 0 -29 -19 -86 -30 -86
                    -4 0 -11 -34 -15 -75 -4 -41 -11 -75 -16 -75 -5 0 -9 -34 -9 -75 0 -43 -4 -75
                    -10 -75 -6 0 -10 -52 -10 -135 0 -83 4 -135 10 -135 6 0 10 -32 10 -75 0 -41
                    4 -75 9 -75 4 0 12 -36 15 -80 4 -44 11 -80 16 -80 5 0 14 -26 21 -57 7 -32
                    16 -60 19 -63 8 -6 40 -108 40 -126 0 -8 5 -14 10 -14 6 0 10 -7 10 -15 0 -8
                    5 -15 10 -15 6 0 10 -11 10 -25 0 -14 5 -25 10 -25 6 0 10 -7 10 -15 0 -8 4
                    -15 8 -15 10 0 42 -65 42 -85 0 -7 9 -17 20 -20 11 -3 20 -15 20 -25 0 -11 11
                    -30 23 -42 13 -13 27 -36 31 -51 3 -15 13 -27 21 -27 8 0 15 -4 15 -9 0 -5 18
                    -28 40 -51 22 -23 40 -47 40 -52 0 -10 25 -38 34 -38 4 0 38 -30 76 -67 91
                    -88 108 -103 119 -103 6 0 16 -9 23 -19 8 -11 26 -23 41 -26 15 -4 27 -12 27
                    -19 0 -6 16 -20 35 -30 19 -11 35 -23 35 -28 0 -4 7 -8 15 -8 8 0 15 -4 15
                    -10 0 -5 8 -10 18 -10 10 0 24 -9 32 -20 8 -10 27 -22 43 -26 15 -3 31 -12 33
                    -19 3 -7 21 -16 40 -19 19 -4 34 -10 34 -15 0 -11 84 -51 109 -51 11 0 21 -4
                    21 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 11 -10 25 -10 14 0 25 -4 25
                    -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -9 0 -5 21 -12 46 -16 25 -3 48 -13 50
                    -20 4 -8 30 -16 70 -20 35 -4 64 -11 64 -16 0 -5 14 -9 30 -9 17 0 30 -4 30
                    -8 0 -12 70 -32 109 -32 17 0 31 -4 31 -10 0 -5 11 -10 25 -10 43 0 165 -24
                    165 -32 0 -4 23 -8 50 -8 28 0 50 -4 50 -8 0 -5 46 -12 103 -15 56 -4 147 -11
                    202 -17 147 -14 467 -11 650 5 88 8 181 15 208 15 26 0 47 5 47 10 0 6 24 10
                    54 10 31 0 56 5 58 11 2 6 41 16 86 22 45 6 82 15 82 19 0 4 14 8 31 8 39 0
                    109 20 109 32 0 4 16 8 35 8 19 0 35 4 35 9 0 5 25 12 57 16 31 4 63 13 70 21
                    8 8 32 17 54 20 21 4 39 10 39 15 0 5 11 9 25 9 14 0 25 5 25 10 0 6 9 10 20
                    10 11 0 20 5 20 10 0 6 9 10 20 10 11 0 20 4 20 8 0 5 23 15 50 22 28 8 59 22
                    70 32 11 10 33 21 50 24 16 4 30 10 30 14 0 4 15 13 33 21 17 7 39 21 48 31 9
                    10 24 18 33 18 9 0 16 5 16 10 0 6 7 10 15 10 8 0 15 4 15 9 0 5 8 12 18 15
                    10 4 34 20 52 36 19 17 37 30 42 30 4 0 16 8 26 17 11 10 32 27 47 38 15 11
                    41 32 58 48 16 15 33 27 37 27 5 0 12 9 15 20 3 11 15 20 26 20 10 0 19 4 19
                    9 0 5 18 28 40 51 22 23 40 45 40 50 0 4 19 28 43 53 59 65 66 73 79 102 6 14
                    15 25 20 25 4 0 8 9 8 19 0 10 7 21 15 25 8 3 15 14 15 23 0 10 9 25 20 33 11
                    8 20 22 20 32 0 10 4 18 8 18 4 0 16 25 27 55 10 30 22 55 26 55 4 0 10 18 14
                    40 4 22 13 47 21 54 7 8 16 32 20 55 4 23 10 41 13 41 11 0 29 80 37 156 4 38
                    10 71 14 74 11 7 30 195 30 288 0 96 -21 282 -32 282 -4 0 -8 15 -8 33 0 54
                    -20 151 -34 162 -7 6 -16 28 -19 49 -3 22 -10 42 -15 45 -5 3 -15 23 -23 44
                    -7 20 -17 37 -21 37 -4 0 -8 7 -8 15 0 20 -32 85 -42 85 -4 0 -8 8 -8 18 0 10
                    -9 24 -20 32 -11 8 -20 20 -20 27 0 7 -11 23 -25 37 -14 14 -25 30 -25 35 0
                    11 -26 45 -66 86 -12 12 -25 31 -28 43 -4 12 -14 22 -22 22 -8 0 -14 4 -14 9
                    0 10 -55 71 -64 71 -4 0 -21 16 -39 35 -17 19 -38 35 -44 35 -7 0 -13 5 -13
                    10 0 6 -11 16 -25 22 -13 6 -27 19 -30 30 -4 10 -14 18 -23 18 -10 0 -24 9
                    -32 21 -8 11 -28 27 -45 35 -65 32 -67 35 -49 93 20 69 16 113 -13 126 -13 5
                    -23 13 -23 17 0 5 -7 8 -15 8 -8 0 -15 5 -15 10 0 6 -9 10 -21 10 -16 0 -19 4
                    -14 18 15 39 17 84 4 127 -7 25 -17 45 -21 45 -3 0 -10 9 -13 20 -3 11 -12 20
                    -18 20 -7 0 -20 8 -29 18 -37 38 -55 52 -66 52 -12 0 -38 19 -78 58 -13 12
                    -30 22 -38 22 -8 0 -17 9 -21 19 -4 14 -19 22 -51 27 -24 3 -44 10 -44 15 0 5
                    -16 9 -35 9 -19 0 -35 -4 -35 -9 0 -5 -18 -12 -39 -16 -31 -5 -42 -13 -51 -36
                    -7 -16 -17 -29 -22 -29 -9 0 -60 -51 -138 -139 -39 -43 -50 -50 -82 -51 -21 0
                    -38 5 -38 10 0 6 -22 10 -50 10 -27 0 -50 5 -50 10 0 6 -18 10 -40 10 -22 0
                    -40 5 -40 10 0 6 -21 10 -47 10 -27 0 -86 9 -133 20 -47 11 -101 20 -122 20
                    -20 0 -38 6 -40 13 -6 16 -805 18 -816 1 -7 -11 -255 -54 -309 -54 -18 0 -33
                    -4 -33 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -20 -10 -45 -10
                    -25 0 -45 -4 -45 -10 0 -5 -22 -10 -50 -10 -27 0 -50 -4 -50 -10 0 -18 -25
                    -11 -40 11 -14 20 -38 46 -72 75 -7 7 -28 30 -47 53 -19 23 -40 41 -47 41 -7
                    0 -16 9 -19 20 -3 11 -12 20 -20 20 -8 0 -16 9 -20 19 -4 13 -19 22 -45 26
                    -22 4 -40 11 -40 16 0 5 -16 9 -35 9 -19 0 -35 -4 -35 -9z m104 -126 c10 -14
                    24 -25 31 -25 7 0 16 -11 19 -24 3 -14 15 -27 26 -31 10 -3 22 -15 25 -26 4
                    -10 13 -19 21 -19 8 0 14 -4 14 -9 0 -5 29 -39 65 -76 36 -36 65 -71 65 -76 0
                    -5 7 -9 15 -9 8 0 15 -4 15 -10 0 -5 14 -23 30 -38 17 -16 30 -34 30 -40 0 -6
                    9 -14 20 -17 11 -3 20 -13 20 -21 0 -8 10 -26 23 -39 38 -42 47 -54 47 -67 0
                    -8 9 -20 20 -28 11 -8 20 -20 20 -26 0 -7 6 -19 14 -26 16 -17 46 -73 46 -88
                    0 -5 9 -19 20 -30 11 -11 20 -27 20 -35 0 -8 4 -15 8 -15 4 0 14 -16 21 -35 7
                    -19 16 -35 21 -35 4 0 11 -16 14 -35 4 -19 11 -35 16 -35 6 0 10 -11 10 -25 0
                    -14 4 -25 9 -25 5 0 13 -14 16 -32 4 -17 14 -38 21 -46 8 -7 17 -25 20 -40 9
                    -43 26 -82 35 -82 4 0 11 -20 15 -45 3 -25 10 -45 15 -45 5 0 9 -9 9 -20 0
                    -11 5 -20 10 -20 6 0 10 -11 10 -24 0 -13 3 -26 8 -28 9 -4 42 -100 42 -122 0
                    -9 4 -16 8 -16 4 0 14 -20 21 -45 7 -25 17 -45 21 -45 4 0 11 -20 14 -45 4
                    -25 11 -45 17 -45 5 0 9 -11 9 -25 0 -14 4 -25 9 -25 6 0 13 -21 17 -46 4 -26
                    15 -55 25 -65 11 -11 19 -28 19 -39 0 -11 5 -20 10 -20 6 0 10 -9 10 -20 0
                    -11 8 -28 19 -39 10 -10 21 -39 25 -65 4 -25 11 -46 17 -46 5 0 9 -9 9 -20 0
                    -11 4 -20 9 -20 5 0 13 -25 16 -55 4 -30 11 -55 16 -55 10 0 29 -66 29 -102 0
                    -16 5 -28 10 -28 6 0 10 -37 10 -90 0 -49 -4 -90 -9 -90 -5 0 -11 -10 -15 -23
                    -6 -26 -38 -67 -52 -67 -5 0 -16 -9 -24 -20 -9 -13 -31 -21 -67 -26 -29 -3
                    -53 -10 -53 -14 0 -5 -26 -12 -57 -16 -32 -4 -61 -12 -65 -18 -10 -15 -1176
                    -15 -1186 0 -4 6 -34 14 -67 18 -32 4 -62 10 -65 15 -3 5 -49 12 -102 16 -54
                    4 -98 11 -98 16 0 5 -16 9 -35 9 -19 0 -35 5 -35 10 0 6 -29 10 -66 10 -55 0
                    -70 -4 -85 -21 -22 -26 -99 -39 -99 -18 0 8 -9 21 -20 29 -11 8 -20 22 -20 32
                    0 9 -11 28 -24 41 -13 13 -27 34 -31 46 -4 13 -16 32 -26 42 -10 10 -19 26
                    -19 35 0 8 -11 35 -25 60 -14 25 -25 54 -25 64 0 11 -4 20 -10 20 -5 0 -10 11
                    -10 25 0 14 -4 25 -10 25 -5 0 -10 11 -10 25 0 14 -4 25 -8 25 -9 0 -42 170
                    -42 217 0 17 3 44 6 61 6 28 11 31 70 43 35 7 64 16 64 21 0 4 52 8 115 8 63
                    0 115 -4 115 -9 0 -8 56 -14 238 -28 45 -3 82 -10 82 -14 0 -5 25 -9 55 -9 30
                    0 55 -4 55 -10 0 -5 23 -10 50 -10 28 0 50 -4 50 -10 0 -5 16 -10 35 -10 19 0
                    35 -4 35 -9 0 -5 30 -12 67 -16 36 -4 72 -13 80 -20 7 -8 33 -17 58 -21 25 -3
                    45 -10 45 -15 0 -5 16 -9 35 -9 19 0 35 -4 35 -10 0 -5 11 -10 25 -10 14 0 25
                    -4 25 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 24 0 70 -21
                    70 -32 0 -4 11 -8 25 -8 14 0 25 -4 25 -9 0 -5 19 -12 42 -16 23 -4 48 -14 56
                    -22 8 -8 27 -17 43 -20 16 -3 29 -9 29 -14 0 -5 9 -9 20 -9 11 0 20 -4 20 -10
                    0 -5 5 -10 10 -10 6 0 10 9 10 20 0 11 -4 20 -9 20 -5 0 -13 25 -16 55 -4 30
                    -11 55 -16 55 -12 0 -29 69 -29 123 0 26 -4 47 -10 47 -6 0 -10 119 -10 338 0
                    185 -5 366 -10 402 -6 36 -13 89 -17 118 -3 28 -10 52 -14 52 -5 0 -9 14 -9
                    30 0 17 -4 30 -10 30 -5 0 -10 11 -10 25 0 14 -4 25 -9 25 -5 0 -11 23 -15 50
                    -4 28 -11 50 -16 50 -5 0 -13 12 -16 27 -13 51 -50 103 -73 103 -6 0 -11 5
                    -11 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25 -10 0 -5 -25 -10 -55 -10 -30 0
                    -55 5 -55 10 0 6 -16 10 -35 10 -19 0 -35 5 -35 10 0 6 -11 10 -25 10 -14 0
                    -25 5 -25 10 0 6 -10 10 -23 10 -13 0 -43 9 -66 21 -24 11 -59 23 -77 26 -19
                    3 -34 10 -34 14 0 5 -18 9 -40 9 -22 0 -40 4 -40 9 0 5 -33 15 -74 22 -62 11
                    -76 17 -85 36 -16 36 6 53 68 53 28 0 51 -4 51 -10 0 -5 9 -10 20 -10 12 0 30
                    -9 42 -20 12 -11 30 -20 40 -20 10 0 18 -4 18 -10 0 -13 77 -13 82 0 2 5 28
                    12 58 16 50 5 58 4 83 -19 15 -15 27 -29 27 -32 0 -21 85 -75 118 -75 13 0 22
                    6 22 15 0 20 -58 85 -129 145 -22 19 -41 39 -41 45 0 6 -9 21 -20 33 -20 21
                    -29 92 -11 92 5 0 11 11 13 25 7 32 -10 32 -48 0 -37 -32 -54 -29 -68 14 -17
                    53 -36 51 -36 -3 0 -32 -5 -48 -17 -54 -15 -9 -15 -11 -1 -26 9 -8 18 -21 21
                    -28 3 -9 11 -6 24 10 38 42 63 24 63 -43 0 -31 -3 -35 -36 -41 -66 -12 -118 0
                    -153 36 -17 18 -31 37 -31 41 0 5 -7 9 -15 9 -8 0 -17 11 -21 25 -3 14 -10 25
                    -15 25 -5 0 -9 6 -9 14 0 8 -6 17 -12 20 -10 5 -10 7 0 12 6 3 12 12 12 20 0
                    8 4 14 9 14 5 0 13 9 16 20 3 11 13 20 21 20 8 0 14 5 14 10 0 6 6 10 13 10 7
                    0 19 9 27 20 8 11 20 20 27 20 7 0 26 14 43 30 17 17 34 30 39 30 4 0 20 11
                    35 25 21 19 38 25 74 25 39 0 51 -5 66 -25z m2762 0 c15 -14 33 -25 40 -25 8
                    0 14 -6 14 -13 0 -8 18 -22 40 -32 22 -10 42 -26 45 -36 4 -11 13 -19 21 -19
                    8 0 14 -4 14 -10 0 -5 5 -10 10 -10 18 0 70 -60 70 -81 0 -39 -29 -80 -110
                    -151 -25 -22 -80 -32 -134 -24 -49 8 -61 24 -44 61 9 19 19 25 45 25 18 0 33
                    -5 33 -10 0 -6 12 1 26 16 22 21 24 28 13 35 -8 5 -17 9 -21 9 -5 0 -8 12 -8
                    28 0 15 -5 34 -10 42 -8 13 -11 12 -24 -5 -9 -11 -16 -28 -16 -37 0 -26 -23
                    -22 -48 7 -27 31 -62 34 -62 5 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 5
                    -15 10 -15 6 0 10 -9 10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -6 -10 -14 0 -8
                    -27 -42 -60 -76 -33 -34 -60 -65 -60 -70 0 -5 -9 -12 -20 -15 -11 -3 -20 -13
                    -20 -20 0 -8 -9 -23 -21 -34 -24 -22 -18 -41 13 -41 31 0 118 56 118 76 0 5
                    12 19 28 32 23 21 34 23 77 18 27 -3 51 -10 53 -16 4 -12 92 -14 92 -2 0 4 16
                    14 35 21 19 7 35 17 35 22 0 5 14 9 30 9 17 0 30 5 30 10 0 6 20 10 45 10 25
                    0 45 -4 45 -10 0 -5 7 -10 15 -10 21 0 19 -7 -9 -35 -18 -18 -45 -28 -95 -37
                    -39 -7 -71 -16 -71 -20 0 -4 -18 -8 -40 -8 -22 0 -40 -4 -40 -10 0 -5 -10 -10
                    -22 -10 -13 0 -47 -11 -76 -25 -30 -14 -60 -25 -68 -25 -8 0 -14 -4 -14 -10 0
                    -5 -11 -10 -25 -10 -14 0 -25 -4 -25 -10 0 -5 -16 -10 -35 -10 -19 0 -35 -4
                    -35 -10 0 -5 -25 -10 -55 -10 -30 0 -55 5 -55 10 0 6 -11 10 -25 10 -14 0 -25
                    -4 -25 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -8 0 -5 -11 -22 -24 -37 -13
                    -15 -26 -37 -29 -49 -3 -12 -14 -46 -26 -76 -12 -30 -21 -63 -21 -72 0 -10 -4
                    -18 -10 -18 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 -18 -10 -40
                    0 -22 -4 -40 -9 -40 -16 0 -21 -80 -29 -489 -6 -295 -12 -406 -20 -409 -7 -2
                    -12 -17 -12 -33 0 -36 -20 -122 -30 -129 -4 -3 -10 -30 -14 -60 -3 -30 -3 -49
                    0 -42 3 6 14 12 25 12 10 0 19 4 19 8 0 4 20 13 45 20 25 7 45 16 45 21 0 5
                    18 12 40 16 22 4 40 11 40 16 0 5 9 9 20 9 11 0 20 3 20 8 0 8 71 32 95 32 8
                    0 15 5 15 10 0 6 11 10 25 10 14 0 25 5 25 10 0 6 11 10 25 10 14 0 25 5 25
                    10 0 6 14 10 30 10 17 0 30 4 30 8 0 4 27 14 60 20 33 7 60 17 60 22 0 4 29
                    12 65 15 36 4 65 11 65 16 0 5 20 9 45 9 25 0 45 5 45 10 0 6 20 10 45 10 25
                    0 45 5 45 10 0 6 23 10 50 10 28 0 50 5 50 10 0 6 17 10 38 10 64 0 242 23
                    242 32 0 4 86 8 190 8 177 0 192 -1 212 -20 12 -11 28 -20 35 -20 9 0 13 -15
                    13 -45 0 -25 -4 -45 -9 -45 -5 0 -11 -21 -14 -47 -17 -142 -20 -163 -27 -163
                    -4 0 -10 -15 -13 -32 -10 -59 -31 -128 -39 -128 -5 0 -8 -9 -8 -20 0 -11 -4
                    -20 -10 -20 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -19 0
                    -11 -7 -26 -15 -35 -8 -8 -15 -22 -15 -30 0 -9 -4 -16 -10 -16 -5 0 -10 -5
                    -10 -10 0 -10 -8 -22 -37 -55 -7 -7 -13 -19 -13 -25 0 -6 -11 -22 -25 -36 -14
                    -14 -25 -29 -25 -34 0 -6 -8 -18 -18 -28 -15 -15 -21 -16 -30 -6 -7 7 -12 18
                    -12 23 0 5 -9 13 -20 16 -11 3 -20 11 -20 16 0 5 -7 9 -15 9 -8 0 -15 5 -15
                    10 0 6 -27 10 -60 10 -33 0 -60 -4 -60 -10 0 -5 -24 -10 -54 -10 -30 0 -56 -4
                    -58 -9 -1 -6 -54 -13 -115 -17 -62 -4 -113 -10 -113 -14 0 -4 -30 -11 -67 -15
                    -38 -4 -71 -12 -75 -19 -10 -15 -1086 -16 -1096 0 -4 6 -36 14 -71 18 -36 3
                    -67 10 -70 15 -3 4 -33 11 -66 14 -33 3 -61 11 -63 17 -2 5 -9 10 -16 10 -6 0
                    -18 10 -26 21 -8 12 -20 27 -26 33 -26 27 -34 60 -34 140 0 88 16 186 30 186
                    4 0 11 25 15 55 3 30 11 55 16 55 5 0 9 14 9 30 0 17 4 30 9 30 5 0 12 18 16
                    40 4 22 11 40 16 40 5 0 9 9 9 19 0 11 5 23 10 26 6 3 10 15 10 26 0 10 4 19
                    9 19 5 0 11 13 14 29 3 16 13 35 21 44 9 8 18 33 22 56 4 23 10 41 15 41 5 0
                    9 9 9 20 0 11 4 20 9 20 5 0 12 19 16 42 4 23 14 48 22 56 8 8 17 27 20 43 3
                    16 9 29 14 29 5 0 9 11 9 25 0 14 4 25 10 25 5 0 12 23 16 50 4 28 10 50 15
                    50 5 0 9 7 9 15 0 8 5 15 10 15 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 9 10
                    21 0 12 11 43 25 71 14 27 25 60 25 72 0 12 9 32 19 43 11 11 23 37 26 57 4
                    20 11 36 16 36 5 0 9 9 9 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 5 20 10 20
                    6 0 10 8 10 18 0 9 7 26 15 36 8 11 15 28 15 38 0 10 4 18 9 18 5 0 11 13 14
                    29 3 16 12 35 20 43 8 8 18 24 21 36 4 12 12 22 17 22 5 0 9 9 9 20 0 11 5 20
                    10 20 6 0 10 8 10 19 0 10 11 31 25 47 31 36 35 41 59 83 10 18 45 60 77 93
                    33 33 59 64 59 69 0 5 6 9 14 9 8 0 18 9 21 20 3 11 10 20 14 20 10 0 51 35
                    51 43 0 4 14 20 30 37 17 17 30 35 30 40 0 6 7 10 15 10 8 0 15 4 15 8 0 5 11
                    22 25 38 14 16 28 39 31 52 4 12 11 22 18 22 6 0 22 12 35 25 33 36 109 35
                    147 0z m-1126 -135 c0 -6 33 -10 80 -10 47 0 80 -4 80 -10 0 -5 21 -10 46 -10
                    59 0 184 -21 184 -31 0 -4 47 -10 105 -14 79 -6 105 -11 105 -21 0 -8 5 -14
                    10 -14 6 0 -4 -16 -22 -34 -47 -50 -58 -64 -58 -76 0 -5 -4 -10 -10 -10 -5 0
                    -10 -6 -10 -14 0 -8 -9 -18 -20 -21 -11 -3 -23 -17 -26 -31 -3 -13 -10 -24
                    -15 -24 -5 0 -9 -6 -9 -14 0 -8 -7 -16 -15 -20 -8 -3 -15 -10 -15 -16 0 -6 -9
                    -20 -20 -32 -11 -12 -20 -28 -20 -35 0 -7 -4 -13 -8 -13 -4 0 -16 -22 -27 -50
                    -11 -27 -23 -50 -27 -50 -4 0 -8 -9 -8 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9
                    -10 -20 0 -11 -4 -20 -9 -20 -5 0 -12 -16 -16 -36 -3 -20 -15 -46 -26 -57 -10
                    -11 -19 -27 -19 -35 0 -8 -11 -36 -25 -64 -14 -27 -25 -61 -25 -74 0 -13 -4
                    -24 -10 -24 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10 -20 0
                    -11 -4 -20 -10 -20 -5 0 -12 -22 -16 -50 -4 -27 -10 -50 -15 -50 -5 0 -9 -7
                    -9 -15 0 -8 -4 -15 -8 -15 -4 0 -14 -20 -21 -45 -7 -25 -17 -45 -21 -45 -5 0
                    -12 -20 -16 -45 -3 -25 -10 -45 -15 -45 -5 0 -9 -9 -9 -20 0 -11 -4 -20 -9
                    -20 -5 0 -12 -20 -15 -44 -4 -24 -13 -47 -21 -50 -8 -3 -18 -23 -21 -46 -4
                    -22 -10 -40 -14 -40 -4 0 -10 -13 -13 -29 -3 -16 -12 -35 -20 -43 -8 -8 -18
                    -33 -22 -56 -4 -23 -11 -42 -16 -42 -5 0 -9 -9 -9 -20 0 -11 -4 -20 -9 -20 -4
                    0 -11 -19 -14 -42 -3 -24 -10 -44 -16 -46 -5 -2 -15 -25 -22 -53 -7 -27 -16
                    -49 -21 -49 -4 0 -8 -13 -8 -28 0 -16 -7 -37 -15 -48 -8 -10 -15 -31 -15 -46
                    0 -21 -5 -28 -20 -28 -13 0 -20 7 -20 20 0 11 -4 20 -9 20 -5 0 -12 20 -15 45
                    -4 25 -11 45 -17 45 -5 0 -9 14 -9 30 0 17 -4 30 -8 30 -4 0 -14 20 -21 45 -7
                    25 -17 45 -21 45 -5 0 -11 18 -15 40 -4 22 -11 40 -16 40 -5 0 -9 11 -9 25 0
                    14 -4 25 -9 25 -5 0 -12 18 -16 40 -4 22 -10 40 -14 40 -11 0 -31 47 -31 70 0
                    11 -4 20 -10 20 -5 0 -10 10 -10 23 0 13 -8 32 -19 43 -10 11 -21 41 -25 67
                    -4 26 -11 47 -17 47 -5 0 -9 7 -9 15 0 8 -4 15 -9 15 -5 0 -11 23 -15 50 -4
                    28 -10 50 -15 50 -11 0 -31 46 -31 70 0 11 -4 20 -9 20 -5 0 -11 10 -14 23
                    -12 46 -32 97 -39 97 -5 0 -8 9 -8 20 0 11 -4 20 -10 20 -5 0 -10 11 -10 25 0
                    14 -4 25 -9 25 -5 0 -12 16 -16 36 -3 20 -15 45 -25 56 -10 11 -22 38 -25 59
                    -4 22 -13 46 -22 54 -8 8 -17 31 -20 50 -3 19 -10 35 -14 35 -5 0 -9 5 -9 11
                    0 6 -7 17 -15 25 -8 9 -15 24 -15 35 0 10 -4 19 -9 19 -5 0 -15 16 -22 35 -7
                    19 -16 35 -20 35 -4 0 -12 14 -19 30 -7 17 -15 30 -19 30 -4 0 -15 17 -26 37
                    -26 49 -46 73 -62 73 -7 0 -13 4 -13 8 0 8 -11 23 -50 69 -5 7 -10 19 -10 28
                    0 8 -4 15 -10 15 -18 0 -10 29 10 35 11 3 20 10 20 15 0 4 29 11 65 15 36 3
                    65 10 65 14 0 10 148 31 218 31 28 0 52 5 52 10 0 6 38 10 95 10 57 0 95 4 95
                    10 0 6 108 10 305 10 197 0 305 -4 305 -10z m-1990 -470 c0 -5 18 -10 40 -10
                    22 0 40 -4 40 -10 0 -5 11 -10 25 -10 14 0 25 -4 25 -10 0 -5 11 -10 25 -10
                    14 0 25 -4 25 -10 0 -5 13 -10 28 -10 16 0 38 -9 49 -19 13 -13 43 -22 82 -27
                    34 -3 61 -11 61 -15 0 -5 38 -9 85 -9 69 0 85 -3 85 -15 0 -8 5 -15 10 -15 6
                    0 10 -7 10 -15 0 -8 4 -15 9 -15 5 0 12 -24 15 -52 4 -29 12 -56 18 -59 6 -4
                    19 -46 29 -95 11 -48 24 -91 31 -95 8 -5 12 -117 14 -420 1 -293 5 -414 13
                    -417 6 -2 11 -24 11 -48 0 -32 -4 -44 -15 -44 -8 0 -15 5 -15 10 0 6 -11 10
                    -25 10 -14 0 -25 5 -25 10 0 6 -10 10 -22 10 -27 0 -88 22 -88 31 0 4 -20 11
                    -45 15 -25 3 -45 10 -45 15 0 5 -11 9 -25 9 -14 0 -25 5 -25 10 0 6 -13 10
                    -30 10 -16 0 -30 5 -30 10 0 6 -11 10 -25 10 -14 0 -25 5 -25 10 0 6 -11 10
                    -25 10 -30 0 -105 22 -105 31 0 4 -35 10 -77 14 -43 4 -80 11 -83 15 -9 13
                    -233 50 -302 50 -39 0 -68 4 -68 10 0 6 -43 10 -110 10 -67 0 -110 -4 -110
                    -10 0 -6 -50 -10 -130 -10 l-130 0 0 28 c0 48 23 182 32 182 4 0 8 14 8 30 0
                    17 5 30 10 30 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 9 10 20 0 11 4 28 9 38
                    6 9 21 43 35 75 14 31 29 57 34 57 5 0 12 16 15 35 3 19 17 46 31 60 14 14 26
                    30 26 35 0 4 16 26 35 47 58 63 65 73 65 83 0 6 7 13 15 16 8 4 15 10 15 15 0
                    14 33 49 47 49 6 0 15 9 18 20 3 11 11 20 16 20 5 0 29 19 53 43 68 66 114
                    107 120 107 3 0 15 8 25 18 35 31 50 42 58 42 5 0 15 9 23 20 8 11 22 20 32
                    20 10 0 18 5 18 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m3450 0 c0 -5 8 -10
                    18 -10 10 0 24 -9 32 -20 8 -11 20 -20 27 -20 7 0 13 -4 13 -10 0 -5 7 -10 15
                    -10 8 0 20 -9 27 -20 7 -11 17 -20 23 -20 6 0 18 -8 26 -17 9 -10 26 -25 39
                    -33 12 -8 28 -21 34 -28 50 -57 65 -72 71 -72 10 0 105 -100 105 -110 0 -5 16
                    -26 35 -47 67 -73 75 -82 75 -93 0 -5 3 -10 8 -10 4 0 16 -18 26 -40 11 -22
                    23 -40 28 -40 4 0 8 -9 8 -20 0 -11 4 -20 10 -20 5 0 12 -16 16 -35 3 -19 10
                    -35 15 -35 5 0 9 -9 9 -20 0 -11 5 -20 10 -20 6 0 10 -7 10 -15 0 -8 5 -15 10
                    -15 6 0 10 -11 10 -24 0 -14 4 -27 9 -30 5 -4 12 -24 16 -46 4 -22 10 -40 15
                    -40 4 0 10 -19 13 -42 18 -135 19 -157 8 -174 -6 -11 -11 -13 -11 -6 0 15
                    -510 18 -510 2 0 -6 -28 -10 -62 -10 -75 -1 -258 -34 -267 -48 -3 -6 -43 -13
                    -89 -17 -45 -4 -82 -11 -82 -15 0 -9 -70 -30 -99 -30 -11 0 -21 -4 -21 -10 0
                    -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -4
                    -25 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -4 -25 -9 0 -5 -23 -12 -52 -16 -28
                    -4 -58 -14 -66 -21 -7 -8 -31 -17 -53 -20 -21 -4 -39 -10 -39 -15 0 -5 -13 -9
                    -30 -9 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -17 -10 -15 0 -15 13 -7 138 5 75 9
                    263 9 417 0 235 3 293 18 360 26 112 39 155 47 155 4 0 10 23 14 50 4 28 11
                    50 16 50 6 0 10 14 10 30 0 17 5 30 10 30 6 0 10 11 10 24 0 30 16 36 96 36
                    35 0 64 4 64 9 0 4 29 11 65 14 37 4 72 13 80 21 8 8 28 17 45 21 16 4 30 11
                    30 16 0 5 14 9 30 9 17 0 30 5 30 10 0 6 11 10 25 10 14 0 25 5 25 10 0 6 18
                    10 40 10 22 0 40 5 40 10 0 6 28 10 65 10 37 0 65 -4 65 -10z m-1760 -2015 c0
                    -9 11 -26 25 -39 14 -13 25 -28 25 -35 0 -6 9 -11 20 -11 11 0 23 -7 26 -15 4
                    -8 17 -15 30 -15 13 0 24 -4 24 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0
                    -6 35 -10 85 -10 47 0 85 -4 85 -8 0 -5 57 -12 128 -15 299 -16 362 -20 362
                    -28 0 -5 65 -9 145 -9 80 0 145 4 145 9 0 8 38 11 313 28 59 3 107 10 107 14
                    0 5 32 9 70 9 40 0 70 4 70 10 0 6 20 10 45 10 25 0 45 5 45 10 0 6 20 10 44
                    10 24 0 46 4 48 10 5 15 180 18 185 3 3 -7 11 -13 18 -13 21 0 29 -39 14 -69
                    -8 -14 -29 -39 -48 -56 -19 -16 -52 -47 -72 -67 -21 -21 -45 -38 -54 -38 -8 0
                    -15 -5 -15 -10 0 -6 -11 -15 -25 -20 -14 -5 -25 -14 -25 -19 0 -5 -10 -13 -22
                    -17 -23 -7 -40 -19 -66 -46 -9 -10 -24 -18 -34 -18 -10 0 -18 -4 -18 -10 0 -5
                    -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 -8 -10 -18 -10 -10 0 -24 -9 -32 -20
                    -8 -10 -27 -22 -43 -26 -15 -3 -31 -12 -33 -19 -3 -7 -21 -16 -40 -19 -19 -4
                    -34 -11 -34 -16 0 -6 -11 -10 -25 -10 -14 0 -25 -4 -25 -9 0 -5 -15 -14 -32
                    -22 -18 -7 -35 -16 -38 -19 -3 -3 -24 -12 -47 -20 -24 -7 -43 -17 -43 -21 0
                    -4 -20 -11 -45 -15 -25 -3 -45 -10 -45 -14 0 -9 -119 -50 -145 -50 -8 0 -15
                    -4 -15 -10 0 -5 -18 -10 -40 -10 -22 0 -40 -4 -40 -10 0 -5 -13 -10 -30 -10
                    -16 0 -30 -4 -30 -10 0 -5 -18 -10 -40 -10 -22 0 -40 -4 -40 -10 0 -5 -13 -10
                    -30 -10 -39 0 -134 -21 -140 -30 -3 -5 -64 -11 -135 -15 -72 -4 -132 -11 -135
                    -15 -7 -10 -282 -30 -413 -30 -127 0 -410 20 -417 30 -3 4 -57 10 -120 14 -63
                    4 -117 11 -120 15 -6 8 -125 31 -164 31 -14 0 -26 5 -26 10 0 6 -18 10 -40 10
                    -22 0 -40 5 -40 10 0 6 -11 10 -25 10 -14 0 -25 5 -25 10 0 6 -18 10 -40 10
                    -22 0 -40 5 -40 10 0 6 -9 10 -19 10 -22 0 -133 40 -141 51 -3 4 -26 10 -52
                    14 -27 4 -48 11 -48 15 0 5 -16 14 -35 21 -19 7 -35 17 -35 21 0 4 -9 8 -20 8
                    -24 0 -70 21 -70 32 0 4 -9 8 -20 8 -11 0 -20 4 -20 9 0 5 -14 13 -32 16 -17
                    4 -40 16 -52 26 -11 11 -28 19 -38 19 -10 0 -18 5 -18 10 0 6 -7 10 -15 10 -8
                    0 -15 5 -15 10 0 6 -9 10 -19 10 -11 0 -23 9 -26 20 -4 11 -15 20 -26 20 -11
                    0 -36 16 -56 35 -20 19 -44 35 -55 35 -10 0 -18 7 -18 15 0 9 -8 19 -17 22
                    -10 4 -25 15 -34 25 -9 10 -21 18 -27 18 -6 0 -14 9 -17 20 -3 11 -14 20 -23
                    20 -10 0 -24 10 -32 21 -8 12 -21 27 -28 33 -80 70 -93 93 -72 126 7 12 27 14
                    92 12 46 -1 85 -7 86 -12 2 -6 19 -10 38 -10 19 0 34 -4 34 -10 0 -5 25 -10
                    55 -10 30 0 55 -4 55 -10 0 -6 27 -10 60 -10 33 0 60 -4 60 -9 0 -14 269 -26
                    585 -26 329 0 655 13 655 27 0 4 36 8 80 8 47 0 80 4 80 10 0 6 16 10 35 10
                    19 0 35 5 35 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 6 10 13 10 14 0 57 33 57
                    43 0 4 12 20 28 36 29 32 62 40 62 16z"/>
            </g>
        </svg>
    );
};

export const Cifrao = props => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="35.000000pt" height="35.000000pt" viewBox="0 0 799.000000 799.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,799.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M5961 6534 c-134 -36 -255 -137 -314 -261 -27 -59 -32 -82 -35 -166
                    -4 -90 -6 -98 -26 -102 -215 -47 -338 -86 -481 -154 -311 -148 -547 -389 -645
                    -659 -47 -129 -62 -212 -67 -372 -22 -630 365 -1064 1100 -1235 l117 -27 0
                    -418 0 -418 -47 14 c-89 27 -268 121 -409 216 -161 108 -221 131 -349 132
                    -145 1 -243 -36 -342 -128 -97 -90 -153 -229 -140 -351 13 -124 91 -256 206
                    -348 289 -231 596 -365 971 -426 l105 -17 6 -160 c4 -133 9 -168 28 -217 43
                    -108 126 -192 247 -249 60 -28 80 -32 177 -36 180 -8 282 26 393 130 71 67
                    117 149 134 237 5 29 10 118 10 197 0 115 3 144 14 144 20 0 209 64 294 99
                    315 131 587 377 712 644 62 131 91 258 97 425 12 300 -54 547 -199 744 -165
                    225 -467 391 -890 492 l-28 6 0 381 0 380 22 -7 c57 -18 157 -69 241 -123 127
                    -82 198 -105 307 -98 137 8 233 51 328 149 90 91 127 190 120 321 -7 145 -73
                    250 -232 371 -179 137 -455 262 -708 321 l-78 19 0 71 c0 99 -15 169 -50 240
                    -82 164 -241 256 -443 254 -51 0 -116 -7 -146 -15z m-351 -1734 c0 -159 -4
                    -290 -8 -290 -13 0 -125 69 -150 93 -69 64 -99 185 -72 283 17 60 92 141 162
                    176 29 15 56 27 61 28 4 0 7 -130 7 -290z m1068 -1600 c79 -117 68 -231 -33
                    -330 l-45 -44 0 227 0 228 24 -18 c13 -10 38 -38 54 -63z"/>
                <path d="M741 5974 c-142 -30 -274 -130 -334 -253 l-32 -66 0 -1730 0 -1730
                    27 -57 c50 -108 173 -209 310 -258 85 -30 221 -43 304 -30 168 27 294 136 344
                    298 19 60 20 94 20 593 l0 529 493 0 c270 0 535 -5 588 -10 156 -15 248 -61
                    303 -150 33 -52 44 -110 50 -255 17 -388 129 -654 354 -841 96 -79 176 -121
                    287 -149 109 -28 246 -33 337 -14 126 27 240 111 298 221 58 109 52 258 -16
                    374 -30 51 -115 132 -171 164 -56 31 -71 66 -88 208 -28 221 -46 337 -66 412
                    -38 142 -131 298 -247 416 l-51 52 113 113 c302 306 424 644 376 1039 -72 587
                    -560 1056 -1175 1129 -59 7 -417 11 -1025 10 -761 0 -947 -3 -999 -15z m1938
                    -899 c101 -56 186 -181 212 -313 16 -86 6 -247 -21 -337 -46 -148 -139 -283
                    -223 -324 -42 -21 -55 -21 -655 -21 l-612 0 0 515 0 516 623 -3 622 -3 54 -30z"/>
            </g>
        </svg>
    );
};