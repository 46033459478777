import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store/store";
import App from "./App";

const app = (
    <Provider store={store}>
        <BrowserRouter basename="/">
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
