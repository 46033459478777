// Dependências
import axios from 'axios';
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsFillLockFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../constants/GlobalConfig";

// Actions
import * as authActions from "../../../store/actions/auth";

// Components
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Titulo from "../../../components/UI/Titulo/Titulo";

// Css
import classes from "./SenhaScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import statusHandler from "../../../helpers/statusHandler";
import * as validar from "../../../helpers/validar";

const SenhaScreen = props => {
    const dispatch = useDispatch();

    const darkMode = useSelector(state => state.colorPalette.darkMode);
    const token = useSelector(state => state.auth.token);

    const [senha, setSenha] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");

    const [displaySenha, setDisplaySenha] = useState(false);
    const [displayNovaSenha, setDisplayNovaSenha] = useState(false);
    const [displayConfirmarSenha, setDisplayConfirmarSenha] = useState(false);

    const [isValid, setIsValid] = useState({
        senha: true,
        novaSenha: true,
        confirmarSenha: true
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();

        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (validar.senha(novaSenha)) {
            if (novaSenha === confirmarSenha) {
                copiaIsValid.novaSenha = true;
                copiaIsValid.confirmarSenha = true;
            } else {
                formIsValid = false;
                copiaIsValid.novaSenha = false;
                copiaIsValid.confirmarSenha = false;
            };
        } else {
            formIsValid = false;
            copiaIsValid.novaSenha = false;
            copiaIsValid.confirmarSenha = true;
        };

        if (formIsValid) {
            setIsLoading(true);

            await axios({
                method: 'PUT',
                url: `${API_URL}/usuario/senha`,
                headers: { Authorization: token },
                data: { senha: senha, novaSenha: novaSenha }
            }).then(result => {
                setIsLoading(false);

                dispatch(authActions.saveToken(result.data.access_token, false));
                exibirMensagem.success("Senha alterada com sucesso!");
                props.history.push('/inicio');
            }).catch(error => {
                setIsLoading(false);

                if (error.response?.status === 401)
                    copiaIsValid.senha = false;
                else
                    statusHandler(error.response?.status); 
            });
        };

        setIsValid(copiaIsValid);
    };

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className={darkMode ? classes.CardDark : classes.Card}>
                    <Titulo>Altere sua senha</Titulo>

                    <div className="row mb-3">
                        <div className="col-2 text-right d-none d-md-block">
                            <BsFillLockFill title="Senha" size="30" />
                        </div>
                        <div className="col-12 col-md-10">
                            <Input
                                enterHandler
                                size="100%"
                                name="password"
                                maxLength="30"
                                placeholder="Senha atual"
                                invalid={!isValid.senha}
                                type={displaySenha ? "text" : "password"}
                                onChange={value => setSenha(value)}
                            />

                            {displaySenha ? (
                                <AiOutlineEye
                                    size="30"
                                    title="Ocultar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplaySenha(false)}
                                />
                            ) : (
                                <AiOutlineEyeInvisible
                                    size="30"
                                    title="Mostrar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplaySenha(true)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 text-right d-none d-md-block">
                            <BsFillLockFill title="Nova senha" size="30" />
                        </div>
                        <div className="col-12 col-md-10">
                            <Input
                                enterHandler
                                size="100%"
                                name="password"
                                maxLength="30"
                                placeholder="Nova senha"
                                invalid={!isValid.novaSenha}
                                type={displayNovaSenha ? "text" : "password"}
                                onChange={value => setNovaSenha(value)}
                            />

                            {displayNovaSenha ? (
                                <AiOutlineEye
                                    size="30"
                                    title="Ocultar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplayNovaSenha(false)}
                                />
                            ) : (
                                <AiOutlineEyeInvisible
                                    size="30"
                                    title="Mostrar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplayNovaSenha(true)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-2 text-right d-none d-md-block">
                            <BsFillLockFill title="Confirmar nova senha" size="30" />
                        </div>
                        <div className="col-12 col-md-10">
                            <Input
                                enterHandler
                                size="100%"
                                name="password"
                                maxLength="30"
                                placeholder="Confirmar nova senha"
                                invalid={!isValid.confirmarSenha}
                                type={displayConfirmarSenha ? "text" : "password"}
                                onChange={value => setConfirmarSenha(value)}
                            />

                            {displayConfirmarSenha ? (
                                <AiOutlineEye
                                    size="30"
                                    title="Ocultar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplayConfirmarSenha(false)}
                                />
                            ) : (
                                <AiOutlineEyeInvisible
                                    size="30"
                                    title="Mostrar senha"
                                    className={classes.Eye}
                                    onClick={() => setDisplayConfirmarSenha(true)}
                                />
                            )}
                        </div>
                    </div>

                    {Object.entries(isValid).find(validade => !validade[1]) ? (
                        <div className="row">
                            <div className={`col-12 ${classes.ErrorMessage}`}>
                                {!isValid.senha ? (
                                    "* Senha atual está incorreta."
                                ) : !isValid.confirmarSenha && !isValid.novaSenha ? (
                                    "* Senhas não coincidem."
                                ) : (
                                    "* Senha escolhida é inválida."
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div className="row">
                        <div className="col-12 mt-4">
                            {isLoading ? <Spinner /> : (
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Button
                                            type="submit"
                                            color="green"
                                            className="w-50"
                                        >Confirmar</Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </form>
        </div>
    );
};

export default SenhaScreen;