// Dependências
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { BiRightArrow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

// Actions
import * as cadastroActions from "../../../store/actions/cadastro";
import * as sucatasActions from "../../../store/actions/sucatas";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../components/UI/Button/Button";
import ContextMenu from "../../../components/UI/ContextMenu/ContextMenu";
import Dropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import Table from "../../../components/UI/Table/Table";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Sucata from "../../../components/Cadastro/Sucata/Sucata";

// Css
import classes from "./CadastroSucataScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import * as formatar from "../../../helpers/formatar";

const SucataScreen = props => {
    const dispatch = useDispatch();

    const sucatas = useSelector(state => state.sucatas.sucatas);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [copiaSucatas, setCopiaSucatas] = useState([]);
    const [opcoesStatus, setOpcoesStatus] = useState([]);

    const [idSucataContextMenu, setIdSucataContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    const statusMobile = [3, 4, 350];

    // Requisição das sucatas em cadastro
    useEffect(() => {
        const fetchSucatasNaoCadastradas = async () => {
            try {
                setIsLoading(true);
                await dispatch(sucatasActions.fetchSucatasNaoCadastradas());
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            };

            setIsRequestCompleted(true);
        };

        fetchSucatasNaoCadastradas();
    }, [dispatch]);

    // Setando copiaSucatas e opcoesStatus
    useEffect(() => {
        setCopiaSucatas([...sucatas]);

        if (sucatas.length && isRequestCompleted) {
            let opcoesStatusAtualizado = [];
            sucatas.map(sucata => sucata.status).forEach(status => {
                if (status)
                    if (!opcoesStatusAtualizado.find(statusAtualizado => statusAtualizado.nome === status.nome))
                        opcoesStatusAtualizado.push({ ...status });
            });
            setOpcoesStatus(opcoesStatusAtualizado);

            setIsLoading(false);
        };

        if (!sucatas.length && isRequestCompleted)
            setIsLoading(false);
    }, [sucatas, isRequestCompleted]);

    // Resetando o storage do cadastroSucata
    useEffect(() => {
        dispatch(cadastroActions.reset());
    }, [dispatch]);

    // Chama o action de apagar uma sucata
    const deleteSucata = async idSucata => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta sucata? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(sucatasActions.deleteSucata(idSucata));
                    setCopiaSucatas(copiaSucatas.filter(sucata => sucata.id !== idSucata));
                    exibirMensagem.success("Sucata deletada com sucesso!");
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idSucata) => {
        event.preventDefault();

        setIdSucataContextMenu(idSucata);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    return isLoading ? <Spinner /> : sucatas.length ? (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h4>Sucatas em processo de cadastro</h4>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-8">
                    <BarraPesquisa onChange={busca => {
                        setCopiaSucatas(sucatas.filter(sucata =>
                            formatar.alphaNumeric(sucata.fipe.modelo.nome).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(sucata.fipe.marca.nome).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(formatar.data(sucata.dataCriacao)).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(sucata.status.nome).includes(formatar.alphaNumeric(busca))
                        ));
                    }} />
                </div>
                {opcoesStatus.length ? (
                    <div className="col-sm-12 col-md-4">
                        <Dropdown
                            inline
                            list={[{ id: 0, nome: "Mais recente" }, { id: -1, nome: "Quantidade de peças" }, ...opcoesStatus]}
                            onChange={idStatus => {
                                if (idStatus) setCopiaSucatas(sucatas.filter(sucata => sucata.status.id === idStatus));
                                if (idStatus === 0) setCopiaSucatas([...sucatas]);
                                if (idStatus === -1) {
                                    setCopiaSucatas([...sucatas].sort((a, b) => {
                                        if (a.quantidadePecas < b.quantidadePecas) return 1;
                                        return -1;
                                    }));
                                };
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <div className={`row ${classes.Table}`}>
                <Table
                    fixed
                    hoverble
                    header={["Marca", "Modelo", "Ano", "Data de início", "Peças", "Próxima etapa"]}>
                    {(isMobile ? copiaSucatas.filter(copiaSucata => statusMobile.includes(copiaSucata.status.id)) : copiaSucatas).map(sucata =>
                        <Sucata
                            key={sucata.id}
                            sucata={sucata}
                            onContextMenu={event => handleContextMenuClick(event, sucata.id)} />)}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => deleteSucata(idSucataContextMenu)}>Excluir</li>
                </ContextMenu>
            </div>
            <div className="row">
                <div className="col-12 text-right">
                    <Button
                        color="green"
                        className={classes.Button}
                        onClick={() => props.history.push("/cadastro/sucata/selecao-fipe")}
                    >Começar um novo cadastro <BiRightArrow /></Button>
                </div>
            </div>
        </div>
    ) : <Redirect to="/cadastro/sucata/selecao-fipe" />
};

export default SucataScreen;