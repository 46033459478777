// Dependêcias
import React from "react";
import { withRouter } from "react-router-dom";

// Helpers
import Button from "../../../components/UI/Button/Button";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Etiqueta from "../Etiqueta";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { renderToString } from "react-dom/server"
import { useEffect } from "react";
import svgToDataUri from "../../../utils/svgToDataUri";

const LocalizacaoRow = props => {

    const [qrCode, setQrCode] = useState(null);
    const [download, setDownload] = useState(false);

    useEffect(() => {
        async function convertSvgToDataUri() {
            const svg = (
              <QRCodeSVG
                value={`${props.localizacao.id}`}
              />
            )
      
            const dataUri = await svgToDataUri(renderToString(svg))
            setQrCode(dataUri || '')
          }
          convertSvgToDataUri()
    }, [props.localizacao.id])

    return (
        <tr
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td>{props.localizacao.id}</td>
            <td className="text-left">{props.localizacao.nome}</td>
            <td>{props.localizacao.quantidadePeca}</td>
            <td>
                {download ? (
                    <PDFDownloadLink
                        document={<Etiqueta localizacao={[{nome: props.localizacao.nome, qrCode}]} />}
                        fileName={`etiqueta_${props.localizacao.nome}.pdf`}
                    >
                        {({loading}) => (
                            <Button
                                color="green"
                                onClick={() => setDownload(false)}
                                style={{ height: '40px', margin: 0}}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Imprimir'}
                            </Button>
                        )}
                    </PDFDownloadLink>
                ) : (
                    <Button
                        color="green"
                        onClick={() => setDownload(true)}
                        style={{ height: '40px', margin: 0}}
                    >
                        Etiqueta
                    </Button>
                )}
                
            </td>
            <td>
                <Button
                    color="blue"
                    onClick={() => props.onEdit(props.localizacao.id)}
                    style={{ height: '40px', margin: 0}}
                >
                    Editar
                </Button>
            </td>
            <td>
                <Button
                    color="red"
                    onClick={() => props.onDelete(props.localizacao.id)}
                    disabled={props.localizacao.quantidadePeca > 0}
                    style={{ height: '40px', margin: 0}}
                >
                    Deletar
                </Button>
            </td>
        </tr>
    );
};



export default withRouter(LocalizacaoRow);