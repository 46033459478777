// Dependências
import React from "react";
import { withRouter } from "react-router-dom";

import classes from './Sucata.module.css';

// Helpers
import * as formatar from '../../../helpers/formatar';

const Sucata = (props) => {
    console.log(props.sucata)
    return (
        <tr style={{ fontWeight: "700" }} onClick={() => props.history.push(`/estoque/sucatas/${props.sucata.id}`)}>
            <td>{props.sucata.fipe.modelo.nome || props.sucata.fipe.veiculo.nome}{props.sucata.cor ? ` - ${props.sucata.cor}` : null}</td>

            <td>{formatar.dinheiro(props.sucata.valores.estoque.valor, false)}</td>

            <td>{formatar.dinheiro(props.sucata.valores.despesas.valor, false)}</td>

            <td>{formatar.dinheiro(props.sucata.valores.vendas.valor, false)}</td>

            <td className={`${props.sucata.lucro > 0 ? classes.ValorPositivo : props.sucata.lucro < 0 ? classes.ValorNegativo : ""}`}>
                {props.sucata.lucro < 0 ? "-" : null} {formatar.dinheiro(props.sucata.lucro, false)}
            </td>

            <td className="text-nowrap" style={{ backgroundColor: "#79cd5615" }}>
                {formatar.dinheiro(props.sucata.valores.vendas.receita, false)}
            </td>

            <td className={props.sucata.valores.vendas.porcentagem >= 100 ? classes.ValorPositivo : ''}>
                {`${Math.round(props.sucata.valores.vendas.porcentagem)}%`}
            </td>
        </tr>
    );
};

export default withRouter(Sucata);
