import axios from 'axios';
import { API_URL } from "../../constants/GlobalConfig"


export const FETCH_RESPOSTAS_PRONTAS = "FETCH_RESPOSTAS_PRONTAS";
export const FETCH_RESPOSTA_PRONTA = "FETCH_RESPOSTA_PRONTA";
export const DELETE_RESPOSTA_PRONTA = "DELETE_RESPOSTA_PRONTA";
export const UPDATE_RESPOSTA_PRONTA = "UPDATE_RESPOSTA_PRONTA";
export const CREATE_RESPOSTA_PRONTA = "CREATE_RESPOSTA_PRONTA"

export const fetchRespostasProntas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/respostas-prontas`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let respostasProntas = [];
            try {
                respostasProntas = await response.json();
                dispatch({ type: FETCH_RESPOSTAS_PRONTAS, respostasProntas: respostasProntas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            throw new Error("Algo deu errado");
        }
    };
};

export const removerRespostaPronta = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(`${API_URL}/resposta-pronta/${id}`, {
            method: "DELETE",
            headers: { Authorization: token }
        });

        if (response.ok) {
            dispatch({ type: DELETE_RESPOSTA_PRONTA, id: id });
        } else {
            throw new Error("Algo deu errado");
        }
    };
};

export const atualizarRespostaPronta = (id, values) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/resposta-pronta/${id}`,
            headers: {
                Authorization: token
            },
            data: values
        }).then((result) => {
            if (result.status) {
                console.log(result)
                dispatch({ type: UPDATE_RESPOSTA_PRONTA, respostaPronta: { ...result.data } });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            throw error
        });
    };
};

export const criarRespostaPronta = (values) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/resposta-pronta`,
            headers: {
                Authorization: token
            },
            data: values
        }).then((result) => {
            if (result.status) {
                dispatch({ type: CREATE_RESPOSTA_PRONTA, respostaPronta: { ...result.data } });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            throw error
        });
    };
};
