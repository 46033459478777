// Dependências
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../../../constants/GlobalConfig";

// Actions
import { fetchFotosPecas } from "../../../../../store/actions/pecas";

// Components
import BarraPesquisa from "../../../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../../../components/UI/Button/Button";
import OrderableDropzone from "../../../../../components/UI/OrderableDropzone/OrderableDropzone";
import Input from "../../../../../components/UI/Input/Input";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

// Css
import classes from "./FotosPecasScreen.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";
import statusHandler from "../../../../../helpers/statusHandler";

// Hooks
import useQuery from "../../../../../hooks/useQuery";


const FotosPecasScreen = ({ match, history }) => {
    const dispatch = useDispatch();
    const idSucata = match.params.id;

    const stringPecasSelecionadas = useQuery('pecas');

    const pecas = useSelector(state => state.pecas.pecas);
    const token = useSelector(state => state.auth.token);

    const [newPecas, setNewPecas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [busca, setBusca] = useState("");


    // Requisição das peças
    useEffect(() => {
        const fetchFotosPecasReq = async () => {
            setIsLoading(true);

            try {
                await dispatch(fetchFotosPecas(idSucata, stringPecasSelecionadas ? stringPecasSelecionadas.split(',') : undefined));
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        fetchFotosPecasReq();
    }, [dispatch, idSucata, stringPecasSelecionadas]);

    // Setando newpecas
    useEffect(() => {
        setNewPecas([...pecas]);
    }, [pecas]);

    const submitHandler = async () => {
        if (isLoading) return;
        setIsLoading(true);

        const editedPecas = newPecas.filter(newPeca => newPeca.edited);

        const formData = new FormData();
        const newFotos = [].concat.apply([], editedPecas.filter(peca => peca.fotos.map(foto => foto.type).includes('uploaded')).map(peca => peca.fotos.map((foto, index) => ({ ...foto, idPeca: peca.id, index })).filter(foto => foto.type === 'uploaded')));

        formData.append('pecas', JSON.stringify(editedPecas.map(peca => ({ ...peca, titulo: peca.titulo, fotos: peca.fotos.map((foto, index) => ({ ...foto, index })).filter(foto => foto.type !== 'uploaded') }))));
        newFotos.forEach(foto => formData.append(JSON.stringify({ atrelamento: { id: foto.idPeca }, index: foto.index }), foto.caminho));

        axios({
            method: 'PUT',
            url: idSucata ? `${API_URL}/sucatas/${idSucata}/pecas/fotos` : `${API_URL}/pecas/busca/fotos`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then(result => {
            if (result.status) {
                setIsLoading(false);
                history.push(idSucata ? `/estoque/sucatas/${idSucata}` : '/estoque/pecas/');
            } else {
                setIsLoading(false);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            setIsLoading(false);
            statusHandler(error.response?.status);
            console.log(error);
        });
    };

    // TODO: Botão de excluir todas as peças
    return isLoading ? <Spinner /> : (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <h3>Fotos das peças</h3>

                <Button color="green" onClick={submitHandler}>Salvar alterações</Button>
            </div>

            <BarraPesquisa cleanOption onChange={newBusca => setBusca(newBusca)} />

            <div className={classes.DropzonesWrapper}>
                {pecas.map(peca => (
                    <div
                        key={peca.id}
                        className={classes.Dropzone}
                        style={{ display: (!busca || formatar.alphaNumeric(peca.nome).includes(formatar.alphaNumeric(busca))) ? 'block' : 'none' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Input
                                maxLength={60}
                                value={peca.titulo}
                                size="95%"
                                onChange={newTitulo => {
                                    setNewPecas(prevNewPecas => prevNewPecas.map(prevPeca => prevPeca.id === peca.id ? ({ ...prevPeca, edited: true, titulo: newTitulo }) : prevPeca));
                                }}
                            />

                            <span style={{ whiteSpace: "nowrap" }}>
                                {newPecas.find(newPeca => newPeca.id === peca.id)?.titulo.length || peca.titulo.length} / 60
                            </span>
                        </div>

                        <Input
                            maxLength={60}
                            value={peca.codigo}
                            placeholder="GTIN"
                            size="96%"
                            onChange={newCodigo => {
                                setNewPecas(prevNewPecas => prevNewPecas.map(prevPeca => prevPeca.id === peca.id ? ({ ...prevPeca, edited: true, codigo: newCodigo }) : prevPeca));
                            }}
                        />

                        <OrderableDropzone
                            deleteAllButton
                            defaultValue={peca.fotos?.map(foto => ({ key: foto.id, value: foto.caminho }))}
                            limit={9}
                            onChange={(newFotos, firstCicle) => {
                                const newFotosFormatado = newFotos.map(foto => ({ id: foto.key, caminho: foto.value, type: foto.type }));
                                setNewPecas(prevNewPecas => prevNewPecas.map(prevPeca => prevPeca.id === peca.id ? ({ ...prevPeca, edited: !firstCicle, fotos: newFotosFormatado }) : prevPeca));
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FotosPecasScreen;