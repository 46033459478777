// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../../store/actions/anuncios";
import * as clientesActions from "../../../../store/actions/clientes";
import * as funcionariosActions from "../../../../store/actions/funcionarios";
import * as vendasActions from "../../../../store/actions/vendas";

// Components
import BeautyDropdown from "../../../UI/BeautyDropdown/BeautyDropdown";
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import Spinner from "../../../UI/Spinner/Spinner";
import Titulo from "../../../UI/Titulo/Titulo";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

const ModalCreateVenda = props => {
    const dispatch = useDispatch();

    const clientes = useSelector((state) => state.clientes.clientes);
    const funcionarios = useSelector((state) => state.funcionarios.funcionarios);
    const plataformas = useSelector((state) => state.anuncios.plataformas);

    const [isLoading, setIsLoading] = useState(true);
    const [venda, setVenda] = useState({
        data: dataHoje(),
        cliente: { id: null, nome: "", cpf: "", telefone: "" },
        funcionario: { id: null, nome: null },
        plataforma: { id: null, nome: null },
        preco: props.peca.preco
    });
    const [isValid, setIsValid] = useState({
        cpf: true,
        data: true,
        preco: true,
        nome: true,
        telefone: true
    });

    // Requisição dos clientes
    useEffect(() => {
        const fetchClientes = async () => {
            try {
                setIsLoading(true);
                await dispatch(clientesActions.fetchClientes());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        fetchClientes();
    }, [dispatch]);

    // Requisição dos funcionários
    useEffect(() => {
        const fetchFuncionarios = async () => {
            try {
                setIsLoading(true);
                await dispatch(funcionariosActions.fetchFuncionarios());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        fetchFuncionarios();
    }, [dispatch]);

    // Requisição das plataformas
    useEffect(() => {
        const fetchPlataformas = async () => {
            try {
                setIsLoading(true);
                await dispatch(anunciosActions.fetchPlataformas());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        fetchPlataformas();
    }, [dispatch]);

    const verificarCliente = () => {
        if (venda.cliente.cpf) {
            let clienteEncontrado = clientes.find(cliente => cliente.cpf === venda.cliente.cpf);

            let copiaVenda = { ...venda };
            if (clienteEncontrado) {
                copiaVenda.cliente.id = clienteEncontrado.id;
                copiaVenda.cliente.nome = clienteEncontrado.nome;
                copiaVenda.cliente.cpf = clienteEncontrado.cpf;
                copiaVenda.cliente.telefone = clienteEncontrado.telefone;
            } else
                copiaVenda.cliente.id = null;

            setVenda(copiaVenda);
        };
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = true;

        let copiaVenda = { ...venda };
        let copiaIsValid = { ...isValid };

        if (validar.cpf(venda.cliente.cpf))
            copiaIsValid.cpf = true;
        else {
            copiaIsValid.cpfInvalid = true;
            formIsValid = false;
        };

        if (validar.nome(venda.cliente.nome))
            copiaIsValid.nome = true;
        else {
            copiaIsValid.nome = false;
            formIsValid = false;
        };

        if (validar.telefone(venda.cliente.telefone))
            copiaIsValid.telefone = true;
        else {
            copiaIsValid.telefone = false;
            formIsValid = false;
        };

        if (validar.preco(venda.preco))
            copiaIsValid.preco = true;
        else {
            copiaIsValid.preco = false;
            formIsValid = false;
        };

        if (validar.data(venda.data))
            copiaIsValid.data = true;
        else {
            copiaIsValid.data = false;
            formIsValid = false;
        };

        if (!venda.plataforma.id) {
            copiaVenda.plataforma = { id: plataformas[0].id, nome: plataformas[0].nome };
            setVenda(copiaVenda);
        };

        setIsValid(copiaIsValid);

        if (formIsValid) {
            setIsLoading(true);

            try {
                await dispatch(vendasActions.createVenda(props.peca.id, copiaVenda));
                setIsLoading(false);
                exibirMensagem.success("A venda foi cadastrada com sucesso");
                props.createVenda();
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            };
        } else
            exibirMensagem.formInvalid();
    };

    return !props.display ? null : (
        <Modal titulo="Cadastrar venda" onClose={props.onClose}>
            {isLoading || !plataformas.length ? <Spinner /> : (
                <form onSubmit={handleSubmit}>
                    <Titulo>Informações do Comprador</Titulo>
                    <div className="container-fluid text-center">
                        <div className="row">
                            <div className="col-2">
                                CPF:
                            </div>
                            <div className="col-10">
                                <Input
                                    cpf
                                    type="text"
                                    maxLength="14"
                                    placeholder="CPF do comprador"
                                    invalid={!isValid.cpf}
                                    setValue={venda.cliente.cpf || ""}
                                    datalist={clientes.length ? clientes.map(cliente => cliente.cpf) : ""}
                                    onChange={value => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.cliente.cpf = value;
                                        setVenda(copiaVenda);
                                    }}
                                    onBlur={verificarCliente}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                Nome:
                            </div>
                            <div className="col-10">
                                <Input
                                    type="text"
                                    maxLength="50"
                                    placeholder="Nome do comprador"
                                    invalid={!isValid.nome}
                                    setValue={venda.cliente.nome || ""}
                                    datalist={clientes ? clientes.map(cliente => cliente.nome) : ""}
                                    onChange={value => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.cliente.nome = value;
                                        setVenda(copiaVenda);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                Telefone:
                            </div>
                            <div className="col-10">
                                <Input
                                    telefone
                                    type="text"
                                    maxLength="15"
                                    placeholder="Telefone do comprador"
                                    invalid={!isValid.telefone}
                                    setValue={venda.cliente.telefone || ""}
                                    datalist={clientes ? clientes.map(cliente => cliente.telefone) : ""}
                                    onChange={value => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.cliente.telefone = value;
                                        setVenda(copiaVenda);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <Titulo>Informações de Venda</Titulo>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2">
                                Data:
                            </div>
                            <div className="col-4">
                                <Input
                                    type="date"
                                    value={dataHoje() || ""}
                                    invalid={!isValid.data}
                                    onBlur={event => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.data = event.target.value;
                                        setVenda(copiaVenda);
                                    }} />
                            </div>

                            <div className="col-2">
                                Origem:
                            </div>
                            <div className="col-4">
                                <BeautyDropdown
                                    returnObject
                                    list={plataformas.map(plataforma => { return { id: plataforma.id, nome: plataforma.nome } })}
                                    onChange={value => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.plataforma = value;
                                        setVenda(copiaVenda);
                                    }} />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-2">
                                Preço:
                            </div>
                            <div className="col-4">
                                <Input
                                    onlyMoney
                                    maxLength="9"
                                    placeholder="Preço de venda (R$)"
                                    value={props.peca.preco || ""}
                                    invalid={!isValid.preco}
                                    onBlur={event => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.preco = event.target.value;
                                        setVenda(copiaVenda);
                                    }}
                                />
                            </div>

                            <div className="col-2">
                                Vendedor:
                            </div>
                            <div className="col-4">
                                <BeautyDropdown
                                    returnObject
                                    list={[{ id: 0, nome: "Nenhum" }, ...funcionarios.map(funcionario => { return { id: funcionario.id, nome: funcionario.nome } })]}
                                    onChange={value => {
                                        let copiaVenda = { ...venda };
                                        copiaVenda.funcionario = value;
                                        setVenda(copiaVenda);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="col-12 text-center">
                                <Button color="green" type="submit">Cadastrar</Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Modal >
    );
};

export default ModalCreateVenda;