// Dependências
import React, { useEffect, useState } from "react";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Peca from "./Peca/Peca";
import Table from "../../UI/Table/Table";

const Medidas = props => {

    const [copiaPecas, setCopiaPecas] = useState([]);

    // Setando copiaPecas
    useEffect(() => {
        setCopiaPecas([...props.pecas.map(peca => { return { ...peca, anunciar: false } })]);
    }, [props.pecas]);

    const submitHandler = () =>
        props.onProxClick(copiaPecas.map(peca => { return { id: peca.id, medidas: peca.medidas } }));

    // Pulando página direto, apagar isso quando o MDP voltar
    if (copiaPecas.length)
        submitHandler();

    return (
        <div>
            <div className="row" style={{ height: "72vh" }}>
                <Table
                    fixed
                    header={["Peça", "Peso (g)", "Altura (cm)", "Largura (cm)", "Comprimento (cm)"]}>
                    {copiaPecas.length ? copiaPecas.map(peca =>
                        <Peca
                            showSucata={props.showSucata}
                            key={peca.id}
                            peca={peca}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(peca => peca.id === pecaAtualizada.id ? pecaAtualizada : peca);
                                setCopiaPecas([...copiaPecasAtualizadas]);
                            }}
                        />
                    ) :
                        <tr className="text-center h3">
                            <td colSpan="5">Nenhuma peça encontrada</td>
                        </tr>}
                </Table>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default Medidas;