import Accents from "remove-accents";

export const alphaNumeric = value => {
    try {
        return Accents.remove(value.toString()).replace(/[^A-Za-z0-9]/g, "").toLowerCase().trim();
    } catch (error) {
        console.log(error);
        return "Not a value";
    };
};

export const data = (data, onlyMonthAndYear, convertMonthToString) => {
    try {
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

        let dia = "";
        let mes = "";
        let ano = "";

        if (data.length > 10)
            data = data.slice(0, 10);

        let arrData = data.toString().split("-");
        if (arrData.length !== 3) arrData = data.toString().split("/");
        if (arrData.length !== 3) return "Not a date";
        mes = arrData[1];

        if (arrData[0].length === 4) {
            ano = arrData[0];
            dia = arrData[2];
        } else if (arrData[2].length === 4) {
            ano = arrData[2];
            dia = arrData[0];
        } else return "Not a date";

        if (parseInt(dia) < 10) dia = `0${parseInt(dia)}`;
        if (parseInt(mes) < 10) mes = `0${parseInt(mes)}`;

        if (convertMonthToString) {
            mes = meses[parseInt(mes) - 1];
            ano = ano.replace('20', '');
        };

        return onlyMonthAndYear ? `${mes}/${ano}` : `${dia}/${mes}/${ano}`;
    } catch (error) {
        console.log(error);
        return "Not a date";
    };
};

export const dinheiro = (valor, comVirgula = true, comCifrao = true) => {
    try {
        valor = parseFloat(valor).toFixed(2);

        if (valor >= 0) {
            valor = valor.toString().split(".");
            valor = valor.toString().split(",");

            valor[0] = valor[0].split(/(?=(?:...)*$)/).join(".");

            if (valor.length > 1) {
                if (valor[1].length === 1)
                    valor = valor[0] + "," + valor[1] + "0";
                else if (valor[1].length === 2)
                    valor = valor[0] + "," + valor[1];
            } else
                valor = valor[0] + ",00"

            if (comVirgula)
                return (comCifrao ? "R$ " : "") + valor;
            else
                return comCifrao ? `R$ ${valor.split(",")[0]}` : valor.split(",")[0];

        } else {
            valor = (valor * -1).toString().split(".");
            valor = valor.toString().split(",");

            valor[0] = valor[0].split(/(?=(?:...)*$)/).join(".");

            if (valor.length > 1) {
                if (valor[1].length === 1)
                    valor = valor[0] + "," + valor[1] + "0";
                else if (valor[1].length === 2)
                    valor = valor[0] + "," + valor[1];
            } else
                valor = valor[0] + ",00";

            return comVirgula ? (comCifrao ? "R$ " : "") + valor : (comCifrao ? "R$ " : "") + valor.split(",")[0];
        };
    } catch (error) {
        console.log(error);
        return "Not a value";
    };
};

export const stringToFloat = value => {
    if (!value)
        return value;

    try {
        return parseFloat(value.toString().replace(",", "."));
    } catch (error) {
        console.log(error);
        return value;
    };
};