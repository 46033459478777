// Dependências
import React, { useEffect, useState } from "react";
import { BiRightArrow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

// Actions
import * as cadastroActions from "../../../store/actions/cadastro";
import * as gruposSucatasActions from "../../../store/actions/grupos-sucatas";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../components/UI/Button/Button";
import ContextMenu from "../../../components/UI/ContextMenu/ContextMenu";
import Dropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import GrupoSucatas from "../../../components/Cadastro/GrupoSucatas/GrupoSucatas";
import Table from "../../../components/UI/Table/Table";
import Spinner from "../../../components/UI/Spinner/Spinner";

// Css
import classes from "./CadastroGrupoPecasScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import * as formatar from "../../../helpers/formatar";

const SucataScreen = props => {
    const dispatch = useDispatch();

    const gruposSucatas = useSelector(state => state.gruposSucatas.gruposSucatas);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [copiaGruposSucatas, setCopiaGruposSucatas] = useState([]);
    const [opcoesStatus, setOpcoesStatus] = useState([]);

    const [idGrupoSucatasContextMenu, setIdGrupoSucatasContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição dos gruposSucatas
    useEffect(() => {
        const fetchGruposSucatas = async () => {
            try {
                setIsLoading(true);
                await dispatch(gruposSucatasActions.fetchGruposSucatasNaoCadastradas());
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            };

            setIsRequestCompleted(true);
        };

        fetchGruposSucatas();
    }, [dispatch]);

    // Setando copiaGruposSucatas e opcoesStatus
    useEffect(() => {
        setCopiaGruposSucatas([...gruposSucatas]);

        if (gruposSucatas.length && isRequestCompleted) {
            let opcoesStatusAtualizado = [];
            gruposSucatas.map(grupoSucatas => grupoSucatas.status).forEach(status => {
                if (status)
                    if (!opcoesStatusAtualizado.find(statusAtualizado => statusAtualizado.nome === status.nome))
                        opcoesStatusAtualizado.push({ ...status });
            });
            setOpcoesStatus(opcoesStatusAtualizado);

            setIsLoading(false);
        };

        if (!gruposSucatas.length && isRequestCompleted)
            setIsLoading(false);
    }, [gruposSucatas, isRequestCompleted]);

    // Resetando o storage do cadastro
    useEffect(() => {
        dispatch(cadastroActions.reset());
    }, [dispatch]);

    // Chama o action de apagar uma sucata
    const deleteGrupoSucata = async idSucata => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta sucata? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(gruposSucatasActions.deleteGrupoSucata(idSucata));
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idSucata) => {
        event.preventDefault();

        setIdGrupoSucatasContextMenu(idSucata);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    return isLoading ? <Spinner /> : gruposSucatas.length ? (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h4>Peças em processo de cadastro</h4>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8">
                    <BarraPesquisa onChange={busca => {
                        setCopiaGruposSucatas(gruposSucatas.filter(grupoSucatas =>
                            formatar.alphaNumeric(grupoSucatas.id).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(grupoSucatas.quantidade).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(grupoSucatas.status.nome).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(grupoSucatas.dataCadastro).includes(formatar.alphaNumeric(busca))
                        ));
                    }} />
                </div>
                {opcoesStatus.length ? (
                    <div className="col-4">
                        <Dropdown
                            inline
                            list={[{ id: 0, nome: "Todas" }, ...opcoesStatus]}
                            onChange={idStatus => idStatus ? setCopiaGruposSucatas(gruposSucatas.filter(grupoSucatas => grupoSucatas.status.id === idStatus)) : setCopiaGruposSucatas([...gruposSucatas])}
                        />
                    </div>
                ) : null}
            </div>

            <div className="row mb-3" style={{ height: "70vh" }}>
                <Table
                    fixed
                    hoverble
                    header={["Código", "Nome", "Quantidade de peças", "Data de início", "Próxima etapa"]}>
                    {copiaGruposSucatas.map(grupoSucatas =>
                        <GrupoSucatas
                            key={grupoSucatas.id}
                            grupoSucatas={grupoSucatas}
                            onContextMenu={event => handleContextMenuClick(event, grupoSucatas.id)} />)}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => deleteGrupoSucata(idGrupoSucatasContextMenu)}>Excluir</li>
                </ContextMenu>
            </div>
            <div className="row">
                <div className="col-12 text-right">
                    <Button
                        color="green"
                        className={classes.Button}
                        onClick={() => props.history.push("/cadastro/grupo-pecas/selecao-pecas")}
                    >Começar um novo cadastro <BiRightArrow /></Button>
                </div>
            </div>
        </div>
    ) : <Redirect to="/cadastro/grupo-pecas/selecao-pecas" />
};

export default SucataScreen;