// Dependências
import React, { useState } from "react";

// Components
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Titulo from "../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

const PesquisaMercadoLivreScreen = props => {
    const [busca, setBusca] = useState("");

    const getLink = () =>
        `https://lista.mercadolivre.com.br/${busca.trim().replace(/[ ]/g, '-').toLocaleLowerCase()}-usado`;


    const submitHandler = event => {
        event.preventDefault();

        if (busca)
            window.open(getLink(), '_blank');
    };

    return (
        <div className="container-fluid pt-3">
            <Titulo>Pesquisa no Mercado Livre</Titulo>

            <form onSubmit={submitHandler}>
                <div className="row pt-5">

                    <div className="col-12 mb-5">
                        <h5>Digite sua busca</h5>
                        <Input
                            placeholder="Busca no Mercado Livre"
                            enterHandler={submitHandler}
                            onChange={value => setBusca(value)} />
                    </div>

                    <div className="col-6">
                        <Button
                            onClick={() => {
                                if (busca) {
                                    exibirMensagem.success("Copiado");
                                    navigator.clipboard.writeText(getLink())
                                };
                            }}
                        > Copiar link</Button>
                    </div>

                    <div className="col-6 text-right">
                        <Button type="submit" color="green"> Pesquisar </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PesquisaMercadoLivreScreen;
