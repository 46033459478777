// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Css
import classes from "./PecasEmRevisao.module.css";

// Components
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import Peca from "./Peca/Peca";
import Spinner from "../../../UI/Spinner/Spinner";
import Table from "../../../UI/Table/Table";
import Titulo from "../../../UI/Titulo/Titulo";

const PecasEmRevisao = props => {
    const dispatch = useDispatch();

    const pecas = useSelector(state => state.pecas.pecas);

    const [copiaPecas, setCopiaPecas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas em revisão
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(props.idSucata));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [props.idSucata, dispatch]);

    // Setando copiaPecas
    useEffect(() => {
        setCopiaPecas([...pecas.filter(peca => peca.status?.id === 5).map(peca => { return { ...peca, anunciar: false } })]);
    }, [pecas]);

    const submitHandler = () => {
        props.onProxClick(copiaPecas, pecas.find(peca => peca.arquetipo.id === 350) ? true : false);
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid p-md-0">
            <Titulo>
                Peças Pendentes
            </Titulo>

            <div className="row">
                <div className="col-12 mb-2">
                    <span style={{ fontSize: "16px" }}>
                        As peças abaixo foram marcadas como <span style={{ color: "#593196" }}>PENDENTE</span>. Peça ao supervisor da loja que verifique as peças, marcando em <span style={{ color: "#79cd56" }}>VERDE</span> as que serão anunciadas e em BRANCO as que não serão anunciadas. Para isso, basta clicar nas peças.
                    </span>
                </div>
            </div>

            <div className={`row ${classes.Table}`}>
                <Table
                    fixed
                    hoverble
                    header={["Peça", "Observações"]}>
                    {copiaPecas.length ? copiaPecas.map(peca =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(peca => peca.id === pecaAtualizada.id ? pecaAtualizada : peca);
                                setCopiaPecas([...copiaPecasAtualizadas]);
                            }}
                        />
                    ) :
                        <tr className="text-center h3">
                            <td colSpan="2">Nenhuma peça encontrada</td>
                        </tr>}
                </Table>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default PecasEmRevisao;