import {
    FETCH_CLIENTES
} from "../actions/clientes";

const initialState = {
    clientes: []
};

const clientesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLIENTES:
            return { ...state, clientes: action.clientes };
        default:
            return state;
    }
};

export default clientesReducer;
