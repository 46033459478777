// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import BottomNavbar from "../../../../components/Cadastro/BottomNavbar/BottomNavbar";
import FichaTecnica from "../../../../components/Cadastro/FichaTecnica/FichaTecnica";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Titulo from "../../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const PART_NUMBER = {
    dica: null,
    id: "PART_NUMBER",
    maxLength: 255,
    naoSeAplica: false,
    nome: "Número de peça",
    obrigatorio: false,
    padrao: 1212211212,
    tipo: "string",
    valores: [],
    value: 1212211212
}


const FichaTecnicaScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;
    const pecas = useSelector(state => state.pecas.pecas);


    const [isLoading, setIsLoading] = useState(false);
    const [isRequestComplete, setIsRequestComplete] = useState(false);
    const [copiaPecas, setCopiaPecas] = useState([]);

    // Requisição das peças com atributos
    useEffect(() => {
        const fetchPecasAtributos = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecasAtributos(null, idGrupoSucatas));
                setIsRequestComplete(true);
            } catch (error) {
                console.log(error);
            };
        };

        fetchPecasAtributos();
    }, [dispatch, idGrupoSucatas]);

    // Setando copiaPecas
    useEffect(() => {
        if (isRequestComplete) {
            setCopiaPecas([...pecas.map(peca => {
                let newAtibutos = [...peca.atributos];
                newAtibutos = newAtibutos.filter(atributo => atributo.id !== "PART_NUMBER");
                newAtibutos = [...newAtibutos, PART_NUMBER];
                return {
                    ...peca,
                    sucata: peca.sucata.fipe.veiculo.nome,
                    atributos: newAtibutos.map(atributo => {
                        let value = atributo.padrao || null;

                        if (!value) {
                            if (atributo.tipo === "boolean" || atributo.tipo === "list")
                                value = atributo.valores[0];
                            if (atributo.tipo === "number_unit")
                                value = { number: null, unit: atributo.valores[0] };
                        };

                        return {
                            ...atributo,
                            value: value,
                            naoSeAplica: false
                        };
                    })
                };
            })]);
            setIsLoading(false);
        }
    }, [pecas, isRequestComplete]);

    const submitHandler = async () => {
        try {
            setIsLoading(true);
            await dispatch(pecasActions.createFichaTecnica(copiaPecas.map(peca => {
                return {
                    id: peca.id,
                    status: { id: 9 },
                    atributos: peca.atributos.map(atributo => {
                        return {
                            id: atributo.id,
                            valor: atributo.value
                        };
                    })
                };
            })));
            exibirMensagem.success("Fichas técnicas cadastradas com sucesso!");
            props.history.push(`/cadastro/grupo-pecas/fotos-pecas/${idGrupoSucatas}`);
        } catch (error) {
            console.log(error);
        };
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Insira os Atributos/Ficha Técnica das peças</Titulo>

            <div className="row">
                <div className="col-12 mb-4">
                    <span style={{ fontSize: "16px" }}><i>Esta etapa é opcional, porém desejável</i> para <strong>aumentar a relevância</strong> dos seus anúncios no Mercado Livre.</span>
                </div>
            </div>

            <div className="row">
                {copiaPecas.map(peca =>
                    <FichaTecnica
                        key={peca.id}
                        peca={peca}
                        showSucata
                        onChange={pecaAtualizada => {
                            let copiaPecasAtualizadas = [...copiaPecas];
                            copiaPecasAtualizadas = copiaPecasAtualizadas.map(copiaPecaAtualizada => copiaPecaAtualizada.id === pecaAtualizada.id ? pecaAtualizada : copiaPecaAtualizada);
                            setCopiaPecas(copiaPecasAtualizadas);
                        }} />)}
            </div>

            <BottomNavbar
                onAntClick={() => props.history.push("/cadastro/grupo-pecas")}
                onProxClick={submitHandler} />
        </div>
    );
};

export default FichaTecnicaScreen;