// Dependências
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";

// Components
import SelecaoTiposAnuncio from "../../../../components/Cadastro/SelecaoTiposAnuncio/SelecaoTiposAnuncio";

const SelecaoTiposAnuncioScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const cadastroGrupoPecas = useSelector(state => state.cadastro.cadastroGrupoPecas);

    return (
        <div>
            <SelecaoTiposAnuncio
                plataformas={cadastroGrupoPecas.plataformas}
                onAntClick={() => props.history.push("/cadastro/grupo-pecas")}
                onProxClick={plataformasAtualizadas => {
                    let cadastroSucataAtualizado = { ...cadastroGrupoPecas };
                    cadastroSucataAtualizado.plataformas = [...plataformasAtualizadas];
                    dispatch(cadastroActions.setCadastroGrupoPecas(cadastroSucataAtualizado));
                    props.history.push(`/cadastro/grupo-pecas/fretes-e-garantias/${idSucata}`);
                }} />
        </div>
    );
};

export default SelecaoTiposAnuncioScreen;