// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import * as sucatasActions from "../../../store/actions/sucatas";

// Css
import classes from "./SucatasScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import ContextMenu from "../../../components/UI/ContextMenu/ContextMenu";
import Dropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Sucata from "../../../components/Estoque/Sucata/Sucata";
import Table from "../../../components/UI/Table/Table";

const SucatasScreen = props => {
    const dispatch = useDispatch();
    const sucatas = useSelector((state) => state.sucatas.sucatas);

    const [busca, setBusca] = useState("");
    const [doravante, setDoravante] = useState(1);
    const [ordem, setOrdem] = useState("maior-lucro");
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false);

    const [copiaSucatas, setCopiaSucatas] = useState([]);

    const [idSucataContextMenu, setIdSucataContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    const fetchSucatas = async novoDoravante => {
        setDoravante(novoDoravante);

        try {
            setIsLoading(true);
            await dispatch(sucatasActions.fetchSucatasComModelo(novoDoravante, busca, ordem));
            setIsFirstRequestCompleted(true);
        } catch (error) {
            console.log(error);
        };

        setIsLoading(false);
    };

    // Requisição das sucatas quando muda busca e ordem
    useEffect(() => {
        fetchSucatas(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, busca, ordem]);

    // Setando copiaSucatas quando sucatas muda
    useEffect(() => {
        if (doravante !== 1)
            setCopiaSucatas([...copiaSucatas, ...sucatas.filter(sucata => !copiaSucatas.find(copiaSucata => copiaSucata.id === sucata.id))]);
        else
            setCopiaSucatas([...sucatas]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucatas]);

    // Chama o action de apagar uma sucata
    const deleteSucata = async idSucata => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta sucata? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(sucatasActions.deleteSucata(idSucata));
                    setCopiaSucatas(copiaSucatas.filter(sucata => sucata.id !== parseInt(idSucata)));
                    exibirMensagem.success("Sucata excluída com sucesso.");
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idSucata) => {
        event.preventDefault();

        setIdSucataContextMenu(idSucata);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const handleOnScrollBottom = () => {
        if ((sucatas.length === 50 || !isFirstRequestCompleted) && !isLoading)
            fetchSucatas(doravante + 1);
    };

    return !isFirstRequestCompleted ? <Spinner /> : sucatas.length || isLoading || busca.length ? (
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-8">
                    <BarraPesquisa
                        placeholder="Pesquisar no meu estoque"
                        onChange={novaBusca => setBusca(novaBusca)} />
                </div>

                <div className="col-4">
                    <Dropdown
                        list={[
                            { id: "mais-recente", nome: "Mais recentes" },
                            { id: "mais-antigo", nome: "Mais antigas" }
                        ]}
                        onChange={idOrdem => setOrdem(idOrdem)} />
                </div>
            </div>

            <div className="row" style={{ height: "84vh" }}>
                <Table
                    fixed
                    hoverble={sucatas.length}
                    bordered={sucatas.length}
                    isLoading={isLoading}
                    onScrollBottom={handleOnScrollBottom}
                    header={["Código", "Marca", "Modelo", "Ano", "Cor", "Placa", "Data de Cadastro"]}>
                    {copiaSucatas.length || isLoading ? copiaSucatas.map(sucata =>
                        <Sucata
                            key={sucata.id}
                            sucata={sucata}
                            onContextMenu={event => handleContextMenuClick(event, sucata.id)} />
                    ) : <tr><td className="h5" colSpan="5">Nenhum resultado encontrado</td></tr>}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => deleteSucata(idSucataContextMenu)}>Excluir</li>
                </ContextMenu>
            </div>
        </div>
    ) : (
        <div className="row" style={{ height: "83vh" }}>
            <div style={{ position: "relative", width: "100%", height: "90%" }}>
                <div className={classes.Center}>
                    <h2>Você ainda não tem nenhuma sucata registrada!</h2>
                    <br />
                    <h4>Você pode cadastrar uma sucata clicando <Link to="/cadastro"> aqui</Link>.</h4>
                </div>
            </div>
        </div>
    );
};

export default SucatasScreen;
