import {
    FETCH_RESPOSTAS_PRONTAS,
    FETCH_RESPOSTA_PRONTA,
    UPDATE_RESPOSTA_PRONTA,
    DELETE_RESPOSTA_PRONTA,
    CREATE_RESPOSTA_PRONTA
} from "../actions/respostas-prontas";

const initialState = {
    respostasProntas: [],
    respostaPronta: {}
};

const respostaProntaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESPOSTAS_PRONTAS:
            return { ...state, respostasProntas: action.respostasProntas };
        case FETCH_RESPOSTA_PRONTA:
            return { ...state, respostaPronta: action.respostaPronta };
        case UPDATE_RESPOSTA_PRONTA:
            let respostasProntasAtualizadas = state.respostasProntas.map((respostaPronta) => respostaPronta.id !== action.respostaPronta.id ? respostaPronta : action.respostaPronta);

            return { ...state, respostasProntas: respostasProntasAtualizadas };
        case DELETE_RESPOSTA_PRONTA:
            let respostasProntasNaoDeletadas = state.respostasProntas.filter(
                (respostaPronta) => respostaPronta.id !== action.id
            );
            return { ...state, respostasProntas: respostasProntasNaoDeletadas };
        case CREATE_RESPOSTA_PRONTA:
            let respostasProntas = [...state.respostasProntas];
            respostasProntas.push(action.respostaPronta);
            return { ...state, respostasProntas: respostasProntas };
        default:
            return state;
    }
};

export default respostaProntaReducer;
