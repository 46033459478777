// Dependências
import React, { useState } from "react";
import axios from 'axios';
import { API_URL } from '../../constants/GlobalConfig';
// Css
import classes from "./CardCadastroLoja.module.css";

// Actions
// import * as authActions from "../../store/actions/auth";

// Helpers
import statusHandler from "../../helpers/statusHandler";
import * as validar from "../../helpers/validar";

// Components
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/Conhecer/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import Titulo from "../../components/UI/Titulo/Titulo";
const CardCadastroLoja = props => {
    // const dispatch = useDispatch();

    const cargos = [
        { id: null, nome: "Selecione cargo:" },
        { id: 1, nome: "Sócio/Proprietário" },
        { id: 2, nome: "Gerente" },
        { id: 3, nome: "Administrator da conta no Mercado Livre" },
        { id: 4, nome: "Vendedor (Balcão)" },
        { id: 5, nome: "Outro" },
    ]

    // States dos campos
    const [nomeFantasia, setNomeFantasia] = useState("");
    const [telefone, setTelefone] = useState(false);
    const [nomeResponsavel, setNomeResponsavel] = useState("");
    const [cargo, setCargo] = useState("Selecione cargo:");

    // States de Invalids
    const [nomeFantasiaInvalid, setNomeFantasiaInvalid] = useState(false);
    const [telefoneInvalid, setTelefoneInvalid] = useState(false);
    const [nomeResponsavelInvalid, setNomeResponsavelInvalid] = useState(false);
    const [cargoInvalid, setCargoInvalid] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async event => {
        event.preventDefault();
        let formIsValid = true;

        if (validar.nome(nomeFantasia)) setNomeFantasiaInvalid(false);
        else {
            setNomeFantasiaInvalid(true);
            formIsValid = false;
            setErrorMessage("Nome fantasia inválido");
        };

        if (validar.telefone(telefone) && telefone) setTelefoneInvalid(false);
        else {
            setTelefoneInvalid(true);
            formIsValid = false;
            setErrorMessage("Telefone Inválido");
        };

        if (validar.nome(nomeResponsavel)) setNomeResponsavelInvalid(false);
        else {
            setNomeResponsavelInvalid(true);
            formIsValid = false;
            setErrorMessage("Nome inválido");
        };

        if (cargo && cargo !== 'Selecione cargo:') setCargoInvalid(false);
        else {
            setCargoInvalid(true);
            formIsValid = false;
            setErrorMessage("Cargo inválido");
        }

        if (formIsValid) {
            setIsLoading(true);
            await axios({
                method: 'POST',
                url: `${API_URL}/cadastro-rapido`,
                data: {
                    nomeFantasia: nomeFantasia,
                    telefone: telefone,
                    nomeResponsavel: nomeResponsavel,
                    cargo: cargo,
                }
            }).then(async (result) => {
                setIsLoading(false);
                window.location.href = "https://unificar.mundodapeca.com/obrigado";
            }).catch(error => {
                if (error.response) {
                    setIsLoading(false);

                    statusHandler(error.response.status);
                    console.log(error);
                } else {
                    statusHandler();
                    console.log(error);
                    throw new Error(error);
                }
            });
        }
    };

    return (
        <div className="col-sm-12 p-0 d-flex justify-content-center">
            <div className={classes.Form}>
                <form onSubmit={handleSubmit}>
                    <Titulo subtitulo="Crie seu usuário para testar GRÁTIS!" lightMode>Solicitar Acesso:</Titulo>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                            <div className="col-12 text-left mb-2"> Seu nome: </div>
                                <div className="col-12">
                                    <Input
                                        size="100%"
                                        placeholder="Nome completo"
                                        invalid={nomeResponsavelInvalid}
                                        onChange={(value) => setNomeResponsavel(value)}
                                        name="nome"
                                        lightMode
                                    />
                                </div>
             
                                <div className="col-12 text-left mb-2"> WhatsApp para contato: </div>

                                <div className="col-12">
                                    <Input
                                        size="100%"
                                        placeholder="(99) 99999-99999"
                                        invalid={telefoneInvalid}
                                        onChange={(value) => setTelefone(value)}
                                        name="telefone"
                                        maxLength="15"
                                        lightMode
                                        telefone
                                    />
                                </div>
                                <div className="col-12 text-left mb-2"> Nome da empresa: </div>

                                <div className="col-12">
                                    <Input
                                        size="100%"
                                        placeholder="Nome fantasia"
                                        maxLength="100"
                                        invalid={nomeFantasiaInvalid}
                                        lightMode
                                        onChange={(value) => setNomeFantasia(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 text-left mb-2"> Seu cargo: </div>
                                <div className="col-12 col-md-10 text-left">
                                    <Dropdown
                                        lightMode
                                        list={cargos}
                                        invalid={cargoInvalid}
                                        onChange={value => {
                                            let copiaCargo = cargos.filter(cargo => cargo.id === value)[0].nome;
                                            if (copiaCargo)
                                                setCargo(copiaCargo);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {nomeFantasiaInvalid || telefoneInvalid || nomeResponsavelInvalid ? (
                        <div className="row">
                            <div className={`col-12 ${classes.ErrorMessage}`}>
                                * {errorMessage}
                            </div>
                        </div>
                    ) : null}

                    <div className="row">
                        <div className="col-12 mt-4">
                            {isLoading ? <Spinner /> : (
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Button type="submit" invert className="text-left">
                                            <span>COMEÇAR A TESTAR</span>
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CardCadastroLoja;