import { API_URL } from "../../constants/GlobalConfig"
import axios from 'axios';
import statusHandler from "../../helpers/statusHandler";

export const CREATE_GRUPO_SUCATAS = "CREATE_GRUPO_SUCATAS";
export const DELETE_GRUPO_SUCATA = "DELETE_GRUPO_SUCATA";
export const FETCH_GRUPO_SUCATAS = "FETCH_GRUPO_SUCATAS";
export const FETCH_NEW_GRUPOS_SUCATAS = "FETCH_NEW_GRUPOS_SUCATAS";
export const FETCH_GRUPOS_SUCATAS = "FETCH_GRUPOS_SUCATAS";

export const createGrupoSucatas = grupoSucatas => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/sucatas`,
            headers: {
                Authorization: token
            },
            data: grupoSucatas
        }).then(result => {
            if (result.status === 201) {
                dispatch({ type: CREATE_GRUPO_SUCATAS, grupoSucatas: result.data });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deleteGrupoSucata = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(`${API_URL}/grupos-sucatas/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            await dispatch({ type: DELETE_GRUPO_SUCATA, id: id });
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchGrupoSucatas = (id, idStatus) => {
    return async (dispatch, getState) => {
        if (!id)
            return;

        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/grupos-sucatas/${id}${idStatus ? `?status=${idStatus}` : ""}`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let grupoSucatas = [];
            try {
                grupoSucatas = await response.json();
                dispatch({ type: FETCH_GRUPO_SUCATAS, grupoSucatas: grupoSucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchGruposSucatas = (doravante, busca, ordem, quantidade) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/grupos-sucatas?cadastro=${false}${quantidade ? `&quantidade=${quantidade}` : ""}${doravante && quantidade ? `&doravante=${doravante - 1}` : ""} ${busca ? `&busca=${busca}` : ""} ${ordem ? `&ordem=${ordem}` : ""} `, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let gruposSucatas = [];
            try {
                gruposSucatas = await response.json();
                dispatch({ type: FETCH_GRUPOS_SUCATAS, gruposSucatas: gruposSucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchGruposSucatasNaoCadastradas = (doravante, busca, ordem, quantidade) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/grupos-sucatas?cadastro=${true}${quantidade ? `&quantidade=${quantidade}` : ""}${doravante && quantidade ? `&doravante=${doravante - 1}` : ""}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let gruposSucatas = [];
            try {
                gruposSucatas = await response.json();
                dispatch({ type: FETCH_GRUPOS_SUCATAS, gruposSucatas: gruposSucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const updateStatus = (idGrupoSucatas, idStatus) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/grupos-sucatas/${idGrupoSucatas}/status`,
            headers: { Authorization: token },
            data: idStatus ? { status: { id: idStatus } } : undefined
        }).then(result => {
            if (result.status !== 200) {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};