// Dependências
import React from "react";
import { Pie } from "react-chartjs-2";

const Grafico = props => {
    return !props.data?.length ? null : (
        <Pie
            options={{
                animation: {
                    duration: 0
                },
                legend: {
                    display: props.labels?.length ? true : false,
                    position: props.legendPosition || "left",
                    labels: {
                        fontSize: 12,
                        fontFamily: "Quicksand",
                    },
                },
                title: {
                    display: props.title ? true : false,
                    text: props.title,
                    fontSize: 20,
                    fontFamily: "Quicksand",
                },
            }}
            data={{
                labels: props.labels,
                datasets: [
                    {
                        data: props.data,
                        backgroundColor: ["#3b3e51", "#c34858", "#ecc34d"],
                        borderWidth: 0,
                    },
                ],
            }}
        />
    );
};

export default Grafico;
