// Dependências
import React, { useEffect, useState } from "react";

// Css
import classes from "./Button.module.css";

const Button = props => {
    const [backgroundColor, setBackgroundColor] = useState("");
    const [textColor, setTextColor] = useState("");

    useEffect(() => {
        switch (props.color) {
            case "blue":
                setBackgroundColor("#3b3e51");
                setTextColor("#fff");
                break;
            case "purple":
                setBackgroundColor("#593196");
                setTextColor("#fff");
                break;
            case "red":
                setBackgroundColor("#c34858");
                setTextColor("#fff");
                break;
            case "green":
                setBackgroundColor("#218838");
                setTextColor("#fff");
                break;
            case "gray":
                setBackgroundColor("#6c757d");
                setTextColor("#fff");
                break;
            default:
                if (props.color)
                    setBackgroundColor(props.color);
                else
                    setBackgroundColor("#593196");
        };

        switch (props.textColor) {
            case "purple":
                setTextColor("#593196");
                break;
            case "red":
                setTextColor("#c34858");
                break;
            case "green":
                setTextColor("#79cd56");
                break;
            case "gray":
                setTextColor("#6c757d");
                break;
            default:
                if (props.textColor)
                    setTextColor(props.textColor);
                else
                    setTextColor("#fff");
        };
    }, [props.color, props.textColor])

    return (
        <button
            type={props.type ? props.type : "button"}
            disabled={props.disabled}
            className={`${classes.Button} ${props.className}`}
            style={{
                backgroundColor: backgroundColor,
                color: textColor,
                fontSize: props.small ? "small" : "inherit",
                ...props.style
            }}
            onClick={() => props.onClick ? props.onClick() : null}
        >{props.children}</button>
    );
};

export default Button;