// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Actions
import * as pecasTiposActions from "../../../../store/actions/pecas-tipos";

// Components
import Arquetipo from "./Arquetipo/Arquetipo";
import BarraPesquisa from "../../../UI/BarraPesquisa/BarraPesquisa";
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import ButtonCreatePeca from "./ButtonCreatePeca/ButtonCreatePeca";
import Button from "../../../UI/Button/Button";
import ContextMenu from "../../../UI/ContextMenu/ContextMenu";
import Dropdown from "../../../UI/BeautyDropdown/BeautyDropdown";
import Spinner from "../../../UI/Spinner/Spinner";
import Table from "../../../UI/Table/Table";
import Titulo from "../../../UI/Titulo/Titulo";

// Css
import classes from "./SelecaoPecas.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import * as validar from "../../../../helpers/validar";

const SelecaoPecas = props => {
    const dispatch = useDispatch();

    const areas = useSelector(state => state.pecasTipos.areas);
    const arquetipos = useSelector(state => state.pecasTipos.arquetipos);
    const usuario = useSelector(state => state.usuario.meuUsuario);

    // Todos os arquetipos que o usuário selecionou ou não para gerar peças
    const [arquetiposSelecionados, setArquetiposSelecionados] = useState([]);
    // Copia para fazer filtragem e buscas
    const [copiaArquetiposSelecionados, setCopiaArquetiposSelecionados] = useState([]);

    const [busca, setBusca] = useState("");
    const [area, setArea] = useState({ id: 0, nome: "Todas" });
    const [isLoading, setIsLoading] = useState(false);
    const [selecaoRapida, setSelecaoRapida] = useState(false);
    const [showTip, setShowTip] = useState(true);

    const [keyArquetipoContextMenu, setKeyArquetipoContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição das areas
    useEffect(() => {
        const fetchAreas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasTiposActions.fetchAreas());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (!areas.length)
            fetchAreas();
    }, [areas.length, dispatch]);

    // Requisição dos arquetipos
    useEffect(() => {
        const fetchArquetipos = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasTiposActions.fetchArquetipos(props.idSucata));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchArquetipos();
    }, [dispatch, props.idSucata]);

    // Setando arquetiposSelecionados
    useEffect(() => {
        setArquetiposSelecionados([...arquetipos.filter(arquetipo => arquetipo.id !== 350).map(arquetipo => {
            return {
                ...arquetipo,
                nomePeca: arquetipo.nome,
                anunciar: false,
                revisar: false,
                quantidade: arquetipo.quantidadePadrao,
                selecaoRapida: arquetipo.selecaoRapida,
                observacao: ""
            };
        })]);
    }, [arquetipos]);

    // Setando copiaArquetiposSelecionados
    useEffect(() => {
        const buscar = arquetiposFiltrados => {
            if (busca) {
                arquetiposFiltrados = arquetiposFiltrados.filter(arquetipo => {
                    if (arquetipo.nomePeca)
                        return (
                            formatar.alphaNumeric(arquetipo.nome).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(arquetipo.nomePeca).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(arquetipo.area.nome).includes(formatar.alphaNumeric(busca))
                        );
                    else
                        return (
                            formatar.alphaNumeric(arquetipo.nome).includes(formatar.alphaNumeric(busca)) ||
                            formatar.alphaNumeric(arquetipo.area.nome).includes(formatar.alphaNumeric(busca))
                        );
                });
            } else
                arquetiposFiltrados = [...arquetiposFiltrados];

            setCopiaArquetiposSelecionados([...arquetiposFiltrados]);
        };

        if (area.id && area.id > 0)
            buscar(arquetiposSelecionados.filter(arquetipo => arquetipo.area.id === area.id));
        else if (!area.id)
            buscar([...arquetiposSelecionados]);
        else if (area.id === -1)
            buscar(arquetiposSelecionados.filter(arquetipo => arquetipo.revisar));
        else if (area.id === -2)
            buscar(arquetiposSelecionados.filter(arquetipo => arquetipo.anunciar));
    }, [arquetiposSelecionados, busca, area.id]);

    // Setando selecaoRapida
    useEffect(() => {
        setSelecaoRapida(copiaArquetiposSelecionados.filter(arquetipo => (arquetipo.anunciar || arquetipo.revisar) && arquetipo.selecaoRapida).length >= copiaArquetiposSelecionados.filter(arquetipo => arquetipo.selecaoRapida).length / 2);
    }, [copiaArquetiposSelecionados]);

    // Exibe primeira dica
    useEffect(() => {
        /* exibirMensagem.tip({
            html: `
                <div class="row">
                    <div class="col-4 py-3">
                        <span style="background-color: #79cd5666; padding: 2px 10px; border-radius: 4px; margin: 0px 5px;">
                            Anunciar
                        </span>
                    </div>
                    <div class="col-8 text-left">
                        Marque em VERDE as peças que deseja anunciar.
                    </div>
                </div>
                
                <div class="row mb-3">
                    <div class="col-4 py-5">
                        <span style="background-color: #593196; color: #fff; padding: 2px 10px; border-radius: 4px; margin: 0px 5px;">
                            Pendente
                        </span>
                    </div>
                    <div class="col-8 text-left py-2">
                    Marque em ROXO as peças que você não conseguiu confirmar se estão aptas para serem anunciadas. Estas peças ficarão pendentes para que o supervisor da loja as confirme na próxima tela.
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-4 py-3">
                        <span style="border: 1px solid #000; padding: 2px 10px; border-radius: 4px; margin: 0px 5px;">
                            Não anunciar
                        </span>
                    </div>
                    <div class="col-8 text-left">
                        Marque em BRANCO as peças que não deseja anunciar (indisponíveis ou danificadas).
                    </div>
                </div>
            `
        }); */
    }, []);

    // Exibe segunda dica
    useEffect(() => {
        if (showTip && arquetiposSelecionados.filter(arquetipo => arquetipo.anunciar || arquetipo.revisar).length === 5) {
            exibirMensagem.tip({
                html: `
                    <div style="text-align: justify">
                        Para facilitar a seleção, utilize o filtro de partes do carro, localizado na parte superior direita da tela. 
                        <br />
                        Quando a sucata estiver bastante íntegra nessa parte, clique em ATIVAR SELEÇÃO INTELIGENTE, assim as principais peças serão selecionadas automaticamente.
                    </div>
                `
            });

            setShowTip(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arquetiposSelecionados]);

    const createArquetipo = () => {
        if (arquetipos.length) {
            const newArquetipo = arquetipos.find(arquetipo => arquetipo.id === 350);

            if (newArquetipo) {
                if (arquetiposSelecionados.filter(arquetipo => arquetipo.id === 350).length < newArquetipo.maximoPorSucata) {
                    exibirMensagem.exibir({
                        title: 'Digite o nome da peça',
                        input: 'text',
                        inputValue: busca,
                        inputValidator: value => {
                            if (!validar.nome(value))
                                return 'O nome informado não é válido'
                        },
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Salvar'
                    }).then(value => {
                        if (value.isConfirmed)
                            setArquetiposSelecionados([{
                                ...newArquetipo,
                                key: uuidv4(),
                                nomePeca: value.value,
                                anunciar: true,
                                revisar: false,
                                quantidade: 1,
                                maximoPorSucata: 1,
                                observacao: ""
                            }, ...arquetiposSelecionados]);
                    });
                } else {
                    exibirMensagem.warning("Você já adicionou o máximo de peças adicionais nessa sucata.");
                };
            };
        };
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, keyArquetipo) => {
        event.preventDefault();

        setKeyArquetipoContextMenu(keyArquetipo);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const handleSelecaoInteligenteClick = () => {
        let arquetiposSelecionadosAtualizados = [...arquetiposSelecionados];
        arquetiposSelecionadosAtualizados = arquetiposSelecionadosAtualizados.map(arquetipo => {
            if (!arquetipo.selecaoRapida || (arquetipo.area.id !== area.id && area.id !== 0))
                return arquetipo;
            else {
                return {
                    ...arquetipo,
                    anunciar: arquetipo.revisar ? false : selecaoRapida ? false : true
                };
            };
        });

        setArquetiposSelecionados([...arquetiposSelecionadosAtualizados]);
    };

    const selectAll = () => {
        let arquetiposSelecionadosAtualizados = [...arquetiposSelecionados];

        arquetiposSelecionadosAtualizados = arquetiposSelecionadosAtualizados.map(arquetipo => {
            return {
                ...arquetipo,
                anunciar: arquetipo.anunciar ? false : true
            };
        });

        setArquetiposSelecionados([...arquetiposSelecionadosAtualizados]);
    };

    const submitHandler = () => {
        if (!arquetiposSelecionados.find(arquetipo => arquetipo.anunciar || arquetipo.revisar))
            exibirMensagem.warning("Selecione ao menos uma peça para prosseguir o processo de cadastro.");
        else
            props.onProxClick(arquetiposSelecionados.filter(peca => peca.anunciar || peca.revisar).map(peca => {
                return {
                    nome: peca.nomePeca || peca.nome,
                    arquetipo: { id: peca.id, nome: peca.nome },
                    quantidade: peca.quantidade,
                    observacao: peca.observacao,
                    revisar: peca.revisar
                };
            }));
    };

    return isLoading || !arquetipos.length || !areas.length ? <Spinner /> : (
        <div className="container-fluid pb-4">
            <Titulo>Selecione as peças que deseja anunciar</Titulo>

            {usuario?.conta?.tipo?.id === 4 ? (
                <div className={classes.SelectAll}>
                    <Button onClick={selectAll}>
                        Selecionar tudo
                    </Button>
                </div>
            ) : null}

            <div className="row">
                <div className="col-8">
                    <BarraPesquisa cleanOption onChange={value => setBusca(value)} />
                </div>
                <div className="col-4 text-center">
                    <Dropdown
                        inline
                        returnObject
                        list={[{ id: 0, nome: "Todas" }, { id: -2, nome: "Para anunciar" }, { id: -1, nome: "Para revisão" }, ...areas]}
                        onChange={novaArea => setArea({ ...novaArea })} />
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-8">
                            <span className={classes.AnunciarLabel}>Anunciar</span>
                            <span className={classes.RevisaoLabel}>Pendente</span>
                            <span className={classes.AusenteLabel}>Ausente/Danificada/Não anunciar</span>
                        </div>
                        {area.id >= 0 ? (
                            <div className="col-sm-12 col-md-4 text-sm-left text-md-right mb-1">
                                <Button
                                    small
                                    color={selecaoRapida ? "gray" : "green"}
                                    onClick={handleSelecaoInteligenteClick}
                                >
                                    {selecaoRapida ? "Desativar seleção inteligente" : "Ativar seleção inteligente"} - {area.nome}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="row" style={{ height: "64vh" }}>
                <Table
                    fixed
                    hoverble
                    header={["Peça", "Quantidade", "Obs."]}>
                    {copiaArquetiposSelecionados.length ? copiaArquetiposSelecionados.map(arquetipo =>
                        <Arquetipo
                            key={arquetipo.id === 350 ? arquetipo.key : arquetipo.id}
                            arquetipo={arquetipo}
                            onContextMenu={event => arquetipo.key ? handleContextMenuClick(event, arquetipo.key) : null}
                            onChange={pecaAtualizada => {
                                let arquetiposSelecionadosAtualizados = [...arquetiposSelecionados];
                                arquetiposSelecionadosAtualizados = arquetiposSelecionadosAtualizados.map(arquetipo => {
                                    if (arquetipo.key)
                                        return arquetipo.key === pecaAtualizada.key ? pecaAtualizada : arquetipo;
                                    else
                                        return arquetipo.id === pecaAtualizada.id ? pecaAtualizada : arquetipo;
                                });

                                setArquetiposSelecionados([...arquetiposSelecionadosAtualizados]);
                            }} />
                    ) :
                        <ButtonCreatePeca onClick={createArquetipo} />}

                    {busca.length && copiaArquetiposSelecionados.length ?
                        <ButtonCreatePeca onClick={createArquetipo} />
                        : null}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => {
                        if (keyArquetipoContextMenu) {
                            setArquetiposSelecionados(arquetiposSelecionados.filter(arquetipo => arquetipo.key !== keyArquetipoContextMenu));
                            setDisplayContextMenu(false);
                        };
                    }}>Excluir</li>
                </ContextMenu>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default SelecaoPecas;