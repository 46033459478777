// Dependências
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as pecasActions from "../../../store/actions/pecas";
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import { FiPlus, FiPrinter } from 'react-icons/fi'

// Components
import Spinner from "../../../components/UI/Spinner/Spinner";
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../components/UI/Button/Button";
import Table from "../../../components/UI/Table/Table";
import LocalizacaoRow from "./LocalizacaoRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { renderToString } from "react-dom/server"
import { QRCodeSVG } from "qrcode.react";
import svgToDataUri from "../../../utils/svgToDataUri";
import Etiqueta from "../Etiqueta";
import LocalizacaoModal from "./LocalizacaoModal";

// Css

const GerenciarScreen = props => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const localizacoes = useSelector(state => state.pecas.localizacoes);
    const [showLocalizacaoModal, setShowLocalizacaoModal] = useState(false);
    const [selectedLocalizacao, setSelectedLocalizacao] = useState(null);
    
    useEffect(() => {
        const fetchLocalizacoes = async () => {
            try {
                await dispatch(pecasActions.fetchLocalizacoes());
            } catch (error) {
                console.log(error);
            } 
        };
        fetchLocalizacoes();
    }, [dispatch]);

    useEffect(() => {
        if (localizacoes.length > 0) {
            setIsLoading(false);
        }
    }, [localizacoes])

    const [qrCodes, setQrCodes] = useState([]);

    useEffect(() => {
        async function convertSvgToDataUri() {
            console.log({localizacoes})
            if(localizacoes.length > 0){
                console.log(1)
                let qrCodes = [];
                for(const loc of localizacoes){
                    if(loc){
                        const svg = (
                            <QRCodeSVG
                            value={`${loc.id}`}
                            />
                        )
                    
                        const dataUri = await svgToDataUri(renderToString(svg))
                        qrCodes.push({nome: loc.nome, qrCode: dataUri || ''})
                    }
                }
                setQrCodes(qrCodes)
            }
        }
        convertSvgToDataUri()
    }, [localizacoes]);

    const handleDeleteLocalizacao = useCallback(async (id) => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta localização? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            console.log(result)
            if (result.isConfirmed) {
                try {
                    await dispatch(pecasActions.deleteLocalizacao(id));
                } catch (error) {
                    console.log(error);
                }
            } else {
                return;
            }
        })
        
    }, [dispatch]);

    const handleEditLocalizacao = useCallback(async (id) => {
        setSelectedLocalizacao(id);
        setShowLocalizacaoModal(true);
    }, []);

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h4>Localizações</h4>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-8">
                    <BarraPesquisa onChange={busca => console.log(busca)} />
                </div>
                <Button
                    color="green"
                    onClick={() => setShowLocalizacaoModal(true)}
                    style={{ height: '40px', margin: 0}}
                >
                    <FiPlus style={{ marginRight: 6}} />
                    Nova localização
                </Button>
                {qrCodes.length > 0 && (
                    <PDFDownloadLink
                        document={<Etiqueta localizacao={qrCodes.length > 0 ? qrCodes : []} />}
                        fileName={`etiquetas.pdf`}
                    >
                        {({loading}) => (
                            <Button
                                color="purple"
                                onClick={() => {}}
                                style={{ height: '40px', margin: 0, marginLeft: '10px'}}
                            >
                                <FiPrinter style={{ marginRight: 6}}/>
                                {loading ? 'Loading' : 'Imprimir todas'}
                            </Button>
                        )}
                    </PDFDownloadLink>
                )}
            </div>
            <div className={`row`}>
                <Table
                    fixed
                    hoverble
                    header={["Id", "Nome", "Qtdade Peça", "Imprimir", "Editar", "Deletar"]}>
                    {localizacoes.map(loc =>
                        <LocalizacaoRow
                            key={loc.id}
                            localizacao={loc}
                            onEdit={() => handleEditLocalizacao(loc)}	
                            onPrint={(id) => {console.log(id)}}	
                            onDelete={(id) => handleDeleteLocalizacao(id)}	
                        />
                    )}
                </Table>
            </div>
            {showLocalizacaoModal && (
                <LocalizacaoModal
                    onClose={() => setShowLocalizacaoModal(false)}
                    localizacao={selectedLocalizacao}
                />
            )}
        </div>
    );
};

export default GerenciarScreen;