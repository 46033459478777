// Dependências
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { BsFillLockFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { FiAtSign } from "react-icons/fi";
import { API_URL } from "../../constants/GlobalConfig";

// Css
import classes from "./LoginScreen.module.css";

// Actions
import * as authActions from "../../store/actions/auth";

// Helpers
import statusHandler from "../../helpers/statusHandler";
import * as validar from "../../helpers/validar";

// Components
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import Titulo from "../../components/UI/Titulo/Titulo";

const LoginScreen = props => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.colorPalette.darkMode);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const [nome, setNome] = useState("Administrador");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const [isValid, setIsValid] = useState({
        nome: true,
        email: true,
        senha: true
    });

    const [displaySenha, setDisplaySenha] = useState(false);
    const [lembreDeMim, setLembreDeMim] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    // Verifica se usuário esta logado e redireciona
    useEffect(() => {
        if (isAuthenticated)
            props.history.push('/inicio');
    }, [isAuthenticated, props.history]);

    const handleSubmit = async event => {
        event.preventDefault();

        let formIsValid = true;
        let copiaIsValid = { ...isValid };
        copiaIsValid.email = true;

        if (!validar.nome(nome)) {
            copiaIsValid.nome = false;
            formIsValid = false;
        } else
            copiaIsValid.nome = true;

        if (!validar.email(email)) {
            copiaIsValid.email = false;
            formIsValid = false;
        } else
            copiaIsValid.email = true;

        if (!validar.senha(senha)) {
            copiaIsValid.senha = false;
            formIsValid = false;
        } else
            copiaIsValid.senha = true;

        if (formIsValid) {
            setIsLoading(true);
            await axios({
                method: 'POST',
                url: `${API_URL}/login`,
                data: { nome: nome, email: email, senha: senha }
            }).then(async (result) => {
                setIsLoading(false);

                await dispatch(authActions.saveToken(result.data.access_token, lembreDeMim));
                props.history.push('/inicio');
            }).catch(error => {
                setIsLoading(false);

                if (error.response?.status === 401) {
                    copiaIsValid.nome = false;
                    copiaIsValid.email = false;
                    copiaIsValid.senha = false;
                } else
                    statusHandler(error.response?.status);
            });
        };

        setIsValid(copiaIsValid);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={darkMode ? classes.CardDark : classes.CardLight}>
                <Titulo subtitulo="Entre com sua conta para prosseguir">Bem Vindo!</Titulo>

                <div className="row mb-3">
                    <div className="col-2 text-right d-none d-md-block">
                        <FaUserAlt title="Usuário" size="30" />
                    </div>
                    <div className="col-12 col-md-10">
                        <Input
                            enterHandler
                            size="100%"
                            name="nickname"
                            maxLength="255"
                            invalid={!isValid.nome}
                            value="Administrador"
                            placeholder="Nome de usuário"
                            onChange={value => setNome(value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 text-right d-none d-md-block">
                        <FiAtSign title="E-mail" size="30" />
                    </div>
                    <div className="col-12 col-md-10">
                        <Input
                            enterHandler
                            size="100%"
                            placeholder="E-mail"
                            invalid={!isValid.email}
                            onChange={(value) => setEmail(value)}
                            name="email"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 text-right d-none d-md-block">
                        <BsFillLockFill title="Senha" size="30" />
                    </div>
                    <div className="col-12 col-md-10">
                        <Input
                            enterHandler
                            size="100%"
                            placeholder="Senha"
                            type={displaySenha ? "text" : "password"}
                            invalid={!isValid.senha}
                            onChange={value => setSenha(value)}
                        />

                        {displaySenha ? (
                            <AiOutlineEye
                                size="30"
                                title="Ocultar senha"
                                className={classes.Eye}
                                onClick={() => setDisplaySenha(false)}
                            />
                        ) : (
                            <AiOutlineEyeInvisible
                                size="30"
                                title="Mostrar senha"
                                className={classes.Eye}
                                onClick={() => setDisplaySenha(true)}
                            />
                        )}
                    </div>
                </div>

                {Object.entries(isValid).find(validade => !validade[1]) ? (
                    <div className="row">
                        <div className={`col-12 ${classes.ErrorMessage}`}>
                            {!isValid.email && !isValid.senha && !isValid.nome ? (
                                "* Senha, E-mail ou usuário incorretos"
                            ) : !isValid.nome ? (
                                "* Nome de usuário não existe"
                            ) : !isValid.email ? (
                                "* Email não existe"
                            ) : (
                                "* Informe uma senha válida"
                            )}
                        </div>
                    </div>
                ) : null}

                <div className={`row ${classes.Rodape}`}>
                    <div className="col-6 text-left">
                        <Checkbox
                            defaultChecked={lembreDeMim}
                            onChange={() => setLembreDeMim(!lembreDeMim)}
                            size="small"
                        >Lembre de mim</Checkbox>
                    </div>
                    <div className="col-6 text-right">
                        Novo por aqui? <Link to="/conhecer">Registre-se</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4">
                        {isLoading ? <Spinner /> : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <Button
                                        type="submit"
                                        color="green"
                                        className="w-50"
                                    >Entrar</Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginScreen;