import {
    CREATE_DESPESA,
    DELETE_DESPESA,
    FETCH_CATEGORIA_DESPESAS,
    FETCH_CATEGORIAS_DESPESAS,
    FETCH_DADOS_MENSAIS_DESPESAS,
    FETCH_DESPESA,
    FETCH_DESPESAS,
    FETCH_FREQUENCIAS_DESPESAS,
    FETCH_OPCOES_DESPESAS,
    UPDATE_DESPESA
} from "../actions/despesas";

const initialState = {
    despesa: {},
    despesas: [],
    categoria: {},
    categorias: [],
    dadosMensais: [],
    frequencias: [],
    opcoes: []
};

const despesasReducer = (state = initialState, action) => {
    let copiaDespesas = [...state.despesas];

    switch (action.type) {
        case CREATE_DESPESA:
            copiaDespesas.unshift({ ...action.despesa });
            return { ...state, despesas: copiaDespesas };
        case DELETE_DESPESA:
            copiaDespesas = copiaDespesas.filter(despesa => despesa.id !== parseInt(action.id));
            return { ...state, despesas: copiaDespesas };
        case FETCH_CATEGORIA_DESPESAS:
            return { ...state, categoria: action.categoria };
        case FETCH_CATEGORIAS_DESPESAS:
            return { ...state, categorias: action.categorias };
        case FETCH_DADOS_MENSAIS_DESPESAS:
            return { ...state, dadosMensais: action.dadosMensais };
        case FETCH_DESPESA:
            return { ...state, despesa: action.despesa };
        case FETCH_DESPESAS:
            return { ...state, despesas: action.despesas };
        case FETCH_FREQUENCIAS_DESPESAS:
            return { ...state, frequencias: action.frequencias };
        case FETCH_OPCOES_DESPESAS:
            return { ...state, opcoes: action.opcoes };
        case UPDATE_DESPESA:
            copiaDespesas = copiaDespesas.map(despesa => despesa.id === action.despesa.id ? action.despesa : despesa);
            return { ...state, despesas: copiaDespesas };
        default:
            return state;
    }
};

export default despesasReducer;
