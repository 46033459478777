import axios from 'axios';
import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const CREATE_MENSAGEM = "CREATE_MENSAGEM";
export const DELETE_ARQUETIPOS_DETRAN = "DELETE_ARQUETIPOS_DETRAN";
export const DELETE_MENSAGEM = "DELETE_MENSAGEM";
export const FETCH_ARQUETIPOS_DETRAN = "FETCH_ARQUETIPOS_DETRAN";
export const FETCH_CONTA_TOKEN = "FETCH_CONTA_TOKEN";
export const FETCH_CONTAS = "FETCH_CONTAS";
export const FETCH_MENSAGENS = "FETCH_MENSAGENS";

export const createArquetipoDetran = arquetipoDetran => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/administrador/arquetipos-detran`,
            headers: { Authorization: token },
            data: arquetipoDetran
        }).then((result) => {
            if (result.status === 201) {
                let arquetiposDetran = [...result.data];
                dispatch({ type: FETCH_ARQUETIPOS_DETRAN, arquetiposDetran: arquetiposDetran });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const createMensagem = mensagem => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/administrador/mensagens`,
            headers: { Authorization: token },
            data: mensagem
        }).then(result => {
            if (result.status === 201) {
                let mensagemCriada = { ...result.data };
                dispatch({ type: CREATE_MENSAGEM, mensagem: mensagemCriada, contas: [...mensagem.contas] });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteArquetipoDetran = (nomeArquetipoDetran, idArquetipo) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/administrador/arquetipos-detran/${nomeArquetipoDetran}${idArquetipo ? `?id=${idArquetipo}` : ""}`,
            headers: { Authorization: token }
        }).then((result) => {
            if (result.status === 200)
                dispatch({ type: DELETE_ARQUETIPOS_DETRAN, nomeArquetipoDetran: nomeArquetipoDetran, idArquetipo: idArquetipo });
            else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteMensagem = idMensagem => {
    return async (dispatch, getState) => {
        if (!idMensagem)
            return;

        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/administrador/mensagens/${idMensagem}`,
            headers: { Authorization: token }
        }).then((result) => {
            if (result.status === 200)
                dispatch({ type: DELETE_MENSAGEM, idMensagem: idMensagem });
            else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchArquetiposDetran = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/administrador/arquetipos-detran`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let arquetiposDetran = [];
            try {
                arquetiposDetran = await response.json();
                dispatch({ type: FETCH_ARQUETIPOS_DETRAN, arquetiposDetran: arquetiposDetran });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchContas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/administrador/contas`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let contas = [];
            try {
                contas = await response.json();
                dispatch({ type: FETCH_CONTAS, contas: contas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchMensagens = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/administrador/mensagens`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let mensagens = [];
            try {
                mensagens = await response.json();
                dispatch({ type: FETCH_MENSAGENS, mensagens: mensagens });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const switchConta = idConta => {
    return async (dispatch, getState) => {
        if (idConta) {
            const token = getState().auth.token;
            const response = await fetch(`${API_URL}/administrador/contas/${idConta}/token`, {
                headers: { Authorization: token },
            });

            if (response.ok) {
                let novoToken = "";
                try {
                    novoToken = await response.json();
                    novoToken = "Bearer " + novoToken.access_token
                    dispatch({ type: FETCH_CONTA_TOKEN, token: novoToken, tokenReserva: token });
                } catch (error) {
                    throw new Error("Algo deu errado");
                }
            } else {
                if (response.status) {
                    statusHandler(response.status);
                } else {
                    statusHandler();
                    throw new Error("Algo deu errado");
                };
            };
        } else {
            const token = getState().auth.tokenReserva;
            dispatch({ type: FETCH_CONTA_TOKEN, token: token, tokenReserva: null });
        };
    };
};