// Dependências
import React from "react";

// Components
import BeautyDropdown from "../../../../UI/BeautyDropdown/BeautyDropdown";
import BeautyInput from "../../../../UI/BeautyInput/BeautyInput";

const NumberUnit = props => {
    return (
        <div className="row">
            <div className="col-10">
                <BeautyInput
                    small
                    notInline
                    onlyNumbers
                    placeholder={props.atributo.nome}
                    defaultValue={props.atributo.value.number}
                    maxLength={props.atributo.maxLength}
                    hint={props.atributo.dica}
                    tooltip={props.atributo.tooltip}
                    invalid={props.atributo.isValid === false ? true : false}
                    onBlur={event => {
                        let copiaAtributo = { ...props.atributo };
                        copiaAtributo.value.number = parseFloat(event.target.value) || null;
                        props.onChange(copiaAtributo);
                    }} />
            </div>
            <div className="col-2">
                <BeautyDropdown
                    small
                    notInline
                    returnObject
                    default={props.atributo.value.unit?.nome}
                    list={props.atributo.valores}
                    onChange={value => {
                        let copiaAtributo = { ...props.atributo };
                        copiaAtributo.value.unit = value;
                        props.onChange(copiaAtributo);
                    }} />
            </div>
        </div>
    );
};

export default NumberUnit;