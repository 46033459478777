

const getEtapaCadastroSucata = (idStatus, idSucata) => {
    switch (idStatus) {
        case 3:
            return `/cadastro/sucata/selecao-pecas/${idSucata}`;
        case 4:
            return `/cadastro/sucata/pecas-em-revisao/${idSucata}`;
        case 350:
            return `/cadastro/sucata/categorias/${idSucata}`;
        case 5:
            return `/cadastro/sucata/upload-fotos/${idSucata}`;
        case 6:
            return `/cadastro/sucata/medidas-pecas/${idSucata}`;
        case 7:
            return `/cadastro/sucata/ficha-tecnica/${idSucata}`;
        case 8:
            return `/cadastro/sucata/titulo-sucata/${idSucata}`;
        case 9:
            return `/cadastro/sucata/titulo-pecas/${idSucata}`;
        case 10:
            return `/cadastro/sucata/fotos-pecas/${idSucata}`;
        case 11:
            return `/cadastro/sucata/despesas/${idSucata}`;
        case 13:
            return `/cadastro/sucata/precificacao/${idSucata}`;
        case 12:
            return `/cadastro/sucata/selecao-tipos-anuncio/${idSucata}`;
        default:
            return "/cadastro/sucata";
    };
};

export default getEtapaCadastroSucata;