import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig"

export const FETCH_FINANCEIRO = "FETCH_FINANCEIRO";
export const FETCH_RESET_FINANCEIRO = "FETCH_RESET_FINANCEIRO";
export const FETCH_FINANCEIRO_SUCATA = "FETCH_FINANCEIRO_SUCATA";

export const fetchFinanceiro = (doravante = 1, busca, ordem, quantidade = 50) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/financeiro?quantidade=${quantidade}&doravante=${doravante - 1}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let financeiro = [];
            try {
                financeiro = await response.json();
                dispatch({ type: doravante === 1 ? FETCH_RESET_FINANCEIRO : FETCH_FINANCEIRO, financeiro: financeiro });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};