// Dependências
import React from "react";
import { useDispatch } from "react-redux";
import { BsTrashFill } from "react-icons/bs";

// Actions
import * as adminActions from "../../../store/actions/admin";

// Components
import Button from "../../UI/Button/Button";

// Css
import classes from "./Mensagem.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

const Mensagem = props => {
    const dispatch = useDispatch();

    const deleteMensagem = async () => {
        try {
            await dispatch(adminActions.deleteMensagem(props.mensagem.id));
            exibirMensagem.success("Mensagem apagada!");
        } catch (error) {
            console.log(error);
        };
    };

    const experimentarMensagem = () => {
        exibirMensagem.exibir({
            icon: props.mensagem.icone,
            title: props.mensagem.titulo,
            text: props.mensagem.corpo,
            allowOutsideClick: true,
            confirmButtonText: props.mensagem.textoBotao || "Ok"
        });
    };

    return (
        <tr>
            <td>{props.mensagem.id}</td>
            <td>{props.mensagem.caminho}</td>
            <td><Button onClick={experimentarMensagem}>Visualizar</Button></td>
            <td style={{ position: "relative" }}>
                {props.mensagem.contas.map(conta =>
                    <div key={conta.id}>{conta.nome} ({conta.quantidade}/{props.mensagem.quantidade || "Sempre"})</div>
                )}

                <BsTrashFill
                    title="Apagar mensagem"
                    className={classes.DeleteButton}
                    onClick={deleteMensagem} />
            </td>
        </tr>
    );
};

export default Mensagem;