// Dependências
import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import * as despesasActions from "../../../../store/actions/despesas";
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import Despesas from "../../../../components/Cadastro/Sucata/Despesas/Despesas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const DespesasScreen = props => {
    const dispatch = useDispatch();
    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async despesas => {
        setIsLoading(true);

        try {
            if (despesas.length) {
                await dispatch(despesasActions.createDespesasSucata(idSucata, despesas));
                exibirMensagem.success("Despesas cadastradas com sucesso.");
            } else
                await dispatch(sucatasActions.updateStatusSucata(idSucata));

            props.history.push(`/cadastro/sucata/precificacao/${idSucata}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div>
            <Despesas
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={despesas => submitHandler(despesas)}
            />
        </div>
    );
};

export default DespesasScreen;