export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const saveToken = (token, lembreDeMim) => {
    if (!token)
        return;

    return async (dispatch) => {
        if (lembreDeMim) {
            localStorage.setItem("bearerToken", JSON.stringify(`Bearer ${token}`));
            sessionStorage.setItem("bearerToken", JSON.stringify(""));
        } else {
            sessionStorage.setItem("bearerToken", JSON.stringify(`Bearer ${token}`));
            localStorage.setItem("bearerToken", JSON.stringify(""));
        };

        try {
            dispatch({ type: LOGIN, token: `Bearer ${token}` });
        } catch (error) {
            throw new Error(error);
        }
    };
};

export const logout = () => {
    return async (dispatch) => {
        localStorage.setItem("bearerToken", JSON.stringify(""));
        sessionStorage.setItem("bearerToken", JSON.stringify(""));

        try {
            dispatch({ type: LOGOUT });
        } catch (error) {
            throw new Error(error);
        }
    };
};