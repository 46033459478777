// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./Card.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Card = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return props.plataforma ? (
        <div
            className={classes.Card}
            style={{ color: darkMode ? "#fff" : "#000", borderColor: darkMode ? "#fff" : "#000" }}
            onClick={props.onClick}
        >
            <div className="row mb-1" style={{ minHeight: "60px" }}>
                <div className="col-8">
                    <p className={classes.Titulo}>{props.titulo}</p>
                </div>

                <div className="col-4">
                    <img src={props.img} className={classes.Image} alt="" />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className={classes.TextoCard}>
                        <p>
                            <span className={classes.TituloCard}>Vendas:</span>
                            <span className={classes.ValueCard}>{props.plataforma.quantidade}</span>
                        </p>
                        <p>
                            <span className={classes.TituloCard}>Valor Total:</span>
                            <span className={classes.ValueCard}>{formatar.dinheiro(props.plataforma.valorVenda, false)}</span>
                        </p>
                        <p>
                            <span className={classes.TituloCard}>Ticket Médio:</span>
                            <span className={classes.ValueCard}>{formatar.dinheiro(props.plataforma.ticket, false)}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Card;
