// Dependências
import React from "react";
import { FaTrash } from "react-icons/fa";

// Css
import classes from "./Despesa.module.css";

// Components
import BeautyDropdown from "../../../../UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../../UI/Input/Input";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Despesa = props => {
    return (
        <tr>
            <td>
                <BeautyDropdown
                    inline
                    returnObject
                    list={props.subcategorias}
                    default={props.despesa.subcategoria.nome}
                    onChange={value => {
                        let copiaDespesa = { ...props.despesa };
                        copiaDespesa.nome = value.nome;
                        copiaDespesa.subcategoria = { id: value.id, nome: value.nome };
                        props.onChange(copiaDespesa);
                    }}
                />
            </td>
            <td>
                <Input
                    type="date"
                    size="75%"
                    placeholder="Data"
                    value={props.despesa.data}
                    invalid={props.isValid ? !props.isValid.data : false}
                    onBlur={event => {
                        let copiaDespesa = { ...props.despesa };
                        copiaDespesa.data = event.target.value;
                        props.onChange(copiaDespesa);
                    }}
                />
            </td>
            <td style={{ position: "relative" }}>
                <Input
                    onlyMoney
                    type="text"
                    size="60%"
                    maxLength="9"
                    placeholder="Valor (R$)"
                    invalid={props.isValid ? !props.isValid.valor : false}
                    value={props.despesa.valor ? formatar.dinheiro(props.despesa.valor, true, false) : props.despesa.valor}
                    onBlur={event => {
                        let copiaDespesa = { ...props.despesa };
                        copiaDespesa.valor = formatar.stringToFloat(event.target.value);
                        props.onChange(copiaDespesa);
                    }}
                />
                <FaTrash
                    onClick={props.onDelete}
                    className={classes.DeleteButton}
                    size="17px"
                    title="Apagar despesa" />
            </td>
        </tr>
    );
};

export default Despesa;