// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./SwitchColorButton.module.css"

const SwitchColorButton = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return (
        <div className={classes.ToggleWrapper}>
            <input
                type="checkbox"
                id="switchMode"
                style={{ visibility: "hidden" }}
                defaultChecked={darkMode}
                onChange={props.onChange}
            />
            <label htmlFor="switchMode" className={classes.Toggle}>
                <span className={classes.ToggleHanddler}>
                    <span className={`${classes.Crater} ${classes.Crater1}`} />
                    <span className={`${classes.Crater} ${classes.Crater2}`} />
                    <span className={`${classes.Crater} ${classes.Crater3}`} />
                </span>
                <span className={`${classes.Star} ${classes.Star1}`} />
                <span className={`${classes.Star} ${classes.Star2}`} />
                <span className={`${classes.Star} ${classes.Star3}`} />
                <span className={`${classes.Star} ${classes.Star4}`} />
                <span className={`${classes.Star} ${classes.Star5}`} />
                <span className={`${classes.Star} ${classes.Star6}`} />
            </label>
        </div>
    );
};

export default SwitchColorButton;