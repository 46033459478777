// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";
import * as grupoSucatasActions from "../../../../store/actions/grupos-sucatas";

// Components
import SelecaoFreteGarantia from "../../../../components/Cadastro/SelecaoFreteGarantia/SelecaoFreteGarantia";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const SelecaoFreteGarantiaScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;
    const grupoSucatas = useSelector(state => state.gruposSucatas.grupoSucatas);
    const cadastroGrupoPecas = useSelector(state => state.cadastro.cadastroGrupoPecas);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestComplete, setIsRequestComplete] = useState(false);

    // Requisição das peças
    useEffect(() => {
        const fetchGrupoSucatas = async () => {
            setIsLoading(true);
            try {
                await dispatch(grupoSucatasActions.fetchGrupoSucatas(idGrupoSucatas, 12));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
            setIsRequestComplete(true);
        };

        if (!cadastroGrupoPecas.pecas.length)
            fetchGrupoSucatas();
        else {
            setIsLoading(false);
            setIsRequestComplete(true);
        };
    }, [dispatch, idGrupoSucatas, cadastroGrupoPecas.pecas.length]);

    return isLoading ? <Spinner /> : (
        <div>
            {isRequestComplete ?
                <SelecaoFreteGarantia
                    showSucata
                    pecas={cadastroGrupoPecas.pecas.length ? [...cadastroGrupoPecas.pecas] : [].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas.map(peca => { return { ...peca, sucata: sucata.fipe.veiculo.nome } })))}
                    onAntClick={() => props.history.push(`/cadastro/grupo-pecas/selecao-tipos-anuncio/${idGrupoSucatas}`)}
                    onProxClick={pecasAtualizadas => {
                        let cadastroGrupoPecasAtualizado = { ...cadastroGrupoPecas };
                        cadastroGrupoPecasAtualizado.pecas = [...pecasAtualizadas];
                        dispatch(cadastroActions.setCadastroGrupoPecas(cadastroGrupoPecasAtualizado));
                        props.history.push(`/cadastro/grupo-pecas/resumo/${idGrupoSucatas}`);
                    }} />
                : null}
        </div>
    );
};

export default SelecaoFreteGarantiaScreen;