// Dependências
import React from "react";
import { AiFillCar/* , AiOutlineFileExcel */ } from "react-icons/ai";
import { GiSteeringWheel } from "react-icons/gi";
import { useSelector } from "react-redux";

// Components
import Titulo from "../../components/UI/Titulo/Titulo";

// Css
import classes from "./CadastroScreen.module.css";

const CadastroScreen = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    return (
        <div className="container-fluid">
            <Titulo>Selecione como deseja cadastrar as peças:</Titulo>

            <div className="row pt-1 mb-3">

                <div className="col-6 text-center">
                    <div className={darkMode ? classes.OptionDark : classes.OptionLight}>
                        <div onClick={() => props.history.push("/cadastro/sucata")}>
                            <h3>A partir de uma sucata</h3>
                            <div className={classes.Icon}>
                                <AiFillCar size="100%" />
                            </div>
                            <div className={classes.Text}>
                                Cadastre múltiplas peças de uma única sucata.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 text-center">
                    <div className={darkMode ? classes.OptionDark : classes.OptionLight}>
                        <div onClick={() => props.history.push("/cadastro/grupo-pecas")} style={{ textDecoration: "none" }}>
                            <h3>Peças diversas</h3>
                            <div className={classes.Icon}>
                                <GiSteeringWheel size="100%" />
                            </div>
                            <div className={classes.Text}>
                                Cadastre múltiplas peças de diversas sucatas.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row">
                <div className="col-6 text-center">
                    <div className={darkMode ? classes.OptionDark : classes.OptionLight}>
                        <div className={classes.Beta}>BETA</div>
                        <div onClick={() => props.history.push("/cadastro/arquivo-detran")}>
                            <h3>Arquivo do DETRAN</h3>
                            <div className={classes.Icon}>
                                <AiOutlineFileExcel size="100%" />
                            </div>
                            <div className={classes.Text}>
                                Importe várias peças de uma vez através do arquivo Excel que o DETRAN gera.
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
};

export default CadastroScreen;