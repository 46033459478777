import Swal from "sweetalert2";

const mergeObjects = (defaultOptions = {}, options = {}) => {
    const darkMode = JSON.parse(localStorage.getItem("darkMode"));

    if (typeof options === 'string' || options instanceof String)
        options = { text: options };


    if (options.text || options.html)
        options.html = darkMode ? `<div style="color: #fff">${options.text || options.html}</div>` : options.text || options.html;

    if (options.title)
        options.title = `<strong style="color: ${darkMode ? "#fff" : "#000"}; margin-bottom: 5px">${options.title}</strong>`;


    for (let key in options)
        defaultOptions[key] = options[key];

    return defaultOptions;
};

export const exibir = (options) => {
    const darkMode = JSON.parse(localStorage.getItem("darkMode"));

    /* Configurar aqui as opções padrão para todas as mensagens. */
    const defaultOptions = {
        title: "Aviso",
        background: darkMode ? "#262828" : "#f8f9fa",
        reverseButtons: true,
        cancelButtonColor: "#aaa",
        confirmButtonColor: "#79cd56",
        confirmButtonText: "Salvar",
        cancelButtonText: "Cancelar"
    };

    return Swal.fire(mergeObjects(defaultOptions, options));
};

export const success = (options) => {
    const defaultOptions = ({
        title: "Feito!",
        icon: "success",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000
    });

    return exibir(mergeObjects(defaultOptions, options));
};

export const error = (options) => {
    const defaultOptions = ({
        title: "Ops...",
        icon: "error",
        text: "Alguma coisa deu errado, tente novamente. Se o problema persistir, por favor contate-nos",
        confirmButtonText: "OK"
    });

    return exibir(mergeObjects(defaultOptions, options));
};

export const warning = (options) => {
    const defaultOptions = {
        title: "Ops...",
        icon: "warning",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    };

    return exibir(mergeObjects(defaultOptions, options));
};

export const formInvalid = (options) => {
    const defaultOptions = ({
        title: "Alguns campos estão incompletos.",
        text: "Verifique os campos destacados em vermelho e preencha-os corretamente.",
        icon: "warning",
    });

    return exibir(mergeObjects(defaultOptions, options));
};

export const tip = (options) => {
    const defaultOptions = ({
        title: "Dica",
        width: 700,
        allowOutsideClick: false,
        confirmButtonColor: "#79cd56",
        confirmButtonText: "Ok",
        padding: "20px 5px",
    });

    return exibir(mergeObjects(defaultOptions, options));
};
