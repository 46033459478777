// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

// Actions
import * as despesasActions from "../../../../store/actions/despesas";

// Components
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import Button from "../../../UI/Button/Button";
import Despesa from "./Despesa/Despesa";
import Spinner from "../../../UI/Spinner/Spinner";
import Table from "../../../UI/Table/Table";
import Titulo from "../../../UI/Titulo/Titulo";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

const Despesas = props => {
    const dispatch = useDispatch();

    const categoria = useSelector(state => state.despesas.categoria);

    const [isValid, setIsValid] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [despesas, setDespesas] = useState([
        {
            id: uuidv4(),
            nome: "",
            valor: "",
            data: dataHoje(),
            subcategoria: { id: "", nome: "" }
        },
        {
            id: uuidv4(),
            nome: "",
            valor: "",
            data: dataHoje(),
            subcategoria: { id: "", nome: "" }
        }
    ]);

    // Requisição da categoria de despesa das sucatas
    useEffect(() => {
        const fetchCategorias = async () => {
            try {
                setIsLoading(true);
                await dispatch(despesasActions.fetchCategorias("5"));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchCategorias();
    }, [dispatch]);

    // Verificando se despesas tem despesas sem id
    useEffect(() => {
        if (!categoria.id)
            return;

        const despesasAtualizadas = [...despesas];

        let atualizou = false;
        let contador = 0;
        despesasAtualizadas.forEach(despesa => {
            if (!despesa.subcategoria.id || !despesa.subcategoria.nome || !despesa.nome) {
                atualizou = true;

                if (contador === categoria.subcategorias.length - 1)
                    contador = 0;

                despesa.subcategoria.id = categoria.subcategorias[contador].id;
                despesa.subcategoria.nome = categoria.subcategorias[contador].nome;
                despesa.nome = categoria.subcategorias[contador].nome;

                contador++;
            };
        });

        if (atualizou)
            setDespesas(despesasAtualizadas);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoria]);

    useEffect(() => {
        let copiaIsValid = [...isValid];

        despesas.forEach(despesa => {
            let existe = false;
            copiaIsValid.forEach(copia => copia.id === despesa.id ? existe = true : null);

            if (!existe)
                copiaIsValid.push(newIsValid(despesa.id));
        });

        setIsValid(copiaIsValid);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [despesas]);

    const newIsValid = id => {
        return {
            id: id,
            data: true,
            valor: true
        };
    };

    const submitHandler = () => {
        let formIsValid = true;
        let copiaIsValid = [...isValid];

        despesas.forEach(despesa => {
            let newCopiaIsValidDespesa = copiaIsValid.find(isValidDespesa => isValidDespesa.id === despesa.id);

            if (!validar.data(despesa.data)) {
                formIsValid = false;
                newCopiaIsValidDespesa.data = false;
            } else
                newCopiaIsValidDespesa.data = true;

            if (!validar.preco(despesa.valor)) {
                formIsValid = false;
                newCopiaIsValidDespesa.valor = false;
            } else
                newCopiaIsValidDespesa.valor = true;

            copiaIsValid = copiaIsValid.map(isValidDespesa => isValidDespesa.id === despesa.id ? { ...newCopiaIsValidDespesa } : isValidDespesa);
        });

        setIsValid(copiaIsValid);
        if (formIsValid)
            props.onProxClick(despesas);
        else
            exibirMensagem.warning("Alguns campos foram preenchidos incorretamente, por favor verifique os campos destacados em vermelho.");
    };

    return isLoading || !categoria.id ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <Titulo>Informe as despesas com esta sucata</Titulo>

            <div className="row">
                <div className="col-12 mb-3">
                    <span style={{ fontSize: "16px" }}>Estes dados são fundamentais para gerarmos seus relatórios de lucro em cada sucata. Lembramos que estas informações não são utilizadas para a criação dos anúncios e ficarão disponíveis apenas em seu sistema, nos relatórios financeiros.</span>
                </div>
            </div>

            <div className="row">
                <Table header={["Tipo", "Data", "Valor (R$)"]}>
                    {despesas.map(despesa =>
                        <Despesa
                            key={despesa.id}
                            despesa={despesa}
                            subcategorias={[...categoria.subcategorias]}
                            isValid={isValid.find(isValidDespesa => isValidDespesa.id === despesa.id)}
                            onDelete={() => {
                                let copiaDespesas = [...despesas];
                                copiaDespesas = copiaDespesas.filter(copiaDespesa => copiaDespesa.id !== despesa.id);
                                setDespesas(copiaDespesas);
                            }}
                            onChange={novaDespesa => {
                                let copiaDespesas = [...despesas];
                                copiaDespesas = copiaDespesas.map(despesa => despesa.id === novaDespesa.id ? novaDespesa : despesa);
                                setDespesas(copiaDespesas);
                            }}
                        />
                    )}
                </Table>
            </div>

            <div className="row">
                <div className="col-12 text-center">
                    <Button
                        color="green"
                        onClick={() => {
                            let newId = uuidv4()
                            let copiaDespesas = [...despesas];
                            let copiaIsValid = [...isValid];

                            copiaDespesas.push({
                                id: newId,
                                nome: categoria.subcategorias[categoria.subcategorias.length - 1].nome,
                                valor: "",
                                data: dataHoje(),
                                subcategoria: { id: categoria.subcategorias[categoria.subcategorias.length - 1].id, nome: categoria.subcategorias[categoria.subcategorias.length - 1].nome }
                            });

                            copiaIsValid.push(newIsValid(newId));

                            setIsValid(copiaIsValid);
                            setDespesas(copiaDespesas);
                        }}>Nova despesa</Button>
                </div>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default Despesas;