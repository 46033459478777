// Dependências
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

// Css
import classes from "./Item.module.css";

const Item = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);
    const id = useState(uuidv4);

    return (
        <div className="row">
            <div className="col-12">
                <label
                    htmlFor={id[0]}
                    className={`${darkMode ? classes.ItemDark : classes.ItemLight} ${props.defaultChecked ? classes.Active : ""}`}
                >
                    <input
                        id={id[0]}
                        type="radio"
                        name={props.name}
                        style={{ display: "none" }}
                        onChange={() => props.onChange(props.item)}
                        defaultChecked={props.defaultChecked}
                    />
                    <span>{props.item.nome}</span>
                </label>
            </div>
        </div>
    );
};

export default Item;