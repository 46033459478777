// Dependências
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import { API_URL } from "../../../../constants/GlobalConfig"

// Components
import Button from "../../../../components/UI/Button/Button";
import Dropdown from "../../../../components/UI/BeautyDropdown/BeautyDropdown";
import Endereco from "../../../../components/Admin/Conta/CadastroConta/Endereco/Endereco";
import Input from "../../../../components/UI/Input/Input";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Telefone from "../../../../components/Admin/Conta/CadastroConta/Telefone/Telefone";
import Titulo from "../../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import statusHandler from "../../../../helpers/statusHandler";
import * as validar from "../../../../helpers/validar";

const CadastroConta = props => {
    const token = useSelector(state => state.auth.token);

    const randomPassword = () => {
        const charset = "0123456789";
        let retVal = "";

        for (let i = 0, n = charset.length; i < 8; ++i)
            retVal += charset.charAt(Math.floor(Math.random() * n));

        return retVal;
    };

    const [novaConta, setNovaConta] = useState({
        nome: "",
        email: "",
        cnpj: "",
        tipo: { id: 2, nome: "Comum" },
        senha: randomPassword(),
        nomeUsuario: "Administrador",
        telefones: [
            {
                id: uuidv4(),
                ddd: "",
                nome: "",
                descricao: "",
                tipo: {
                    id: 3,
                    nome: "Celular & WhatsApp"
                }
            }
        ],
        enderecos: [
            {
                id: uuidv4(),
                estado: "",
                cidade: "",
                cep: "",
                bairro: "",
                logradouro: "",
                número: "",
                complemento: ""
            }
        ]
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState({
        cnpj: true,
        email: true,
        nome: true,
        nomeUsuario: true
    });

    const createConta = async () => {
        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (!novaConta.cnpj?.length || validar.cnpj(novaConta.cnpj))
            copiaIsValid.cnpj = true;
        else {
            copiaIsValid.cnpj = false;
            formIsValid = false;
        };

        if (validar.nome(novaConta.nome))
            copiaIsValid.nome = true;
        else {
            copiaIsValid.nome = false;
            formIsValid = false;
        };

        if (validar.nome(novaConta.nomeUsuario))
            copiaIsValid.nomeUsuario = true;
        else {
            copiaIsValid.nomeUsuario = false;
            formIsValid = false;
        };

        if (validar.email(novaConta.email))
            copiaIsValid.email = true;
        else {
            copiaIsValid.email = false;
            formIsValid = false;
        };

        setIsValid({ ...copiaIsValid });

        if (formIsValid) {
            setIsLoading(true);

            await axios({
                method: 'POST',
                url: `${API_URL}/administrador/contas`,
                headers: { Authorization: token },
                data: { ...novaConta, telefones: novaConta.telefones.filter(telefone => telefone.nome), enderecos: novaConta.enderecos.filter(endereco => endereco.estado) }
            }).then(result => {
                if (result.status === 201) {
                    exibirMensagem.exibir({
                        title: "Dados do usuário:",
                        text: `
                        <div class="text-left">
                            <p><strong>Nome:</strong> ${novaConta.nomeUsuario}</p>
                            <p><strong>Email:</strong> ${novaConta.email}</p>
                            <p><strong>Senha:</strong> ${novaConta.senha}</p>
                        </div>
                        `,
                        confirmButtonText: "Ok"
                    }).then(() => props.history.push("/contas"));
                } else {
                    statusHandler(result.status);
                    throw new Error("Algo deu errado");
                };
            }).catch(error => {
                if (error.response)
                    statusHandler(error.response.status);
                else
                    throw new Error(error);
            });

            setIsLoading(false);
        } else
            exibirMensagem.formInvalid();
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Criar uma Nova Conta</Titulo>

            <div className="row mb-4">
                <div className="col-8 mb-3">
                    <Input
                        maxLength="50"
                        placeholder="Nome da Empresa"
                        value={novaConta.nome}
                        invalid={!isValid.nome}
                        onBlur={event => setNovaConta({ ...novaConta, nome: event.target.value, email: novaConta.email || !event.target.value ? novaConta.email : `${formatar.alphaNumeric(event.target.value)}@unificar.com` })}
                    />
                </div>
                <div className="col-4 mb-3">
                    <Dropdown
                        returnObject
                        default={novaConta.tipo.nome}
                        list={[
                            { id: 2, nome: "Comum" },
                            { id: 3, nome: "Convidado" },
                            { id: 4, nome: "Teste" },
                        ]}
                        onChange={value => setNovaConta({ ...novaConta, tipo: value })}
                    />
                </div>

                <div className="col-6">
                    <Input
                        maxLength="50"
                        placeholder="Nome do Proprietário"
                        value={novaConta.nomeUsuario}
                        invalid={!isValid.nomeUsuario}
                        onChange={value => setNovaConta({ ...novaConta, nomeUsuario: formatar.alphaNumeric(value) })}
                    />
                </div>

                <div className="col-6">
                    <Input
                        cnpj
                        name="cnpj"
                        maxLength="18"
                        placeholder="CNPJ"
                        value={novaConta.cnpj}
                        invalid={!isValid.cnpj}
                        onChange={value => setNovaConta({ ...novaConta, cnpj: formatar.alphaNumeric(value) })}
                    />
                </div>

                <div className="col-12">
                    <Input
                        name="email"
                        maxLength="155"
                        placeholder="Email"
                        setValue={novaConta.email || ""}
                        invalid={!isValid.email}
                        onChange={value => setNovaConta({ ...novaConta, email: value })}
                    />
                </div>
            </div>

            {novaConta.enderecos.map(endereco =>
                <Endereco
                    key={endereco.id}
                    endereco={endereco}
                    onChange={novoEndereco => {
                        setNovaConta({ ...novaConta, enderecos: novaConta.enderecos.map(contaEndereco => contaEndereco.id === endereco.id ? novoEndereco : contaEndereco) })
                    }}
                    onDelete={() => setNovaConta({ ...novaConta, enderecos: novaConta.enderecos.filter(contaEndereco => contaEndereco.id !== endereco.id) })}
                />
            )}

            <div className="mb-4" />

            {novaConta.telefones.map(telefone =>
                <Telefone
                    key={telefone.id}
                    telefone={telefone}
                    onChange={novoTelefone => {
                        setNovaConta({ ...novaConta, telefones: novaConta.telefones.map(contaTelefone => contaTelefone.id === telefone.id ? novoTelefone : contaTelefone) })
                    }}
                    onDelete={() => setNovaConta({ ...novaConta, telefones: novaConta.telefones.filter(contaTelefone => contaTelefone.id !== telefone.id) })}
                />
            )}

            <div className="row mt-5">
                <div className="col-4 text-left">
                    <Button
                        color="blue"
                        onClick={() => setNovaConta({ ...novaConta, telefones: [{ id: uuidv4(), ddd: "", nome: "", descricao: "", tipo: { id: 3, nome: "Celular & WhatsApp" } }, ...novaConta.telefones] })}
                    >Novo Telefone</Button>
                </div>
                <div className="col-4 text-center">
                    <Button
                        color="green"
                        onClick={createConta}
                    >Cadastrar Conta</Button>
                </div>
                <div className="col-4 text-right">
                    <Button
                        color="purple"
                        onClick={() => setNovaConta({ ...novaConta, enderecos: [{ id: uuidv4(), estado: "", cidade: "", cep: "", bairro: "", logradouro: "", número: "", complemento: "" }, ...novaConta.enderecos] })}
                    >Novo Endereço</Button>
                </div>
            </div>
        </div>
    );
};

export default CadastroConta;