// Dependências
import React from "react";
import { FaLink, FaComments, /*FaHandshake,*/ FaDollarSign, FaStore, FaPollH, FaTag,  /* FaClone */ } from "react-icons/fa";
import { BiTimer } from 'react-icons/bi'
import { HiUserGroup } from "react-icons/hi";

// Css
import classes from "./ConhecerScreen.module.css";

// Components
import BotaoWhatsapp from "../../components/Conhecer/BotaoWhatsapp/BotaoWhatsapp";
import Button from "../../components/Conhecer/Button/Button";
import Card from "../../components/Conhecer/Card/Card";
import Navbar from "../../components/Conhecer/Navbar/Navbar";
import CardCadastroLoja from '../../components/CadastrarLoja/CardCadastroLoja';
import clientDetector from '../../helpers/clientDetector';

const Conhecer = props => {
    const isSafari = (clientDetector.obterNavegador() === 'Safari');

    return (
        <div className={`${classes.Conhecer}`}>
            <Navbar />
            <BotaoWhatsapp />

            <div className={`row ${classes.BackgroundColor}`}>
                <div className={`col-12 ${classes.Container1}`}>
                    <h2>UnifiCAR - Testado e aprovado por desmontes automotivos</h2>
                    <h5 className="mb-4 mb-md-5">Anuncie suas peças rapidamente no Mercado Livre - Sistema desenvolvido para o setor</h5>
                    <a href="#formulario">
                        <Button className="mt-md-5" invert><span className={classes.TextoBotao}>TESTAR GRÁTIS</span></Button>
                    </a>
                </div>
            </div>
            <div className="row">
                <div className={`col-12 ${classes.Container2}`} id="container2">
                    <div className="row">
                        <div className={`col-sm-12 p-0 d-flex justify-content-center`}>
                            <iframe
                                className={classes.Video}
                                src="https://www.youtube.com/embed/c6jPVVgT5oM"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                title="tutorial"
                                allowFullScreen
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Crie anúncios automaticamente" icon={<BiTimer />}>
                                O Unificar permite a criação de 250 anúncios por sucata no <strong>Mercado Livre</strong>, de uma vez só e automaticamente! Selecione as peças disponíveis e o preço, nosso sistema fará o restante por você.
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Integração entre estoque e anúncios" icon={<FaLink />}>
                                Com um único processo, você alimenta seu estoque e já cria anúncios no Mercado Livre e Mundo da Peça, totalmente integrados.
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Responda perguntas com um clique" icon={<FaComments />}>
                                Crie até 30 respostas padronizadas para sua loja, ganhando agilidade e padronização no seu atendimento. Gerencie perguntas do Mercado Livre e Mundo da Peça em uma única tela.
                            </Card>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Etiquetas de Identificação" icon={<FaTag />}>
                                Ao cadastrar a sucata, etiquetas de identificação com dados da peça e veículo de origem são geradas automaticamente.
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Controle financeiro" icon={<FaDollarSign />}>
                                Saiba exatamente quanto cada sucata custou para sua empresa, o que já foi vendido e quais peças ainda estão em estoque.
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card titulo="Agilize o atendimento no balcão" icon={<FaStore />}>
                                Em poucos cliques, é possível consultar a disponibilidade de qualquer peça, preço sugerido, preço mínimo e também marcá-la como vendida.
                            </Card>
                        </div>
                    </div>

                    <h2 className="mt-3">Extremamente intuitivo e fácil de operar</h2>
                    <h5 className="mb-5">Assistindo apenas um vídeo-tutorial de 30min. você domina 100% dos recursos disponíveis!</h5>

                    <a href="#formulario">
                        <Button className="mt-5" invert><span className={classes.TextoBotao}>TESTAR GRÁTIS</span></Button>
                    </a>
                </div>
            </div>

            <div className={`row ${isSafari ? classes.BackgroundColor : classes.BackgroundImage}`}>
                <div className={`col-12 ${classes.Container3}`}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h1 className="mb-5">Dê adeus a criação de anúncios manualmente!</h1>

                            <div className="mb-4">
                                <span>
                                    {/* <FaClone className={`mb-2 mr-2`} /> */}
                                    <h4 className="d-inline">Crie até 250 anúncios por sucata automaticamente</h4>
                                </span>
                                <span className="d-block mt-3"> Ao cadastrar a sucata, selecione as peças disponíveis e o preço. Nosso sistema fará o restante por você. </span>
                            </div>

                            <div className="mb-4">
                                <span>
                                    {/* <FaDollarSign className="mb-2 mr-2" /> */}
                                    <h4 className="d-inline">Controle Financeiro</h4>
                                </span>
                                <span className="d-block mt-3"> Acompanhe o valor real do seu estoque, as vendas realizadas e quais sucatas já atingiram o ponto de equilíbrio. </span>
                            </div>

                            <div className="mb-4">
                                <span>
                                    {/* <FaLink className="mb-2 mr-2" /> */}
                                    <h4 className="d-inline">Integração entre vendas no Balcão e Online</h4>
                                </span>
                                <span className="d-block mt-3"> Registre vendas no balcão de forma rápida, sem se preocupar com os anúncios. O UnifiCAR desativa os anúncios automaticamente para você! </span>
                            </div>

                            <a href="#formulario">
                                <Button className="mt-5" invert><span className={classes.TextoBotao}>TESTAR GRÁTIS</span></Button>
                            </a>
                        </div>

                        <div className={`col-sm-12 col-md-6 ${classes.Imagem}`}>
                            <img className="w-100" src="/images/fluxo2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`row ${classes.BackgroundColor}`} id="formulario">
                <div className={`col-12 py-5 ${classes.Container5}`}>
                    <CardCadastroLoja history={props.history} />
                </div>
            </div>

            <div className="row">
                <div className={`col-12 ${classes.Container4}`}>
                    <h3>Recursos em desenvolvimento <br /> (em breve)</h3>
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-4  mb-5">
                            <Card titulo="Emissão de NFs" icon={<FaPollH />}>
                                Emita, consulte e controle suas notas fiscais rapidamente logo após a venda.
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-4  mb-5">
                            <Card titulo="Diferentes níveis de acesso" icon={<HiUserGroup />}>
                                Crie múltiplos logins e gerencie quais informações cada usuário pode acessar ou editar.
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Conhecer;