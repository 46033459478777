// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./Titulo.module.css";

const Titulo = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return (
        <h4 className={`${props.lightMode ? classes.TituloLight : darkMode ? classes.TituloDark : classes.TituloLight} ${props.className} mb-4`}>
            <span> {props.children} </span>
            {props.subtitulo ? (
                <p className={classes.Subtitulo}>{props.subtitulo}</p>
            ) : null}
        </h4>
    );
};

export default Titulo;
