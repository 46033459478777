// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Precificacao from "../../../../components/Cadastro/Precificacao/Precificacao";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const PrecificacaoScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const pecas = useSelector(state => state.pecas.pecas);

    const [isLoading, setIsLoading] = useState(true);

    // Requisição das peças
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 10, "tipo"));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [dispatch, idSucata]);

    const updatePecas = async pecasAtualizadas => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(pecasAtualizadas.map(peca => { return { id: peca.id, preco: peca.preco, observacao: peca.observacao } })));
            exibirMensagem.success("Alterações realizadas com sucesso.");
            props.history.push(`/cadastro/sucata/selecao-tipos-anuncio/${idSucata}`);
        } catch (error) {
            console.log(error);
        };

        setIsLoading(false);
    };

    return isLoading ? <Spinner /> : (
        <div>
            <Precificacao
                pecas={[...pecas]}
                onAntClick={() => props.history.push(`/cadastro/sucata`)}
                onProxClick={pecasAtualizadas => updatePecas(pecasAtualizadas)}
            />
        </div>
    );
};

export default PrecificacaoScreen;