// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../store/actions/anuncios";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Peca from "./Peca/Peca";
import Spinner from "../../UI/Spinner/Spinner";
import Table from "../../UI/Table/Table";
import Titulo from "../../UI/Titulo/Titulo";

const SelecaoFreteGarantia = props => {
    const dispatch = useDispatch();

    const usuario = useSelector(state => state.usuario.meuUsuario);
    const fretes = useSelector(state => state.anuncios.fretes);

    const [copiaPecas, setCopiaPecas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Requisição dos fretes
    useEffect(() => {
        const fetchFretesPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(anunciosActions.fetchFretesPecas(props.pecas.map(peca => { return peca.arquetipo.id !== 350 ? { idArquetipo: peca.arquetipo.id } : { nome: peca.arquetipo.nome } })));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (props.pecas.length)
            fetchFretesPecas();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.pecas]);

    // Setando copiaPecas
    useEffect(() => {
        if (usuario.id)
            if (fretes.length) {
                setCopiaPecas([...props.pecas.map(peca => {
                    let fretePeca = fretes.find(frete => peca.arquetipo.id === 350 ? frete.nome === peca.arquetipo.nome : frete.idArquetipo === peca.arquetipo.id);
                    return {
                        ...peca,
                        frete: peca.frete || fretePeca ? fretePeca?.fretes[0] : { id: 1, nome: "A combinar" },
                        garantia: peca.garantia || usuario.conta.configuracoes.garantia
                    };
                })]);
            };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuario.id, fretes, props.pecas]);

    return isLoading || !usuario.id ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <Titulo>Confirme o tipo de frete e o prazo de garantia de cada peça</Titulo>

            <div className="row">
                <div className="col-12 mb-3">
                    <span style={{ fontSize: "16px" }}>Lembre-se que o Mercado Livre seleciona automaticamente quais tipos de peça serão enquadrados no MERCADO ENVIOS e quais terão ENTREGA A COMBINAR COM O VENDEDOR. Sendo assim, selecione “Entrega a Combinar” apenas para peças permitidas, que geralmente são peças grandes como motor, capô, parachoque, etc. </span>
                </div>
            </div>

            <div className="row" style={{ height: "82vh" }}>
                <Table
                    fixed
                    header={["Peça", "Fretes disponíveis", "Garantia (dias)"]}>
                    {copiaPecas.map(peca =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            showSucata={props.showSucata}
                            options={fretes.find(frete => peca.arquetipo.id === 350 ? frete.nome === peca.arquetipo.nome : frete.idArquetipo === peca.arquetipo.id).fretes}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(copiaPeca => copiaPeca.id === pecaAtualizada.id ? pecaAtualizada : copiaPeca);
                                setCopiaPecas(copiaPecasAtualizadas);
                            }}
                        />
                    )}
                </Table>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={() => props.onProxClick([...copiaPecas])} />
        </div>
    );
};

export default SelecaoFreteGarantia;