// Dependências
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import Spinner from "../../../../components/UI/Spinner/Spinner";
import FotosSucata from "../../../../components/Cadastro/Sucata/FotosSucata/FotosSucata";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const FotosSucataScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    const postFotos = async () => {
        setIsLoading(true);
        try {
            // Envia as fotos para postagem, tornando um array de objetos e não enviando o src. A transformação pra formData é feita na action.
            await dispatch(sucatasActions.createFotos(idSucata, [].concat.apply([], cadastroSucata.fotos.map(foto => foto.fotos.map(fotoMapeada => { return { atrelamento: fotoMapeada.atrelamento, file: fotoMapeada.file } })))));

            exibirMensagem.success("Fotos cadastradas com sucesso");
            props.history.push(`/cadastro/sucata/medidas-pecas/${idSucata}`);
        } catch (error) {
            console.log(error);
        };
        setIsLoading(false);
    };

    return isLoading ? <Spinner /> : (
        <div>
            <FotosSucata
                fotos={[...cadastroSucata.fotos]}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={postFotos}
                onChange={fotosAtualizadas => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.fotos = [...fotosAtualizadas];
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                }}
            />
        </div>
    );
};

export default FotosSucataScreen;