// Dependências
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {QrScanner} from '@yudiel/react-qr-scanner';

// Components
import Titulo from "../../../components/UI/Titulo/Titulo";
import * as pecasActions from "../../../store/actions/pecas";
import Spinner from "../../../components/UI/Spinner/Spinner";
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import Swal from "sweetalert2";
// Css

const PecaLocalizacaoScreen = props => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const localizacoes = useSelector(state => state.pecas.localizacoes);
    console.log(isScanning)
    
    useEffect(() => {
        const fetchLocalizacoes = async () => {
            try {
                await dispatch(pecasActions.fetchLocalizacoes());
            } catch (error) {
                console.log(error);
            } 
        };
        fetchLocalizacoes();
    }, [dispatch]);

    useEffect(() => {
        if (localizacoes) {
            setIsLoading(false);
        }
    }, [localizacoes])

    const handleError = useCallback((err) => {
        console.log(err)
    }, [])

    const handleScanLocation = useCallback(async (data) => {
        if(isScanning) {
            console.log('scanning....')
        } else {
            console.log({data})
            if (data) {
                console.log(data)
                console.log(localizacoes)
                const location = localizacoes.find(location => parseInt(location.id) === parseInt(data));
                console.log({location})
                setIsScanning(true);
                if (location) {
                    Swal.fire({
                        title: "Sucesso",
                        text: "Localização selecionada com sucesso",
                        icon: "success",
                        confirmButtonColor: "#79cd56",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                    }).then((result) => {
                        if(result.isConfirmed){
                            setLocation(location);
                            setIsScanning(false)
                        }
                    })
                } else {
                    exibirMensagem.error({
                        title: "Erro",
                        text: "Localização não encontrada"
                    }).then((result) => {
                        if(result.isConfirmed){
                            setIsScanning(false)
                        }
                    })
                }
            }
        };
    }, [localizacoes, isScanning])

    const handleReset = useCallback(() => {
        setLocation(null)
        setIsScanning(false);
    }, [])

    const handleScanPeca = useCallback(async (data) => {
        if(isScanning) {
            console.log('scanning....')
        } else {
            if (data) {
                console.log(data)
                try {
                    setIsScanning(true);
                    Swal.fire({
                        title: "Sucesso",
                        text: "Peça encontrada com sucesso. Deseja atualizar a localização?`",
                        icon: "success",
                        confirmButtonColor: "#79cd56",
                        confirmButtonText: "Sim",
                        cancelButtonText: "Não",
                        showCancelButton: true,
                        allowOutsideClick: false,
                    }).then((result) => {
                        if(result.isConfirmed){
                            dispatch(pecasActions.updateLocPeca(data, location.id));
                            handleReset();
                        } else {
                            handleReset();
                        }
                    })
                } catch (e) {
                    console.log(e)
                    exibirMensagem.error({
                        title: "Erro",
                        text: "Peça não encontrada"
                    }).then((result) => {
                        if(result.isConfirmed){
                            handleReset()
                        }
                    })
                }
            }
        }
    }, [dispatch, isScanning, location, handleReset])

    let localstream;

    const camON = () => {
        let vid = document.getElementsByTagName("video")[0];
        if(vid) {
            if (navigator?.mediaDevices?.getUserMedia !== null) {
                var options = {
                  video: true,
                  audio: true
                };
                if(navigator){
                    navigator.getUserMedia(
                        options,
                        function (stream) {
                          vid.srcObject = stream;
                          //localstream = stream;
                          vid.play();
                          console.log(stream, "streaming");
                        },
                        function (e) {
                          console.log("background error : " + e.name);
                        }
                      );
                }
            }
        }
      };

    return isLoading ?
        (
            <Spinner />
        ) : (
            <div className="container-fluid">
                {!location ? (
                    <>
                        <Titulo>Escaneie o qrcode da caixa</Titulo>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{width: '80%', height: '60%'}}>
                                    <QrScanner
                                        onDecode={handleScanLocation}
                                        onError={handleError}
                                        tracker={false}
                                    />
                                </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Titulo>Escaneie o qrcode da peça</Titulo>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{width: '80%', height: '60%'}}>
                                    <QrScanner
                                        onDecode={handleScanPeca}
                                        onError={handleError}
                                    />
                                </div>
                        </div>
                    </>
                )}
                <button type="button" onClick={camON}>Permissão</button>
            </div>
        )
};

export default PecaLocalizacaoScreen;