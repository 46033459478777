const dataHoje = (variacao) => {
    const date = new Date();

    if (variacao)
        date.setMonth(date.getMonth() + variacao);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return year.toString() + '-' + month.toString() + '-' + day.toString();
}

export default dataHoje;