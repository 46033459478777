// Dependências
import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";

// Css
import classes from "./BotaoWhatsapp.module.css";

const BotaoWhatsapp = props => {
    return (
        <a
            title="Fale conosco"
            className={`${classes.BotaoWhatsapp}`}
            href={`https://wa.me/5545999463628`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <IoLogoWhatsapp size="55" />
        </a>
    );
};

export default BotaoWhatsapp;