// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as despesasActions from "../../../../store/actions/despesas";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

// Components
import BeautyDropdown from "../../../UI/BeautyDropdown/BeautyDropdown";
import Button from "../../../UI/Button/Button";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import Spinner from "../../../UI/Spinner/Spinner";

const ModalCadastroDespesa = props => {
    const dispatch = useDispatch();

    const opcoes = useSelector(state => state.despesas.opcoes);
    const frequencias = useSelector(state => state.despesas.frequencias);

    const [isLoading, setIsLoading] = useState(false);
    const [despesa, setDespesa] = useState({
        nome: "",
        frequencia: { id: "", nome: "", periodica: false },
        categoria: { id: "", nome: "", opcoes: [] },
        subcategoria: { id: "", nome: "" },
        valor: "",
        atrelamento: { id: "", nome: "" },
        dataInicio: dataHoje(),
        dataFinal: "",
        possuiDataFinal: true
    });
    const [isValid, setIsValid] = useState({
        nome: true,
        valor: true,
        dataInicio: true,
        dataFinal: true,
        atrelamento: true
    });
    const [subcategorias, setSubcategorias] = useState([]);

    // Requisição dos tipos de frequencias
    useEffect(() => {
        const fetchFrequencias = async () => {
            try {
                setIsLoading(true);
                await dispatch(despesasActions.fetchFrequencias());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchFrequencias();
    }, [dispatch]);

    // Requisição das opcoes
    useEffect(() => {
        const fetchOpcoes = async () => {
            try {
                setIsLoading(true);
                await dispatch(despesasActions.fetchOpcoes());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchOpcoes();
    }, [dispatch]);

    // Setando frequencia inicial
    useEffect(() => {
        if (frequencias.length) {
            let copiaDespesa = { ...despesa };
            copiaDespesa.frequencia = { id: frequencias[0].id, nome: frequencias[0].nome };
            setDespesa(copiaDespesa);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequencias]);

    // Setando categoria inicial
    useEffect(() => {
        if (opcoes.length) {
            let copiaDespesa = { ...despesa };
            copiaDespesa.categoria = { id: opcoes[0].id, nome: opcoes[0].nome, opcoes: opcoes[0].opcoes ? [...opcoes[0].opcoes] : [] };
            setDespesa(copiaDespesa);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcoes]);

    // Setando subcategoria
    useEffect(() => {
        if (opcoes.length) {
            let copiaDespesa = { ...despesa };

            opcoes.forEach(categoria => {
                if (categoria.id === copiaDespesa.categoria.id) {
                    setSubcategorias([...categoria.subcategorias]);
                    copiaDespesa.subcategoria = { id: categoria.subcategorias[0].id, nome: categoria.subcategorias[0].nome };
                };
            });

            setDespesa(copiaDespesa);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [despesa.categoria.id]);

    // Setando atrelamento e nome
    useEffect(() => {
        if (opcoes.length) {
            let copiaDespesa = { ...despesa };

            if (despesa.categoria.opcoes.length) {
                if (!copiaDespesa.atrelamento.id) {
                    copiaDespesa.atrelamento = { id: despesa.categoria.opcoes[0].id, nome: despesa.categoria.opcoes[0].nome };
                    copiaDespesa.nome = `${copiaDespesa.subcategoria.nome} (${despesa.categoria.opcoes[0].nome})`;
                } else
                    copiaDespesa.nome = `${copiaDespesa.subcategoria.nome} (${despesa.atrelamento.nome})`;
            } else {
                copiaDespesa.nome = `${copiaDespesa.subcategoria.nome}`;
                copiaDespesa.atrelamento = { id: "", nome: "" };
            };


            setDespesa(copiaDespesa);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [despesa.subcategoria.nome, despesa.atrelamento.id]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (validar.nome(despesa.nome))
            copiaIsValid.nome = true;
        else {
            copiaIsValid.nome = false;
            formIsValid = false;
        };

        if (validar.preco(despesa.valor))
            copiaIsValid.valor = true;
        else {
            copiaIsValid.valor = false;
            formIsValid = false;
        };

        if (validar.data(despesa.dataInicio))
            copiaIsValid.dataInicio = true;
        else {
            copiaIsValid.dataInicio = false;
            formIsValid = false;
        };

        if (despesa.possuiDataFinal && despesa.frequencia.periodica) {
            if (validar.data(despesa.dataFinal))
                copiaIsValid.dataFinal = true;
            else {
                copiaIsValid.dataFinal = false;
                formIsValid = false;
            };
        };

        if (despesa.possuiDataFinal && despesa.frequencia.periodica) {
            if (new Date(despesa.dataInicio) >= new Date(despesa.dataFinal)) {
                copiaIsValid.dataInicio = false;
                copiaIsValid.dataFinal = false;
                formIsValid = false;
            };
        };

        if (despesa.categoria.opcoes.length) {
            if (despesa.atrelamento.id && despesa.atrelamento.nome)
                copiaIsValid.atrelamento = true;
            else {
                copiaIsValid.atrelamento = false;
                formIsValid = false;

            }
        } else
            copiaIsValid.atrelamento = true;

        if (formIsValid) {
            try {
                setIsLoading(true);
                await dispatch(despesasActions.createDespesa({
                    nome: despesa.nome,
                    valor: parseFloat(despesa.valor),
                    idFrequencia: despesa.frequencia.id,
                    idSubcategoria: despesa.subcategoria.id,
                    dataInicio: despesa.dataInicio,
                    dataFinal: despesa.possuiDataFinal && despesa.frequencia.periodica ? despesa.dataFinal : null,
                    idAtrelamento: despesa.categoria.opcoes && despesa.atrelamento.id ? despesa.atrelamento.id : null
                }));
                exibirMensagem.success("A despesa foi cadastrada com sucesso");
                props.onClose();
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        } else {
            exibirMensagem.formInvalid();
        };

        setIsValid(copiaIsValid);
    };

    return (
        <Modal titulo="Cadastrar despesa" onClose={() => props.onClose()}>
            {isLoading ? <Spinner /> : (
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid text-center">

                        <div className="row my-1">
                            <div className="col-2">
                                Frequência:
                            </div>

                            <div className="col-5">
                                <BeautyDropdown
                                    inline
                                    returnObject
                                    list={frequencias}
                                    default={despesa.frequencia.nome}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.frequencia = { id: value.id, nome: value.nome, periodica: value.periodica };
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row my-1">
                            <div className="col-2">
                                Categoria:
                            </div>

                            <div className="col-5">
                                <BeautyDropdown
                                    inline
                                    returnObject
                                    list={opcoes.filter(opcao => opcao.id !== 5)}
                                    default={despesa.categoria.nome}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.categoria = { id: value.id, nome: value.nome, opcoes: value.opcoes ? [...value.opcoes] : [] };
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2">
                                Subcategoria:
                            </div>

                            <div className="col-5">
                                <BeautyDropdown
                                    inline
                                    returnObject
                                    list={subcategorias}
                                    default={despesa.subcategoria.nome}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.subcategoria = { id: value.id, nome: value.nome };
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>

                        {despesa.categoria.opcoes.length ? (
                            <div className="row">
                                <div className="col-2">
                                    {despesa.categoria.nome.slice(0, -1)}:
                                </div>
                                <div className="col-5">
                                    <BeautyDropdown
                                        inline
                                        returnObject
                                        list={[...despesa.categoria.opcoes]}
                                        default={despesa.categoria.opcoes[0].nome}
                                        invalid={!isValid.atrelamento}
                                        onChange={value => {
                                            let copiaDespesa = { ...despesa };
                                            copiaDespesa.atrelamento = { id: value.id, nome: value.nome };
                                            setDespesa(copiaDespesa);
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}

                        <hr />

                        <div className="row my-1">
                            <div className="col-2">
                                {!despesa.frequencia.periodica ? "Data:" : "Data de início:"}
                            </div>
                            <div className={!despesa.frequencia.periodica ? "col-10" : "col-4"}>
                                <Input
                                    type="date"
                                    size="100%"
                                    invalid={!isValid.dataInicio}
                                    placeholder="Data"
                                    value={dataHoje()}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.dataInicio = value;
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                            {!despesa.frequencia.periodica || !despesa.possuiDataFinal ? null : (
                                <div className="col-2">
                                    Data final:
                                </div>
                            )}
                            {!despesa.frequencia.periodica || !despesa.possuiDataFinal ? null : (
                                <div className="col-4">
                                    <Input
                                        type="date"
                                        size="100%"
                                        invalid={!isValid.dataFinal}
                                        placeholder="Data"
                                        value={despesa.dataFinal}
                                        onChange={value => {
                                            let copiaDespesa = { ...despesa };
                                            copiaDespesa.dataFinal = value;
                                            setDespesa(copiaDespesa);
                                        }}
                                    />
                                </div>
                            )}

                            {!despesa.frequencia.periodica ? null : (
                                <div className="col-12 text-right">
                                    <Checkbox
                                        size="small"
                                        defaultChecked={!despesa.possuiDataFinal}
                                        onChange={() => {
                                            let copiaDespesa = { ...despesa };
                                            copiaDespesa.possuiDataFinal = !despesa.possuiDataFinal;
                                            setDespesa(copiaDespesa);
                                        }}
                                    >Não possui data final</Checkbox>
                                </div>
                            )}
                        </div>

                        <hr />

                        <div className="row my-2">
                            <div className="col-2">
                                {!despesa.frequencia.periodica ? "Valor:" : "Valor das Parcelas:"}
                            </div>
                            <div className="col-10">
                                <Input
                                    onlyMoney
                                    size="100%"
                                    invalid={!isValid.valor}
                                    placeholder={!despesa.frequencia.periodica ? "Valor (R$)" : "Valor das Parcelas (R$)"}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.valor = value;
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>

                        <hr />

                        <div className="row my-1">
                            <div className="col-2">
                                Nome:
                            </div>
                            <div className="col-10">
                                <Input
                                    size="100%"
                                    invalid={!isValid.nome}
                                    placeholder="Nome (Ex: Aluguel do pátio)."
                                    maxLength="55"
                                    setValue={despesa.nome}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.nome = value;
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <Button
                                    color="green"
                                    type="submit"
                                >Cadastrar</Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default ModalCadastroDespesa;