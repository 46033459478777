// Dependêcias
import React from "react";
import { withRouter } from "react-router-dom";

// Css
import classes from "./Sucata.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";
import getEtapaCadastro from "../../../helpers/getEtapaCadastroSucata";

const Sucata = props => {
    return (
        <tr
            className={props.sucata.status.id === 4 ? classes.Purple : props.sucata.status.id === 12 ? classes.Green : ""}
            onClick={() => props.history.push(getEtapaCadastro(props.sucata.status.id, props.sucata.id))}
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td>
                <img
                    className={classes.LogoMarca}
                    src={`/images/logos-marcas/${formatar.alphaNumeric(props.sucata.fipe.marca.nome)}.png`}
                    title={props.sucata.fipe.marca.nome}
                    alt={props.sucata.fipe.marca.nome}
                />
            </td>
            <td className="text-left">{props.sucata.fipe.modelo.nome || props.sucata.fipe.veiculo?.nome}</td>
            <td>{props.sucata.fipe.ano.nome}</td>
            <td>{formatar.data(props.sucata.dataCriacao)}</td>
            <td>{props.sucata.quantidadePecas || "-"}</td>
            <td>{props.sucata.status.nome}</td>
        </tr>
    );
};

export default withRouter(Sucata);