// Dependências
import React from "react";
import { BsFillGearFill } from "react-icons/bs";
import { FaCar, FaTools, FaDollarSign, FaComment, FaQuestion, FaHome, FaBoxes } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { ImUsers } from "react-icons/im";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// Actions
import * as authActions from "../../store/actions/auth";

// Css
import classes from "./Navbar.module.css";

// Components
import BotaoWhatsapp from "./BotaoWhatsapp/BotaoWhatsapp";

// Helpers
import * as exibirMensagem from "../../helpers/exibirMensagem";

const Navbar = props => {
    const dispatch = useDispatch();

    const darkMode = useSelector(state => state.colorPalette.darkMode);
    const usuario = useSelector(state => state.usuario.meuUsuario);

    const logout = () => {
        exibirMensagem.exibir({
            title: "Tem certeza?",
            text: `Deseja mesmo realizar Logout?`,
            showCancelButton: true,
            confirmButtonText: "Sim",
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    dispatch(authActions.logout());
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    return (
        <div>
            <header className={`${classes.NavbarMobile} ${darkMode ? classes.Dark : ""}`}>
                <nav className={classes.NavbarNavigation}>

                    <div className={classes.Logo}>
                        <NavLink to="/inicio" >
                            <img src={"/images/logo.png"} alt="Logo" />
                        </NavLink>
                    </div>

                    <div className={classes.Spacer} />

                    <HiOutlineMenuAlt3
                        size="40px"
                        className={classes.NavbarToggler}
                        onClick={props.onDrawerClick}
                    />
                </nav>
            </header>

            <nav className={`${classes.Navbar} ${darkMode ? classes.Dark : ""} ${usuario.conta.configuracoes.demonstracao ? classes.ModoDemonstracao : ""} navbar navbar-expand-lg`}>
                <div className={classes.NavbarBrand}>
                    <img src="/images/logo.png" alt="" />
                    <span>{usuario.conta.nome}</span>
                    <span>{usuario.nome || "Carregando..."}</span>
                </div>

                <div className={classes.NavbarCollapse}>
                    {usuario.conta.tipo.id === 1 ? (
                        <ul className={`${classes.NavbarNav} navbar-nav mt-auto`}>
                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/contas"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <ImUsers className={classes.Icon} />
                                    <span>Gerenciar Contas</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/mensagens"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaComment className={classes.Icon} />
                                    <span>Mensagens</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/arquetipos-detran"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <GiCarWheel className={classes.Icon} />
                                    <span>Arquétipos Detran</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <div
                                    className={`${classes.NavLink} nav-link`}
                                    onClick={logout}
                                >
                                    <RiLogoutBoxLine className={classes.Icon} />
                                    <span>Logout</span>
                                </div>
                            </li>
                        </ul>
                    ) : (
                        <ul className={`${classes.NavbarNav} navbar-nav mt-auto`}>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/inicio"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaHome className={classes.Icon} />
                                    <span>Início</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/estoque/sucatas"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaCar className={classes.Icon} />
                                    <span>Minhas sucatas</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/estoque/pecas"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <GiCarWheel className={classes.Icon} />
                                    <span>Minhas peças</span>
                                </NavLink>
                            </li>

                            {usuario.permissoes.find(permissao => permissao.id === 3) ? (
                                <li className={`${classes.NavItem} nav-item`}>
                                    <NavLink
                                        to="/financeiro"
                                        className={`${classes.NavLink} nav-link`}
                                        activeClassName={classes.NavLinkActive}
                                    >
                                        <FaDollarSign className={classes.Icon} />
                                        <span>Financeiro</span>
                                    </NavLink>
                                </li>
                            ) : null}

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/perguntas"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaComment className={classes.Icon} />
                                    <span>Perguntas</span>
                                </NavLink>
                            </li>

                            {usuario.permissoes.find(permissao => permissao.id === 4) ? (
                                <li className={`${classes.NavItem} nav-item`}>
                                    <NavLink
                                        to="/funcionarios"
                                        className={`${classes.NavLink} nav-link`}
                                        activeClassName={classes.NavLinkActive}>
                                        <ImUsers className={classes.Icon} />
                                        <span>Funcionários</span>
                                    </NavLink>
                                </li>
                            ) : null}

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/cadastro"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaTools className={classes.Icon} />
                                    <span>Anúnciar peças</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/localizacao"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}
                                >
                                    <FaBoxes className={classes.Icon} />
                                    <span>Localizações</span>
                                </NavLink>
                            </li>
                            {/* <li className={`${classes.NavItem} nav-item`}>
                            <NavLink
                                to="/usuarios"
                                className={`${classes.NavLink} nav-link`}
                                activeClassName={classes.NavLinkActive}>
                                <ImUsers className={classes.Icon} />
                                <span>Usuários</span>
                            </NavLink>
                        </li> */}

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/tutoriais"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}>
                                    <FaQuestion className={classes.Icon} />
                                    <span>Tutoriais</span>
                                </NavLink>
                            </li>

                            <li className={`${classes.NavItem} nav-item`}>
                                <NavLink
                                    to="/configuracoes"
                                    className={`${classes.NavLink} nav-link`}
                                    activeClassName={classes.NavLinkActive}>
                                    <BsFillGearFill className={classes.Icon} />
                                    <span>Configurações</span>
                                </NavLink>
                            </li>

                        </ul>
                    )}
                </div>

                <BotaoWhatsapp />
            </nav>
        </div >
    );
};

export default Navbar;
