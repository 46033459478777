// Dependências
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import * as fipeActions from "../../../../store/actions/fipe";

// Components
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import Lista from "./Lista/Lista";
import Menu from "./Menu/Menu";
import Spinner from "../../../UI/Spinner/Spinner";
import Titulo from "../../../UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const SelecaoFipe = props => {
    const dispatch = useDispatch();

    const tipos = useSelector(state => state.fipe.tipos);
    const marcas = useSelector(state => state.fipe.marcas);
    const anos = useSelector(state => state.fipe.anos);
    const veiculos = useSelector(state => state.fipe.veiculos);
    const modelos = useSelector(state => state.fipe.modelos);

    const [isLoading, setIsLoading] = useState(true);
    const [itensLista, setItensLista] = useState();

    const [tipo, setTipo] = useState(props.fipe.tipo ? props.fipe.tipo : { id: "", nome: "" });
    const [marca, setMarca] = useState(props.fipe.marca ? props.fipe.marca : { id: "", nome: "" });
    const [ano, setAno] = useState(props.fipe.ano ? props.fipe.ano : { id: "", nome: "" });
    const [veiculo, setVeiculo] = useState(props.fipe.veiculo ? props.fipe.veiculo : { id: "", nome: "" });
    const [modelo, setModelo] = useState(props.fipe.modelo ? props.fipe.modelo : { id: "", nome: "" });

    // Requisição dos tipos
    useEffect(() => {
        const fetchTipos = async () => {
            setIsLoading(true);
            try {
                await dispatch(fipeActions.fetchTipos());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchTipos();
    }, [dispatch]);

    // Requisição das marcas
    useEffect(() => {
        const fetchMarcas = async () => {
            setIsLoading(true);
            try {
                await dispatch(fipeActions.fetchMarcas(tipo.id));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (tipo.id)
            fetchMarcas();
    }, [dispatch, tipo]);

    // Requisição dos anos
    useEffect(() => {
        const fetchAnos = async () => {
            setIsLoading(true);
            try {
                await dispatch(fipeActions.fetchAnos(tipo.id, marca.id));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (tipo.id && marca.id)
            fetchAnos();
    }, [dispatch, tipo, marca]);

    // Requisição dos veículos
    useEffect(() => {
        const fetchVeiculos = async () => {
            setIsLoading(true);
            try {
                await dispatch(fipeActions.fetchVeiculos(tipo.id, marca.id, ano.id));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (tipo.id && marca.id && ano.id)
            fetchVeiculos();
    }, [dispatch, tipo, marca, ano]);

    // Requisição dos modelos
    useEffect(() => {
        const fetchModelos = async () => {
            setIsLoading(true);
            try {
                await dispatch(fipeActions.fetchModelos(ano.id, veiculo.id));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (tipo.id && marca.id && ano.id && veiculo.id)
            fetchModelos();
    }, [dispatch, tipo, marca, ano, veiculo]);

    // Setando itensLista
    useEffect(() => {
        if (veiculo.id)
            setItensLista(modelos);
        else if (ano.id)
            setItensLista(veiculos);
        else if (marca.id)
            setItensLista(anos);
        else if (tipo.id)
            setItensLista(marcas);
        else
            setItensLista(tipos);
    }, [tipo, marca, ano, veiculo, modelo, tipos, marcas, anos, veiculos, modelos]);

    // onChange
    useEffect(() => {
        props.onChange({
            tipo: tipo,
            marca: marca,
            ano: ano,
            veiculo: veiculo,
            modelo: modelo
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo, marca, ano, veiculo, modelo]);

    const getDefaultValue = () => {
        if (veiculo.id)
            return modelo;
        else if (ano.id)
            return veiculo;
        else if (marca.id)
            return ano;
        else if (tipo.id)
            return marca;
        else
            return tipo;
    };

    const getPasso = () => {
        if (modelo.id)
            return 6;
        if (veiculo.id)
            return 5;
        else if (ano.id)
            return 4;
        else if (marca.id)
            return 3;
        else if (tipo.id)
            return 2;
        else
            return 1;
    };

    const onChangeHandler = (value) => {
        if (veiculo.id) {
            setModelo({ ...value });

            props.onChange({
                tipo: tipo,
                marca: marca,
                ano: ano,
                veiculo: veiculo,
                modelo: { ...value }
            });

            if (props.onProxClick)
                props.onProxClick();
        } else if (ano.id)
            setVeiculo({ ...value });
        else if (marca.id)
            setAno({ ...value });
        else if (tipo.id)
            setMarca({ ...value });
        else
            setTipo({ ...value });
    };

    const submitHandler = () => {
        if (tipo.id && marca.id && ano.id && veiculo.id && modelo.id) {
            if (props.onProxClick)
                props.onProxClick();
        } else
            exibirMensagem.warning("Por favor, informe o modelo da sucata antes de prosseguir.");
    };

    return (
        <div className="row">
            <Titulo>Selecione a FIPE do veículo que deseja cadastrar</Titulo>
            <div className="col-9">
                {isLoading ? <Spinner /> : (
                    <Lista
                        itens={itensLista}
                        defaultValue={getDefaultValue()}
                        onChange={value => onChangeHandler(value)} />
                )}
            </div>
            <div className="col-3">
                <Menu
                    tipo={tipo.nome}
                    marca={marca.nome}
                    ano={ano.nome}
                    veiculo={veiculo.nome}
                    modelo={modelo.nome}
                    selecaoTipo={() => {
                        setTipo({ id: "", nome: "" });
                        setMarca({ id: "", nome: "" });
                        setAno({ id: "", nome: "" });
                        setVeiculo({ id: "", nome: "" });
                        setModelo({ id: "", nome: "" });
                    }}
                    selecaoMarca={() => {
                        setMarca({ id: "", nome: "" });
                        setAno({ id: "", nome: "" });
                        setVeiculo({ id: "", nome: "" });
                        setModelo({ id: "", nome: "" });
                    }}
                    selecaoAno={() => {
                        setAno({ id: "", nome: "" });
                        setVeiculo({ id: "", nome: "" });
                        setModelo({ id: "", nome: "" });
                    }}
                    selecaoVeiculo={() => {
                        setVeiculo({ id: "", nome: "" });
                        setModelo({ id: "", nome: "" });
                    }}
                    selecaoModelo={() => setModelo({ id: "", nome: "" })}
                    passo={getPasso()} />
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default SelecaoFipe;