// Dependências
import React, { useEffect, useState } from "react";
import classes from "./Checkbox.module.css";
import { v4 as uuidv4 } from 'uuid'

const Checkbox = (props) => {
    const [checked, setChecked] = useState(props.defaultChecked ? true : false);
    const idCheckbox = uuidv4();

    let checkboxColor = {
        stroke: "#c8ccd4",
    };

    checked ? props.checkboxColor ? (checkboxColor.stroke = props.checkboxColor) : (checkboxColor.stroke = "#6f37d6") : (checkboxColor.stroke = "#c8ccd4");

    useEffect(() => {
        if (props.defaultChecked)
            setChecked(props.defaultChecked);
        else
            setChecked(false);
    }, [props.defaultChecked]);

    return (
        <div className={`${props.disabled ? classes.Disabled : null} ${props.className}`}>
            <input
                type="checkbox"
                id={props.id ? props.id : idCheckbox}
                className={classes.UltraCheckbox}
                style={{ display: "none" }}
                checked={checked}
                onChange={() => {
                    if (props.disabled)
                        return;

                    setChecked(!checked);
                    if (props.onChange)
                        props.onChange(!checked);
                }}
            />
            <label
                htmlFor={props.disabled ? null : props.id ? props.id : idCheckbox}
                className={`${classes.Check} ${props.size === "small" ? classes.Small : ""}`}
                style={{ width: props.size }}
            >
                <svg viewBox="0 0 18 18" style={checkboxColor}>
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                    <polyline points="1 9 7 14 15 4" />
                </svg>
            </label>

            <label
                htmlFor={props.disabled ? null : props.id ? props.id : idCheckbox}
                className={classes.textLabel}
                style={props.labelStyle}
            >
                {props.children}
            </label>
        </div>
    );
};

export default Checkbox;
