import {
    LOGIN,
    LOGOUT
} from "../actions/auth";

import {
    FETCH_CONTA_TOKEN
} from "../actions/admin";

const getInitialState = () => {
    if (JSON.parse(localStorage.getItem("bearerToken")) || JSON.parse(sessionStorage.getItem("bearerToken"))) {
        return {
            token: JSON.parse(localStorage.getItem("bearerToken")) || JSON.parse(sessionStorage.getItem("bearerToken")),
            isAuthenticated: true
        };
    };

    return {
        token: "",
        tokenReserva: null,
        isAuthenticated: false
    };
}

const initialState = getInitialState();

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTA_TOKEN:
            return { ...state, token: action.token, tokenReserva: action.tokenReserva, isAuthenticated: true };
        case LOGIN:
            return { ...state, token: action.token, isAuthenticated: true };
        case LOGOUT:
            return { ...state, token: "", tokenReserva: null, isAuthenticated: false };
        default:
            return state;
    }
};
