// Dependências
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

// Components
import Input from "../../../../UI/Input/Input";

// Css
import classes from "./Endereco.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Endereco = props => {
    return (
        <div className="row mb-3">
            <div className="col-4">
                <Input
                    name="estado"
                    maxLength="50"
                    placeholder="Estado"
                    value={props.endereco.estado}
                    onChange={value => props.onChange({ ...props.endereco, estado: value })}
                />
            </div>
            <div className="col-8">
                <Input
                    name="cidade"
                    maxLength="50"
                    placeholder="Cidade"
                    value={props.endereco.cidade}
                    onChange={value => props.onChange({ ...props.endereco, cidade: value })}
                />

                <AiOutlineClose
                    className={classes.CloseButton}
                    title="Apagar endereço"
                    onClick={props.onDelete}
                />
            </div>
            <div className="col-2">
                <Input
                    onlyNumbers
                    name="cep"
                    maxLength="10"
                    placeholder="CEP"
                    value={props.endereco.cep}
                    onChange={value => props.onChange({ ...props.endereco, cep: formatar.alphaNumeric(value) })}
                />
            </div>
            <div className="col-4">
                <Input
                    name="bairro"
                    maxLength="50"
                    placeholder="Bairro"
                    value={props.endereco.bairro}
                    onChange={value => props.onChange({ ...props.endereco, bairro: value })}
                />
            </div>
            <div className="col-4">
                <Input
                    name="logradouro"
                    maxLength="50"
                    placeholder="Logradouro"
                    value={props.endereco.logradouro}
                    onChange={value => props.onChange({ ...props.endereco, logradouro: value })}
                />
            </div>
            <div className="col-2">
                <Input
                    onlyNumbers
                    name="numero"
                    maxLength="5"
                    placeholder="Número"
                    value={props.endereco.numero}
                    onChange={value => props.onChange({ ...props.endereco, numero: value })}
                />
            </div>
            <div className="col-12">
                <Input
                    name="complemento"
                    maxLength="50"
                    placeholder="Complemento"
                    value={props.endereco.complemento}
                    onChange={value => props.onChange({ ...props.endereco, complemento: value })}
                />
            </div>
        </div>
    );
};

export default Endereco;