import { v4 as uuidv4 } from 'uuid';
import dataHoje from "../../helpers/dataHoje";

import { UPDATE_CADASTRO_SUCATA, UPDATE_CADASTRO_GRUPO_PECAS, RESET_CADASTRO } from "../actions/cadastro";

import { CREATE_ANUNCIOS } from "../actions/anuncios";

const getInitialState = () => {
    let cadastroSucata = JSON.parse(sessionStorage.getItem("cadastro-sucata"));
    let cadastroGrupoPecas = JSON.parse(sessionStorage.getItem("cadastro-grupo-pecas"));

    if (!cadastroSucata) {
        cadastroSucata = {
            despesas: [
                {
                    id: uuidv4(),
                    nome: "",
                    valor: "",
                    data: dataHoje(),
                    subcategoria: { id: "", nome: "" }
                },
                {
                    id: uuidv4(),
                    nome: "",
                    valor: "",
                    data: dataHoje(),
                    subcategoria: { id: "", nome: "" }
                }
            ],
            fotos: [],
            plataformas: [],
            pecas: [],
            pecasAdicionais: [],
            sucata: {
                cambio: "",
                chassi: "",
                cor: "",
                motor: "",
                observacoes: "",
                placa: "",
                renavam: "",
                fipe: {
                    tipo: "",
                    marca: "",
                    ano: "",
                    veiculo: "",
                    modelo: ""
                },
            },
        };
    };

    if (!cadastroGrupoPecas) {
        cadastroGrupoPecas = {
            pecas: [],
            plataformas: []
        };
    };

    return {
        cadastroSucata: cadastroSucata,
        cadastroGrupoPecas: cadastroGrupoPecas
    };
};

const initialState = getInitialState();

const cadastroSucata = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CADASTRO_GRUPO_PECAS:
            return { ...state, cadastroGrupoPecas: action.cadastroGrupoPecas };
        case UPDATE_CADASTRO_SUCATA:
            return { ...state, cadastroSucata: action.cadastroSucata };
        case RESET_CADASTRO:
            return { ...state, cadastroSucata: getInitialState().cadastroSucata, cadastroGrupoPecas: getInitialState().cadastroGrupoPecas };
        case CREATE_ANUNCIOS:
            sessionStorage.removeItem("cadastro-sucata");
            sessionStorage.removeItem("cadastro-grupo-pecas");
            return { ...state, cadastroSucata: getInitialState().cadastroSucata, cadastroGrupoPecas: getInitialState().cadastroGrupoPecas };
        default:
            return state;
    };
};

export default cadastroSucata;
