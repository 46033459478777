// Dependências
import React from "react";

const ManutencaoScreen = props => {
    return (
        <div className="container-fluid text-center py-5">
            <div className="row">
                <div className="col-12 pb-4">
                    <img src="/images/logo.png" style={{ width: "200px" }} alt="Unificar" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h1>Em manutenção.</h1>
                    <h4>Estamos finalizando um novo pacote de upgrades.</h4>
                    <h4>Retomaremos as atividades em breve.</h4>
                </div>
            </div>
        </div>
    );
};

export default ManutencaoScreen;