// Dependências
import React from "react";

// Css
import classes from "./Medidas.module.css";

const Medidas = props => {
    return (
        <tr>
            <td
               
                title="Editar altura"
                className={classes.Medida}
                onClick={() => props.updatePeca("altura")}
            >{props.medidas.altura ? `${props.medidas.altura}cm` : "-"}</td>

            <td
               
                title="Editar comprimento"
                className={classes.Medida}
                onClick={() => props.updatePeca("comprimento")}
            >{props.medidas.comprimento ? `${props.medidas.comprimento}cm` : "-"}</td>

            <td
               
                title="Editar largura"
                className={classes.Medida}
                onClick={() => props.updatePeca("largura")}
            >{props.medidas.largura ? `${props.medidas.largura}cm` : "-"}</td>

            <td
               
                title="Editar peso"
                className={classes.Medida}
                onClick={() => props.updatePeca("peso")}
            >{props.medidas.peso ? `${props.medidas.peso}g` : "-"}</td>
        </tr>
    );
};

export default Medidas;