// Dependências
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Css
import classes from "./PerguntasScreen.module.css";

// Actions
import * as perguntasActions from "../../store/actions/perguntas";
import * as respostasProntasActions from "../../store/actions/respostas-prontas";

// Components
import Pergunta from '../../components/Perguntas/Pergunta/Pergunta';
import Spinner from "../../components/UI/Spinner/Spinner";
import Titulo from "../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../helpers/exibirMensagem";

const PerguntasScreen = props => {
    const dispatch = useDispatch();

    const perguntas = useSelector((state) => state.perguntas.perguntas);
    const respostasProntas = useSelector((state) => state.respostasProntas.respostasProntas);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        exibirMensagem.warning({
            title: "Atenção!",
            text: "Lembre-se que não é permitido informar dados de contato aos compradores, nem direcioná-los para outras plataformas de venda ou meios de pagamento."
        });
    }, []);

    useEffect(() => {
        const fetchPerguntasEmAberto = async () => {
            try {
                setIsLoading(true);

                await dispatch(perguntasActions.fetchPerguntasEmAberto());
                await dispatch(respostasProntasActions.fetchRespostasProntas());

            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchPerguntasEmAberto();
    }, [dispatch]);

    const responderPergunta = async (idPergunta, marketplace, resposta) => {
        try {
            await dispatch(perguntasActions.enviarResposta(idPergunta, marketplace, resposta));
        } catch (error) {
            console.log(error);
        };
    };

    return isLoading ? <Spinner /> :
        perguntas.length ? (
            <div>
                <div className="container-fluid p-0">
                    <Titulo>Perguntas Recebidas</Titulo>

                    {perguntas.map(pergunta =>
                        <Pergunta
                            key={pergunta.id}
                            pergunta={pergunta}
                            respostasProntas={respostasProntas}
                            onEnviarResposta={resposta => responderPergunta(pergunta.id, pergunta.origem.id, resposta)}
                        />
                    )}
                </div>
            </div>
        ) : (
            <div className="row" style={{ height: "83vh" }}>
                <div style={{ position: "relative", width: "100%", height: "90%" }}>
                    <div className={classes.Center}>
                        <h2>Você não tem nenhuma pergunta em aberto!</h2>
                        <br />
                        <h4>Todas as suas perguntas já foram respondidas.</h4>
                    </div>
                </div>
            </div>
        );
};

export default PerguntasScreen;