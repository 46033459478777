// Dependências
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";

const GraficoPizza = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    const [valorTotal, setValorTotal] = useState(0);

    // Definindo valorTotal
    useEffect(() => {
        props.data.forEach(value => setValorTotal(valorTotal + value));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.labels, props.colors]);

    return (
        <Pie
            options={{
                legend: {
                    position: "left",
                    labels: {
                        fontSize: 14,
                        fontFamily: "Quicksand",
                        fontColor: darkMode ? "#f8f9fa" : "#000",
                    },
                },
                title: {
                    display: true,
                    text: props.title,
                    fontSize: 24,
                    fontFamily: "Quicksand",
                }
            }}
            height={280}
            data={{
                labels: props.labels,
                datasets: [{
                    data: props.data.map(value => parseFloat(value).toFixed(2)),
                    backgroundColor: props.colors,
                    borderWidth: 0,
                }],
            }}
        />
    );
};

export default GraficoPizza;
