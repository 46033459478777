import {
    CREATE_ANUNCIOS,
    CREATE_ANUNCIOS_PECA,
    DELETE_ANUNCIO,
    FETCH_ANUNCIOS,
    FETCH_FRETES,
    FETCH_PLATAFORMAS,
    FETCH_TAXAS,
    UPDATE_ANUNCIO,
    UPDATE_ANUNCIOS,
} from "../actions/anuncios";

const initialState = {
    anuncios: [],
    fretes: [],
    plataformas: [],
    taxas: []
};

const anunciosReducer = (state = initialState, action) => {
    let anunciosAtualizados = [...state.anuncios];
    switch (action.type) {
        case CREATE_ANUNCIOS:
            action.anuncios.forEach(novoAuncio => anunciosAtualizados.push(novoAuncio));
            return { ...state, anuncios: anunciosAtualizados };
        case CREATE_ANUNCIOS_PECA:
            anunciosAtualizados = action.anuncios;
            return { ...state, anuncios: anunciosAtualizados };
        case DELETE_ANUNCIO:
            anunciosAtualizados = anunciosAtualizados.filter(anuncio => anuncio.id !== action.id);
            return { ...state, anuncios: anunciosAtualizados };
        case FETCH_ANUNCIOS:
            return { ...state, anuncios: action.anuncios };
        case FETCH_FRETES:
            return { ...state, fretes: action.fretes };
        case FETCH_PLATAFORMAS:
            return { ...state, plataformas: action.plataformas };
        case FETCH_TAXAS:
            return { ...state, taxas: action.taxas };
        case UPDATE_ANUNCIO:
            anunciosAtualizados = anunciosAtualizados.map(anuncio => anuncio.id === action.anuncio.id ? action.anuncio : anuncio);
            return { ...state, anuncios: anunciosAtualizados };
        case UPDATE_ANUNCIOS:
            return { ...state, anuncios: action.anuncios };
        default:
            return state;
    }
};

export default anunciosReducer;
