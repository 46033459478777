// Dependências
import React, { useEffect, useState } from "react";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Peca from "./Peca/Peca";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";

const TituloPecas = props => {

    const [copiaPecas, setCopiaPecas] = useState([]);

    // Setando copiaPecas
    useEffect(() => {
        setCopiaPecas([...props.pecas.map(peca => {
            const copiaPeca = copiaPecas.find(copiaPeca => copiaPeca.id === peca.id);

            if (copiaPeca)
                return { ...peca, titulo: copiaPeca.titulo, observacao: copiaPeca.observacao };

            return peca;
        })]);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pecas]);

    const submitHandler = () =>
        props.onProxClick(copiaPecas.map(peca => { return { id: peca.id, titulo: peca.titulo, observacao: peca.observacao } }));

    return (
        <div>
            {!props.hideTitle ? (
                <div>
                    <Titulo>Confirme o título dos anúncios</Titulo>

                    <div className="row">
                        <div className="col-12 mb-2">
                            <span>
                                <i>Você pode editar os títulos abaixo, caso deseje.</i>
                                <strong> À esquerda do título, temos o ícone das OBSERVAÇÕES. Clicando nele, é possível adicionar informações relevantes à descrição do anúncio, como: riscos, detalhes na peça, explicações sobre as fotos, entre outros.</strong>
                            </span>
                        </div>
                        <div className="col-12 mb-4">
                            <i><label style={{ fontSize: "12px", color: "#c34858" }}>Dica: Não é necessário preencher o campo OBSERVAÇÕES em todas as peças, apenas nas que possuem detalhes ou informações relevantes para o comprador. O sistema já gera uma descrição automática em todas as peças, informando a FIPE do carro do qual a peça foi retirada e também a DESCRIÇÃO DA LOJA, inserida na aba “Configurações”.</label></i>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="row" style={{ height: "68vh" }}>
                <Table
                    fixed
                    header={[]}>
                    {copiaPecas.length ? copiaPecas.map(peca =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(peca => peca.id === pecaAtualizada.id ? pecaAtualizada : peca);
                                setCopiaPecas([...copiaPecasAtualizadas]);
                                if (props.onChange)
                                    props.onChange([...copiaPecasAtualizadas]);
                            }}
                        />
                    ) :
                        <tr className="text-center h3">
                            <td colSpan="5">Nenhuma peça encontrada</td>
                        </tr>}
                </Table>
            </div>

            {
                props.bottomNavbar === false ? null :
                    <BottomNavbar
                        onAntClick={props.onAntClick}
                        onProxClick={submitHandler} />
            }
        </div >
    );
};

export default TituloPecas;