// Dependências
import React from "react";

// Components
import BeautyDropdown from "../../../../UI/BeautyDropdown/BeautyDropdown";

const Boolean = props => {
    return (
        <BeautyDropdown
            small
            notInline
            returnObject
            default={props.atributo.value.nome}
            titulo={props.atributo.nome}
            list={props.atributo.valores}
            invalid={props.atributo.isValid === false ? true : false}
            onChange={value => {
                let copiaAtributo = { ...props.atributo };
                copiaAtributo.value = value;
                props.onChange(copiaAtributo);
            }} />
    );
};

export default Boolean;