// Dependências
import React, { useEffect, useState } from "react";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import ModalTaxas from "./ModalTaxas/ModalTaxas";
import Peca from "./Peca/Peca";
import Table from "../../UI/Table/Table";
import Titulo from "../../UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import * as validar from "../../../helpers/validar";

const PrecificacaoPecas = props => {
    const [copiaPecas, setCopiaPecas] = useState([]);

    const [displayModal, setDisplayModal] = useState(false);
    const [pecasInvalidas, setPecasInvalidas] = useState([]);
    const [pecaTaxa, setPecaTaxa] = useState({});

    // Setando copiaPecas
    useEffect(() => {
        if (!copiaPecas.length)
            setCopiaPecas([...props.pecas.map(peca => {
                return {
                    ...peca,
                    preco: copiaPecas.find(copiaPeca => copiaPeca.id === peca.id)?.preco || peca.preco || null
                };
            })]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pecas]);

    const handleSubmit = () => {
        let pecasInvalidasAtualizadas = copiaPecas.filter(peca => !validar.preco(peca.preco));
        setPecasInvalidas(pecasInvalidasAtualizadas.map(peca => peca.id));

        if (!pecasInvalidasAtualizadas.length)
            props.onProxClick([...copiaPecas]);
        else
            exibirMensagem.warning("Verifique os campos destacados em vermelho.");
    };

    return (
        <div className="container-fluid pb-5">
            <Titulo>Insira o preço das peças</Titulo>

            <div className="row">
                <div className="col-12 mb-3">
                    <strong style={{ fontSize: "16px" }}>À esquerda do título, algumas peças possuem um ícone vermelho, indicando que foram inseridas OBSERVAÇÕES na descrição da peça. Clique no ícone de OBSERVAÇÕES (ao lado do preço) para visualizar e leve isso em consideração para estabelecer o preço.</strong>
                </div>
            </div>

            {displayModal ?
                <ModalTaxas
                    peca={pecaTaxa}
                    onClose={() => setDisplayModal(false)} /> : null}

            <div className="row">
                <Table header={["Peça", "Preço"]}>
                    {copiaPecas.map(peca =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            showSucata={props.showSucata}
                            deleteAvailable={copiaPecas.length !== 1}
                            isValid={!pecasInvalidas.includes(peca.id)}
                            onDelete={() => setCopiaPecas(copiaPecas.filter(copiaPeca => copiaPeca.id !== peca.id))}
                            onTaxasClick={() => {
                                setDisplayModal(true);
                                setPecaTaxa({ ...peca });
                            }}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(copiaPeca => copiaPeca.id === pecaAtualizada.id ? pecaAtualizada : copiaPeca);
                                setCopiaPecas(copiaPecasAtualizadas);
                            }}
                        />
                    )}
                </Table>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={() => handleSubmit()} />
        </div>
    );
};

export default PrecificacaoPecas;