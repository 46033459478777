import {
    CREATE_FOTOS_PECA,
    CRETE_LOCALIZACAO,
    DELETE_LOCALIZACAO,
    CREATE_PECAS,
    DELETE_PECA,
    DELETE_PECAS,
    FETCH_FOTOS_PECAS,
    FETCH_LOCALIZACOES,
    FETCH_PECA,
    FETCH_PECAS,
    FETCH_NEW_PECAS,
    UPDATE_PECA,
    UPDATE_PECAS,
    UPDATE_LOCALIZACAO
} from "../actions/pecas";

import { CREATE_ANUNCIOS, CREATE_ANUNCIOS_PECA, DELETE_ANUNCIO, UPDATE_ANUNCIO } from "../actions/anuncios";

import { CREATE_VENDA } from "../actions/vendas";

const initialState = {
    localizacoes: [],
    pecas: [],
    peca: {},
};

const pecasReducer = (state = initialState, action) => {
    let copiaPeca = { ...state.peca };
    let copiaPecas = [...state.pecas];
    let copiaLocalizacoes = [...state.localizacoes];

    switch (action.type) {
        case CREATE_FOTOS_PECA:
            return { ...state, peca: action.peca };
        case UPDATE_LOCALIZACAO:
            copiaLocalizacoes = copiaLocalizacoes.map(loc => {
                if (loc.id === action.localizacao.id){
                    return {
                        ...loc,
                        nome: action.localizacao.nome
                    };
                } else {
                    return loc;
                }
            });
            return { ...state, localizacoes: copiaLocalizacoes };
        case DELETE_LOCALIZACAO:
            console.log(action)
            copiaLocalizacoes = copiaLocalizacoes.filter(localizacao => localizacao.id !== action.id);
            return { ...state, localizacoes: copiaLocalizacoes };
        case CRETE_LOCALIZACAO:
            if (action.pecas.find(peca => peca.id === copiaPeca.id))
                copiaPeca.localizacao = action.localizacao;

            copiaPecas = copiaPecas.map(copiaPeca => action.pecas.find(peca => peca.id === copiaPeca.id)?.id ? action.pecas.find(peca => peca.id === copiaPeca.id) : copiaPeca)
            return { ...state, localizacoes: [...copiaLocalizacoes, action.localizacao], pecas: copiaPecas, peca: copiaPeca }
        case CREATE_PECAS:
            return { ...state, pecas: action.pecas, peca: action.pecas.length === 1 ? action.pecas[0] : copiaPeca };
        case DELETE_PECA:
            let pecasAtualizadas = state.pecas.filter(peca => peca.id !== action.id);
            return { ...state, pecas: pecasAtualizadas };
        case DELETE_PECAS:
            copiaPecas = copiaPecas.filter(peca => action.idPecasExcluidas.find(pecaExcluida => pecaExcluida.id === peca.id) ? false : true);
            return { ...state, pecas: copiaPecas };
        case FETCH_FOTOS_PECAS:
            return { ...state, pecas: action.pecas };
        case FETCH_LOCALIZACOES:
            return { ...state, localizacoes: action.localizacoes };
        case FETCH_PECA:
            return { ...state, peca: action.peca };
        case FETCH_PECAS:
            return { ...state, pecas: action.pecas };
        case FETCH_NEW_PECAS:
            action.pecas.forEach(peca => copiaPecas.push({ ...peca }));
            return { ...state, pecas: [...copiaPecas] };
        case UPDATE_PECA:
            return { ...state, peca: action.peca };
        case UPDATE_PECAS:
            return { ...state, pecas: copiaPecas.map(copiaPeca => action.pecas.find(peca => peca.id === copiaPeca.id)?.id ? action.pecas.find(peca => peca.id === copiaPeca.id) : copiaPeca) };

        case CREATE_ANUNCIOS:
            if (!copiaPeca.anuncios)
                return state;

            action.anuncios.forEach(anuncio => copiaPeca.anuncios.push(anuncio));
            return { ...state, peca: copiaPeca };
        case CREATE_ANUNCIOS_PECA:
            if (copiaPeca.id === action.idPeca)
                copiaPeca.anuncios = [...action.anuncios];

            return { ...state, peca: copiaPeca };
        case CREATE_VENDA:
            return { ...state, peca: action.peca };
        case DELETE_ANUNCIO:
            if (!copiaPeca.anuncios)
                return state;

            copiaPeca.anuncios = copiaPeca.anuncios.filter(anuncio => anuncio.id !== action.id);
            return { ...state, peca: copiaPeca };
        case UPDATE_ANUNCIO:
            if (!copiaPeca.anuncios)
                return state;

            copiaPeca.anuncios = copiaPeca.anuncios.map(anuncio => anuncio.id === action.anuncio.id ? action.anuncio : anuncio);
            return { ...state, peca: copiaPeca };
        default:
            return state;
    }
};

export default pecasReducer;
