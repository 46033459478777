// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// Actions
import * as usuarioActions from "../../../../store/actions/usuario";

// Components
import Button from "../../../../components/UI/Button/Button";
import Input from "../../../../components/UI/Input/Input";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import TipoPermissao from "../../../../components/Configuracoes/TipoPermissao/TipoPermissao";
import Titulo from "../../../../components/UI/Titulo/Titulo";

// Css
import classes from "./CadastroUsuarioScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";


const CadastroUsuarioScreen = props => {
    const dispatch = useDispatch();

    const tiposPermissao = useSelector(state => state.usuario.tiposPermissao);

    const [copiaTiposPermissao, setCopiaTiposPermissao] = useState([]);
    const [usuario, setUsuario] = useState({
        nome: "",
        senha: "",
        confirmarSenha: "",
        permissoes: []
    });
    const [isValid, setIsValid] = useState({
        nome: true,
        senha: true,
        confirmarSenha: true
    });

    const [displaySenha, setDisplaySenha] = useState(false);
    const [displayConfirmarSenha, setDisplayConfirmarSenha] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição dos tiposPermissoes
    useEffect(() => {
        const fetchTiposPermissao = async () => {
            setIsLoading(true);

            try {
                await dispatch(usuarioActions.fetchTiposPermissao());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        fetchTiposPermissao();
    }, [dispatch]);

    // Setando copiaTiposPermissao marcando checked como false e excluindo a permissão dono
    useEffect(() => {
        setCopiaTiposPermissao([...tiposPermissao.map(tipoPermissao => tipoPermissao.id === 1 ?
            {
                ...tipoPermissao, permissoes: tipoPermissao.permissoes.filter(permissao => permissao.id !== 1)
            } : tipoPermissao).map(tipoPermissao => {
                return {
                    ...tipoPermissao,
                    permissoes: tipoPermissao.permissoes.map(permissao => {
                        return {
                            ...permissao,
                            checked: false
                        }
                    })
                }
            })]);
    }, [tiposPermissao]);

    const createUsuario = async () => {
        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (validar.senha(usuario.senha)) {
            if (usuario.senha === usuario.confirmarSenha) {
                copiaIsValid.senha = true;
                copiaIsValid.confirmarSenha = true;
            } else {
                copiaIsValid.confirmarSenha = false;
                formIsValid = false;
            };
        } else {
            formIsValid = false;
            copiaIsValid.senha = false;
        };

        if (validar.nome(usuario.nome))
            copiaIsValid.nome = true;
        else {
            formIsValid = false;
            copiaIsValid.nome = false;
        };

        if (formIsValid) {
            try {
                await dispatch(usuarioActions.createUsuario({
                    nome: usuario.nome,
                    senha: usuario.senha,
                    permissoes: [].concat.apply([], copiaTiposPermissao.filter(tipoPermissao => tipoPermissao.permissoes.filter(permissao => permissao.checked).length).map(tipoPermissao => tipoPermissao.permissoes))
                }));

                exibirMensagem.success("Usuario criado com sucesso!");
                props.history.push("/inicio");
            } catch (error) {
                console.log(error);
            };
        } else
            exibirMensagem.formInvalid();

        setIsValid(copiaIsValid);
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Cadastrar Usuário</Titulo>

            <div className="mb-5 h5 text-center">
                <div className="row mb-3">
                    <div className="col-3 pt-2">
                        Nome de usuário
                    </div>
                    <div className="col-9">
                        <Input
                            placeholder="Nome de usuário"
                            invalid={!isValid.nome}
                            onChange={value => setUsuario({ ...usuario, nome: value })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-3 pt-2">
                        Senha para login
                    </div>
                    <div className="col-9">
                        <Input
                            placeholder="Senha"
                            invalid={!isValid.senha}
                            type={displaySenha ? "text" : "password"}
                            onChange={value => setUsuario({ ...usuario, senha: value })}
                        />

                        {displaySenha ? (
                            <AiOutlineEye
                                size="30"
                                title="Ocultar senha"
                                className={classes.Eye}
                                onClick={() => setDisplaySenha(false)}
                            />
                        ) : (
                            <AiOutlineEyeInvisible
                                size="30"
                                title="Mostrar senha"
                                className={classes.Eye}
                                onClick={() => setDisplaySenha(true)}
                            />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-3 pt-2">
                        Confirmar senha
                    </div>
                    <div className="col-9">
                        <Input
                            placeholder="Confirmar senha"
                            invalid={!isValid.confirmarSenha}
                            type={displayConfirmarSenha ? "text" : "password"}
                            onChange={value => setUsuario({ ...usuario, confirmarSenha: value })}
                        />

                        {displayConfirmarSenha ? (
                            <AiOutlineEye
                                size="30"
                                title="Ocultar senha"
                                className={classes.Eye}
                                onClick={() => setDisplayConfirmarSenha(false)}
                            />
                        ) : (
                            <AiOutlineEyeInvisible
                                size="30"
                                title="Mostrar senha"
                                className={classes.Eye}
                                onClick={() => setDisplayConfirmarSenha(true)}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4><strong>Permissões</strong></h4>

                    <span style={{ fontSize: "16px" }}>Gerencie abaixo as funções do sistema às quais o usuário terá acesso:</span>
                </div>

                <div className="col-12 pt-4">
                    {copiaTiposPermissao.map(tipoPermissao =>
                        <TipoPermissao
                            key={tipoPermissao.id}
                            tipoPermissao={tipoPermissao}
                            onChange={permissoesAtualizas => setCopiaTiposPermissao(copiaTiposPermissao.map(copiaTipoPermissao => { return { ...copiaTipoPermissao, permissoes: copiaTipoPermissao.id === tipoPermissao.id ? [...permissoesAtualizas] : copiaTipoPermissao.permissoes } }))}
                        />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-center pt-5">
                    <Button
                        color="green"
                        onClick={createUsuario}
                    >Cadastrar usuário</Button>
                </div>
            </div>
        </div>
    );
};

export default CadastroUsuarioScreen;