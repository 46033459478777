// Dependências
import React from "react";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";

// Css
import classes from "./Modal.module.css"

const Modal = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return (
        <div>
            <main className={classes.ModalBackground} onClick={props.onClose} />
            <div
                className={darkMode ? classes.ModalDark : classes.ModalLight}
                style={props.big ? { width: "90vw", ...props.style } : {...props.style}}>
                <div className={classes.Header}>
                    <AiOutlineClose
                        className={classes.ModalClose}
                        onClick={props.onClose}
                        size="24"
                        title="Fechar aba"
                    />
                    <div className="row mb-2">
                        <div className="col-12 text-center">
                            <h3>{props.titulo}</h3>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={`row ${classes.ModalBody}`}>
                        <div className="col-12">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;