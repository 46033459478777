// Dependências
import React, { useEffect, useRef, useState } from "react";

// Components
import Atributo from "./Atributo/Atributo";
import Titulo from "../../UI/Titulo/Titulo";
import { set } from "react-ga";

const PART_NUMBER = {
    dica: null,
    id: "PART_NUMBER",
    maxLength: 255,
    naoSeAplica: false,
    nome: "Número de peça",
    obrigatorio: false,
    padrao: 12345678,
    tipo: "string",
    valores: [],
    value: 12345678
}

const FichaTecnica = props => {
    const pecaRef = useRef();
    if (pecaRef && props.scroll)
        pecaRef.current.scrollIntoView();

    const [atributos, setAtributos] = useState(props.peca.atributos)

    /* useEffect(() => {
        if(props.peca.atributos.filter(atr => atr.id === 'PART_NUMBER').length === 0) {
            setAtributos([...props.peca.atributos, PART_NUMBER])
        } else {
            setAtributos(props.peca.atributos)
        }
    }, [props.peca.atributos]) */
    return (
        <div className="container-fluid" ref={pecaRef} style={{ marginBottom: "50px" }}>
            <Titulo><span style={{ fontSize: "20px", color: "#808080" }}>{props.peca.arquetipo.nome} {props.showSucata ? `- ${props.peca.sucata}` : null}</span></Titulo>

    
            <div className="row">
                {atributos.map(atributo => (
                    <Atributo
                        key={atributo.id}
                        atributo={atributo}
                        onChange={atributoAtualizado => {
                            let copiaPeca = { ...props.peca };
                            copiaPeca.atributos = copiaPeca.atributos.map(copiaAtributo => copiaAtributo.id === atributo.id ? atributoAtualizado : copiaAtributo);
                            props.onChange(copiaPeca);
                        }} />
                ))}
            </div>
        </div>
    );
};

export default FichaTecnica;