// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import DadosSucata from "../../../../components/Cadastro/Sucata/DadosSucata/DadosSucata";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const SelecaoFipeScreen = props => {
    const dispatch = useDispatch();

    const sucata = useSelector(state => state.sucatas.sucata);
    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    const [isLoading, setIsLoading] = useState(false);
    const [isSucataCreated, setIsSucataCreated] = useState(false);

    // Mudança de página após criação da sucata
    useEffect(() => {
        if (isSucataCreated && sucata.id)
            props.history.push(`/cadastro/sucata/selecao-pecas/${sucata.id}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSucataCreated, sucata]);

    const confirmacao = () => {
        exibirMensagem.exibir({
            title: "Tem certeza?",
            text: `Deseja cadastrar <b>${cadastroSucata.sucata.fipe.modelo.nome}</b>? Você não poderá alterar estes dados até terminar o cadastro.`,
            showCancelButton: true,
            confirmButtonText: "Cadastrar sucata",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    await dispatch(sucatasActions.createSucata(cadastroSucata.sucata));
                    exibirMensagem.success("A sucata foi cadastrada com sucesso.");
                    setIsSucataCreated(true);
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                };
            } else {
                return;
            };
        });
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <DadosSucata
                sucata={cadastroSucata.sucata}
                onAntClick={() => props.history.push('/cadastro/sucata/selecao-fipe')}
                onProxClick={confirmacao}
                onChange={sucataAtualizada => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.sucata = sucataAtualizada;
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                }}
            />
        </div>
    );
};

export default SelecaoFipeScreen;