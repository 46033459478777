// Dependências
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";

// Css
import classes from "./Dropdown.module.css";

// Components
import BarraPesquisa from "../BarraPesquisa/BarraPesquisa";
import Button from "../Button/Button";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Dropdown = (props) => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    const [busca, setBusca] = useState("");
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const [value, setValue] = useState(props.padrao ? props.padrao : props.list.length ? props.list[0].nome : "");
    const [copiaList, setCopiaList] = useState([...props.list]);

    // Setando value pra "" quando list muda
    useEffect(() => {
        setValue(props.padrao || "");
    }, [props.list, props.padrao]);

    // Setando padrao
    useEffect(() => {
        if (props.padrao)
            setValue(props.padrao);
    }, [props.padrao]);

    // Setando copiaList
    useEffect(() => {
        if (props.list)
            setCopiaList([...props.list]);
    }, [props.list]);

    // Lidando com busca
    useEffect(() => {
        if (busca && props.list)
            setCopiaList(props.list.filter(item => formatar.alphaNumeric(item.nome).includes(formatar.alphaNumeric(busca))));
        else if (props.list)
            setCopiaList(props.list);
        else
            setCopiaList([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [busca]);

    return (
        <div className="dropdown">
            <Button
                onClick={() => setShowDropdownMenu(!showDropdownMenu)}
                color={props.invalid ? "red" : props.lightMode ? "purple" : darkMode ? "gray" : "purple"}
                small={props.buttonSmall}>
                {value} <IoMdArrowDropdown className={classes.Icon} />
            </Button>

            {showDropdownMenu ? (
                <div>
                    <main className={classes.DropdownBackground} onClick={() => setShowDropdownMenu(false)} />

                    <ul
                        className={`${props.lightMode ? classes.DropdownMenuLight
                            : darkMode ?
                                classes.DropdownMenuDark
                                : classes.DropdownMenuLight} ${props.small ? classes.DropdownMenuSmall : ''}`}>

                        {props.busca ? <BarraPesquisa defaultValue={busca} onChange={value => setBusca(value)} /> : null}

                        {copiaList.map(item => (
                            <li
                                key={item.id}
                                className={`${classes.DropdownItem} ${props.textAlignLeft ? "text-left" : ""} dropdown-item`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setShowDropdownMenu(false);
                                    setValue(item.nome);
                                    if (props.onChange) {
                                        if (props.returnObject) props.onChange(item);
                                        else props.onChange(item.id);

                                    }
                                }}
                            > {item.nome} </li>
                        ))}
                    </ul>
                </div>

            ) : null
            }
        </div >
    );
};

export default Dropdown;
