import {
    DELETE_VENDA,
    FETCH_VENDAS,
    FETCH_RESET_VENDAS,
} from "../actions/vendas";

const initialState = {
    vendas: [],
    total: null
};

const vendasReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_VENDA:
            const vendasAtualizadas = state.vendas.filter(venda => venda.id !== action.id);
            return { ...state, vendas: vendasAtualizadas };
        case FETCH_VENDAS:
            let copiaVendas = [...state.vendas];
            action.vendas.vendas.forEach(venda => copiaVendas.push(venda));
            return { ...state, vendas: copiaVendas, total: action.vendas?.total };
        case FETCH_RESET_VENDAS:
            return { ...state, vendas: action.vendas?.vendas, total: action.vendas?.total};
        default:
            return state;
    }
};

export default vendasReducer;
