// Dependências
import React from "react";

// Css
import classes from "./ArquetipoSelecionado.module.css";

const ArquetipoSelecionado = props => {
    return (
        <span className={classes.ArquetipoSelecionado} title="Excluir arquétipo" onClick={props.onClick}>
            {props.children}
        </span>
    );
};

export default ArquetipoSelecionado;