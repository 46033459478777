import { API_URL } from "../../constants/GlobalConfig"
import statusHandler from "../../helpers/statusHandler";

export const FETCH_AREAS = "FETCH_AREAS";
export const FETCH_PECAS_TIPOS_ARQUETIPOS = "FETCH_PECAS_TIPOS_ARQUETIPOS";

export const fetchAreas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/pecas-tipos/areas`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let areas = [];
            try {
                areas = await response.json();
                dispatch({ type: FETCH_AREAS, areas: areas });
            } catch (error) {
                throw new Error(error);
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchArquetipos = (idSucata, isAlphabetical) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        const response = await fetch(`${API_URL}${idSucata ? `/sucatas/${idSucata}` : ""}/pecas-arquetipos${isAlphabetical ? `?ordem=nome` : ""}`, {
            headers: {
                Authorization: token,
            }
        });

        if (response.ok) {
            let arquetipos = [];
            try {
                arquetipos = await response.json();
                dispatch({ type: FETCH_PECAS_TIPOS_ARQUETIPOS, arquetipos: arquetipos });
            } catch (error) {
                throw new Error(error);
            };
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};