const getEtapaCadastroGrupoPecas = (idStatus, idGrupoSucatas) => {
    switch (idStatus) {
        case 6:
            return `/cadastro/grupo-pecas/medidas/${idGrupoSucatas}`;
        case 7:
            return `/cadastro/grupo-pecas/ficha-tecnica/${idGrupoSucatas}`;
        case 10:
            return `/cadastro/grupo-pecas/fotos-pecas/${idGrupoSucatas}`;
        case 13:
            return `/cadastro/grupo-pecas/precificacao/${idGrupoSucatas}`;
        case 12:
            return `/cadastro/grupo-pecas/selecao-tipos-anuncio/${idGrupoSucatas}`;
        default:
            return "/cadastro/grupo-pecas";
    };
};

export default getEtapaCadastroGrupoPecas;