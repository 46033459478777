// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import * as pecasActions from "../../../store/actions/pecas";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

// Components
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { useCallback } from "react";

const LocalizacaoModal = props => {
    const dispatch = useDispatch();

    const [localizacao, setLocalizacao] = useState(props.localizacao || {});
    console.log({localizacao})

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (localizacao.id) {
                await dispatch(pecasActions.updateLocalizacao({id: localizacao.id, nome: localizacao.nome}));
                exibirMensagem.success("A localização foi editada com sucesso");
            } else {
                await dispatch(pecasActions.createLocalizacao(localizacao?.nome))
                exibirMensagem.success("A localização foi cadastrada com sucesso");
            };
            props.onClose();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (props.localizacao) {
            setLocalizacao(props.localizacao);
        };
    }, [props.localizacao]);

    const handleChange = useCallback((value) => {
        setLocalizacao({ ...localizacao, nome: value });
    }, [localizacao]);

    return (
        <Modal
            onClose={props.onClose}
            titulo={localizacao?.id ? "Editar localizacação" : "Cadastrar uma nova localização"}
            style={{ maxWidth: '530px', maxHeight: '350px'}}

        >
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h5>Nome:</h5>
                            <Input
                                value={localizacao?.nome}
                                onChange={handleChange}
                                size="100%"
                                placeholder="Caixa X"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Button color="green" type="submit">Salvar</Button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal >
    );
};

export default LocalizacaoModal;