// Dependências
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Accents from "remove-accents";

// Css
import classes from "./BeautyInput.module.css";

// Components
import Tooltip from "../Tooltip/Tooltip";


const BeautyInput = props => {
    const idInput = uuidv4();
    const idList = uuidv4();

    const [naoSeAplica, setNaoSeAplica] = useState(false);

    const handleOnChange = (event) => {
        let copiaValue = event.target.value;

        if (props.toUpperCase)
            copiaValue = copiaValue.toUpperCase();

        if (props.onlyNumbers)
            copiaValue = copiaValue.replace(/\D/g, ''); // Remove o que não for número

        if (props.onlyMoney) {
            if (!copiaValue.match(/^[0-9]+((\.|,)[0-9]{0,2})?$/gm)) // Considera números, pontos e vírgulas
                copiaValue = copiaValue.substring(0, copiaValue.length - 1);
        }

        if (props.onlyLettersAndNumbers) {
            copiaValue = copiaValue.replace(/[^A-Z0-9]+/g, ''); // Remove o que não for número ou letra
        }

        if (props.cpf) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/^(\d{3})(\d)/g, '$1.$2'); //Coloca ponto entre o terceiro e o quarto
            copiaValue = copiaValue.replace(/(\d)(\d{3})(\d{2})$/, '$1.$2-$3'); //Coloca ponto entre o terceiro e o quarto, sexto e o sétimo e traço entre o nono e décimo digitos
        }

        if (props.cnpj) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); //Coloca ponto entre o terceiro e o quarto, sexto e o sétimo e traço entre o nono e décimo digitos
        }

        if (props.tag) {
            copiaValue = copiaValue.toLowerCase().replace(' ', '-'); // Deixa minúsculo e troca espeços por hífens
            if (copiaValue[0] !== "#") copiaValue = "#" + copiaValue; // Coloca # no primeiro char
            copiaValue = Accents.remove(copiaValue); // Retira acentos
            copiaValue = copiaValue.replace(/[^a-z0-9-#]+/g, ''); // Remove o que não for número ou letra
        }

        if (props.telefone) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
            copiaValue = copiaValue.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        }

        if (props.placa) {
            copiaValue = copiaValue.toUpperCase();
            copiaValue = copiaValue.replace(/[^A-Z0-9]+/g, ''); // Remove o que não for número ou letra
            copiaValue = copiaValue.trim().replace(/^(\w{3})(\w)/g, '$1-$2') // Coloca hífen depois do 3º char
        }

        event.target.value = copiaValue;
        if (props.onChange)
            props.onChange(event.target.value);
    }

    return (
        <div className={`${classes.FormGroup} ${props.small ? classes.Small : ""}`}>
            <input
                id={idInput}
                type={props.type ? props.type : "text"}
                className={`${classes.FormField} ${props.invalid ? classes.Invalid : ""} ${naoSeAplica ? classes.Disable : ""}`}
                placeholder={props.placeholder}
                name={props.name}
                defaultValue={props.defaultValue}
                maxLength={props.maxLength}
                readOnly={naoSeAplica}
                list={props.datalist ? idList : null}
                onKeyDown={(event) => { if (event.key === "Enter" && !props.enterHandler) event.target.blur(); }}
                onChange={(event) => handleOnChange(event)}
                style={props.tooltip ? { paddingRight: "30px" } : null}
                onBlur={props.onBlur} />

            {props.tooltip ?
                <Tooltip
                    tipPosition="left"
                    className={classes.Tooltip}
                >{props.tooltip}</Tooltip>
                : null}

            {props.onNaoSeAplica ?
                <span
                    className={classes.NA}
                    style={naoSeAplica ? { color: "#6f37d6" } : null}
                    onClick={() => {
                        props.onNaoSeAplica(!naoSeAplica);
                        setNaoSeAplica(!naoSeAplica);
                    }}>N/A</span>
                : null}

            {props.hint ? (
                <span className={classes.Hint}> {props.hint} </span>
            ) : null}

            <label
                className={classes.FormLabel}
                htmlFor={idInput}>{props.placeholder}</label>

            {props.datalist ? (
                <datalist id={idList}>
                    {props.datalist.map(data => (
                        <option key={uuidv4()} value={data} />
                    ))}
                </datalist>
            ) : null}
        </div>
    );
};

export default BeautyInput;