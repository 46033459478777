// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Categorias from "../../../../components/Cadastro/Categorias/Categorias";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const CategoriaScreen = props => {
    const dispatch = useDispatch();

    const pecas = useSelector(state => state.pecas.pecas);

    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das peças
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 350));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [dispatch, idSucata]);

    return isLoading ? <Spinner /> : (
        <div>
            <Categorias
                pecas={pecas}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={() => props.history.push(`/cadastro/sucata/upload-fotos/${idSucata}`)} />
        </div>
    );
};

export default CategoriaScreen;