// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as despesasActions from "../../../../store/actions/despesas";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

// Components
import BeautyDropdown from "../../../../components/UI/BeautyDropdown/BeautyDropdown";
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import Modal from "../../../../components/UI/Modal/Modal";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Input from "../../../../components/UI/Input/Input";

const DespesaScreen = (props) => {
    const dispatch = useDispatch();

    const frequencias = useSelector(state => state.despesas.frequencias);
    const opcoes = useSelector(state => state.despesas.opcoes);

    const [isLoading, setIsLoading] = useState(false);
    const [despesa, setDespesa] = useState({
        nome: "",
        categoria: { id: "", nome: "" },
        subcategoria: { id: "", nome: "" },
        frequencia: { id: "", nome: "", periodica: "" },
        valor: "",
        atrelamento: { id: "", nome: "" },
        dataInicio: "",
        dataFinal: "",
        possuiDataFinal: ""
    });
    const [isValid, setIsValid] = useState({
        nome: true,
        valor: true,
        dataInicio: true,
        dataFinal: true,
        atrelamento: true
    });

    const [subcategorias, setSubcategorias] = useState([]);

    // Requisição das frequencias
    useEffect(() => {
        const fetchFrequencias = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchFrequencias());
            } catch (error) {
                console.log(error)
            }

            setIsLoading(false);
        };

        fetchFrequencias();
    }, [dispatch]);

    // Requisição das opções
    useEffect(() => {
        const fetchOpcoes = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchOpcoes());
            } catch (error) {
                console.log(error)
            }

            setIsLoading(false);
        };

        fetchOpcoes();
    }, [dispatch]);

    // Setando despesa e subcategorias
    useEffect(() => {
        if (!opcoes.length)
            return;

        let copiaDespesa = { ...despesa };
        let categoriaDespesa = { id: "", nome: "", opcoes: [] };

        opcoes.forEach(categoria => {
            if (categoria.id === parseInt(props.despesa.categoria.id))
                categoriaDespesa = { id: categoria.id, nome: categoria.nome, opcoes: categoria.opcoes ? [...categoria.opcoes] : [] };
        })

        copiaDespesa = {
            id: props.despesa.id,
            nome: props.despesa.nome,
            categoria: categoriaDespesa,
            subcategoria: { id: props.despesa.categoria.subcategoria.id, nome: props.despesa.categoria.subcategoria.nome },
            frequencia: { id: props.despesa.frequencia.id, nome: props.despesa.frequencia.nome },
            valor: props.despesa.valor,
            atrelamento: props.despesa.atrelamento ? { id: props.despesa.atrelamento.id, nome: props.despesa.atrelamento.nome } : { id: "", nome: "" },
            dataInicio: props.despesa.dataInicio,
            dataFinal: props.despesa.dataFinal,
            possuiDataFinal: props.despesa.dataFinal ? true : false
        };

        frequencias.forEach(frequencia => {
            if (frequencia.id === copiaDespesa.frequencia.id)
                copiaDespesa.frequencia.periodica = frequencia.periodica
        });

        setDespesa(copiaDespesa);

        opcoes.forEach(categoria => {
            if (categoria.id === copiaDespesa.categoria.id)
                setSubcategorias([...categoria.subcategorias]);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.despesa, frequencias, opcoes]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (validar.nome(despesa.nome))
            copiaIsValid.nome = true;
        else {
            copiaIsValid.nome = false;
            formIsValid = false;
        };

        if (validar.preco(despesa.valor))
            copiaIsValid.valor = true;
        else {
            copiaIsValid.valor = false;
            formIsValid = false;
        };

        if (validar.data(despesa.dataInicio))
            copiaIsValid.dataInicio = true;
        else {
            copiaIsValid.dataInicio = false;
            formIsValid = false;
        };

        if (despesa.possuiDataFinal && despesa.frequencia.periodica) {
            if (validar.data(despesa.dataFinal))
                copiaIsValid.dataFinal = true;
            else {
                copiaIsValid.dataFinal = false;
                formIsValid = false;
            };
        };

        if (despesa.possuiDataFinal && despesa.frequencia.periodica) {
            if (new Date(despesa.dataInicio) >= new Date(despesa.dataFinal)) {
                copiaIsValid.dataInicio = false;
                copiaIsValid.dataFinal = false;
                formIsValid = false;
            };
        };

        if (despesa.categoria.opcoes.length) {
            if (despesa.atrelamento.id && despesa.atrelamento.nome)
                copiaIsValid.atrelamento = true;
            else {
                copiaIsValid.atrelamento = false;
                formIsValid = false;
            };
        } else
            copiaIsValid.atrelamento = true;

        if (formIsValid) {
            try {
                setIsLoading(true);
                await dispatch(despesasActions.updateDespesa(despesa.id, {
                    nome: despesa.nome,
                    valor: parseFloat(despesa.valor),
                    idFrequencia: despesa.frequencia.id,
                    idSubcategoria: despesa.subcategoria.id,
                    dataInicio: despesa.dataInicio,
                    dataFinal: despesa.possuiDataFinal && despesa.frequencia.id === 4 ? despesa.dataFinal : null,
                    idAtrelamento: despesa.categoria.opcoes.length ? despesa.atrelamento.id : null
                }));
                exibirMensagem.success("A despesa foi alterada com sucesso");
                props.onClose();
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        } else {
            exibirMensagem.formInvalid();
        };

        setIsValid(copiaIsValid);
    };

    return (
        <Modal
            titulo="Editar despesa"
            onClose={props.onClose}>

            {!isLoading && despesa.id ? (
                <form onSubmit={handleSubmit}>
                    <div className="row mb-1">
                        <div className="col-2">
                            Frequencia:
                        </div>
                        <div className="col-10">
                            <BeautyDropdown
                                inline
                                returnObject
                                list={frequencias}
                                default={despesa.frequencia.nome}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.frequencia = { id: value.id, nome: value.nome, periodica: value.periodica };
                                    setDespesa(copiaDespesa);
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-2 text-center">
                            Categoria:
                        </div>
                        <div className="col-4">
                            <BeautyDropdown
                                inline
                                returnObject
                                list={opcoes.filter(opcao => opcao.id !== 5)}
                                default={despesa.categoria.nome}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.categoria = { id: value.id, nome: value.nome, opcoes: value.opcoes ? [...value.opcoes] : [] };

                                    opcoes.forEach(categoria => {
                                        if (categoria.id === copiaDespesa.categoria.id) {
                                            setSubcategorias([...categoria.subcategorias]);

                                            copiaDespesa.subcategoria = { id: categoria.subcategorias[0].id, nome: categoria.subcategorias[0].nome };

                                            if (categoria.atrelamentos)
                                                copiaDespesa.atrelamento = { id: categoria.atrelamentos[0].id, nome: categoria.atrelamentos[0].nome };
                                            else
                                                copiaDespesa.atrelamento = { id: "", nome: "" };

                                            setDespesa(copiaDespesa);
                                        };
                                    });

                                    setDespesa(copiaDespesa);
                                }}
                            />
                        </div>

                        <div className="col-2 text-center">
                            Subcategoria:
                        </div>
                        <div className="col-4">
                            <BeautyDropdown
                                inline
                                returnObject
                                list={subcategorias}
                                default={despesa.subcategoria.nome}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.subcategoria = { id: value.id, nome: value.nome };
                                    setDespesa(copiaDespesa);
                                }}
                            />
                        </div>
                    </div>

                    {despesa.categoria.opcoes.length ? (
                        <div className="row mb-1">
                            <div className="col-2 text-center">
                                {despesa.categoria.nome.slice(0, -1)}:
                            </div>
                            <div className="col-10">
                                <BeautyDropdown
                                    inline
                                    returnObject
                                    list={[...despesa.categoria.opcoes]}
                                    default={despesa.atrelamento.nome}
                                    invalid={!isValid.atrelamento}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.atrelamento = { id: value.id, nome: value.nome };
                                        setDespesa(copiaDespesa);
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}

                    <div className="row mb-1">
                        <div className="col-2 text-center">
                            Nome:
                    </div>
                        <div className="col-6">
                            <Input
                                size="100%"
                                placeholder="Nome da despesa"
                                value={despesa.nome}
                                invalid={!isValid.nome}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.nome = value;
                                    setDespesa(copiaDespesa);
                                }} />
                        </div>

                        <div className="col-2 text-center">
                            {despesa.frequencia.periodica ? "Valor das parcelas:" : "Valor:"}
                        </div>
                        <div className="col-2">
                            <Input
                                size="100%"
                                value={despesa.valor}
                                invalid={!isValid.valor}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.valor = value;
                                    setDespesa(copiaDespesa);
                                }} />
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-2 text-center">
                            {despesa.frequencia.periodica ? "Data início:" : "Data"}
                        </div>
                        <div className={despesa.frequencia.periodica ? "col-4" : "col-10"}>
                            <Input
                                type="date"
                                size="100%"
                                value={despesa.dataInicio}
                                invalid={!isValid.dataInicio}
                                onChange={value => {
                                    let copiaDespesa = { ...despesa };
                                    copiaDespesa.dataInicio = value;
                                    setDespesa(copiaDespesa);
                                }} />
                        </div>
                        {despesa.frequencia.periodica && despesa.possuiDataFinal ? (
                            <div className="col-2">
                                Data final:
                            </div>
                        ) : null}
                        {despesa.frequencia.periodica && despesa.possuiDataFinal ? (
                            <div className="col-4">
                                <Input
                                    type="date"
                                    size="100%"
                                    value={despesa.dataFinal}
                                    invalid={!isValid.dataFinal}
                                    onChange={value => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.dataFinal = value;
                                        setDespesa(copiaDespesa);
                                    }} />
                            </div>
                        ) : null}
                        {despesa.frequencia.periodica ? (
                            <div className="col-12 text-right">
                                <Checkbox
                                    size="small"
                                    defaultChecked={!despesa.possuiDataFinal}
                                    onChange={() => {
                                        let copiaDespesa = { ...despesa };
                                        copiaDespesa.possuiDataFinal = !copiaDespesa.possuiDataFinal;
                                        setDespesa(copiaDespesa);
                                    }}
                                >Não possui data final</Checkbox>
                            </div>
                        ) : null}
                    </div>

                    <div className="row mt-5 mb-2">
                        <div className="col-6 text-center">
                            <Button
                                color="red"
                                onClick={() => {
                                    exibirMensagem.warning({
                                        title: "Tem certeza?",
                                        text: `Você tem certeza que deseja excluir esta despesa? Essa ação não poderá ser revertida!`,
                                        showCancelButton: true,
                                        confirmButtonText: "Sim, pode excluir",
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            setIsLoading(true)
                                            try {
                                                await dispatch(despesasActions.deleteDespesa(props.despesa.id));
                                                exibirMensagem.success("A despesa foi deletada com sucesso.");
                                                props.onClose();
                                            } catch (error) {
                                                setIsLoading(false)
                                                console.log(error);
                                            };
                                        } else {
                                            return;
                                        }
                                    });
                                }}
                            >Excluir despesa</Button>
                        </div>
                        <div className="col-6 text-center">
                            <Button
                                type="submit"
                                color="green"
                            >Salvar alterações</Button>
                        </div>
                    </div>

                </form>
            ) : <Spinner />}
        </Modal>
    )
};

export default DespesaScreen;
