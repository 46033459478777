// Dependências
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsFillLockFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { API_URL } from "../../../../constants/GlobalConfig";

// Actions
import * as anunciosActions from "../../../../store/actions/anuncios";
import * as pecasActions from "../../../../store/actions/pecas";
import * as vendasActions from "../../../../store/actions/vendas";

// Css
import * as classes from "./PecaScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import statusHandler from "../../../../helpers/statusHandler";
import * as validar from "../../../../helpers/validar";

// Components
import Anuncio from "../../../../components/Estoque/Peca/Anuncio/Anuncio";
import Button from "../../../../components/UI/Button/Button";
import ContextMenu from "../../../../components/UI/ContextMenu/ContextMenu";
import OrderableDropzone from "../../../../components/UI/OrderableDropzone/OrderableDropzone";
import Medidas from "../../../../components/Estoque/Peca/Medidas/Medidas";
import ModalCreateAnuncio from "../../../../components/Estoque/Peca/ModalCreateAnuncio/ModalCreateAnuncio";
import ModalCreateVenda from "../../../../components/Estoque/Peca/ModalCreateVenda/ModalCreateVenda";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Table from "../../../../components/UI/Table/Table";
import Titulo from "../../../../components/UI/Titulo/Titulo";

const PecaScreen = props => {
    const dispatch = useDispatch();
    const idPeca = props.match.params.id;

    const localizacoes = useSelector(state => state.pecas.localizacoes);
    const peca = useSelector(state => state.pecas.peca);
    const usuario = useSelector(state => state.usuario.meuUsuario);
    const token = useSelector(state => state.auth.token)

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [isAnuncioLoading, setIsAnuncioLoading] = useState(false);

    const [showModalCreateVenda, setShowModalCreateVenda] = useState(false);
    const [showModalCreateAnuncio, setShowModalCreateAnuncio] = useState(false);

    const [idAnuncioContextMenu, setIdAnuncioContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    const [updateFotosCounter, setUpdateFotosCounter] = useState(0);
    const [fotos, setFotos] = useState([]);

    // Requisição da peça
    useEffect(() => {
        const fetchPeca = async idPeca => {
            setIsLoading(true);

            try {
                await dispatch(pecasActions.fetchPeca(idPeca));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
            setIsRequestCompleted(true);
        };

        if (!isRequestCompleted)
            fetchPeca(idPeca);
    }, [dispatch, idPeca, isRequestCompleted]);

    // Requisição das Localizações
    useEffect(() => {
        const fetchLocalizacoes = async () => {
            try {
                setIsLoading(true);
                await dispatch(pecasActions.fetchLocalizacoes());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchLocalizacoes();
    }, [dispatch]);

    // Escolhe se usuário vai criar uma localização um cadastrar uma existente
    const armazenarPeca = () => {
        if (localizacoes.length)
            exibirMensagem.exibir({
                title: "Como deseja armazenar a peça?",
                showCancelButton: true,
                cancelButtonText: "Localização existente",
                confirmButtonText: "Nova localização",
                confirmButtonColor: "#404242",
                cancelButtonColor: "#404242",
            }).then(async result => {
                if (result.isConfirmed)
                    createLocalizacao();
                else
                    exibirMensagem.exibir({
                        title: "Selecione a localização:",
                        input: "select",
                        inputOptions: localizacoes.map(localizacao => localizacao.nome)
                    }).then(async result => {
                        if (result.value) {
                            await dispatch(pecasActions.updatePeca(idPeca, { localizacao: localizacoes[result.value] }));

                            exibirMensagem.success("Peças armazenadas com sucesso.");
                        };
                    });
            });
        else
            createLocalizacao();
    };

    // Cria uma localização
    const createLocalizacao = () => {
        exibirMensagem.exibir({
            title: "Digite o nome da Localização:",
            input: "text",
            showCancelButton: true,
            inputValidator: value => {
                if (!validar.nome(value))
                    return "A localização informada não é válida";
            },
        }).then(async result => {
            if (result.value) {
                await dispatch(pecasActions.createLocalizacao(result.value, [{ id: parseInt(idPeca) }]));

                exibirMensagem.success("Peças armazenadas com sucesso.");
            };
        });
    };

    // Chama o action de apagar um anuncio
    const deleteAnuncio = async idAnuncio => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir este anúncio? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    setIsAnuncioLoading(true);
                    await dispatch(anunciosActions.deleteAnuncio(idAnuncio));
                    exibirMensagem.success("Anúncio excluído com sucesso.");
                } catch (error) {
                    console.log(error);
                };
                setIsAnuncioLoading(false);
            } else {
                return;
            };
        });
    };

    const deletePeca = () => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir esta peça? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(pecasActions.deletePeca(peca.id));
                    exibirMensagem.success("Peça excluída com sucesso.");
                    props.history.push(`/estoque/sucatas/${peca.sucata.id}`)
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    const deleteVenda = () => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja reverter essa venda? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim",
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    setIsAnuncioLoading(true);
                    await dispatch(vendasActions.deleteVenda(peca.id));
                    exibirMensagem.success("Venda revertida com sucesso.");
                    setIsRequestCompleted(false);
                } catch (error) {
                    console.log(error);
                };
                setIsAnuncioLoading(false);
            } else {
                return;
            };
        });

    };

    const updateFotos = () => {
        const formData = new FormData();
        const newFotos = fotos.map((foto, index) => ({ ...foto, index })).filter(foto => foto.type === 'uploaded').map(foto => ({ value: foto.value, index: foto.index }));

        newFotos.forEach(foto => formData.append(JSON.stringify({ atrelamento: { id: idPeca }, index: foto.index }), foto.value));
        formData.append('fotos', JSON.stringify(fotos.map((foto, index) => ({ ...foto, index })).filter(foto => foto.type !== 'uploaded').map(foto => ({ id: foto.id, caminho: foto.value, index: foto.index }))));

        axios({
            method: 'PUT',
            url: `${API_URL}/pecas/${idPeca}/fotos`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then(result => {
            if (result.status === 200) setUpdateFotosCounter(0);
            else throw new Error("Algo deu errado");
        }).catch(error => {
            statusHandler(error.response?.status);
            console.log(error);
        });
    };

    const updatePeca = key => {
        let titulo = "";
        let inputType = "number";
        let inputValue = "";
        let inputAttributes = {};
        let inputValidator = () => true;

        switch (key) {
            case "altura":
                inputValidator = validar.medida;
                inputAttributes = { "maxlength": 3 };
                titulo = "Informe a altura da peça (em centímetros).";
                inputValue = peca.medidas.altura || "";
                break;
            case "comprimento":
                inputValidator = validar.medida;
                inputAttributes = { "maxlength": 3 };
                titulo = "Informe o comprimento da peça (em centímetros).";
                inputValue = peca.medidas.comprimento || "";
                break;
            case "observacao":
                titulo = "Informe a descrição da peça:";
                inputType = "textarea";
                inputValue = peca.observacao || "";
                inputAttributes = { "maxlength": 255 };
                break;
            case "largura":
                inputValidator = validar.medida;
                inputAttributes = { "maxlength": 3 };
                titulo = "Informe a largura da peça (em centímetros).";
                inputValue = peca.medidas.largura || "";
                break;
            case "peso":
                titulo = "Informe o peso da peça (em gramas).";
                inputValue = peca.medidas.peso || "";
                inputValidator = validar.peso;
                inputAttributes = { "maxlength": 5 };
                break;
            case "preco":
                titulo = "Informe o novo preço da peça (R$)";
                inputValue = peca.preco || "";
                inputValidator = validar.preco;
                inputAttributes = { "maxlength": 8 };
                break;
            case "titulo":
                titulo = "Informe o novo título da peça";
                inputValue = peca.titulo || "";
                inputValidator = validar.nome;
                inputAttributes = { "maxlength": 60 };
                inputType = "text";
                break;
            default:
                return;
        };

        exibirMensagem.exibir({
            title: titulo,
            input: inputType,
            inputValue: inputValue,
            inputAttributes: inputAttributes,
            showCancelButton: true,
            confirmButtonText: "Salvar",
            inputValidator: value => {
                return new Promise(resolve => {
                    if (inputValidator(value))
                        resolve();
                    else
                        resolve("Valor informado é inválido");
                });
            }
        }).then(async result => {
            if (result.isConfirmed)
                await dispatch(pecasActions.updatePeca(idPeca, { [key]: result.value }))
        });
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idAnuncio) => {
        event.preventDefault();

        setIdAnuncioContextMenu(idAnuncio);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };


    return isLoading || !peca.id || !isRequestCompleted ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <Titulo subtitulo={`Cód. ${peca.id}`}>{peca.arquetipo.nome}</Titulo>

            <ModalCreateVenda
                display={showModalCreateVenda}
                peca={peca}
                createVenda={() => {
                    setIsRequestCompleted(false);
                    setShowModalCreateVenda(false);
                }}
                onClose={() => setShowModalCreateVenda(false)}
            />

            {showModalCreateAnuncio ? (
                <ModalCreateAnuncio peca={peca} onClose={() => setShowModalCreateAnuncio(false)} />
            ) : null}

            <div className="row mb-1">
                <div className="col-8">
                    <div className="row h5">
                        <div className="col-12">
                            <p><b>Marca: </b>{peca.sucata.fipe.marca.nome}</p>
                            <p>
                                <b>Modelo: </b>
                                <NavLink
                                    className={classes.Link}
                                    to={`/estoque/sucatas/${peca.sucata.id}`}
                                >{peca.sucata.fipe.modelo.nome || peca.sucata.fipe.veiculo.nome}</NavLink>
                            </p>
                            <p><b>Data de Cadastro: </b> {formatar.data(peca.dataCriacao)}</p>
                        </div>
                        <hr />
                        <div className="col-12">
                            <p>
                                <b>Título: </b>
                                {peca.titulo}
                                {peca.status.id === 1 ?
                                    <FiEdit
                                        size="15px"
                                        title="Editar a localização"
                                        className={classes.EditButton}
                                        onClick={() => updatePeca("titulo")} /> : null}
                            </p>
                            <p>
                                <b>Preço: </b>
                                {peca.status.id === 1 || usuario.permissoes.find(permissao => permissao.id === 5) ?
                                    peca.preco ? `${formatar.dinheiro(peca.preco)} ` : "-" : <BsFillLockFill />}
                                {peca.status.id === 1 ? (
                                    <FiEdit
                                        size="15px"
                                        title="Editar o preço"
                                        className={classes.EditButton}
                                        onClick={() => updatePeca("preco")}
                                    />
                                ) : null}
                            </p>
                            <p>
                                <b>Localização: </b>
                                <i>{peca.localizacao?.nome || "-"}</i>

                                {peca.status.editavel ?
                                    <FiEdit
                                        size="15px"
                                        title="Editar a localização"
                                        className={classes.EditButton}
                                        onClick={() => armazenarPeca()} /> : null}
                            </p>
                            <p>
                                <b>Descrição: </b>
                                {peca.status.id === 1 ?
                                    <FiEdit
                                        size="15px"
                                        title="Editar a descrição"
                                        className={classes.EditButton}
                                        onClick={() => updatePeca("observacao")} /> : null}
                                <br />
                                <i>{peca.observacao || ""}</i>
                            </p>
                        </div>
                    </div>
                </div>

                {peca.status.id !== 1 || !peca.fotos?.length ? null : (
                    <div className="col-4">
                        <OrderableDropzone
                            defaultValue={peca.fotos.map(foto => ({ value: foto.url, id: foto.id }))}
                            maxHeight={'300px'}
                            onChange={newFotos => {
                                setFotos(newFotos);
                                setUpdateFotosCounter(prevUpdateFotosCounter => prevUpdateFotosCounter + 1);
                            }}
                        />

                        {updateFotosCounter > 2 ? (
                            <div style={{ display: "flex", justifyContent: "right" }}>
                                <Button color="green" onClick={updateFotos}>Salvar alterações</Button>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>

            <div className="row mb-3">
                <Table
                    small
                    bordered
                    header={["Altura", "Comprimento", "Largura", "Peso"]}>
                    <Medidas medidas={peca.medidas} updatePeca={updatePeca} />
                </Table>
            </div>

            {peca.anuncios.length ? (
                <div>
                    <Titulo>Anúncios</Titulo>

                    <div className="row">
                        {isAnuncioLoading ? <div className="col-12"><Spinner /></div> :
                            <Table
                                bordered
                                header={["Plataforma", "Categoria", "Título", "Status", "Porcentagem", "Preço"]}
                            >
                                {peca.anuncios.map(anuncio =>
                                    <Anuncio
                                        key={anuncio.id}
                                        anuncio={anuncio}
                                        onContextMenu={event => handleContextMenuClick(event, anuncio.id)} />
                                )}
                            </Table>}

                        {/* Menu que aparece ao clicar com o botão direito */}
                        <ContextMenu
                            absolute
                            display={displayContextMenu}
                            onClickOutside={() => setDisplayContextMenu(false)}
                            x={positionX}
                            y={positionY}>

                            <li onClick={() => window.open(peca.anuncios.find(anuncio => anuncio.id === idAnuncioContextMenu).link, '_blank')}>Ir para</li>
                            <li onClick={() => deleteAnuncio(idAnuncioContextMenu)}>Excluir</li>
                        </ContextMenu>
                    </div>

                </div>
            ) : null}
            <div className="row mt-1">
                <div className="col-4 text-left">
                    {peca.status?.id !== 3 ?
                        <Button color="green" onClick={() => setShowModalCreateVenda(true)}>Peça vendida</Button>
                        :
                        <Button color="green" onClick={deleteVenda}>Reverter venda</Button>}
                </div>
                <div className="col-4 text-center">
                    {peca.anuncios.length < 3 && (peca.status.id === 1 || peca.status.id === 11) ? (
                        <Button color="purple" onClick={() => setShowModalCreateAnuncio(true)}>Novo anúncio</Button>
                    ) : null}
                </div>
                <div className="col-4 text-right">
                    <Button color="red" onClick={deletePeca}>Excluir peça</Button>
                </div>
            </div>
        </div >
    );
};

export default PecaScreen;