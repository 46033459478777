// Dependências
import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 as randomString } from 'uuid';

// Components
import FilePreview from "./FilePreview/FilePreview";

// Css
import classes from "./OrderableDropzone.module.css";


const OrderableDropzone = props => {
    const defaultAccept = useMemo(() => ["jpg", "jpeg", "png", "webp"], []);
    const idInput = useMemo(() => randomString(), []);

    const dragItem = useRef();

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [ciclesCount, setCiclesCount] = useState(0);


    const onFileUpload = async fileList => {
        // Transforma a fileList em um array de files
        if (props.limit && (files.length + fileList.length) > props.limit) {
            let arrNewFiles = [];
            for (let i = 0; i < fileList.length; i++) arrNewFiles.push({ key: randomString(), value: fileList[i] });
            setFiles(prevFiles => {
                let result = [...arrNewFiles, ...prevFiles];
                result.length = props.limit;
                return result;
            });
        } else {
            let arrNewFiles = [];
            for (let i = 0; i < fileList.length; i++) arrNewFiles.push({ key: randomString(), value: fileList[i] });
            setFiles(prevFiles => [...arrNewFiles, ...prevFiles]);
        };
    };

    const onDragEnterHandler = key => {
        if (!isDragging) return;
        if (dragItem.current === key) return;

        const draggingItemIndex = files.map(file => file.key).indexOf(dragItem.current);
        const currentItemIndex = files.map(file => file.key).indexOf(key);

        setFiles(oldFiles => {
            const newFiles = [...oldFiles];
            newFiles.splice(currentItemIndex, 0, newFiles.splice(draggingItemIndex, 1)[0]);

            return newFiles;
        });
    };

    // Seta o valor inicial de files
    useEffect(() => {
        if (files.length || props.defaultValue)
            setFiles(prevFiles => [...prevFiles, ...props.defaultValue?.map?.(file => ({ ...file, key: file.key || randomString(), type: 'default' }))])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Quando mudar o file e não tiver arrastando, chama onChange
    useEffect(() => {
        if (!isDragging && props.onChange) {
            props.onChange(files.map(file => ({ ...file, type: file.type || 'uploaded', value: file.value })), ciclesCount <= 1 ? true : false);
            setCiclesCount(prevCiclesCount => prevCiclesCount + 1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging, files]);

    return (
        <div className={classes.Wrapper}>
            <div style={{ marginBottom: '.3rem' }}>{props.label}</div>

            <div
                className={classes.DropWrapper}
                onDragEnter={event => event.preventDefault()}
                onDragLeave={event => event.preventDefault()}
                onDragOver={event => event.preventDefault()}
                onDrop={event => {
                    event.preventDefault();
                    onFileUpload(event.dataTransfer.files)
                }}
                style={{ maxHeight: props.maxHeight }}
            >
                {files.length ? (
                    <ul className={classes.FilePreviewList}>
                        {files.map(file => (
                            <FilePreview
                                accept={props.accept || defaultAccept}
                                key={file.key}
                                file={file.value}
                                onDelete={() => setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.key !== file.key))}
                                onDragEnd={() => setIsDragging(false)}
                                onDragEnter={() => onDragEnterHandler(file.key)}
                                onDragStart={() => { setIsDragging(true); dragItem.current = file.key; }}
                                type={file.type}
                            />
                        ))}
                    </ul>
                ) : (
                    <span className={classes.Instructions}>{props.instructions || 'Arraste os arquivos aqui ou clique abaixo para selecionar.'}</span>
                )}

                <input
                    accept={props.accept || defaultAccept}
                    id={idInput}
                    multiple
                    onChange={event => { onFileUpload(event.target.files); event.target.value = ""; }}
                    style={{ display: 'none' }}
                    type="file"
                />

                <div className={classes.ButtonsWrapper}>
                    {props.deleteAllButton ? (
                        <button
                            type="button"
                            style={{ backgroundColor: '#c34858' }}
                            onClick={() => setFiles([])}
                        >
                            Excluir todas imagens
                        </button>
                    ) : null}

                    <button type="button">
                        <label htmlFor={idInput}> Adicionar imagem </label>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OrderableDropzone;