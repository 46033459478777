// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import * as adminActions from "../../../store/actions/admin";

// Components
import Button from "../../../components/UI/Button/Button";
import Mensagem from "../../../components/Admin/Mensagens/Mensagem";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";

const MensagensScreen = props => {
    const dispatch = useDispatch();

    const mensagens = useSelector(state => state.admin.mensagens);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das mensagens
    useEffect(() => {
        const fetchMensagens = async () => {
            setIsLoading(true);
            try {
                await dispatch(adminActions.fetchMensagens());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchMensagens();
    }, [dispatch]);

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Mensagens aos usuários</Titulo>

            <div className="row">
                <div className="col-12 text-right">
                    <Link to="/mensagens/cadastro">
                        <Button color="green">Criar Mensagem</Button>
                    </Link>
                </div>
            </div>

            <div className="row" style={{ height: "78vh" }}>
                <Table fixed header={["ID", "Página", "Mensagem", "Contas"]}>
                    {mensagens.map(mensagem =>
                        <Mensagem key={mensagem.id} mensagem={mensagem} />
                    )}
                </Table>
            </div>
        </div>
    );
};

export default MensagensScreen;