// Dependências
import React from "react";

// Css
import classes from "./DespesaMensal.module.css";

// Helpers
import * as formatar from '../../../../helpers/formatar';

const DespesaMensal = props => {
    return (
        <tr onClick={props.onClick} className={props.despesa.valores.parcelasRestantes === 0 ? classes.DespesaPaga : null}>
            <td>{props.despesa.id}</td>
            <td>{props.despesa.nome}</td>
            <td>{formatar.dinheiro(props.despesa.valor)}</td>
            <td>{props.despesa.valores.parcelasPagas}</td>
            <td>{props.despesa.valores.parcelasRestantes || "-"}</td>
            <td>{formatar.dinheiro(props.despesa.valores.valorPago)}</td>
            <td>{props.despesa.valores.valorFaltante ? formatar.dinheiro(props.despesa.valores.valorFaltante) : "-"}</td>
            <td>{formatar.data(props.despesa.dataInicio)}</td>
            <td>{props.despesa.dataFinal ? formatar.data(props.despesa.dataFinal) : "-"}</td>
        </tr>
    );
};

export default DespesaMensal;
