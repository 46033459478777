// Dependências
import React, { useEffect, useState } from "react";
import { PDFDownloadLink, Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { renderToString } from "react-dom/server"

// Helpers
import * as formatar from "../../../../helpers/formatar";

// Components
import Button from "../../../UI/Button/Button";
import Spinner from "../../../UI/Spinner/Spinner";
import { QRCodeSVG } from "qrcode.react";
import svgToDataUri from "../../../../utils/svgToDataUri";

const EtiquetasPDFSmall = props => {
    const styles = StyleSheet.create({
        page: {
            fontSize: "8pt",
            margin: "0",
        },
        etiqueta: {
            height: "100%",
            width: "100%",
            margin: "0",
            padding: "4mm",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        text: {
            height: "4mm",
            margin: 0,
        },
        content: {
            flexDirection: 'column',
            transform: 'rotate(-90deg)',
            alignItems: 'center',
            justifyContent: 'center',
            height: '11mm',
            width: '20mm',
            marginBottom: '1mm'
        }
    });

    const [downloadEtiquetas, setDownloadEtiquetas] = useState(false);

    const [sucata, setSucata] = useState({});
    const [pecas, setPecas] = useState([]);

    useEffect(() => {
        async function convertSvgToDataUri() {
            if(props.pecas.length > 0){
                let qrCodes = [];
                for(const loc of props.pecas){
                    if(loc){
                        const svg = (
                            <QRCodeSVG
                                value={`${loc.id}`}
                            />
                        )
                    
                        const dataUri = await svgToDataUri(renderToString(svg))
                        qrCodes.push({...loc, qrcode: dataUri || ''})
                    }
                }
                setPecas(qrCodes)
            }
        }
        convertSvgToDataUri()
        setSucata({ ...props.sucata });
    }, [props.pecas, props.sucata]);

    return downloadEtiquetas ? (!sucata.id && !pecas[0]?.sucata?.id) || !pecas?.length ? <Spinner inline /> : (
        <PDFDownloadLink document={
            <Document>
                {pecas?.map(peca => !peca ? (<Page key={peca.id} size={[85.03, 56.69]} style={styles.page} />) : (
                    <Page key={peca.id} size={[85.03, 56.69]} style={styles.page}>
                        <View style={styles.etiqueta} wrap={true}>
                            <Image style={{ width: '12mm', height: '12mm' }} source={{ uri: peca.qrcode }} />
                            <View style={styles.content} wrap={false}>
                                <Text style={styles.text}>{peca?.id}</Text>
                                <Text style={styles.text}>{sucata?.id}</Text>
                            </View>
                        </View>
                    </Page>
                ))}
            </Document>
        } fileName={`${formatar.alphaNumeric(sucata?.fipe?.modelo?.nome || sucata?.fipe?.veiculo?.nome || props.grupoSucatas?.nome)}.pdf`}>
            {({ blob, url, loading, error }) => (
                loading ? (
                    <Button color="purple" disabled > Carregando... </Button>
                ) : (
                    <Button color="green"> Baixar etiquetas </Button>
                )
            )}
        </PDFDownloadLink >
    ) : <Button onClick={() => setDownloadEtiquetas(true)}> Etiquetas 30x20</Button>;
};

export default EtiquetasPDFSmall;