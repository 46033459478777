// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as grupoSucatasAction from "../../../../store/actions/grupos-sucatas";
import * as pecasActions from "../../../../store/actions/pecas";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";

// Components
import BarraPesquisa from "../../../../components/UI/BarraPesquisa/BarraPesquisa";
import ContextMenu from "../../../../components/UI/ContextMenu/ContextMenu";
import EtiquetasPDF from "../../../../components/Estoque/Sucata/EtiquetasPDF/EtiquetasPDF";
import Peca from "../../../../components/Estoque/Sucata/Peca/Peca";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Table from "../../../../components/UI/Table/Table";
import Titulo from "../../../../components/UI/Titulo/Titulo";

const GrupoSucatasScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;

    const grupoSucatas = useSelector((state) => state.gruposSucatas.grupoSucatas);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [busca, setBusca] = useState("");
    const [copiaPecas, setCopiaPecas] = useState([]);

    const [idPecaContextMenu, setIdPecaContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição das sucatas
    useEffect(() => {
        const fetchGrupoSucatas = async () => {
            setIsLoading(true);

            try {
                await dispatch(grupoSucatasAction.fetchGrupoSucatas(idGrupoSucatas));
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
            setIsRequestCompleted(true);
        };
        fetchGrupoSucatas();
    }, [dispatch, idGrupoSucatas]);

    // Setando copia pecas (lidando com busca)
    useEffect(() => {
        if (grupoSucatas.id) {
            if (busca)
                setCopiaPecas([].concat.apply([], [...grupoSucatas.sucatas.map(sucata => sucata.pecas)]).filter(peca => formatar.alphaNumeric(peca.arquetipo.nome).includes(formatar.alphaNumeric(busca))));
            else
                setCopiaPecas([].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas)));
        };
    }, [grupoSucatas, busca]);

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idPeca) => {
        event.preventDefault();

        setIdPecaContextMenu(idPeca);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const deletePeca = async idPeca => {
        exibirMensagem.warning({
            title: "Tem Certeza?",
            text: "Você deseja excluir permanentemente esta peça?",
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir!",
        }).then(async (result) => {
            if (result.value) {
                try {
                    await dispatch(pecasActions.deletePeca(idPeca));
                    exibirMensagem.success("A peça foi deletada com sucesso.");
                } catch (error) {
                    exibirMensagem.error({
                        timer: "2000",
                        showConfirmButton: false,
                    });
                };
            };
        });
    };

    return isLoading || !grupoSucatas.id || !isRequestCompleted ? <Spinner /> : (
        <div>
            <Titulo subtitulo={`Cód. ${grupoSucatas.id}`}>{grupoSucatas.nome} </Titulo>

            <div className="row mb-5">
                <Titulo> Peças do Grupo ({[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas)).length}) </Titulo>

                <div className="col-12">
                    <div className="row">
                        <div className="col-10">
                            <BarraPesquisa
                                busca={busca}
                                onChange={novaBusca => setBusca(novaBusca)} />
                        </div>

                        {[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas)).length ? (
                            <div className="col-2 text-center">
                                <EtiquetasPDF
                                    grupoSucatas={grupoSucatas}
                                    pecas={[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas.map(peca => { return { ...peca, sucata: sucata } })))} />
                            </div>
                        ) : null}
                    </div>
                </div>

                {copiaPecas.length ? (
                    <Table
                        hoverble
                        header={["Cód.", "Nome", "Preço", "Status"]}>
                        {copiaPecas.map(peca =>
                            <Peca
                                key={peca.id}
                                peca={peca}
                                onContextMenu={event => handleContextMenuClick(event, peca.id)}
                            />
                        )}
                    </Table>
                ) :
                    <div className="col-12 text-center">
                        <h5>Nenhuma peça foi encontrada.</h5>
                    </div>}
            </div>

            {/* Menu que aparece ao clicar com o botão direito */}
            <ContextMenu
                display={displayContextMenu}
                onClickOutside={() => setDisplayContextMenu(false)}
                x={positionX}
                y={positionY}>

                <li onClick={() => deletePeca(idPecaContextMenu)}>Excluir</li>
            </ContextMenu>
        </div >
    );
};

export default GrupoSucatasScreen;
