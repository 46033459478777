// Dependências
import React from "react";

// Css
import classes from "./Card.module.css";

const Card = (props) => {
    return (
        <div
            className={`${classes.Card} ${props.onClick ? classes.Clicavel : null}`}
            style={{ backgroundColor: props.color }}
            onClick={props.onClick}>

            <div className="row w-100">
                <div className="col-6 text-center">
                    {props.icone}
                </div>
                <div className="col-6">
                    <div className={classes.Texto}>
                        <span className={classes.Valor}>{props.valor}</span>
                        <br />
                        <span>{props.titulo}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
