// Dependências
import React from "react";

// Css
import classes from "./Card.module.css";

const Card = props => {
    return (
        <div className={`${classes.Card} ${props.className}`}>
            <div className={classes.Icon}>{props.icon}</div>
            <div className={classes.Titulo}>{props.titulo}</div>
            <div className={classes.Texto}>{props.children}</div>
        </div>
    );
};

export default Card;