// Dependências
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillTrashFill } from "react-icons/bs";
import { FaPaperPlane } from 'react-icons/fa';

// Actions
import * as perguntasActions from "../../../store/actions/perguntas";

// Css
import classes from "./Pergunta.module.css";

// Components
import Textarea from '../../UI/Textarea/Textarea';
import RespostaPronta from './RespostaPronta/RespostaPronta';

const Pergunta = (props) => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    const [resposta, setResposta] = useState("");

    const deletePergunta = () => dispatch(perguntasActions.deletePergunta(props.pergunta.id));

    return (
        <div className={`${classes.Card} ${darkMode ? classes.Dark : ""} ${props.pergunta.origem.nome === "Mercado Livre" ? classes.MercadoLivre : ""}`}>
            {props.pergunta.origem.nome === "Mercado Livre" ? (
                <BsFillTrashFill
                    className={classes.DeleteIcon}
                    title="Apagar pergunta"
                    size="18px"
                    onClick={deletePergunta}
                />
            ) : null}
            <div className="row">
                {props.pergunta.anuncio.foto_principal ?
                    <div className={`col-md-2 ${classes.ImagemPrincipal}`}>
                        <div className={classes.Wrapper}>
                            <img className={classes.Imagem} src={props.pergunta.anuncio.foto_principal} alt="" />
                        </div>
                    </div> : null}

                <div className={props.pergunta.anuncio.foto_principal ? "col-10" : "col-12"}>
                    <div className="row">
                        <div className={`col-sm-12 col-md-10 ${classes.Link}`}>
                            <a
                                target="_blank"
                                href={props.pergunta.anuncio.link}
                                rel="noopener noreferrer"
                            >{props.pergunta.anuncio.nome}</a>
                        </div>

                        <div className="col-2 h6">
                            <div className={classes.Wrapper}>
                                <img
                                    src={props.pergunta.origem.id === 3 ? "images/logo-mercado-livre.png" : "images/logo-mdp.png"}
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.Historico} ${props.pergunta.origem.nome === "Mercado Livre" ? classes.MercadoLivre : ""}`} ref={(el) => { if (el) el.scroll({ top: el.scrollHeight, behavior: 'smooth' }) }}>
                {props.pergunta.historico ?
                    props.pergunta.historico.map(pergunta => (
                        <div key={pergunta.id}>
                            <div className="row">
                                <div className="col-12">
                                    <div className={classes.BubblePergunta}>
                                        <span>{pergunta.pergunta}</span>
                                        <span className={classes.Data}>{pergunta.data}</span>
                                    </div>
                                </div>
                            </div>
                            {pergunta.resposta ? (
                                <div className="row">
                                    <div className="col-12">
                                        <div className={classes.BubbleResposta}>
                                            <span>{pergunta.resposta.texto}</span>
                                            <span className={classes.Data}>{pergunta.resposta.data}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )) : null}
                <div className="row">
                    <div className="col-12">
                        <div className={classes.BubblePergunta}>
                            <span>{props.pergunta.pergunta}</span>
                            <span className={classes.Data}>{props.pergunta.data}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-11 col-9">
                    <Textarea
                        rows="2"
                        setValue={resposta}
                        className={classes.Textarea}
                        placeholder="Digite sua resposta aqui"
                        onChange={value => setResposta(value)}
                    />
                </div>

                <div className="col-md-1 col-3 text-center">
                    <FaPaperPlane
                        className={classes.SendButton}
                        size="30"
                        title="Enviar resposta"
                        onClick={() => props.onEnviarResposta(resposta)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className={classes.Usuario}>
                        <strong>Usuário: </strong>{props.pergunta.cliente.nome}
                    </div>
                </div>
            </div>

            {props.respostasProntas ? (
                <div className="row mt-1">
                    <div className="col-sm-8 col-md-9">
                        {props.respostasProntas.map(respostaPronta => (
                            <RespostaPronta
                                key={respostaPronta.id}
                                titulo={respostaPronta.titulo}
                                padrao={respostaPronta.mostrarParaTodos}
                                onClick={() => setResposta(respostaPronta.texto)}
                            />
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Pergunta;
