// Dependências
import React from "react";
import { FaTrash } from "react-icons/fa";

// Css
import classes from "./NovaDespesa.module.css";

// Components
import BeautyDropdown from "../../../UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../UI/Input/Input";

const NovaDespesa = props => {
    return (
        <tr>
            <td>
                <BeautyDropdown
                    inline
                    returnObject
                    list={props.subcategorias}
                    default={props.despesa.subcategoria.nome}
                    onChange={value => props.onChange({ id: value.id, nome: value.nome }, "subcategoria")}
                />
            </td>
            <td>
                <Input
                    type="date"
                    size="75%"
                    placeholder="Data"
                    value={props.despesa.data}
                    invalid={!props.isValid.data}
                    onBlur={event => props.onChange(event.target.value, "data")}
                />
            </td>
            <td style={{ position: "relative" }}>
                <Input
                    onlyMoney
                    type="text"
                    size="60%"
                    maxLength="9"
                    placeholder="Valor (R$)"
                    value={props.despesa.valor}
                    invalid={!props.isValid.valor}
                    onBlur={event => props.onChange(event.target.value, "valor")}
                />
                <FaTrash
                    onClick={props.onDelete}
                    className={classes.DeleteButton}
                    size="17px"
                    title="Apagar peça" />
            </td>
        </tr>
    );
};

export default NovaDespesa;