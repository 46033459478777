import {
    FETCH_CONFIG
} from "../actions/config";

const initialState = {
    config: {}
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONFIG:
            return { ...state, config: action.config };
        default:
            return state;
    }
};

export default configReducer;
