import axios from 'axios';

import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig"

export const CREATE_USUARIO = "CREATE_USUARIO";
export const CREATE_VINCULO = "CREATE_VINCULO";
export const FETCH_METRICAS = "FETCH_METRICAS";
export const FETCH_MEU_USUARIO = "FETCH_MEU_USUARIO";
export const FETCH_USUARIOS = "FETCH_USUARIOS";
export const FETCH_OUTRO_USUARIO = "FETCH_OUTRO_USUARIO";
export const FETCH_TIPOS_PERMISSAO = "FETCH_TIPOS_PERMISSAO";
export const UPDATE_USUARIO = "UPDATE_USUARIO";

export const createRegistroAcesso = (url, darkMode) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        axios({
            method: 'POST',
            url: `${API_URL}/usuarios/acesso${API_URL === "https://mundodapeca.com:8001" || API_URL.includes("localhost") ? "?testServer=true" : ""}`,
            headers: { Authorization: token },
            data: { url: url, darkMode: darkMode }
        });
    };
};

export const createUsuario = usuario => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/usuarios`,
            headers: { Authorization: token },
            data: usuario
        }).then((result) => {
            if (result.status === 201) {
                let novoUsuario = { ...result.data };
                dispatch({ type: CREATE_USUARIO, usuario: novoUsuario });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const createVinculo = vinculo => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/conta/vinculos`,
            headers: { Authorization: token },
            data: vinculo
        }).then((result) => {
            if (result.status === 201) {
                let vinculos = { ...result.data };
                dispatch({ type: CREATE_VINCULO, vinculos: vinculos });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteImagemUsuario = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        await axios({
            method: 'DELETE',
            url: `${API_URL}/usuario/imagem`,
            headers: { Authorization: token }
        }).then((result) => {
            if (result.status === 200) {
                let usuario = { ...getState().usuario.usuario };
                usuario.imagem = "";
                dispatch({ type: UPDATE_USUARIO, usuario: usuario });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchMetricas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/usuario/metricas`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let metricas = {};
            try {
                metricas = await response.json();
                dispatch({ type: FETCH_METRICAS, metricas: metricas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchMeuUsuario = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/usuario`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let meuUsuario = {};
            try {
                meuUsuario = await response.json();

                dispatch({ type: FETCH_MEU_USUARIO, meuUsuario: meuUsuario });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        };
    };
};

export const fetchTiposPermissao = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/permissoes`, {
            headers: { Authorization: token },
        });

        if (response.ok) {
            let tiposPermissao = [];
            try {
                tiposPermissao = await response.json();
                dispatch({ type: FETCH_TIPOS_PERMISSAO, tiposPermissao: tiposPermissao });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const subtractMensagem = idMensagem => {
    return async (dispatch, getState) => {
        const usuario = { ...getState().usuario.meuUsuario };

        if (!usuario.id)
            return;

        usuario.conta.mensagens.forEach(mensagemConta => {
            if (mensagemConta.id === idMensagem && mensagemConta.quantidade)
                mensagemConta.quantidade--;
        });

        dispatch({ type: FETCH_MEU_USUARIO, meuUsuario: usuario });
    };
};

export const updateUsuario = usuario => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();

        if (usuario.imagem)
            formData.append("imagem", usuario.imagem);

        formData.append("usuario", JSON.stringify(usuario));

        await axios({
            method: 'PUT',
            url: `${API_URL}/usuario`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then(result => {
            if (result.status) {
                const usuario = result.data;
                dispatch({ type: UPDATE_USUARIO, usuario: usuario });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

