// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillLockFill } from "react-icons/bs";
import { FaComments, FaShoppingCart, FaCar } from "react-icons/fa";
import { GiCarWheel } from 'react-icons/gi';

// Actions
import * as vendasActions from "../../store/actions/vendas";
import * as usuarioActions from "../../store/actions/usuario";

// Components
import BarraPesquisa from '../../components/UI/BarraPesquisa/BarraPesquisa';
import Card from '../../components/Inicio/Card/Card';
import Dropdown from "../../components/UI/BeautyDropdown/BeautyDropdown";
import Table from '../../components/UI/Table/Table';
import Titulo from '../../components/UI/Titulo/Titulo';
import Venda from '../../components/Inicio/Venda/Venda';

// Icons
import { Cifrao, MercadoLivre } from "../../Icons/Icons";

const InicioScreen = (props) => {
    const dispatch = useDispatch();

    const vendas = useSelector(state => state.vendas.vendas);
    const metricas = useSelector(state => state.usuario.metricas);
    const usuario = useSelector(state => state.usuario.meuUsuario);

    const accessFinanceiro = usuario.permissoes.find(permissao => permissao.id === 3);
    const accessVendas = usuario.permissoes.find(permissao => permissao.id === 5);

    const [busca, setBusca] = useState("");
    const [doRequest, setDoRequest] = useState(false);
    const [doravante, setDoravante] = useState(1);
    const [ordem, setOrdem] = useState("mais-recente");
    const [isLoading, setIsLoading] = useState(false);

    // Requisição das metricas
    useEffect(() => {
        const fetchMetricas = async () => {
            try {
                await dispatch(usuarioActions.fetchMetricas());
            } catch (error) {
                console.log(error);
            };
        };

        fetchMetricas();
    }, [dispatch]);

    // Requisição das vendas
    useEffect(() => {
        const fetchVendas = async () => {
            setIsLoading(true);
            try {
                await dispatch(vendasActions.fetchVendas(doravante, busca, ordem));
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };

        if (!isLoading)
            fetchVendas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, doravante, busca, doRequest]);

    const handleOnScrollBottom = () => {
        if (!(vendas.length % 50))
            setDoravante(doravante + 1);
    };

    return (
        <div className="container-fluid p-md-0">
            <Titulo>{usuario.conta.nome}</Titulo>

            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#db4b62"
                        titulo="Perguntas"
                        valor={metricas.quantidadePerguntas}
                        icone={<FaComments />}
                        onClick={() => props.history.push("/perguntas")} />
                </div>
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#404242"
                        titulo="Peças"
                        valor={metricas.quantidadePecas}
                        icone={<GiCarWheel />}
                        onClick={() => props.history.push("/estoque/pecas")} />
                </div>
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#3b3e51"
                        titulo="Sucatas"
                        valor={metricas.quantidadeSucatas}
                        icone={<FaCar />}
                        onClick={() => props.history.push("/estoque/sucatas")} />
                </div>
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#79cd56"
                        titulo="Total"
                        valor={accessVendas ? metricas.quantidadeVendas : null}
                        icone={accessVendas ? <FaShoppingCart /> : <BsFillLockFill />} />
                </div>
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#ecc34d"
                        titulo="Vendas"
                        valor={accessVendas ? metricas.plataformas?.find(plataforma => plataforma.id === 2)?.metricas.quantidade : null}
                        icone={accessVendas ? <MercadoLivre /> : <BsFillLockFill />}
                        onClick={accessVendas ? () => props.history.push("/financeiro/vendas/mercado-livre") : null} />
                </div>
                <div className="col-sm-12 col-md-2">
                    <Card
                        color="#593196"
                        titulo="Receita"
                        valor={accessFinanceiro ? metricas.valorVendas ? Math.trunc(metricas.valorVendas) : null : null}
                        icone={accessFinanceiro ? <Cifrao /> : <BsFillLockFill />} />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3">
                    <div className="row">
                        <div className="col-12 my-2">
                            <Titulo>Vendas</Titulo>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-8">
                            <BarraPesquisa
                                placeholder="Pesquisar no meu estoque"
                                onChange={novaBusca => setBusca(novaBusca)} />
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <Dropdown
                                list={[
                                    { id: "mais-recente", nome: "Mais recentes" },
                                    { id: "mais-antigo", nome: "Mais antigas" },
                                ]}
                                onChange={idOrdem => {
                                    if (doravante !== 1)
                                        setDoravante(1);
                                    else
                                        setDoRequest(!doRequest);

                                    setOrdem(idOrdem);
                                }} />
                        </div>
                    </div>
                    <div className={`row`} style={{ height: "83vh" }}>
                        <Table
                            small
                            fixed
                            hoverble
                            bordered={vendas.length}
                            isLoading={isLoading}
                            onScrollBottom={handleOnScrollBottom}
                            header={accessVendas ? ["Local", "Peça", "Sucata", "Data", "Preço", "Receita", "Comprador"] : ["Local", "Peça", "Sucata"]}>
                            {vendas.length || busca.length ? vendas.map(venda => (
                                <Venda
                                    key={venda.id}
                                    venda={venda}
                                    accessVendas={accessVendas} />
                            )) : (
                                <tr><td colSpan="6"><h4>Você não possui nenhuma venda ainda</h4></td></tr>
                            )}
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InicioScreen;
