// Dependências
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// Css
import * as classes from "./ContextMenu.module.css";

function ContextMenu(props) {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);
    const node = useRef();

    const handleClickOutside = e => {
        if (node.current.contains(e.target))
            return;

        props.onClickOutside();
    };

    // Verifica se está mostrando ou não
    useEffect(() => {
        if (props.display)
            document.addEventListener("mousedown", handleClickOutside);
        else
            document.removeEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.display]);

    return (
        <ul
            ref={node}
            className={darkMode ? classes.ContextMenuDark : classes.ContextMenuLight}
            onContextMenu={event => event.preventDefault()}
            style={{ top: `${props.y}px`, left: `${props.x}px`, visibility: props.display && props.x && props.y ? "visible" : "hidden", position: props.absolute ? "absolute" : null }}
        >
            {props.children}
        </ul>
    );
};


export default ContextMenu;