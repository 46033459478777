// Dependências
import React, { useEffect, useState } from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from 'react-redux';
import { API_URL } from "../../../../constants/GlobalConfig";

// Components
import Button from "../../../UI/Button/Button";
import Dropdown from '../../../UI/BeautyDropdown/BeautyDropdown';
import Peca from "../Peca/Peca";
import Spinner from '../../../UI/Spinner/Spinner';

// Css
import classes from "./Sucatas.module.css";

// Helpers
import statusHandler from "../../../../helpers/statusHandler";


const Sucata = props => {
    const token = useSelector(state => state.auth.token);

    const [displayContent, setDisplayContent] = useState(false);
    const [idMarcaSelecionada, setIdMarcaSelecionada] = useState(props.identificacao.fipe.marca.id);

    // Se mudar a marca ou não tiver veiculos, faz request de veiculos
    useEffect(() => {
        if (!props.sucata.fipe.veiculos.length || props.identificacao.fipe.marca.id !== idMarcaSelecionada) {
            const doRequest = async () => {
                const response = await fetch(
                    `${API_URL}/fipe/marcas/${props.identificacao.fipe.marca.id}/veiculos`,
                    { headers: { Authorization: token } }
                );

                if (response.ok) {
                    let veiculos = [];
                    veiculos = await response.json();
                    setIdMarcaSelecionada(props.identificacao.fipe.marca.id);
                    props.onChangeVeiculoOpcoes(veiculos);
                    props.onChange({ ...props.identificacao, fipe: { ...props.identificacao.fipe, veiculo: veiculos[0] } });
                } else
                    statusHandler(response?.status);
            };

            doRequest();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, props.sucata.fipe.veiculos.length, props.identificacao.fipe.marca.id]);

    return (
        <>
            <tbody className={classes.Sucata}>
                <tr>
                    <td className="pl-4" style={{ textAlign: 'left', position: 'relative' }}>
                        <BsFillTrashFill
                            className={classes.DeleteIcon}
                            title="Apagar sucata"
                            onClick={props.onDelete}
                        />
                        <strong>{props.sucataDetran.fipe.modelo.nome} - {props.sucata.placa}</strong>
                    </td>

                    <td>
                        <Dropdown
                            returnObject
                            list={props.sucata.fipe.marcas}
                            onChange={novaMarca => props.onChange({ ...props.identificacao, fipe: { ...props.identificacao.fipe, marca: novaMarca } })} />
                    </td>

                    <td>
                        {idMarcaSelecionada === props.identificacao.fipe.marca.id ? (
                            <Dropdown
                                returnObject
                                default={props.identificacao.fipe.veiculo.nome}
                                list={props.sucata.fipe.veiculos}
                                onChange={novoVeiculo => props.onChange({ ...props.identificacao, fipe: { ...props.identificacao.fipe, veiculo: novoVeiculo } })} />
                        ) : <Spinner inline />}
                    </td>

                    <td>
                        <Dropdown
                            returnObject
                            list={props.sucata.fipe.anos}
                            onChange={novoAno => props.onChange({ ...props.identificacao, fipe: { ...props.identificacao.fipe, ano: novoAno } })} />
                    </td>

                    <td>
                        <Button
                            color={props.identificacao.pecas.map(peca => peca.isValid).includes(false) ? "red" : "blue"}
                            onClick={() => setDisplayContent(prevDisplayContent => !prevDisplayContent)}
                        >
                            Peças ({props.sucata.pecas.length}) <IoIosArrowDown className={classes.Icon} />
                        </Button>
                    </td>
                </tr>
            </tbody>


            <tbody className={`${classes.Dados} ${displayContent ? classes.Active : ""}`}>
                {props.sucata.pecas.map(peca =>
                    <Peca
                        key={peca.idDetran}
                        peca={peca}
                        identificacao={props.identificacao.pecas.find(pecaIdentificada => pecaIdentificada.id === peca.idDetran)}
                        onChange={novaPeca => {
                            let copiaSucata = { ...props.identificacao };
                            copiaSucata.pecas = copiaSucata.pecas.map(copiaPeca => copiaPeca.id === novaPeca.id ? { ...novaPeca } : copiaPeca);
                            props.onChange(copiaSucata);
                        }}
                        onDelete={() => props.deletePeca(peca.idDetran)}
                    />
                )}
            </tbody>
        </>
    );
};

export default Sucata;