import { API_URL } from "../../constants/GlobalConfig"
import axios from 'axios';
import statusHandler from "../../helpers/statusHandler";
import * as validar from "../../helpers/validar";

export const CREATE_DESPESA = "CREATE_DESPESA";
export const CREATE_DESPESAS_SUCATA = "CREATE_DESPESAS_SUCATA";
export const DELETE_DESPESA = "DELETE_DESPESA";
export const FETCH_CATEGORIA_DESPESAS = "FETCH_CATEGORIA_DESPESAS";
export const FETCH_CATEGORIAS_DESPESAS = "FETCH_CATEGORIAS_DESPESAS";
export const FETCH_DADOS_MENSAIS_DESPESAS = "FETCH_DADOS_MENSAIS_DESPESAS";
export const FETCH_DESPESA = "FETCH_DESPESA";
export const FETCH_DESPESAS = "FETCH_DESPESAS";
export const FETCH_FREQUENCIAS_DESPESAS = "FETCH_FREQUENCIAS_DESPESAS";
export const FETCH_OPCOES_DESPESAS = "FETCH_OPCOES_DESPESAS";
export const UPDATE_DESPESA = "UPDATE_DESPESA";

export const createDespesa = despesa => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/despesas`,
            headers: {
                Authorization: token
            },
            data: despesa
        }).then(result => {
            if (result.status === 201) {
                dispatch({ type: CREATE_DESPESA, despesa: result.data });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const createDespesasSucata = (idSucata, despesas) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/sucatas/${idSucata}/despesas`,
            headers: {
                Authorization: token
            },
            data: Array.isArray(despesas) ? despesas : [despesas]
        }).then((result) => {
            if (result.status === 201) {
                dispatch({ type: CREATE_DESPESAS_SUCATA, sucata: result.data });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deleteDespesa = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/despesas/${id}`,
            headers: {
                Authorization: token
            },
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: DELETE_DESPESA, id: result.data.idDespesa });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchCategorias = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/despesas/categorias${id ? `/${id}` : ""}`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let categorias = [];
            try {
                categorias = await response.json();
                if (!id)
                    dispatch({ type: FETCH_CATEGORIAS_DESPESAS, categorias: categorias });
                else
                    dispatch({ type: FETCH_CATEGORIA_DESPESAS, categoria: categorias });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchDadosMensais = (dataInicio, dataFinal) => {
    return async (dispatch, getState) => {
        if (!dataInicio || !dataFinal || !validar.data(dataInicio) || !validar.data(dataFinal))
            return;

        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/despesas/dados-mensais/${dataInicio}/${dataFinal}`, {
            headers: {
                Authorization: token,
            }
        });

        if (response.ok) {
            let dadosMensais = {};
            try {
                dadosMensais = await response.json();
                dispatch({ type: FETCH_DADOS_MENSAIS_DESPESAS, dadosMensais: dadosMensais });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchDespesas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/despesas`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let despesas = [];
            try {
                despesas = await response.json();
                dispatch({ type: FETCH_DESPESAS, despesas: despesas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchFrequencias = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/despesas/frequencias`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let frequencias = [];
            try {
                frequencias = await response.json();
                dispatch({ type: FETCH_FREQUENCIAS_DESPESAS, frequencias: frequencias });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchOpcoes = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/despesas/categorias/opcoes`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let opcoes = [];
            try {
                opcoes = await response.json();
                dispatch({ type: FETCH_OPCOES_DESPESAS, opcoes: opcoes });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const updateDespesa = (id, despesa) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/despesas/${id}`,
            headers: {
                Authorization: token
            },
            data: despesa
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: UPDATE_DESPESA, despesa: result.data });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};
