// Dependências
import React from "react";

// Css
import classes from "./SideDrawer.module.css";

// Components
import SideDrawerButton from "./SideDrawerButton/SideDrawerButton";

const SideDrawer = props => {
    return (
        <nav className={props.show ? classes.SideDrawer : `${classes.SideDrawerFechado} ${classes.SideDrawer}`}>
            <ul>
                <SideDrawerButton to="/inicio" onClick={props.onClick}> Início </SideDrawerButton>

                <SideDrawerButton to="/estoque/pecas" onClick={props.onClick}> Minhas peças </SideDrawerButton>
                
                <SideDrawerButton to="/perguntas" onClick={props.onClick}> Perguntas </SideDrawerButton>

                <SideDrawerButton to="/cadastro" onClick={props.onClick}> Anúnciar peças </SideDrawerButton>         
                <SideDrawerButton to="/localizacao" onClick={props.onClick}> Localizações </SideDrawerButton>       
                
                <SideDrawerButton to="/estoque/grupo-sucatas" onClick={props.onClick}> Grupos de Peças </SideDrawerButton>

            </ul>
        </nav>
    );
};

export default SideDrawer;