// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./RespostaPronta.module.css";

const RespostaPronta = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return (
        <div
            className={`${darkMode ? classes.RespostaProntaDark : classes.RespostaProntaLight} ${props.padrao ? classes.Padrao : ""} ${props.bigger ? classes.RespostaMedium : classes.RespostaSmall}`}
            onClick={props.onClick}
        >
            {props.titulo}
        </div>
    );
};

export default RespostaPronta;
