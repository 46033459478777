// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBoxes } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { MdLocalGroceryStore } from "react-icons/md";

// Actions
import * as financeiroActions from "../../store/actions/financeiro";

// Helpers
import * as formatar from "../../helpers/formatar";

// Components
import BarraPesquisa from '../../components/UI/BarraPesquisa/BarraPesquisa';
import Card from "../../components/Financeiro/Card/Card";
import CardInicio from "../../components/Inicio/Card/Card";
import Dropdown from "../../components/UI/BeautyDropdown/BeautyDropdown";
import Grafico from "../../components/UI/GraficoPizza/GraficoPizza";
import Spinner from "./../../components/UI/Spinner/Spinner";
import Sucata from "../../components/Financeiro/Sucata/Sucata";
import Table from '../../components/UI/Table/Table';
import Titulo from "../../components/UI/Titulo/Titulo";

const FinanceiroScreen = (props) => {
    const dispatch = useDispatch();

    const financeiro = useSelector(state => state.financeiro.financeiro);

    const [busca, setBusca] = useState("");
    const [doravante, setDoravante] = useState(1);
    const [doRequest, setDoRequest] = useState(false);
    const [ordem, setOrdem] = useState("maior-lucro");
    const [isLoading, setIsLoading] = useState(false);

    // Requisição do financeiro
    useEffect(() => {
        const fetchFinanceiro = async () => {
            setIsLoading(true);
            try {
                await dispatch(financeiroActions.fetchFinanceiro(doravante, busca, ordem));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchFinanceiro();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, doravante, doRequest]);

    const handleOnScrollBottom = () => {
        if (!(financeiro.sucatas.length % 50))
            setDoravante(doravante + 1);
    };
    
    return !financeiro.valores ? <Spinner /> : (
        <div>
            <div className="row mb-2">
                <div className="col-md-4">
                    <Card
                        titulo="Mercado Livre"
                        img="images/logo-mercado-livre.png"
                        plataforma={financeiro.plataformas.mercadoLivre}
                        onClick={() => props.history.push("/financeiro/vendas/mercado-livre")}
                    />
                </div>

                <div className="col-md-4">
                    <Card
                        titulo="Loja Física"
                        img="images/lojaFisica2.png"
                        plataforma={financeiro.plataformas.balcao}
                        onClick={() => props.history.push("/financeiro/vendas/loja-fisica")}
                    />
                </div>

                <div className="col-md-4">
                    <Card
                        titulo="Mundo da Peça"
                        img="images/logo-mdp.png"
                        plataforma={financeiro.plataformas.mundoDaPeca}
                        onClick={() => props.history.push("/financeiro/vendas/mundo-da-peca")}
                    />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-md-4">
                    <CardInicio
                        titulo="Vendas"
                        color="#79cd56"
                        icone={<MdLocalGroceryStore />}
                        valor={formatar.dinheiro(financeiro.valores.vendas, false)}
                        onClick={() => props.history.push("/financeiro/vendas")} />
                </div>

                <div className="col-md-4">
                    <CardInicio
                        titulo="Despesas"
                        color="#c34858"
                        icone={<GiPayMoney />}
                        valor={formatar.dinheiro(financeiro.valores.despesas, false)}
                        onClick={() => props.history.push("/financeiro/despesas/categorias")} />
                </div>

                <div className="col-md-4">
                    <CardInicio
                        titulo="Em estoque"
                        color="#ecc34d"
                        icone={<FaBoxes />}
                        valor={formatar.dinheiro(financeiro.valores.estoque, false)}
                        onClick={() => props.history.push("/estoque/sucatas")} />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-5">
                    <Titulo>Suas Melhores Sucatas</Titulo>

                    <div className="row">
                        <div className="col-9">
                            <BarraPesquisa
                                busca={busca}
                                onChange={novaBusca => {
                                    if (doravante !== 1)
                                        setDoravante(1);
                                    else
                                        setDoRequest(!doRequest);

                                    setBusca(novaBusca);
                                }}
                            />
                        </div>
                        <div className="col-3">
                            <Dropdown
                                list={[
                                    { id: "maior-lucro", nome: "Maior lucro" },
                                    { id: "menor-lucro", nome: "Menor lucro" },
                                    { id: "mais-recente", nome: "Mais recentes" },
                                    { id: "mais-antigo", nome: "Mais antigas" },
                                ]}
                                onChange={idOrdem => {
                                    if (doravante !== 1)
                                        setDoravante(1);
                                    else
                                        setDoRequest(!doRequest);

                                    setOrdem(idOrdem);
                                }} />
                        </div>
                    </div>
                    <div className="row" style={{ height: "83vh" }}>
                        <Table
                            header={["Modelo", "Em estoque", "Despesas", "Vendas", "Lucro", "Receita", <span>%&nbsp;Vendido</span>]}
                            isLoading={isLoading}
                            onScrollBottom={handleOnScrollBottom}
                            hoverble={financeiro.sucatas.length}
                            bordered={financeiro.sucatas.length}
                            fixed
                        >

                            {financeiro.sucatas.length || isLoading ?
                                financeiro.sucatas.map(sucata =>
                                    <Sucata key={sucata.id} sucata={sucata} />)
                                : <tr><td className="h5" colSpan="7">Nenhum resultado encontrado</td></tr>}
                        </Table>
                    </div>
                </div>
            </div>

            {financeiro.valores.vendas > 0 ? (
                <div className="row mt-2">
                    <div className="col-6">
                        <Grafico
                            title="Locais com mais vendas"
                            labels={["Mundo da Peça", "Loja Física", "Mercado Livre"]}
                            data={[
                                financeiro.plataformas.mundoDaPeca.quantidade,
                                financeiro.plataformas.balcao.quantidade,
                                financeiro.plataformas.mercadoLivre.quantidade,
                            ]}
                        />
                    </div>
                    {financeiro.melhoresVeiculos.length > 2 ? (
                        <div className="col-6">
                            <Grafico
                                title="Sucatas mais rentáveis"
                                legendPosition="right"
                                labels={[
                                    financeiro.melhoresVeiculos[0].nome,
                                    financeiro.melhoresVeiculos[1].nome,
                                    financeiro.melhoresVeiculos[2].nome,
                                ]}
                                data={[
                                    financeiro.melhoresVeiculos[0].lucro,
                                    financeiro.melhoresVeiculos[1].lucro,
                                    financeiro.melhoresVeiculos[2].lucro,
                                ]}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default FinanceiroScreen;
