// Dependência
import React from "react";

// Components
import DropZone from "../../../UI/DropZone/DropZone";

const Peca = props => {
    return (
        <div className="col-2 mb-2" style={{ height: "200px" }}>
            <DropZone
                quantidade="8"
                subtitulo={props.showSucata ? props.peca.sucata : null}
                src={props.peca.fotos.length ? props.peca.fotos.map(file => file.src) : []}
                title={props.peca.arquetipo.nome}
                imageTitle={props.peca.arquetipo.nome}
                onChange={novasFotos => props.onChange({
                    ...props.peca, fotos: [...props.peca.fotos, ...novasFotos.map(foto => {
                        return {
                            ...foto, atrelamento: {
                                id: null,
                                nome: "peca",
                                atrelamento: { id: props.peca.id },
                                tipo: { id: null, nome: null }
                            }
                        };
                    })]
                })}
                onDelete={index => {
                    let copiaFotos = props.peca.fotos;
                    copiaFotos.splice(index, 1);
                    props.onChange({ ...props.peca, fotos: [...copiaFotos] });
                }}
            />
        </div>
    );
};

export default Peca;