import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const FETCH_CONFIG = "FETCH_CONFIG";

export const fetchConfig = () => {
    return async (dispatch, getState) => {
        const response = await fetch(`${API_URL}/config`);

        if (response.ok) {
            let config = [];
            try {
                config = await response.json();
                dispatch({ type: FETCH_CONFIG, config: config });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};
