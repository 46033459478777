export const UPDATE_CADASTRO_SUCATA = "UPDATE_CADASTRO_SUCATA";
export const UPDATE_CADASTRO_GRUPO_PECAS = "UPDATE_CADASTRO_GRUPO_PECAS";
export const RESET_CADASTRO = "RESET_CADASTRO";

export const reset = () => {
    sessionStorage.removeItem("cadastro-sucata");
    sessionStorage.removeItem("cadastro-grupo-pecas");

    return (dispatch, getState) =>
        dispatch({ type: RESET_CADASTRO });
};

export const setCadastroGrupoPecas = cadastroGrupoPecas => {
    sessionStorage.setItem("cadastro-grupo-pecas", JSON.stringify(cadastroGrupoPecas));

    return (dispatch, getState) =>
        dispatch({ type: UPDATE_CADASTRO_GRUPO_PECAS, cadastroGrupoPecas: cadastroGrupoPecas });
};

export const setCadastroSucata = cadastroSucata => {
    let copiaCadastroSucata = { ...cadastroSucata };
    copiaCadastroSucata.fotos = [];

    sessionStorage.setItem("cadastro-sucata", JSON.stringify(copiaCadastroSucata));

    return (dispatch, getState) =>
        dispatch({ type: UPDATE_CADASTRO_SUCATA, cadastroSucata: cadastroSucata });
};