// Dependências
import React from "react";
import { withRouter } from "react-router-dom";

// Helpers
import * as formatar from '../../../../helpers/formatar';

const DespesaUnica = props => {
    return (
        <tr onClick={props.onClick}>
            <td>{props.despesa.id}</td>
            <td>{props.despesa.nome}</td>
            <td>{formatar.dinheiro(props.despesa.valor)}</td>
            <td>{formatar.data(props.despesa.dataInicio)}</td>
        </tr>
    );
};

export default withRouter(DespesaUnica);
