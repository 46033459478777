// Dependências
import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import PecasEmRevisao from "../../../../components/Cadastro/Sucata/PecasEmRevisao/PecasEmRevisao";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const PecasEmRevisaoScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async (pecas, redirectCategorias) => {
        setIsLoading(true);
        try {
            await dispatch(pecasActions.deletePecas(pecas.filter(peca => !peca.anunciar).map(peca => { return { id: peca.id } })));
            await dispatch(pecasActions.updatePecas(pecas.filter(peca => peca.anunciar).map(peca => { return { id: peca.id, revisar: false, observacao: peca.observacao ? peca.observacao : null } })));
            setIsLoading(false);
            exibirMensagem.success("Alterações realizadas com sucesso.");

            if (redirectCategorias)
                props.history.push(`/cadastro/sucata/categorias/${idSucata}`);
            else
                props.history.push(`/cadastro/sucata/upload-fotos/${idSucata}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div>
            <PecasEmRevisao
                idSucata={idSucata}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={(pecas, redirectCategorias) => submitHandler(pecas, redirectCategorias)} />
        </div>
    );
};

export default PecasEmRevisaoScreen;