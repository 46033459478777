// Dependências
import React from "react";
import { FaCarSide } from 'react-icons/fa';
// Css
import classes from "./Navbar.module.css";

// Components
// import Button from "../Button/Button";
import NavbarButton from "./NavbarButton/NavbarButton";

const Navbar = props => {
    return (
        <nav className={`d-none d-md-block ${classes.Navbar}`}>
            <div className="row">
                <div className={`col-3 ${classes.LogoDiv}`}>
                    <FaCarSide className={classes.IconLogo}/>
                </div>

                <div className={`col-6 ${classes.NavigationItems}`} >
                    <NavbarButton link="/conhecer"> Sistema UnifiCAR </NavbarButton>
                </div>
            </div>
        </nav >
    );
};

export default Navbar;