// Dependências
import React from "react";

// Components
import Boolean from "./Boolean/Boolean";
import List from "./List/List";
import Number from "./Number/Number";
import NumberUnit from "./NumberUnit/NumberUnit";
import String from "./String/String";

const Atributo = props => {
    switch (props.atributo.tipo) {
        case "boolean":
            return (
                <div className="col-6">
                    <Boolean
                        atributo={props.atributo}
                        onChange={value => props.onChange(value)} />
                </div>
            );
        case "list":
            return (
                <div className="col-6">
                    <List
                        atributo={props.atributo}
                        onChange={value => props.onChange(value)} />
                </div>
            );
        case "number":
            return (
                <div className="col-6">
                    <Number
                        atributo={props.atributo}
                        onChange={value => props.onChange(value)} />
                </div>
            );
        case "number_unit":
            return (
                <div className="col-6">
                    <NumberUnit
                        atributo={props.atributo}
                        onChange={value => props.onChange(value)} />
                </div>
            );
        case "string":
            return (
                <div className="col-6">
                    <String
                        atributo={props.atributo}
                        onChange={value => props.onChange(value)} />
                </div>
            );
        default:
            return <div style={{ color: "green" }}>{props.atributo.valueType}</div>;
    };
};

export default Atributo;