import axios from 'axios';
import { API_URL } from "../../constants/GlobalConfig"

import statusHandler from "../../helpers/statusHandler";

export const CREATE_ANUNCIOS = "CREATE_ANUNCIOS";
export const CREATE_ANUNCIOS_PECA = "CREATE_ANUNCIOS_PECA";
export const DELETE_ANUNCIO = "DELETE_ANUNCIO";
export const FETCH_ANUNCIOS = "FETCH_ANUNCIOS";
export const FETCH_FRETES = "FETCH_FRETES";
export const FETCH_PLATAFORMAS = "FETCH_PLATAFORMAS";
export const FETCH_TAXAS = "FETCH_TAXAS";
export const UPDATE_ANUNCIO = "UPDATE_ANUNCIO";
export const UPDATE_ANUNCIOS = "UPDATE_ANUNCIOS"

export const createAnuncios = anuncios => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/anuncios`,
            headers: {
                Authorization: token
            },
            data: anuncios
        }).then((result) => {
            if (result.status === 201) {
                const anunciosCriados = result.data;
                dispatch({ type: CREATE_ANUNCIOS, anuncios: anunciosCriados });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const createAnunciosPeca = (idPeca, anuncios) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/${idPeca}/anuncios`,
            headers: {
                Authorization: token
            },
            data: anuncios
        }).then(result => {
            if (result.status === 201) {
                const anunciosPeca = result.data;
                dispatch({ type: CREATE_ANUNCIOS_PECA, idPeca: idPeca, anuncios: anunciosPeca });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteAnuncio = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(`${API_URL}/anuncios/${id}`, {
            method: "DELETE",
            headers: { Authorization: token }
        });

        if (response.ok) {
            await dispatch({ type: DELETE_ANUNCIO, id: id });
        } else {
            if (response.status)
                statusHandler(response.status);
            else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchAnuncios = (idPeca) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/pecas/${idPeca}/anuncios`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let anuncios = [];
            try {
                anuncios = await response.json();
                dispatch({ type: FETCH_ANUNCIOS, anuncios: anuncios });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchFretes = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/anuncios/fretes`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let fretes = [];
            try {
                fretes = await response.json();
                dispatch({ type: FETCH_FRETES, fretes: fretes });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchFretesPecas = pecas => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/anuncios/fretes`,
            headers: { Authorization: token },
            data: pecas
        }).then(result => {
            if (result.status === 200) {
                const fretes = result.data;
                dispatch({ type: FETCH_FRETES, fretes: fretes });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchPlataformas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/anuncios/plataformas`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let plataformas = [];
            try {
                plataformas = await response.json();
                dispatch({ type: FETCH_PLATAFORMAS, plataformas: plataformas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchTaxas = peca => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/anuncios/taxas`,
            headers: {
                Authorization: token
            },
            data: peca
        }).then(result => {
            if (result.status === 200) {
                try {
                    dispatch({ type: FETCH_TAXAS, taxas: result.data });
                } catch (error) {
                    throw error;
                }
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const updateAnuncio = (id, values) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/anuncios/${id}`,
            headers: {
                Authorization: token
            },
            data: values
        }).then((result) => {
            if (result.status) {
                dispatch({ type: UPDATE_ANUNCIO, anuncio: { ...result.data } });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};