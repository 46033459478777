// Dependências
import React from "react";

// Css
import classes from "./TutoriaisScreen.module.css";

// Components
import Titulo from "../../components/UI/Titulo/Titulo";

const TutorialScreen = props => {
    return (
        <div className="container-fluid">
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Tutorial #1 - Apresentação Geral</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/WkmDCHX7nVk"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Cadastrando até 250 peças no Mercado Livre de uma só vez - Aba "Cadastrar Sucata"</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/koH7QsP7aaM"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                    <div className="col-12 text-center">
                        <Titulo>Financeiro - Saiba quais peças e sucatas estão te dando mais lucro!</Titulo>

                        <iframe
                            className={classes.Video}
                            src="https://www.youtube.com/embed/5J0QhB_jYq0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="tutorialML"
                            allowFullScreen
                        >

                        </iframe>
                    </div>
                </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Quando e como usar o "Cadastrar Sucata" e o "Grupo de Peças"</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/iu0jNEcCDzk"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Tutorial #2 - Recursos da aba "Início"</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/BlzkwJjSjWI"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Tutorial #3 - Utilizando as Respostas Padronizadas</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/E1LNrBMxLAw"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Tutorial #4 - Consultando seu Estoque de peças da maneira correta</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/eygbEdOgcVM"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Como vincular sua conta do Mercado Livre</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/h35Zz9ZlX0Q"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorialML"
                        allowFullScreen
                    >

                    </iframe>
                </div>
            </div>
            <div className="row d-flex justify-content-center my-3">
                <div className="col-12 text-center">
                    <Titulo>Tutorial Completo UnifiCAR</Titulo>

                    <iframe
                        className={classes.Video}
                        src="https://www.youtube.com/embed/AJyMSzHvORY"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="s"
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    );
};

export default TutorialScreen;