// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import BottomNavbar from "../../../../components/Cadastro/BottomNavbar/BottomNavbar";
import FichaTecnica from "../../../../components/Cadastro/FichaTecnica/FichaTecnica";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Titulo from "../../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const FichaTecnicaScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const pecas = useSelector(state => state.pecas.pecas);

    const [copiaPecas, setCopiaPecas] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isRequestComplete, setIsRequestComplete] = useState(false);
    const [isScrollActive, setIsScrollActive] = useState(true);

    // Requisição das peças com atributos
    useEffect(() => {
        const fetchPecasAtributos = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecasAtributos(idSucata));
                setIsRequestComplete(true);
            } catch (error) {
                console.log(error);
            };
        };

        fetchPecasAtributos();
    }, [dispatch, idSucata]);

    // Setando copiaPecas
    useEffect(() => {
        if (isRequestComplete) {
            setCopiaPecas([...pecas.map(peca => {
                return {
                    ...peca,
                    atributos: peca.atributos.map(atributo => {
                        let value = atributo.padrao || null;

                        if (!value) {
                            if (atributo.tipo === "boolean" || atributo.tipo === "list")
                                value = atributo.valores[0];
                            if (atributo.tipo === "number_unit")
                                value = { number: null, unit: atributo.valores[0] };
                        };

                        return {
                            ...atributo,
                            value: value,
                            naoSeAplica: false
                        };
                    })
                };
            })]);
            setIsLoading(false);
        }
    }, [pecas, isRequestComplete]);

    const submitHandler = async () => {
        let formIsValid = true;
        let copiaPecasValidadas = JSON.parse(JSON.stringify([...copiaPecas]));

        copiaPecasValidadas.forEach(peca => {
            peca.atributos.forEach(atributo => {
                if (atributo.obrigatorio && !atributo.value?.length) {
                    atributo.isValid = false;
                    formIsValid = false;
                };
            });
        });

        if (formIsValid) {
            try {
                setIsLoading(true);
                await dispatch(pecasActions.createFichaTecnica(copiaPecas.map(peca => {
                    return {
                        id: peca.id,
                        atributos: peca.atributos.map(atributo => {
                            return {
                                id: atributo.id,
                                valor: atributo.value
                            };
                        })
                    };
                })));
                exibirMensagem.success("Fichas técnicas cadastradas com sucesso!");
                props.history.push(`/cadastro/sucata/titulo-sucata/${idSucata}`);
            } catch (error) {
                console.log(error);
            };
        } else {
            exibirMensagem.formInvalid();
            setIsScrollActive(true);
            setCopiaPecas(copiaPecasValidadas);
        };
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Insira os Atributos/Ficha Técnica das peças</Titulo>

            <div className="row">
                <div className="col-12 mb-4">
                    <span style={{ fontSize: "16px" }}><i>Esta etapa é opcional, porém desejável</i> para <strong>aumentar a relevância</strong> dos seus anúncios no Mercado Livre.</span>
                </div>
            </div>

            {copiaPecas.map(peca =>
                <FichaTecnica
                    key={peca.id}
                    peca={peca}
                    showSucata={props.showSucata}
                    scroll={copiaPecas.find(peca => peca.atributos.find(atributo => atributo.isValid === false))?.id === peca.id ? isScrollActive : false}
                    onChange={pecaAtualizada => {
                        setIsScrollActive(false);
                        let copiaPecasAtualizadas = [...copiaPecas];
                        copiaPecasAtualizadas = copiaPecasAtualizadas.map(copiaPecaAtualizada => copiaPecaAtualizada.id === pecaAtualizada.id ? pecaAtualizada : copiaPecaAtualizada);
                        setCopiaPecas(copiaPecasAtualizadas);
                    }} />)}

            <BottomNavbar
                onAntClick={() => props.history.push("/cadastro/sucata")}
                onProxClick={submitHandler} />
        </div>
    );
};

export default FichaTecnicaScreen;