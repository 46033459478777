// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../../store/actions/anuncios";

// Components
import Modal from "../../../UI/Modal/Modal";
import Table from "../../../UI/Table/Table";
import Taxa from "./Taxa/Taxa";
import Spinner from "../../../UI/Spinner/Spinner";

const ModalTaxas = props => {
    const dispatch = useDispatch();

    const taxas = useSelector(state => state.anuncios.taxas);
    const [isLoading, setIsLoading] = useState(false);

    // Requisição das taxas
    useEffect(() => {
        const fetchTaxas = async () => {
            setIsLoading(true);
            await dispatch(anunciosActions.fetchTaxas({ id: props.peca.id, preco: props.peca.preco }));
            setIsLoading(false);
        };

        fetchTaxas();
    }, [dispatch, props.peca, props.tiposAnuncio]);

    return (
        <Modal
            big
            titulo={`Taxas - ${props.peca.arquetipo.nome}`}
            onClose={props.onClose}>

            {isLoading ? <Spinner /> : (
                <div>
                    <Table header={["Plataforma", "Categoria de anúncio", "Preço da peça", "Frete", "Taxa de venda", "Total", "Você recebe"]}>
                        {taxas.map(taxa =>
                            <Taxa key={taxa.tipoAnuncio.id} taxa={taxa} preco={props.peca.preco} />
                        )}
                    </Table>
                </div>
            )}
        </Modal>
    );
};

export default ModalTaxas;