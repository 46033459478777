export const SWITCH_DARK_MODE = "SWITCH_DARK_MODE";

export const switchColorMode = () => {
    return (dispatch, getState) => {
        let darkMode = !getState().colorPalette.darkMode;
        localStorage.setItem("darkMode", JSON.stringify(darkMode));

        try {
            dispatch({ type: SWITCH_DARK_MODE, darkMode: darkMode });
        } catch (error) {
            console.log(error);
        };
    };
};