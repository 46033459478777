// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as adminActions from "../../../store/actions/admin";
import * as pecasTiposActions from "../../../store/actions/pecas-tipos";

// Componets
import Arquetipo from "../../../components/Admin/ArquetiposDetran/Arquetipo/Arquetipo";
import ArquetipoDetran from "../../../components/Admin/ArquetiposDetran/ArquetipoDetran/ArquetipoDetran";
import ArquetipoSelecionado from "../../../components/Admin/ArquetiposDetran/ArquetipoSelecionado/ArquetipoSelecionado";
import Button from "../../../components/UI/Button/Button";
import Dropdown from "../../../components/UI/Dropdown/Dropdown";
import Input from "../../../components/UI/Input/Input";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import * as formatar from "../../../helpers/formatar";

const ArquetiposDetranScreen = () => {
    const dispatch = useDispatch();

    const arquetiposDetran = useSelector(state => state.admin.arquetiposDetran);
    const arquetipos = useSelector(state => state.pecasTipos.arquetipos);

    const [novoArquetipoDetran, setNovoArquetipoDetran] = useState({ nome: "", arquetipos: [] });
    const [arquetiposFiltrados, setArquetiposFiltrados] = useState([]);

    const [busca, setBusca] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    // Requisição dos arquétipos
    useEffect(() => {
        const fetchArquetipos = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasTiposActions.fetchArquetipos(null, true));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchArquetipos();
    }, [dispatch]);

    // Requisição dos arquétipos do detran
    useEffect(() => {
        const fetchArquetiposDetran = async () => {
            setIsLoading(true);
            try {
                await dispatch(adminActions.fetchArquetiposDetran());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchArquetiposDetran();
    }, [dispatch]);

    // Setando arquetiposFiltrados
    useEffect(() => {
        if (arquetipos.length && formatar.alphaNumeric(busca).length >= 4) {
            let arquetiposSugeridosAtualizados = [];

            const palavras = busca.trim().replace(/\s\s+/g, ' ').split(" ");

            palavras.forEach(palavra => {
                if (palavra.length >= 4)
                    arquetipos.forEach(arquetipo => {
                        if (formatar.alphaNumeric(arquetipo.nome).includes(formatar.alphaNumeric(palavra)))
                            if (!arquetiposSugeridosAtualizados.find(arquetipoSugerido => arquetipoSugerido.id === arquetipo.id))
                                arquetiposSugeridosAtualizados.push({ id: arquetipo.id, nome: arquetipo.nome });
                    });
            });

            if (arquetiposSugeridosAtualizados.length)
                setArquetiposFiltrados([...arquetiposSugeridosAtualizados]);
            else
                setArquetiposFiltrados([...arquetipos]);
        } else if (!busca)
            setArquetiposFiltrados([...arquetipos]);
    }, [arquetipos, busca]);

    const createArquetipoDetran = async () => {
        try {
            await dispatch(adminActions.createArquetipoDetran(novoArquetipoDetran));
            setNovoArquetipoDetran({ nome: "", arquetipos: [] });
            exibirMensagem.success("Arquétipos ligados com sucesso.");
        } catch (error) {
            console.log(error);
        };
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <div className="row">
                <div className="col-10">
                    <Input
                        placeholder="Digite aqui a busca"
                        onChange={value => setBusca(value)} />
                </div>
                <div className="col-2">
                    <Button
                        color="green"
                        onClick={createArquetipoDetran}>Cadastrar</Button>
                </div>
            </div>

            {arquetiposDetran.filter(arquetipoDetran => !arquetipoDetran.arquetipos?.length).length ? (
                <div className="row">
                    <div className="col-12 mb-1">
                        <Dropdown
                            busca
                            returnObject
                            padrao={novoArquetipoDetran.nome || "Nenhum valor selecionado"}
                            list={arquetiposDetran.filter(arquetipoDetran => !arquetipoDetran.arquetipos?.length)}
                            onChange={value => setNovoArquetipoDetran({ ...novoArquetipoDetran, nome: value.nome })}
                        />
                    </div>
                </div>
            ) : null}

            <div className="row">
                <div className="col-12 mb-2" style={{ minHeight: "43px" }}>
                    <div style={{ height: "100%", padding: "3px", border: "1px solid #f8f8f8", borderRadius: "10px" }}>
                        {novoArquetipoDetran.arquetipos.map(arquetipoSelecionado => (
                            <ArquetipoSelecionado
                                key={arquetipoSelecionado.id}
                                onClick={() => setNovoArquetipoDetran({ ...novoArquetipoDetran, arquetipos: [...novoArquetipoDetran.arquetipos.filter(novoArquetipoDetranArquetipo => novoArquetipoDetranArquetipo.id !== arquetipoSelecionado.id)] })}
                            >
                                {arquetipoSelecionado.nome}
                            </ArquetipoSelecionado>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row" style={{ height: "56vh" }}>
                <Table
                    bordered
                    hoverble
                    fixed
                    small
                    header={["ID", "Nome"]}
                >
                    {arquetiposFiltrados.map(arquetipo =>
                        <Arquetipo
                            key={arquetipo.id}
                            arquetipo={arquetipo}
                            highlight={novoArquetipoDetran.arquetipos.find(novoArquetipo => novoArquetipo.id === arquetipo.id) ? true : false}
                            onClick={() => {
                                if (!novoArquetipoDetran.arquetipos.find(novoArquetipo => novoArquetipo.id === arquetipo.id))
                                    setNovoArquetipoDetran({ ...novoArquetipoDetran, arquetipos: [...novoArquetipoDetran.arquetipos, { id: arquetipo.id, nome: arquetipo.nome }] });
                                else
                                    setNovoArquetipoDetran({ ...novoArquetipoDetran, arquetipos: [...novoArquetipoDetran.arquetipos.filter(novoArquetipoDetranArquetipo => novoArquetipoDetranArquetipo.id !== arquetipo.id)] });
                            }}
                        />)}
                </Table>
            </div>

            <div className="row">
                <div className="col-12 py-4" style={{ fontSize: "14px", textAlign: "justify" }}>
                    Esta página foi criada para que se possa fazer ligação entre os arquétipos do Detran e os
                    arquétipos do Unificar. Com isso, pode-se importar a tabela do Detran dos clientes e
                    identificar as peças dela. Para utilizar, basta digitar o nome da peça do Detran na
                    barra de pesquisa e selecionar da tabela os possíveis arquétipos dela. A lista da tabela
                    mudará para tentar encontrar peças com nomes parecidos com o que fora digitado. Depois,
                    basta clicar em Cadastrar. Abaixo estão os arquétipos já identificados.
                </div>
            </div>

            <div className="row">
                <Titulo>Arquétipos Identificados</Titulo>

                <Table bordered header={["Nome", "Opções"]}>
                    {arquetiposDetran.filter(arquetipoDetran => arquetipoDetran.arquetipos?.length).map(arquetipoDetran =>
                        <ArquetipoDetran key={arquetipoDetran.nome} arquetipoDetran={arquetipoDetran} />
                    )}
                </Table>
            </div>
        </div>
    );
};

export default ArquetiposDetranScreen;