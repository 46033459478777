// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as adminActions from "../../../../store/actions/admin";

// Components
import Button from "../../../../components/UI/Button/Button";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import Conta from "../../../../components/Admin/Mensagens/Conta/Conta";
import Dropdown from "../../../../components/UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../../components/UI/Input/Input";
import Table from "../../../../components/UI/Table/Table";
import Textarea from "../../../../components/UI/Textarea/Textarea";
import Titulo from "../../../../components/UI/Titulo/Titulo";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const CadastroMensagensScreen = props => {
    const dispatch = useDispatch();

    const contas = useSelector(state => state.admin.contas);
    const mensagemInitialState = {
        icone: null,
        titulo: "Atenção!",
        corpo: "",
        textoBotao: "Ok",
        caminho: "/inicio",
        quantidade: 2,

        contas: []
    };

    const [isLoading, setIsLoading] = useState(false);
    const [novaMensagem, setNovaMensagem] = useState({ ...mensagemInitialState });

    // Requisição das contas
    useEffect(() => {
        const fetchContas = async () => {
            setIsLoading(true);
            try {
                await dispatch(adminActions.fetchContas());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchContas();
    }, [dispatch]);

    const createMensagem = async () => {
        let formIsValid = true;

        if (!novaMensagem.contas.length) {
            exibirMensagem.warning("Você precisa informar pelo menos um usuário para cadastrar uma mensagem.");
            formIsValid = false;
        };

        if (formIsValid) {
            setIsLoading(true);

            try {
                await dispatch(adminActions.createMensagem(novaMensagem));
                exibirMensagem.success("Mensagem cadastrada com sucesso!");
                props.history.push("/mensagens");
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Mensagens para Contas</Titulo>

            <div className="row mb-1">
                <div className="col-4 text-center">
                    <Dropdown
                        alignCenter
                        list={[
                            { id: null, nome: "Sem ícone" },
                            { id: "warning", nome: "Aviso" },
                            { id: "info", nome: "Informação" },
                            { id: "error", nome: "Erro" },
                            { id: "success", nome: "Sucesso" },
                            { id: "question", nome: "Pergunta" },
                        ]}
                        onChange={icon => setNovaMensagem({ ...novaMensagem, icone: icon })}
                    />
                </div>

                <div className="col-8 text-right">
                    <Button
                        color="green"
                        onClick={() => {
                            exibirMensagem.exibir({
                                icon: novaMensagem.icone,
                                title: novaMensagem.titulo,
                                text: novaMensagem.corpo,
                                allowOutsideClick: false,
                                confirmButtonText: novaMensagem.textoBotao || "Ok"
                            })
                        }}
                    >Experimentar</Button>
                </div>

                <div className="col-12 h3 text-center">
                    <Input
                        alignCenter
                        size="80%"
                        placeholder="Título da mensagem"
                        value={novaMensagem.titulo}
                        maxLength="20"
                        onChange={value => setNovaMensagem({ ...novaMensagem, titulo: value })}
                    />
                </div>

                <div className="col-12 mb-4">
                    <Textarea
                        rows="6"
                        maxLength="255"
                        placeholder="Digite aqui o corpo da mensagem."
                        onChange={value => setNovaMensagem({ ...novaMensagem, corpo: value })}
                    />
                </div>

                <div className="col-12 text-center">
                    <Input
                        alignCenter
                        size="200px"
                        placeholder="Texto do botão"
                        value={novaMensagem.textoBotao}
                        maxLength="20"
                        onChange={value => setNovaMensagem({ ...novaMensagem, textoBotao: value })}
                    />
                </div>
            </div>

            <hr />

            <div className="row mb-5 text-right">
                <div className="col-12">
                    Exibir
                    <div style={{ display: "inline-block", width: "45px", margin: "0 5px" }}>
                        <Input
                            alignCenter
                            onlyNumbers
                            value={novaMensagem.quantidade}
                            onChange={value => setNovaMensagem({ ...novaMensagem, quantidade: parseInt(value) || null })}
                        />
                    </div>
                    vez{novaMensagem.quantidade === 1 ? "" : "es"}.
                    <span style={{ color: "gray" }}> (Deixe em branco ou 0 para sempre exibir).</span>
                </div>
                <div className="col-12">
                    Exibir em:
                    <div style={{ display: "inline-block", width: "387px", margin: "0 0 0 5px" }}>
                        <Input
                            rota
                            placeholder="Caminho da página"
                            value={novaMensagem.caminho}
                            onChange={value => setNovaMensagem({ ...novaMensagem, caminho: value })}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <h4>Selecione quais contas receberão a mensagem</h4>
                </div>
                <div className="col-4 text-right">
                    <Checkbox
                        size="small"
                        onChange={value => {
                            if (value)
                                setNovaMensagem({ ...novaMensagem, contas: contas.map(conta => { return { id: conta.id, nome: conta.nome } }) });
                            else
                                setNovaMensagem({ ...novaMensagem, contas: [] });
                        }}
                    >Todas as contas</Checkbox>
                </div>
            </div>

            <div className="row mb-5" style={{ height: "60vh" }}>
                <Table
                    fixed
                    bordered
                    hoverble
                    header={["ID", "Conta", "Tipo"]}
                >
                    {contas.map(conta => (
                        <Conta
                            key={conta.id}
                            conta={conta}
                            highlight={novaMensagem.contas.find(contaSelecionada => contaSelecionada.id === conta.id) ? true : false}
                            onClick={() => {
                                if (novaMensagem.contas.find(contaSelecionada => contaSelecionada.id === conta.id))
                                    setNovaMensagem({ ...novaMensagem, contas: [...novaMensagem.contas.filter(contaSelecionada => contaSelecionada.id !== conta.id)] });
                                else
                                    setNovaMensagem({ ...novaMensagem, contas: [...novaMensagem.contas, { id: conta.id, nome: conta.nome }] });
                            }}
                        />
                    ))}
                </Table>
            </div>

            <div className="row">
                <div className="col-12 text-center">
                    <Button color="green" onClick={createMensagem}>Cadastrar Mensagem</Button>
                </div>
            </div>
        </div>
    );
};

export default CadastroMensagensScreen;