// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./BottomNavbar.module.css";

// Components
import Button from "../../UI/Button/Button";

const CadastroNavigator = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    return (
        <div className={darkMode ? classes.CadastroNavigatorDark : classes.CadastroNavigatorLight}>
            <div className="row">
                <div className="col-6 text-left">
                    {props.onAntClick ? (
                        <Button color="red" onClick={props.onAntClick}> Anterior </Button>
                    ) : null}
                </div>
                {props.onProxClick ? (
                    <div className="col-6 text-right">
                        <Button color="green" onClick={props.onProxClick}> Próximo </Button>
                    </div>
                ) : null}
            </div>
        </div >
    );
};

export default CadastroNavigator;
