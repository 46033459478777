import {
    FETCH_ANUNCIOS_MERCADO_LIVRE,
    FETCH_ANUNCIOS_REVISAO_MERCADO_LIVRE,
    FETCH_STATUS_MERCADO_LIVRE_CREATE_ANUNCIOS,
    FETCH_STATUS_MERCADO_LIVRE_CREATE_PECAS,
    CREATE_PECA_MERCADO_LIVRE,
    DELETE_ANUNCIOS_MERCADO_LIVRE
} from "../actions/mercado-livre";

const initialState = {
    anuncios: [],
    anunciosRevisao: [],
    statusCreateAnucios: {},
    statusCreatePecas: {}
};

const mercadoLivreReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANUNCIOS_MERCADO_LIVRE:
            return { ...state, anuncios: action.anuncios };
        case FETCH_ANUNCIOS_REVISAO_MERCADO_LIVRE:
            return { ...state, anunciosRevisao: action.anunciosRevisao };
        case FETCH_STATUS_MERCADO_LIVRE_CREATE_ANUNCIOS:
            return { ...state, statusCreateAnucios: action.status };
        case FETCH_STATUS_MERCADO_LIVRE_CREATE_PECAS:
            return { ...state, statusCreatePecas: action.status };
        case CREATE_PECA_MERCADO_LIVRE:
            let anunciosAtualizados = [...state.anuncios];
            anunciosAtualizados = anunciosAtualizados.filter(anuncio => anuncio.id !== action.id)
            return { ...state, anuncios: anunciosAtualizados };
        case DELETE_ANUNCIOS_MERCADO_LIVRE:
            return { ...state, anuncios: [] };
        default:
            return state;
    }
};

export default mercadoLivreReducer;
