// Dependências
import React from "react";

// Css
import classes from "./Arquetipo.module.css";

const Arquetipo = props => {
    return (
        <tr className={props.highlight ? classes.Selected : null} onClick={props.onClick}>
            <td>{props.arquetipo.id}</td>
            <td>{props.arquetipo.nome}</td>
        </tr>
    );
};

export default Arquetipo;