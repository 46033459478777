// Dependências
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const GraficoLinha = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    const [dataSets, setDataSets] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        let newDataSets = [];

        if (props.dadosCategorias) {
            newDataSets = props.dadosCategorias.map(categoria => {
                return {
                    label: categoria.nome,
                    data: categoria.dadosMeses.map(dadoMes => dadoMes.valor),
                    borderColor: categoria.cor,
                    lineTension: 0,
                    fill: false,
                    pointBackgroundColor: "transparent",
                    pointBorderColor: "transparent",
                };
            });
        };

        if (props.dadosMeses) {
            newDataSets = [{
                label: "Total",
                data: props.dadosMeses.map(dadoMes => dadoMes.valor),
                borderColor: "#c34858",
                lineTension: 0,
                fill: false,
                pointBackgroundColor: "transparent",
                pointBorderColor: "transparent",
            }];
        };

        setDataSets(newDataSets);
    }, [props.dadosCategorias, props.dadosMeses]);

    useEffect(() => {
        let newLabels = [];

        if (props.dadosCategorias)
            newLabels = props.dadosCategorias[0].dadosMeses.map(dadoMes => formatar.data(dadoMes.data, true, true));

        if (props.dadosMeses)
            newLabels = props.dadosMeses.map(dadoMes => formatar.data(dadoMes.data, true, true));

        setLabels(newLabels);
    }, [props.dadosCategorias, props.dadosMeses]);

    return dataSets.length ? (
        <Line
            height={120}
            data={{
                labels: labels,
                datasets: [...dataSets]
            }}
            options={{
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        boxWidth: 30,
                        fontFamily: "Quicksand",
                        fontColor: darkMode ? "#f8f9fa" : "#000",
                    }
                },
                title: {
                    display: true,
                    text: props.title ? props.title : "",
                    fontSize: 24,
                    fontFamily: "Quicksand",
                }
            }}
        />
    ) : null;
};

export default GraficoLinha;
