// Dependêcias
import React from "react";
import { withRouter } from "react-router-dom";

// Css
import classes from "./GrupoSucatas.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";
import getEtapaCadastroGrupoPecas from "../../../helpers/getEtapaCadastroGrupoPecas";

const Sucata = props => {
    return (
        <tr
            className={props.grupoSucatas.status.id === 4 ? classes.Purple : props.grupoSucatas.status.id === 12 ? classes.Green : ""}
            onClick={() => props.history.push(getEtapaCadastroGrupoPecas(props.grupoSucatas.status.id, props.grupoSucatas.id))}
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td>{props.grupoSucatas.id}</td>
            <td>{props.grupoSucatas.nome || "-"}</td>
            <td>{props.grupoSucatas.quantidade}</td>
            <td>{formatar.data(props.grupoSucatas.dataCadastro)}</td>
            <td>{props.grupoSucatas.status.nome}</td>
        </tr>
    );
};

export default withRouter(Sucata);