// Dependências
import React from "react";
import { withRouter } from "react-router-dom";

// Css
import classes from './Venda.module.css';

// Helpers
import * as formatar from '../../../helpers/formatar';

const Venda = props => {
    return (
        <tr onClick={() => props.venda.plataforma.urlBase ? window.open(props.venda.plataforma.urlBase, "_blank") : props.history.push(`/estoque/pecas/${props.venda.peca.id}`)}>

            <td> <img className={classes.Imagem} src={props.venda.plataforma.imagem} alt="" /> </td>

            <td className="text-left"> {props.venda.peca.arquetipo.nome || props.venda.anuncio.titulo} </td>

            <td className={props.venda.sucata.fipe.modelo.nome || props.venda.sucata.fipe.veiculo.nome ? "text-left" : "text-center"}>
                {props.venda.sucata.fipe.modelo.nome || props.venda.sucata.fipe.veiculo.nome || "-"}
            </td>

            {props.accessVendas ? <td className="text-nowrap"> {formatar.data(props.venda.data, false)} </td> : null}

            {props.accessVendas ? (
                <td className="text-nowrap" style={{ backgroundColor: "#79cd5615" }}>
                    {formatar.dinheiro(props.venda.valor, false)}
                </td>
            ) : null}

            <td className="text-nowrap" style={{ backgroundColor: "#79cd5615" }}>
                {props.venda.receita ? formatar.dinheiro(props.venda.receita, false) : '-'}
            </td>

            {props.accessVendas ? <td> {props.venda.cliente.nome} </td> : null}
        </tr >
    );
};

export default withRouter(Venda);
