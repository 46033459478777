// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as despesasActions from "../../../../store/actions/despesas";
import * as sucatasActions from "../../../../store/actions/sucatas";
import * as pecasActions from "../../../../store/actions/pecas";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import * as validar from "../../../../helpers/validar";

// Components
import BarraPesquisa from "../../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../../components/UI/Button/Button";
import ContextMenu from "../../../../components/UI/ContextMenu/ContextMenu";
import EtiquetasPDF from "../../../../components/Estoque/Sucata/EtiquetasPDF/EtiquetasPDF";
import Despesa from "../../../../components/Estoque/Sucata/Despesa/Despesa";
import Grafico from "../../../../components/Estoque/Sucata/Grafico/Grafico";
import GraficoPizza from "../../../../components/UI/GraficoPizza/GraficoPizza";
import ModalCreatePeca from "../../../../components/Estoque/Sucata/ModalCreatePeca/ModalCreatePeca";
import NovaDespesa from "../../../../components/Estoque/Sucata/NovaDespesa/NovaDespesa";
import Peca from "../../../../components/Estoque/Sucata/Peca/Peca";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Table from "../../../../components/UI/Table/Table";
import Titulo from "../../../../components/UI/Titulo/Titulo";
import EtiquetasPDFSmall from "../../../../components/Estoque/Sucata/EtiquetasPDFSmall/EtiquetasPDFSmall";

const SucataScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;

    const categorias = useSelector(state => state.despesas.categorias);
    const sucata = useSelector(state => state.sucatas.sucata);
    const usuario = useSelector(state => state.usuario.meuUsuario);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [busca, setBusca] = useState("");
    const [isDespesaLoading, setIsDespesaLoading] = useState(false);
    const [showNovaDespesa, setShowNovaDespesa] = useState(false);
    const [novaDespesa, setNovaDespesa] = useState({});
    const [copiaPecas, setCopiaPecas] = useState([]);
    const [isDespesaValid, setIsDespesaValid] = useState({ nome: true, data: true, valor: true });

    const [showModalCreatePeca, setShowModalCreatePeca] = useState(false);

    const [idPecaContextMenu, setIdPecaContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição das sucatas
    useEffect(() => {
        const fetchSucata = async () => {
            setIsLoading(true);

            try {
                await dispatch(sucatasActions.fetchSucata(idSucata));
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
            setIsRequestCompleted(true);
        };
        fetchSucata();
    }, [dispatch, idSucata]);

    // Requisição das categorias de despesas
    useEffect(() => {
        const fetchCategorias = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchCategorias());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchCategorias();
    }, [dispatch]);

    // Setando novaDespesa
    useEffect(() => {
        if (categorias.length) {
            setNovaDespesa({ subcategoria: { id: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].id, nome: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].nome }, nome: "", data: dataHoje(), valor: "" });
        };
    }, [categorias]);

    // Setando copia pecas (lidando com busca)
    useEffect(() => {
        if (isRequestCompleted && sucata.pecas?.length) {
            if (busca)
                setCopiaPecas([...sucata.pecas].filter(peca => formatar.alphaNumeric(peca.arquetipo.nome).includes(formatar.alphaNumeric(busca))));
            else
                setCopiaPecas([...sucata.pecas]);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequestCompleted, busca]);

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idPeca) => {
        event.preventDefault();

        setIdPecaContextMenu(idPeca);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const createDespesa = () => {
        let formIsValid = true;
        let copiaIsDespesaValid = { ...isDespesaValid };

        if (validar.preco(novaDespesa.valor))
            copiaIsDespesaValid.valor = true;
        else {
            copiaIsDespesaValid.valor = false;
            formIsValid = false;
        };

        if (validar.data(novaDespesa.data))
            copiaIsDespesaValid.data = true;
        else {
            copiaIsDespesaValid.data = false;
            formIsValid = false;
        };

        setIsDespesaValid(copiaIsDespesaValid);

        if (formIsValid) {
            const adicionarGasto = async () => {
                setIsDespesaLoading(true);

                try {
                    await dispatch(despesasActions.createDespesasSucata(parseInt(idSucata), {
                        subcategoria: novaDespesa.subcategoria,
                        nome: novaDespesa.nome,
                        valor: parseFloat(novaDespesa.valor),
                        data: novaDespesa.data,
                    }));
                    setShowNovaDespesa(false);
                    setNovaDespesa({ subcategoria: { id: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].id, nome: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].nome }, nome: "", data: dataHoje(), valor: "" });
                } catch (error) {
                    console.log(error);
                }
                setIsDespesaLoading(false);
            };
            adicionarGasto();
        } else
            exibirMensagem.formInvalid();
    };

    const deleteDespesa = (despesa) => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir a despesa "${despesa.nome}"? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(despesasActions.deleteDespesa(despesa.id));
                    exibirMensagem.success("A despesa foi deletada com sucesso.");
                } catch (error) {
                    console.log(error);
                };
            } else
                return;
        });
    };

    const deleteSucata = async () => {
        exibirMensagem.warning({
            title: "Tem Certeza?",
            text: "Você deseja excluir permanentemente esta sucata?",
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir!",
        }).then(async (result) => {
            if (result.value) {
                try {
                    await dispatch(sucatasActions.deleteSucata(sucata.id));
                    exibirMensagem.success("A sucata foi deletada com sucesso.");
                    props.history.push(`/estoque`);
                } catch (error) {
                    exibirMensagem.error({
                        timer: "2000",
                        showConfirmButton: false,
                    });
                };
            };
        });
    };

    const deletePeca = async idPeca => {
        exibirMensagem.warning({
            title: "Tem Certeza?",
            text: "Você deseja excluir permanentemente esta peça?",
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir!",
        }).then(async (result) => {
            if (result.value) {
                try {
                    await dispatch(pecasActions.deletePeca(idPeca));
                    exibirMensagem.success("A peça foi deletada com sucesso.");
                } catch (error) {
                    exibirMensagem.error({
                        timer: "2000",
                        showConfirmButton: false,
                    });
                };
            };
        });
    };

    return isLoading || !sucata.id || !isRequestCompleted ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo subtitulo={`Cód. ${sucata.id}`}>{sucata.fipe.marca.nome} {sucata.fipe.modelo.nome || sucata.fipe.veiculo.nome} </Titulo>

            {showModalCreatePeca ? (
                <ModalCreatePeca
                    idSucata={sucata.id}
                    display={showModalCreatePeca}
                    onRedirect={route => props.history.push(route)}
                    onClose={() => setShowModalCreatePeca(false)} />
            ) : null}

            {usuario.permissoes.find(permissao => permissao.id === 3) && sucata.pecas.filter(peca => peca.status.id === 3).length ? (
                <div className="row mb-4">
                    <div className="col-4">
                        {/* Gráfico de valor das peças vendidas e preço das peças cadastradas */}
                        <GraficoPizza
                            title="Valor das Peças"
                            labels={["Valor em estoque (R$)", "Valor vendido (R$)"]}
                            data={[
                                sucata.pecas.filter(peca => peca.status.id !== 3).map(peca => peca.preco).reduce((a, b) => a + b, 0),
                                sucata.pecas.filter(peca => peca.status.id === 3).map(peca => peca.venda.valor).reduce((a, b) => a + b, 0)
                            ]}
                        />
                    </div>

                    <div className="col-4">
                        {/* Gráfico de quantidade de vendas por plataforma */}
                        <GraficoPizza
                            title="Locais de Venda"
                            labels={["Mundo da Peça", "Loja Física", "Mercado Livre"]}
                            data={[
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 1).length,
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 3).length,
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 2).length
                            ]}
                        />
                    </div>

                    <div className="col-4">
                        {/* Gráfico de valor de vendas por plataforma */}
                        <GraficoPizza
                            title="Valor de vendas por Plataforma"
                            labels={["Mundo da Peça (R$)", "Loja Física (R$)", "Mercado Livre (R$)"]}
                            data={[
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 1).map(peca => peca.venda.valor).reduce((a, b) => a + b, 0),
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 3).map(peca => peca.venda.valor).reduce((a, b) => a + b, 0),
                                sucata.pecas.filter(peca => peca.status.id === 3 && peca.venda?.plataforma?.id === 2).map(peca => peca.venda.valor).reduce((a, b) => a + b, 0)
                            ]}
                        />
                    </div>
                </div>
            ) : null}

            <div className="row mb-3">
                <Table
                    small
                    header={["Data de cadastro", "Placa", "RENAVAM", "Nº Chassi", "Nº Motor", "Nº Câmbio"]}>
                    <tr>
                        <td> {sucata.dataCriacao ? formatar.data(sucata.dataCriacao) : "-"} </td>
                        <td> {sucata.placa || "-"} </td>
                        <td> {sucata.renavam || "-"} </td>
                        <td> {sucata.chassi || "-"} </td>
                        <td> {sucata.motor || "-"} </td>
                        <td> {sucata.cambio || "-"} </td>
                    </tr>
                </Table>
            </div>

            {!usuario.permissoes.find(permissao => permissao.id === 3) ? null : (
                <div className="row mb-4">
                    {sucata.status.editavel ? <Titulo>Despesas com a sucata</Titulo> : null}

                    {sucata.financeiro?.vendas?.length > 1 ? (
                        <div className="col-12 mb-5">
                            <Grafico
                                vendas={[...sucata.financeiro.vendas]}
                                payback={sucata.financeiro.vendas.map(venda => { return { valor: sucata.financeiro.payback, data: venda.data } })}
                                lucroMaximo={sucata.financeiro.vendas.map(venda => { return { valor: sucata.financeiro.lucroMaximo, data: venda.data } })} />
                        </div>
                    ) : null}

                    {(sucata.despesas.length || showNovaDespesa) ? (
                        <Table header={["Tipo", "Data", "Valor"]}>
                            {sucata.despesas.map(despesa => (
                                <Despesa
                                    key={despesa.id}
                                    despesa={despesa}
                                    onDelete={() => deleteDespesa({ ...despesa })}
                                />
                            ))}

                            {showNovaDespesa && sucata.status.editavel ? (
                                <NovaDespesa
                                    despesa={novaDespesa}
                                    isValid={isDespesaValid}
                                    subcategorias={categorias.filter(categoria => categoria.id === 5)[0].subcategorias}
                                    onDelete={() => {
                                        setShowNovaDespesa(false);
                                        setNovaDespesa({ subcategoria: { id: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].id, nome: categorias.filter(categoria => categoria.id === 5)[0].subcategorias[0].nome }, nome: "", data: dataHoje(), valor: "" });
                                    }}
                                    onChange={(value, key) => {
                                        let copiaNovaDespesa = { ...novaDespesa };
                                        copiaNovaDespesa[key] = value;
                                        setNovaDespesa(copiaNovaDespesa);
                                    }}
                                />
                            ) : null}

                            {sucata.status.editavel ?
                                <tr>
                                    <td colSpan="10" className="text-center">
                                        {isDespesaLoading ? (
                                            <Spinner />
                                        ) : showNovaDespesa ? (
                                            <Button color="green" onClick={createDespesa}>Salvar</Button>
                                        ) : (<Button color="green" onClick={() => setShowNovaDespesa(true)}>Cadastrar despesa</Button>)}
                                    </td>
                                </tr> : null}
                        </Table>
                    ) : sucata.status.editavel ? <div className="col-12 text-center">
                        <h5 className="mb-3">Nenhuma despesa registrada, clique no botão para registrar uma despesa.</h5>
                        <Button color="green" onClick={() => setShowNovaDespesa(true)}>Cadastrar despesa</Button>
                    </div> : null}
                </div>
            )}

            <div className="row mb-5">
                <Titulo> Peças da sucata ({sucata.pecas.length}) </Titulo>

                <div className="col-4">
                    <BarraPesquisa
                        busca={busca}
                        onChange={novaBusca => setBusca(novaBusca)} />
                </div>

                <div className="col-2 text-center">
                    {sucata.pecas.length < 449 ? (
                        <Button color="green" onClick={() => setShowModalCreatePeca(true)}>
                            Nova Peça
                        </Button>
                    ) : null}
                </div>

                {sucata.pecas.length ? (
                    <div className="col-2 text-center">
                        <EtiquetasPDF
                            sucata={sucata}
                            pecas={sucata.pecas} />
                    </div>
                ) : null}

                {sucata.pecas.length ? (
                    <div className="col-2 text-center">
                        <EtiquetasPDFSmall
                            sucata={sucata}
                            pecas={sucata.pecas} />
                    </div>
                ) : null}

                <div className="col-2 text-center">
                    <Button onClick={() => props.history.push(`/estoque/sucatas/${idSucata}/fotos`)}>
                        Editar fotos
                    </Button>
                </div>

                {copiaPecas.length ? (
                    <Table
                        hoverble
                        header={["Cód.", "Nome", "Preço", "Status"]}>
                        {copiaPecas.map(peca =>
                            <Peca
                                key={peca.id}
                                peca={peca}
                                accessVendas={usuario.permissoes.find(permissao => permissao.id === 5) ? true : false}
                                onContextMenu={event => handleContextMenuClick(event, peca.id)}
                            />
                        )}
                    </Table>
                ) : (
                    <div className="col-12 text-center">
                        <h5>Nenhuma peça foi encontrada.</h5>
                    </div>
                )}

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    absolute
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => deletePeca(idPecaContextMenu)}>Excluir</li>
                </ContextMenu>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-right">
                    <Button color="red" onClick={deleteSucata}>
                        Excluir Sucata
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SucataScreen;
