// Dependências
import React from "react";

// Css
import classes from "./Subcategoria.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Subcategoria = props => {
    return props.dados ? props.dados.map(dado => dado.valor).reduce((a, b) => a + b, 0) > 0 ? (
        <tr className={`${props.total ? classes.Total : ''} ${props.somatoria ? classes.Somatoria : ''}`} onClick={props.onClick}>
            <td className={classes.Subcategoria}>{props.nome}</td>
            {props.dados.map(dado => (
                <td
                    key={dado.data}
                    className={classes.DadoMensal}>
                    {formatar.dinheiro(dado.valor, true, false)}
                </td>
            ))}
        </tr>
    ) : null : null;
};

export default Subcategoria;