// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import DropZone from "../../../UI/DropZone/DropZone";
import Titulo from "../../../UI/Titulo/Titulo";
import Spinner from "../../../UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const FotosSucata = props => {
    const dispatch = useDispatch();

    const fotosAreas = useSelector(state => state.sucatas.fotosAreas);

    const [categorias, setCategorias] = useState([]);
    const [copiaFotosTipo, setCopiaFotosTipo] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [countClick, setCountClick] = useState(0);
    const [showTip, setShowTip] = useState(true);

    // Requisição dos tipos de foto
    useEffect(() => {
        const fetchFotosAreas = async () => {
            try {
                await dispatch(sucatasActions.fetchFotosAreas());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };


        if (!fotosAreas.length)
            fetchFotosAreas();
    }, [dispatch, fotosAreas]);

    // Setando categorias
    useEffect(() => {
        if (fotosAreas.length) {
            let novasCategorias = [];

            fotosAreas.forEach(fotoArea => {
                if (!novasCategorias.find(novaCategoria => novaCategoria.id === fotoArea.categoria?.id))
                    novasCategorias.push({ ...fotoArea.categoria });
            });

            setCategorias(novasCategorias);
        };
    }, [dispatch, fotosAreas]);

    // Setando copiaFotosTipo
    useEffect(() => {
        if (fotosAreas.length) {
            setCopiaFotosTipo(fotosAreas.map(fotoArea => {
                return {
                    id: fotoArea.id,
                    nome: fotoArea.nome,
                    categoria: fotoArea.categoria,
                    maximoPorSucata: fotoArea.maximoPorSucata,
                    files: props.fotos.find(foto => foto.id === fotoArea.id) ? props.fotos.find(foto => foto.id === fotoArea.id).fotos : [],
                };
            }));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fotosAreas]);

    // Retorna as fotos sempre que mudar
    useEffect(() => {
        let fotosAreasSelecionadas = [...copiaFotosTipo].filter(fotoArea => fotoArea.files.length);

        if (fotosAreasSelecionadas.length) {
            props.onChange(fotosAreasSelecionadas.map(foto => {
                return {
                    id: foto.id,
                    fotos: [...foto.files],
                };
            }));
        } else
            props.onChange([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copiaFotosTipo]);

    // Exibe dica
    useEffect(() => {
        if (countClick > 4 && showTip) {
            exibirMensagem.tip("Você não precisa clicar em cada ângulo para adicionar as fotos. Selecione as fotos desejadas diretamente na pasta e arraste para cima do quadrado do ângulo desejado.");
            setShowTip(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countClick]);

    const submitHandler = () => {
        if (props.fotos.length) {
            if (props.onProxClick)
                props.onProxClick();
        } else {
            exibirMensagem.warning("Por favor, adicione ao menos uma foto da sucata para prosseguir.");
        };
    };

    return isLoading || !copiaFotosTipo.length ? <Spinner /> : (
        <div className="container-fluid pb-5" onClick={() => setCountClick(countClick + 1)}>
            <Titulo>Selecione as fotos da sucata</Titulo>

            <div className="row">
                <div className="col-12 pb-4" style={{ fontSize: "16px" }}>
                    <span>Insira os ângulos corretamente, pois cada peça utilizará os ângulos pré-determinados para o anúncio.</span>
                    <p>Ex: A peça  <strong>CAPÔ</strong> utilizará as <strong>quatro</strong> fotos da parte <strong>FRONTAL</strong>.</p>
                </div>
            </div>

            {categorias.map(categoria =>
                <div key={categoria.id} className="row pb-3">
                    <Titulo>{categoria.nome}</Titulo>
                    {copiaFotosTipo.filter(fotoTipo => fotoTipo.categoria.id === categoria.id).map(fotoArea =>
                        <div key={fotoArea.id} className="col-3 mb-2" style={{ height: "200px" }}>
                            <DropZone
                                src={fotoArea.files.map(file => file.src)}
                                accept={["jpg", "jpeg", "png"]}
                                title={fotoArea.nome}
                                imageTitle={fotoArea.nome}
                                quantidade={fotoArea.maximoPorSucata}
                                onDelete={indexDelete => {
                                    let copiaFotosTipoAtualizado = [...copiaFotosTipo].map(fotoArea => { return { ...fotoArea } });

                                    copiaFotosTipoAtualizado.find(fotoAreaAtualizado =>
                                        fotoAreaAtualizado.id === fotoArea.id).files.splice(indexDelete, 1);

                                    setCopiaFotosTipo(copiaFotosTipoAtualizado);
                                }}
                                onChange={filesNovos => {
                                    let copiaFotosTipoAtualizado = [...copiaFotosTipo].map(fotoArea => { return { ...fotoArea } });

                                    let copiaFotoTipoAtualizado = { ...copiaFotosTipoAtualizado.find(fotoAreaAtualizado => fotoAreaAtualizado.id === fotoArea.id) };

                                    filesNovos.forEach(fileNovo => {
                                        copiaFotoTipoAtualizado.files.push({
                                            ...fileNovo, atrelamento: JSON.stringify({
                                                id: null,
                                                nome: "sucata",
                                                tipo: {
                                                    id: fotoArea.id,
                                                    nome: fotoArea.nome
                                                }
                                            })
                                        });
                                    });

                                    copiaFotosTipoAtualizado.map(fotoAreaAtualizado => fotoAreaAtualizado.id === fotoArea.id ? copiaFotoTipoAtualizado : fotoAreaAtualizado);

                                    setCopiaFotosTipo(copiaFotosTipoAtualizado);
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div >
    );
};

export default FotosSucata;