// Dependências
import React from "react";
import { withRouter } from "react-router-dom";

// Components
import ColLink from "../../../components/UI/ColLink/ColLink";

// Css
import classes from "./Sucata.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Sucata = (props) => {
    const sucataScreenLocation = `/estoque/sucatas/${props.sucata.id}`;

    return (
        <tr onContextMenu={event => props.onContextMenu(event)}>
            <ColLink to={sucataScreenLocation}>{props.sucata.id}</ColLink>
            <ColLink to={sucataScreenLocation}>
                <img
                    className={classes.LogoMarca}
                    src={`/images/logos-marcas/${formatar.alphaNumeric(props.sucata.fipe.marca.nome)}.png`}
                    title={props.sucata.fipe.marca.nome}
                    alt={props.sucata.fipe.marca.nome}
                />
            </ColLink>
            <ColLink to={sucataScreenLocation} className="text-left">{props.sucata.fipe.modelo.nome || props.sucata.fipe.veiculo.nome}</ColLink>
            <ColLink to={sucataScreenLocation}>{props.sucata.fipe.ano.nome ? props.sucata.fipe.ano.nome.split(' ')[0] : "-"}</ColLink>
            <ColLink to={sucataScreenLocation}>{props.sucata.cor}</ColLink>
            <ColLink to={sucataScreenLocation}>{props.sucata.placa}</ColLink>
            <ColLink to={sucataScreenLocation}>{formatar.data(props.sucata.dataCriacao)}</ColLink>
        </tr>
    );
};

export default withRouter(Sucata);
