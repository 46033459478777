// Dependêcias
import React from "react";
import { withRouter } from "react-router-dom";

// Helpers
import * as formatar from "../../../helpers/formatar";

const GrupoSucatas = props => {
    return (
        <tr
            onClick={() => props.history.push(`/estoque/grupo-sucatas/${props.grupoSucatas.id}`)}
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td>{props.grupoSucatas.id}</td>
            <td>{props.grupoSucatas.nome || "-"}</td>
            <td>{props.grupoSucatas.quantidade}</td>
            <td>{formatar.data(props.grupoSucatas.dataCadastro)}</td>
        </tr>
    );
};

export default withRouter(GrupoSucatas);