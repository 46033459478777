// Dependências
import React, { useEffect, useState } from "react";
import { useSelector, /*useDispatch*/ } from "react-redux";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import axios from 'axios';
import { API_URL } from "../../constants/GlobalConfig";

// Css
import classes from "./RegistrarScreen.module.css";

// Actions
// import * as authActions from "../../store/actions/auth";

// Helpers
import statusHandler from "../../helpers/statusHandler";
import * as validar from "../../helpers/validar";

// Components
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import Input from "../../components/UI/Input/Input";
import Button from "../../components/Conhecer/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import Titulo from "../../components/UI/Titulo/Titulo";

const RegistrarScreen = props => {
    // const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const cargos = [
        { id: null, nome: "Selecione cargo:" },
        { id: 1, nome: "Sócio/Proprietário" },
        { id: 2, nome: "Gerente" },
        { id: 3, nome: "Administrator da conta no Mercado Livre" },
        { id: 4, nome: "Vendedor (Balcão)" },
        { id: 5, nome: "Outro" },
    ]

    // States dos campos
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    const [nomeFantasia, setNomeFantasia] = useState("");
    const [cnpj, setCnpj] = useState(false);
    const [telefone, setTelefone] = useState(false);
    const [nomeResponsavel, setNomeResponsavel] = useState("");
    const [cargo, setCargo] = useState("Selecione cargo:");
    const [vendeOnline, setVendeOnline] = useState(false);
    const [quantidadeAnuncios, setQuantidadeAnuncios] = useState(false);

    // States de Invalids
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [senhaInvalid, setSenhaInvalid] = useState(false);
    const [confirmarSenhaInvalid, setConfirmarSenhaInvalid] = useState(false);
    const [nomeFantasiaInvalid, setNomeFantasiaInvalid] = useState(false);
    const [cargoInvalid, setCargoInvalid] = useState(false);
    /* const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [telefoneInvalid, setTelefoneInvalid] = useState(false);
    const [nomeResponsavelInvalid, setNomeResponsavelInvalid] = useState(false);
    const [quantidadeAnunciosInvalid, setQuantidadeAnunciosInvalid] = useState(false); */

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [ocultarSenha, setOcultarSenha] = useState(true);
    const [ocultarConfirmarSenha, setOcultarConfirmarSenha] = useState(true);
    const [lembreDeMim, setLembreDeMim] = useState(true);

    useEffect(() => {
        if (isAuthenticated) props.history.push('/visao-geral');
    }, [isAuthenticated, props.history]);

    const handleSubmit = async event => {
        event.preventDefault();
        let formIsValid = true;

        if (validar.email(email)) setEmailInvalid(false);
        else {
            setEmailInvalid(true);
            formIsValid = false;
            setErrorMessage("E-mail inválido");
        };

        if (senha === confirmarSenha) setConfirmarSenhaInvalid(false);
        else {
            setConfirmarSenhaInvalid(true);
            formIsValid = false;
            setErrorMessage("Senhas divergentes");
        };

        if (senha.length >= 4) setSenhaInvalid(false);
        else {
            setSenhaInvalid(true);
            formIsValid = false;
            setErrorMessage("Senha é curta demais");
        };

        if (validar.nome(nomeFantasia)) setNomeFantasiaInvalid(false);
        else {
            setNomeFantasiaInvalid(true);
            formIsValid = false;
            setErrorMessage("Nome fantasia inválido");
        };

        // if ((!vendeOnline) || (vendeOnline && quantidadeAnuncios) >= 1) setQuantidadeAnunciosInvalid(false);
        // else {
        //     setQuantidadeAnunciosInvalid(true);
        //     formIsValid = false;
        //     setErrorMessage("Quantidade de anúncios inválido");
        // };

        // if (validar.cnpj(cnpj)) setCnpjInvalid(false);
        // else {
        //     setCnpjInvalid(true);
        //     formIsValid = false;
        //     setErrorMessage("CNPJ Inválido");
        // };

        // if (validar.telefone(telefone) && telefone) setTelefoneInvalid(false);
        // else {
        //     setTelefoneInvalid(true);
        //     formIsValid = false;
        //     setErrorMessage("Telefone Inválido");
        // };

        // if (validar.nome(nomeResponsavel)) setNomeResponsavelInvalid(false);
        // else {
        //     setNomeResponsavelInvalid(true);
        //     formIsValid = false;
        //     setErrorMessage("Nome inválido");
        // };

        if (cargo && cargo !== 'Selecione cargo:') setCargoInvalid(false);
        else {
            setCargoInvalid(true);
            formIsValid = false;
            setErrorMessage("Cargo inválido");
        }

        if (formIsValid) {
            setIsLoading(true);
            await axios({
                method: 'POST',
                url: `${API_URL}/cadastro`,
                data: {
                    email: email,
                    senha: senha,
                    nomeFantasia: nomeFantasia,
                    cnpj: cnpj,
                    telefone: telefone,
                    nomeResponsavel: nomeResponsavel,
                    cargo: cargo,
                    vendeOnline: vendeOnline,
                    quantidadeAnuncios: quantidadeAnuncios
                }
            }).then(async (result) => {
                setIsLoading(false);

                // await dispatch(authActions.saveToken(result.data.access_token, lembreDeMim));
                props.history.push('/obrigado');
            }).catch(error => {
                if (error.response) {
                    setIsLoading(false);

                    if (error.response.status === 409) {
                        setErrorMessage("E-mail já foi cadastrado");
                        setEmailInvalid(true);
                    } else statusHandler(error.response.status);
                } else
                    throw new Error(error);
            });
        }
    };

    return (
        <div
            className={`${classes.Registrar} mx-3 mx-md-0`}
            style={{
                background: "url('images/sucatas.jpg') no-repeat",
                backgroundSize: "auto 100%",
            }}
        >
            <div className={`row ${classes.RegistrarRow}`}>
                <div className="col-12 py-3 text-center">
                    <div className="row">
                        <div className="col-12"><img style={{ height: "60px" }} src="/images/logo.png" alt="" /></div>
                    </div>
                </div>

                <div className={`col-sm-12 col-md-6 ${classes.Texto}`}>
                    <p className="h3 d-block mb-4">Experimente <strong>GRÁTIS</strong> por um mês e depois decida se quer continuar usando!</p>

                    <div className="d-none d-md-block">
                        <p className={classes.TextoDestacado}>É só preencher o formulário e liberar o acesso para sua empresa.</p>
                        <p className="h5 my-5">Otimize o tempo da sua equipe, anuncie e identifique todo o seu estoque e facilite sua gestão utilizando uma ferramenta desenvolvida especificamente para o seu negócio!</p>

                        <div className="mx-5 mb-5">
                            <p>
                                “Você não pode impor a produtividade, você deve fornecer as ferramentas para permitir que as pessoas entreguem o seu melhor.”
                            </p>
                            <span className="float-right">- Steve Jobs</span>
                        </div>
                        <div className="d-sm-flex justify-content-center d-md-none">
                            <a href="#formulario">
                                <Button>Quero Experimentar</Button>
                            </a>
                        </div>
                        <div className={`${classes.Vantagens} mt-4`}>
                            <h5><FaCheckCircle size="40px" /> Sem instalações, totalmente online</h5>
                            <h5><FaCheckCircle size="40px" /> Geração de anúncios automática </h5>
                            <h5><FaCheckCircle size="40px" /> Integração entre todos os canais de venda</h5>
                            <h5><FaCheckCircle size="40px" /> Etiquetas de identificação das peças </h5>
                            <h5><FaCheckCircle size="40px" /> Agilidade para vendedores no balcão</h5>
                            <h5><FaCheckCircle size="40px" /> Gestão de estoque</h5>
                            <h5><FaCheckCircle size="40px" /> Controle financeiro</h5>
                            <h5><FaCheckCircle size="40px" /> Suporte online via WhatsApp</h5>

                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 p-0 d-flex justify-content-center">
                    <div className={classes.Form} id="formulario">
                        <form onSubmit={handleSubmit}>
                            <Titulo subtitulo="Crie seu usuário para testar GRÁTIS!" lightMode>Conheça o sistema UnifiCAR</Titulo>

                            <div className="row">
                                <div className="col-12 text-left"> E-mail </div>
                                <div className="col-12">
                                    <Input
                                        size="100%"
                                        placeholder="E-mail"
                                        invalid={emailInvalid}
                                        onChange={(value) => setEmail(value)}
                                        lightMode
                                        name="email"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 text-left"> Senha </div>
                                        <div className="col-12">
                                            <Input
                                                type={ocultarSenha ? "password" : "text"}
                                                size="100%"
                                                placeholder="Senha"
                                                invalid={senhaInvalid}
                                                lightMode
                                                onChange={(value) => setSenha(value)}
                                            />

                                            {ocultarSenha ?
                                                (
                                                    <AiOutlineEyeInvisible
                                                        title="Mostrar senha"
                                                        className={classes.Eye}
                                                        size="30"
                                                        onClick={() => setOcultarSenha(false)}
                                                    />
                                                )
                                                : (
                                                    <AiOutlineEye
                                                        title="Ocultar senha"
                                                        className={classes.Eye}
                                                        size="30"
                                                        onClick={() => setOcultarSenha(true)}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 text-left"> Confirmar senha </div>
                                        <div className="col-12">
                                            <Input
                                                type={ocultarConfirmarSenha ? "password" : "text"}
                                                size="100%"
                                                placeholder="Confirmar senha"
                                                invalid={confirmarSenhaInvalid}
                                                lightMode
                                                onChange={(value) => setConfirmarSenha(value)}
                                            />

                                            {ocultarConfirmarSenha ?
                                                (
                                                    <AiOutlineEyeInvisible
                                                        title="Mostrar senha"
                                                        className={classes.Eye}
                                                        size="30"
                                                        onClick={() => setOcultarConfirmarSenha(false)}
                                                    />
                                                )
                                                : (
                                                    <AiOutlineEye
                                                        title="Ocultar senha"
                                                        className={classes.Eye}
                                                        size="30"
                                                        onClick={() => setOcultarConfirmarSenha(true)}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`row ${classes.Rodape}`}>
                                <div className=" col-sm-12 col-md-6 text-md-left">
                                    <Checkbox
                                        defaultChecked={lembreDeMim}
                                        onChange={() => setLembreDeMim(!lembreDeMim)}
                                        size="small"
                                    >Lembre de mim</Checkbox>
                                </div>
                                <div className="col-sm-12 col-md-6 text-md-right">
                                    {/* Já possui uma conta? <Link to="/login">Entrar</Link> */}
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-12 text-left"> Nome fantasia da empresa </div>
                                <div className="col-12">
                                    <Input
                                        size="100%"
                                        placeholder="Nome fantasia"
                                        maxLength="100"
                                        invalid={nomeFantasiaInvalid}
                                        lightMode
                                        onChange={(value) => setNomeFantasia(value)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-7">
                                    <div className="row">
                                        <div className="col-12 text-left"> CNPJ </div>
                                        <div className="col-12">
                                            <Input
                                                size="100%"
                                                placeholder="CNPJ"
                                                /* invalid={cnpjInvalid} */
                                                onChange={(value) => setCnpj(value)}
                                                maxLength="18"
                                                name="cnpj"
                                                lightMode
                                                cnpj
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-5">
                                    <div className="row">
                                        <div className="col-12 text-left"> Telefone </div>
                                        <div className="col-12">
                                            <Input
                                                size="100%"
                                                placeholder="(99) 99999-99999"
                                                /* invalid={telefoneInvalid} */
                                                onChange={(value) => setTelefone(value)}
                                                name="telefone"
                                                maxLength="15"
                                                lightMode
                                                telefone
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-left"> Seu nome: </div>
                                        <div className="col-12">
                                            <Input
                                                size="100%"
                                                placeholder="Nome completo"
                                                /*  invalid={nomeResponsavelInvalid} */
                                                onChange={(value) => setNomeResponsavel(value)}
                                                name="nome"
                                                lightMode
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-left">
                                    <div className="row">
                                        <div className="col-12">
                                            Seu cargo:
                                        </div>
                                        <div className="col-12">
                                            <Dropdown
                                                list={cargos}
                                                invalid={cargoInvalid}
                                                onChange={value => {
                                                    let copiaCargo = cargos.filter(cargo => cargo.id === value)[0].nome;
                                                    if (copiaCargo)
                                                        setCargo(copiaCargo);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-4">
                                <div className="col-sm-12 col-md-5">
                                    <div className="row">
                                        <div className="col-12">
                                            <Checkbox
                                                onChange={(value) => setVendeOnline(value)}
                                                defaultChecked={vendeOnline}
                                            >
                                                <span className={classes.TextoCheckbox}> Já vendo em algum canal online (Mercado Livre, Mundo da Peça, etc) </span>
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>

                                {vendeOnline ? (
                                    <div className="col-sm-12 col-md-7">
                                        <div className="row">
                                            <div className="col-12 text-left"> Quantidade  de anúncios ativos (aproximadamente) </div>
                                            <div className="col-12">
                                                <Input
                                                    type="text"
                                                    placeholder="Quantidade de anúncios ativos"
                                                    size="100%"
                                                    onlyNumbers
                                                    lightMode
                                                    /* invalid={quantidadeAnunciosInvalid} */
                                                    onChange={(value) => setQuantidadeAnuncios(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="row">
                                <div className={`col-12 ${classes.ErrorMessage}`}>
                                    * {errorMessage}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mt-4">
                                    {isLoading ? <Spinner /> : (
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <Button type="submit" invert>
                                                    COMEÇAR A TESTAR
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrarScreen;