// Dependências
import React, { useState } from "react";

// Components
import BottomNavbar from "../../BottomNavbar/BottomNavbar";
import Input from "../../../UI/BeautyInput/BeautyInput";
import Textarea from "../../../UI/Textarea/Textarea";

// Css
import classes from "./DadosSucata.module.css";

// Components
import Titulo from "../../../UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

const DadosSucata = props => {
    const initialState = {
        cor: true
    };

    let [isValid, setIsValid] = useState({ ...initialState });

    const submitHandler = () => {
        let formIsValid = true;
        let copiaIsValid = { ...initialState };

        if (!validar.nome(props.sucata.cor)) {
            formIsValid = false;
            copiaIsValid.cor = false;
        } else
            copiaIsValid.cor = true;

        if (formIsValid) {
            if (props.onProxClick)
                props.onProxClick();
        } else {
            exibirMensagem.warning("Você precisa informar a cor da sucata.");

            setIsValid(copiaIsValid);
        };
    };

    return (
        <div className="container-fluid pb-5">
            <Titulo subtitulo="Essas informações não serão veiculadas nos anúncios e estarão disponíveis apenas para você">
                Adicione as informações da sucata
            </Titulo>
            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}><span style={{ color: "#c34858" }}>*</span> Cor</div>
                </div>
                <div className="col-9">
                    <Input
                        maxLength="25"
                        placeholder="Cor"
                        invalid={!isValid.cor}
                        defaultValue={props.sucata.cor}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.cor = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>Placa</div>
                </div>
                <div className="col-9">
                    <Input
                        placa
                        maxLength="8"
                        placeholder="Placa"
                        defaultValue={props.sucata.placa}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.placa = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>RENAVAM</div>
                </div>
                <div className="col-9">
                    <Input
                        renavam
                        toUpperCase
                        maxLength="11"
                        placeholder="RENAVAM"
                        defaultValue={props.sucata.renavam}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.renavam = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>Nº Chassi</div>
                </div>
                <div className="col-9">
                    <Input
                        chassi
                        toUpperCase
                        maxLength="17"
                        placeholder="Nº Chassi"
                        defaultValue={props.sucata.chassi}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.chassi = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>Nº Motor</div>
                </div>
                <div className="col-9">
                    <Input
                        toUpperCase
                        maxLength="25"
                        placeholder="Nº Motor"
                        defaultValue={props.sucata.motor}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.motor = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>Nº Câmbio</div>
                </div>
                <div className="col-9">
                    <Input
                        toUpperCase
                        maxLength="25"
                        placeholder="Nº Câmbio"
                        defaultValue={props.sucata.cambio}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.cambio = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <div className={classes.Label}>Observações</div>
                </div>
                <div className="col-9">
                    <Textarea
                        rows="3"
                        maxLength="255"
                        placeholder="Observações"
                        value={props.sucata.observacao}
                        onBlur={event => {
                            let copiaSucata = { ...props.sucata };
                            copiaSucata.observacao = event.target.value;
                            props.onChange(copiaSucata);
                        }}
                    />
                </div>
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default DadosSucata;