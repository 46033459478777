// Dependências
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import Accents from "remove-accents";

// Css
import classes from "./Input.module.css";

const Input = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    const idList = uuidv4();
    const inputId = uuidv4();

    const [valorRangeInput, setValorRangeInput] = useState(props.value || 0);

    const handleOnChange = (event) => {
        let copiaValue = event.target.value;

        if (props.toUpperCase)
            copiaValue = copiaValue.toUpperCase();

        if (props.onlyNumbers)
            copiaValue = copiaValue.replace(/\D/g, ''); // Remove o que não for número

        if (props.onlyMoney) {
            if (!copiaValue.match(/^[0-9]+((\.|,)[0-9]{0,2})?$/gm)) // Considera números, pontos e vírgulas
                copiaValue = copiaValue.substring(0, copiaValue.length - 1);
        }

        if (props.onlyLettersAndNumbers) {
            copiaValue = copiaValue.replace(/[^A-Z0-9]+/g, ''); // Remove o que não for número ou letra
        }

        if (props.cpf) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/^(\d{3})(\d)/g, '$1.$2'); //Coloca ponto entre o terceiro e o quarto
            copiaValue = copiaValue.replace(/(\d)(\d{3})(\d{2})$/, '$1.$2-$3'); //Coloca ponto entre o terceiro e o quarto, sexto e o sétimo e traço entre o nono e décimo digitos
        }

        if (props.cnpj) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); //Coloca ponto entre o terceiro e o quarto, sexto e o sétimo e traço entre o nono e décimo digitos
        }

        if (props.tag) {
            copiaValue = copiaValue.toLowerCase().replace(' ', '-'); // Deixa minúsculo e troca espeços por hífens
            if (copiaValue[0] !== "#") copiaValue = "#" + copiaValue; // Coloca # no primeiro char
            copiaValue = Accents.remove(copiaValue); // Retira acentos
            copiaValue = copiaValue.replace(/[^a-z0-9-#]+/g, ''); // Remove o que não for número ou letra ou hashtag
        }

        if (props.rota) {
            copiaValue = copiaValue.toLowerCase().replace(' ', '/'); // Deixa minúsculo e troca espeços por barras
            if (copiaValue[0] !== "/") copiaValue = "/" + copiaValue; // Coloca / no primeiro char
            copiaValue = Accents.remove(copiaValue); // Retira acentos
            copiaValue = copiaValue.replace(/[^a-z0-9-/]+/g, ''); // Remove o que não for número ou letra ou barras
        }

        if (props.telefone) {
            copiaValue = copiaValue.replace(/\D/g, ''); //Remove tudo o que não é dígito

            copiaValue = copiaValue.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
        }

        if (props.placa) {
            copiaValue = copiaValue.toUpperCase();
            copiaValue = copiaValue.replace(/[^A-Z0-9]+/g, ''); // Remove o que não for número ou letra
            copiaValue = copiaValue.trim().replace(/^(\w{3})(\w)/g, '$1-$2') // Coloca hífen depois do 3º char
        }

        event.target.value = copiaValue;
        if (props.onChange)
            props.onChange(event.target.value);
    }

    return props.type === "file" ? (
        <div className={props.className}>
            <label
                htmlFor={props.disabled ? null : inputId}
                onClick={props.onClick}
                className={`
                    ${props.lightMode ? classes.LabelInputFileLight : darkMode ? classes.LabelInputFileDark : classes.LabelInputFileLight}
                    ${props.disabled ? classes.Disabled : ""}
                `}
            >{props.children ? props.children : "Escolher Arquivos"}</label>
            <input
                type="file"
                id={inputId}
                className={classes.InputFile}
                accept={props.accept || "image/png, image/jpg, image/jpeg"}
                multiple={props.multiple}
                onChange={event => props.onChange(event)}
            />
        </div>
    ) : props.type === "range" ? (
        <div className={props.className}>
            <input
                type="range"
                defaultValue={valorRangeInput}
                min={props.min}
                max={props.max}
                onBlur={props.onBlur}
                onChange={(event) => {
                    setValorRangeInput(event.target.value);
                    if (props.onChange)
                        props.onChange(event.target.value)
                }}
            />
            <span className="ml-2">{valorRangeInput}%</span>
        </div>
    ) : (
        <label className={`${props.className} ${classes.Field}`} style={{ width: props.size ? props.size : "100%" }}>
            <input
                type={props.type}
                defaultValue={props.value}
                value={props.setValue}
                id={props.id}
                tabIndex={props.tabIndex}
                maxLength={props.maxLength}
                className={`${props.lightMode ? classes.FieldInputLight : darkMode ? classes.FieldInputDark : classes.FieldInputLight} ${props.invalid ? classes.IsNotValid : props.light ? classes.IsValidLight : classes.IsValid} ${props.alignCenter ? classes.AlignCenter : ""}`}
                placeholder={props.placeholder}
                name={props.name}
                list={props.datalist ? idList : null}
                onKeyUp={props.onKeyUp ? (event) => props.onKeyUp(event) : null}
                onKeyDown={(event) => { if (event.key === "Enter" && !props.enterHandler) event.target.blur(); }}
                style={{ width: props.size ? props.size : "100%", padding: props.padding }}
                onChange={(event) => handleOnChange(event)}
                onBlur={props.onBlur}
            />
            {props.datalist ? (
                <datalist id={idList}>
                    {props.datalist.map(data => (
                        <option key={uuidv4()} value={data} />
                    ))}
                </datalist>
            ) : null}
        </label >
    );
};

export default Input;