// Dependências
import React from "react";

import { Document, Image, Page, Text, StyleSheet, View } from '@react-pdf/renderer';

const Etiqueta = ({localizacao}) => {
    const styles = StyleSheet.create({
        page: {
            fontSize: "14pt",
            margin: "0",
        },
        etiqueta: {
            height: "100%",
            width: "100%",
            margin: "0",
            padding: "5mm 7mm",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        text: {
            height: "16mm",
            margin: 0,
        },
        content: {
            flexDirection: 'column',
            transform: 'rotate(-90deg)',
            alignItems: 'center',
            justifyContent: 'center',
            height: '16mm',
            width: '20mm',
            marginBottom: '1mm'
        }
    });

    const [isReady, setIsReady] = React.useState(false);

    React.useEffect(() => {
        setIsReady(true);
    }, []);

    return isReady ? (
        <Document>
            {localizacao?.map(loc => loc ? (
                <Page key={loc.id} size={[141.73, 113.38]} style={styles.page}>
                    <View style={styles.etiqueta} wrap={true}>
                        <Image style={{ width: '27mm', height: '27mm' }} source={{ uri: loc.qrCode }} />
                        <View style={styles.content} wrap={false}>
                            <Text style={styles.text}>{loc.nome}</Text>
                        </View>
                    </View>
                </Page>
            ) : <Page size={[141.73, 113.38]} style={styles.page} />)}
        </Document>
    ) : (<Document></Document>);
};

export default Etiqueta;