// Dependências
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../../../store/actions/anuncios";
import * as cadastroActions from "../../../../../store/actions/cadastro";
import * as pecasActions from "../../../../../store/actions/pecas";

// Components 
import Button from "../../../../../components/UI/Button/Button";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Titulo from "../../../../../components/UI/Titulo/Titulo";
import TituloPecas from "../../../../../components/Cadastro/TituloPecas/TituloPecas";

// Helpers
import * as exibirMensagem from "../../../../../helpers/exibirMensagem";

const ResumoScreen = props => {
    const dispatch = useDispatch();

    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async () => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(cadastroSucata.pecas.map(peca => {
                return {
                    id: peca.id,
                    observacao: peca.observacao
                };
            })));

            await dispatch(anunciosActions.createAnuncios(cadastroSucata.pecas.map(peca => {
                return {
                    peca: { id: peca.id },

                    anuncios: [].concat.apply([], cadastroSucata.plataformas.map(plataforma => plataforma.tiposAnuncio)).filter(tipoAnuncio => tipoAnuncio.selecionado || tipoAnuncio.obrigatorio).map(tipoAnuncio => {
                        return {
                            id: tipoAnuncio.id,
                            porcentagem: tipoAnuncio.porcentagem,
                            garantia: peca.garantia,
                            titulo: peca.titulo,
                            frete: { id: peca.frete.id }
                        };
                    }),
                };
            })));

            exibirMensagem.success("Anúncios gerados com sucesso!");
            setIsLoading(false);
            props.history.push("/estoque");
        } catch (error) {
            console.log(error);
        };

        setIsLoading(false);
    };

    return isLoading ? (
        <div>
            <div className="row text-center">
                <div className="col-12">
                    <h3>Estamos gerando seus anúncios!</h3>
                    <h4>Isso pode demorar alguns minutos, por favor não interrompa o processo.</h4>
                </div>
            </div>
            <Spinner />
        </div>
    ) : (
        <div className="container-fluid pb-5">
            <Titulo>REVISÃO - Último passo</Titulo>

            <div className="row">
                <div className="col-12 mb-3">
                    <span style={{ fontSize: "16px" }}>Confirme se os títulos e observações estão corretos. Após revisar, clique no botão “Cadastrar anúncios” e aguarde alguns segundos enquanto o sistema cria seus anúncios.</span>
                </div>
            </div>

            <TituloPecas
                hideTitle
                bottomNavbar={false}
                pecas={[...cadastroSucata.pecas]}
                onChange={pecasAtualizadas => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.pecas = [...pecasAtualizadas];
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                }}
            />

            <div className="row">
                <div className="col-12 text-center py-2">
                    <Button color="green" onClick={submitHandler}>Cadastrar anúncios</Button>
                </div>
            </div>
        </div>
    );
};

export default ResumoScreen;