// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Medidas from "../../../../components/Cadastro/Medidas/Medidas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const MedidasScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;

    const pecas = useSelector(state => state.pecas.pecas);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas em revisão
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 6));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [idSucata, dispatch]);

    const submitHandler = async pecas => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(pecas));
            setIsLoading(false);
            exibirMensagem.success("Alterações realizadas com sucesso.");
            props.history.push(`/cadastro/sucata/ficha-tecnica/${idSucata}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div>
            <h4 className="mb-3">Informe as medidas das seguintes peças:</h4>

            <Medidas
                pecas={pecas}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={pecas => submitHandler(pecas)} />
        </div>
    );
};

export default MedidasScreen;