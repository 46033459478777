import axios from 'axios';
import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const CREATE_FUNCIONARIO = "CREATE_FUNCIONARIO";
export const DELETE_FUNCIONARIO = "DELETE_FUNCIONARIO";
export const FETCH_FUNCIONARIO = "FETCH_FUNCIONARIO";
export const FETCH_FUNCIONARIOS = "FETCH_FUNCIONARIOS";
export const UPDATE_FUNCIONARIO = "UPDATE_FUNCIONARIO";

export const createFuncionario = funcionario => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: API_URL + "/funcionarios",
            headers: {
                Authorization: token
            },
            data: funcionario
        }).then((result) => {
            if (result.status === 201) {
                let novoFuncionario = { ...result.data };
                dispatch({ type: CREATE_FUNCIONARIO, funcionario: novoFuncionario });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteFuncionario = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/funcionarios/${id}`,
            headers: {
                Authorization: token
            }
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: DELETE_FUNCIONARIO, id: id });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchFuncionarios = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(API_URL + "/funcionarios", {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let funcionarios = [];
            try {
                funcionarios = await response.json();
                dispatch({ type: FETCH_FUNCIONARIOS, funcionarios: funcionarios });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const updateFuncionario = (id, funcionario) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/funcionarios/${id}`,
            headers: {
                Authorization: token
            },
            data: funcionario
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: UPDATE_FUNCIONARIO, funcionario: { ...result.data } });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};