// Dependências
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import readXlsxFile from 'read-excel-file';
import { API_URL } from "../../../constants/GlobalConfig";

// Actions
import * as gruposSucatasActions from "../../../store/actions/grupos-sucatas";

// Components
import Button from "../../../components/UI/Button/Button";
import DropZone from "../../../components/UI/DropZone/DropZone";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Sucata from "../../../components/Cadastro/ImportarDetran/Sucata/Sucata";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";

// Helpers
import dataHoje from "../../../helpers/dataHoje";
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import statusHandler from "../../../helpers/statusHandler";

const ImportarDetranScreen = props => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);

    const [isLoading, setIsLoading] = useState(false);
    const [sucatas, setSucatas] = useState([]);
    const [opcoesSucatas, setOpcoesSucatas] = useState([]);
    const [identificacaoSucatas, setIdentificacaoSucatas] = useState([]);

    // Se tiver sucatas e arquetipos, manda request de identificação dos dados e coloca opções no lugar
    useEffect(() => {
        if (sucatas.length) {

            setIsLoading(true);

            axios({
                method: 'POST',
                headers: { Authorization: token },
                url: `${API_URL}/pecas-arquetipos/detran`,
                data: sucatas
            }).then(response => {
                if (response.status === 200) {
                    exibirMensagem.exibir({
                        title: "",
                        text: "Nós tentamos identificar sucatas, por favor verifique se preenchemos os dados corretamente e confime para continuar com a identificação das peças.",
                        confirmButtonText: "Ok"
                    });

                    setOpcoesSucatas(response.data.map(sucata => {
                        return {
                            ...sucata,
                            pecas: sucata.pecas.map(peca => {
                                return {
                                    ...peca,
                                    arquetipos: [...peca.arquetipos]
                                };
                            })
                        };
                    }));
                } else
                    statusHandler(response.status);
            });

            setIsLoading(false);
        };
    }, [sucatas, token]);

    // Seta o valor inicial das identificacoes das sucatas
    useEffect(() => {
        if (opcoesSucatas.length && !identificacaoSucatas.length) {
            setIdentificacaoSucatas(opcoesSucatas.map(opcaoSucata => {
                return {
                    placa: opcaoSucata.placa,
                    fipe: {
                        marca: { id: opcaoSucata.fipe.marcas[0]?.id, nome: opcaoSucata.fipe.marcas[0]?.nome },
                        ano: { id: opcaoSucata.fipe.anos[0]?.id, nome: opcaoSucata.fipe.anos[0]?.nome },
                        veiculo: { id: opcaoSucata.fipe.veiculos[0]?.id, nome: opcaoSucata.fipe.veiculos[0]?.nome }
                    },
                    pecas: opcaoSucata.pecas.map(opcaoPeca => {
                        let nome = [].concat.apply([], sucatas.map(sucata => sucata.pecas)).find(pecaDetran => pecaDetran.idDetran === opcaoPeca.idDetran).arquetipo.nome;
                        nome = nome.toLowerCase();
                        nome = nome.charAt(0).toUpperCase() + nome.slice(1);

                        return {
                            id: opcaoPeca.idDetran,
                            arquetipo: { ...opcaoPeca.arquetipos[0] },
                            nome,
                            isValid: true
                        };
                    })
                };
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opcoesSucatas, sucatas]);

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        let formIsValid = true;

        let copiaIdentificacaoSucatas = JSON.parse(JSON.stringify([...identificacaoSucatas]));

        // Verifica se todas as peças de arquétipos 350 possuem nome
        copiaIdentificacaoSucatas.forEach(sucata => {
            sucata.pecas.forEach(peca => {
                if (peca.arquetipo.id === 350 && !peca.nome) {
                    peca.isValid = false;
                    formIsValid = false;
                } else
                    peca.isValid = true;
            });
        });

        if (formIsValid) {
            await dispatch(gruposSucatasActions.createGrupoSucatas({
                nome: `${dataHoje()} - Peças do Detran`,
                sucatas: identificacaoSucatas.map(sucata => {
                    return {
                        ...sucata,
                        pecas: sucata.pecas.map(peca => {
                            return {
                                idDetran: peca.id,
                                nome: peca.nome,
                                arquetipo: { ...peca.arquetipo },
                            };
                        })
                    };
                })
            }));
            props.history.push("/cadastro/sucata");
        } else {
            exibirMensagem.formInvalid();
            setIdentificacaoSucatas([...copiaIdentificacaoSucatas]);
        };

        setIsLoading(false)
    };

    const readFile = files => {
        setIsLoading(true);

        const tooHeavyHandler = file => {
            const sendFile = () => {
                let formData = new FormData();
                formData.append('arquivo-detran', file);

                axios({
                    method: 'POST',
                    url: `${API_URL}/arquivo-detran`,
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                        Authorization: token
                    },
                    data: formData,
                }).then(result => {
                    if (result.status) {
                        exibirMensagem.success("Seu arquivo foi enviado para revisão!");
                    } else {
                        throw new Error("Algo deu errado");
                    };
                }).catch(error => {
                    statusHandler(error.response?.status);
                    console.log(error);
                });
            };

            exibirMensagem.exibir({
                title: "Enviar para revisão?",
                text: `O arquivo escolhido é pesado demais, sugerimos que envie para revisão. Desta forma, nossa equipe ficará responsável
    por fazer a importação das suas peças. Em um prazo médio de 7 dias úteis, suas sucatas estarão disponíveis para prosseguir o processo de cadastro.`,
                showCancelButton: true,
                confirmButtonText: "Sim, enviar para revisão",
            }).then(async result => {
                setIsLoading(false);
                if (result.isConfirmed) {
                    sendFile();
                } else {
                    return;
                };
            });
        };

        try {
            const schema = {
                "[PLACA/CHASSI]": {
                    prop: "sucata",
                    required: true,
                    type: value => {
                        if (value) {
                            let placa = value.split("/")[0];
                            let chassi = value.split("/")[1];

                            return {
                                placa: placa.length > 1 ? placa : null,
                                chassi: chassi.length > 1 ? chassi : null,
                            }
                        } else
                            return null;
                    }
                },
                "FIPE": {
                    prop: "fipe",
                    type: {
                        "[ANO]": {
                            prop: "ano",
                            type: String,
                            required: true
                        },
                        "[MARCA]": {
                            prop: "marca",
                            type: String,
                            required: true
                        },
                        "[MODELO]": {
                            prop: "modelo",
                            type: String
                        }
                    }
                },
                "[COD_ESTOQUE]": {
                    prop: "idDetran",
                    required: true,
                    type: String
                },
                "[DESCRICAO]": {
                    prop: "arquetipo",
                    required: true,
                    type: String
                },
                "[SITUACAO]": {
                    prop: "aceita",
                    required: true,
                    type: value => value === "ACEITO" ? true : false
                }
            };

            const file = files[0];

            if (file.type === "application/vnd.ms-excel") {
                exibirMensagem.error("O formato do arquivo iserido não é suportado.");
                setIsLoading(false);
                return;
            };

            if (file.size >= 100000) {
                tooHeavyHandler(file);
                return;
            };

            readXlsxFile(file, { schema: schema }).then(result => {
                let rows = result.rows.filter(row => row.aceita && row.sucata?.placa).map(row => {
                    return {
                        fipe: {
                            ano: { id: null, nome: row.fipe.ano },
                            marca: { id: null, nome: row.fipe.marca },
                            veiculo: { id: null, nome: null },
                            modelo: { id: null, nome: row.fipe.modelo },
                        },
                        placa: row.sucata.placa,
                        chassi: row.sucata.chassi,

                        peca: { id: null, idDetran: row.idDetran, arquetipo: { id: null, nome: row.arquetipo } }
                    };
                });

                if (rows.length > 300) {
                    tooHeavyHandler(file)
                    return;
                };

                let placas = rows.map(row => row.placa);
                placas = placas.filter((placa, index) => placas.indexOf(placa) === index)

                let sucatasAtualizadas = [];
                placas.forEach(placa => {
                    let sucatasCompativeis = rows.filter(row => row.placa === placa);

                    sucatasAtualizadas.push({
                        ...sucatasCompativeis[0],
                        pecas: sucatasCompativeis.map(sucata => sucata.peca)
                    });
                });

                sucatasAtualizadas.forEach(sucata => delete sucata.peca);

                setSucatas(sucatasAtualizadas);
            });
        } catch (error) {
            console.log(error);
            exibirMensagem.error("Houve um erro inesperado ao tentar ler o seu arquivo, tente novamente. Se o problema persistir, por favor contate-nos.");
        };

        setIsLoading(false);
    };

    return (
        <div className="container-fluid">
            {isLoading ? <Spinner /> : opcoesSucatas.length && identificacaoSucatas.length ? (
                <form onSubmit={handleSubmit}>
                    <Titulo>Identifique as sucatas abaixo</Titulo>
                    <div className="row" style={{ height: "76vh" }}>
                        <Table
                            fixed
                            tbody={false}
                            header={["Sucata", "Marca", "Modelo", "Ano", "Peças"]}
                        >
                            {opcoesSucatas.map(sucata =>
                                <Sucata
                                    key={sucata.placa}
                                    sucataDetran={sucatas.find(sucataDetran => sucataDetran.placa === sucata.placa)}
                                    sucata={{ ...sucata }}
                                    identificacao={identificacaoSucatas.find(identificacao => identificacao.placa === sucata.placa)}
                                    onChange={novaIdentificacao => {
                                        let copiaIdentificacaoSucatas = [...identificacaoSucatas];
                                        copiaIdentificacaoSucatas = copiaIdentificacaoSucatas.map(copiaIdentificacao => {
                                            if (copiaIdentificacao.placa === sucata.placa)
                                                return { ...novaIdentificacao }
                                            else
                                                return copiaIdentificacao
                                        });
                                        setIdentificacaoSucatas(copiaIdentificacaoSucatas);
                                    }}
                                    onChangeVeiculoOpcoes={novasOpcoes => {
                                        let copiaOpcoesSucatas = [...opcoesSucatas];
                                        copiaOpcoesSucatas.map(opcaoSucata => {
                                            if (opcaoSucata.placa === sucata.placa) {
                                                let copiaOpcaoSucata = { ...opcaoSucata };
                                                copiaOpcaoSucata.fipe.veiculos = [...novasOpcoes];
                                                return copiaOpcaoSucata
                                            };
                                            return opcaoSucata;
                                        });
                                        setOpcoesSucatas(copiaOpcoesSucatas);
                                    }}
                                    onDelete={() => {
                                        setOpcoesSucatas(prevOpcoesSucatas => prevOpcoesSucatas.filter(prevSucata => prevSucata.placa !== sucata.placa));
                                        setIdentificacaoSucatas(prevIdentificacaoSucatas => prevIdentificacaoSucatas.filter(prevSucata => prevSucata.placa !== sucata.placa));
                                    }}
                                    deletePeca={idPeca => {
                                        setOpcoesSucatas(prevOpcoesSucatas => prevOpcoesSucatas.map(prevSucata =>
                                            prevSucata.placa === sucata.placa ? { ...prevSucata, pecas: [...prevSucata.pecas].filter(prevPecas => prevPecas.idDetran !== idPeca) } : prevSucata
                                        ));
                                        setIdentificacaoSucatas(prevIdentificacaoSucatas => prevIdentificacaoSucatas.map(prevSucata =>
                                            prevSucata.placa === sucata.placa ? { ...prevSucata, pecas: [...prevSucata.pecas].filter(prevPecas => prevPecas.id !== idPeca) } : prevSucata
                                        ));
                                    }}
                                />
                            )}
                        </Table>
                    </div>
                    <div className="text-right py-1">
                        <Button
                            color="green"
                            type="submit"
                        >
                            Confirmar e continuar
                        </Button>
                    </div>
                </form>
            ) : (
                <>
                    <Titulo>Importar Planilha</Titulo>
                    <div className="row">
                        <div className="col-12 pb-4 text-justify">
                            Importe as suas peças cadastradas no Detran de uma vez. Basta baixar e planilha gerada pelo Detran
                            e seleciona-la no campo abaixo, nós cuidamos do resto!
                            <br />
                            <strong>Formatos de arquivo recomendados:</strong> Pasta de Trabalho do Excel (.xlsx) ou Modelo do Excel (.xltx).
                            <br />
                            <strong>Atenção:</strong> Procure não editar a planilha, isso pode atrapalhar a leitura dos dados.
                        </div>
                    </div >

                    <div className="row">
                        <div className="col-12 px-2" style={{ height: "62vh" }}>
                            <DropZone
                                notImage
                                quantidade="1"
                                title="SELECIONE O ARQUIVO"
                                subtitulo="Arraste e solte o arquivo aqui ou clique para escolher"
                                accept={[".csv", ".xltx", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.template", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                onChange={file => readFile(file)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div >
    );
};

export default ImportarDetranScreen;