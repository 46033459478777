// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import TituloPecas from "../../../../components/Cadastro/TituloPecas/TituloPecas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const TituloPecasScreen = props => {
    const dispatch = useDispatch();

    const pecas = useSelector(state => state.pecas.pecas);
    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas aguardando títulos
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 8));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [idSucata, dispatch]);

    const submitHandler = async pecas => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(pecas));
            setIsLoading(false);
            exibirMensagem.success("Alterações realizadas com sucesso.");
            props.history.push(`/cadastro/sucata/fotos-pecas/${idSucata}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <TituloPecas
                idSucata={idSucata}
                pecas={[...pecas]}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={pecas => submitHandler(pecas)} />
        </div>
    );
};

export default TituloPecasScreen;