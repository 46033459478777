// Dependências
import React from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";

// Css
import classes from "./Peca.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Peca = props => {
    return props.peca.arquetipo ? (
        <tr
            onClick={event => isMobile ? props.onContextMenu(event) : props.onClick()}
            className={props.isSelected ? classes.Selected : ""}
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td>{props.peca.id}</td>
            <td>{props.peca.arquetipo.nome}</td>
            <td> {props.peca.sucata.fipe.modelo.nome || props.peca.sucata.fipe.veiculo.nome} </td>
            <td>{formatar.data(props.peca.dataCriacao)}</td>
            {props.showLocalizacao ? <td>{props.peca.localizacao?.nome || "-"}</td> : null}
            {props.showStatus ? <td>{props.peca.status.nome}</td> : null}
        </tr>

    ) : (
        <tr className={classes.Anuncio}>
            <td>
                <a
                    href={props.peca.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.Link}
                >
                    {props.peca.id}
                </a>
            </td>
            <td colSpan="2">
                <a
                    href={props.peca.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.Link}
                >
                    {props.peca.titulo}
                </a>
            </td>
            <td>
                <a
                    href={props.peca.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.Link}
                >
                    {formatar.data(props.peca.dataCriacao)}
                </a>
            </td>
        </tr>
    );
};

export default withRouter(Peca);
