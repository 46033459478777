// Dependências
import React from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";

// Helpers
import * as formatar from "../../../../helpers/formatar";

const Grafico = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    const lucroMaximoLine = {
        label: "Lucro Máximo",
        data: props.lucroMaximo.map(lucro => lucro.valor),
        borderColor: "#ecc34d",
        lineTension: 0,
        fill: false,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent"
    };

    const paybackLine = {
        label: "Valor de Custo",
        data: props.payback.map(payback => payback.valor),
        borderColor: "#c34858",
        lineTension: 0,
        fill: false,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent"
    };

    const vendasLine = {
        label: "Vendas",
        data: props.vendas.map(venda => venda.valor),
        borderColor: "#79cd56",
        lineTension: 0,
        fill: false,
    };

    return (
        <Line
            height={50}
            data={{
                labels: props.vendas.map(venda => formatar.data(`${venda.data}-01`, true, true)),
                datasets: [lucroMaximoLine, paybackLine, vendasLine],
            }}
            options={{
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        boxWidth: 30,
                        fontColor: darkMode ? "#fff" : "#000",
                    },
                }
            }}
        />
    );
};

export default Grafico;
