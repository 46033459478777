// Dependências
import React from "react";

const Conta = props => {
    return (
        <tr onClick={props.onClick}>
            <td>{props.conta.id}</td>
            <td>{props.conta.nome}</td>
            <td>{props.conta.email}</td>
            <td>{props.conta.tipo.nome}</td>
            <td>{props.conta.usuarios.length}</td>
        </tr>
    );
};

export default Conta;