// Dependências
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

// Actions
import * as usuarioActions from "../../../store/actions/usuario";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

// Components
import Spinner from "../../../components/UI/Spinner/Spinner";

const MercadoLivreScreen = props => {
    const dispatch = useDispatch();

    const useQuery = () =>
        new URLSearchParams(useLocation().search);

    const query = useQuery();

    const code = query.get("code");

    // Verifica se tem code, se tiver envia para API
    useEffect(() => {
        const createVinculo = async () => {
            try {
                await dispatch(usuarioActions.createVinculo({ plataforma: { id: 2 }, code: code }));
                exibirMensagem.success("Sua conta foi vinculada ao Mercado Livre!");
            } catch (error) {
                console.log(error);
            };

            props.history.push("/inicio");
        };

        if (!code) {
            exibirMensagem.error();
            props.history.push("/inicio");
        } else
            createVinculo();

    }, [dispatch, code, props.history]);

    return (
        <div className="text-center pt-5">
            <h3>Por favor, aguarde.</h3>
            <h4>Estamos nos conectando com a sua conta do Mercado Livre.</h4>
            <Spinner />
        </div>
    );
};

export default MercadoLivreScreen;