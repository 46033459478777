// Dependência
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BiCommentDetail } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Input from "../../../UI/Input/Input";

// Css
import classes from "./Peca.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const Peca = props => {
    const dispatch = useDispatch();

    const [titulo, setTitulo] = useState(props.peca.titulo);

    return (
        <tr>
            <td style={{ position: "relative", textAlign: "left" }}>
                <BsFillTrashFill
                    size="25px"
                    className={classes.DeleteButton}
                    title="Apagar peça"
                    onClick={() =>
                        exibirMensagem.exibir({
                            title: "Tem certeza?",
                            text: `Deseja excluir permanentemente esta peça?`,
                            showCancelButton: true,
                            confirmButtonText: "Sim",
                        }).then(async result => {
                            if (result.isConfirmed) {
                                try {
                                    await dispatch(pecasActions.deletePeca(props.peca.id));
                                    exibirMensagem.success("Peça apagada com sucesso.");
                                } catch (error) {
                                    console.log(error);
                                };
                            } else {
                                return;
                            };
                        })
                    }
                />

                <BiCommentDetail
                    size="28px"
                    className={classes.Comentario}
                    style={{ color: props.peca.observacao ? "#c34858" : "" }}
                    title="Alterar descrição dos anúncios"
                    onClick={() => {
                        exibirMensagem.exibir({
                            title: 'Digite uma observação',
                            text: 'Esta observação será incluída na descrição desta peça.',
                            input: 'textarea',
                            inputValue: props.peca.observacao || "",
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Salvar'
                        }).then(value => {
                            if (value.value || value.value === "")
                                props.onChange({ ...props.peca, observacao: value.value });
                        });
                    }} />

                <Input
                    size="85%"
                    defaultValue={props.peca.titulo}
                    placeholder={props.peca.arquetipo.nome}
                    value={props.peca.titulo}
                    maxLength={(props.peca.arquetipo.tituloMaxLength || 60)}
                    onChange={value => setTitulo(value)}
                    onBlur={event => props.onChange({ ...props.peca, titulo: event.target.value })} />

                <span className={classes.Arquetipo}>
                    {props.peca.arquetipo.nome}
                </span>

                <span className={classes.MaxLength}>
                    {titulo ? titulo.length : 0} / {props.peca.arquetipo.tituloMaxLength || 60}
                </span>
            </td>
        </tr>
    );
};

export default Peca;