// Dependências
import React from "react";
import { useDispatch } from "react-redux";

// Actions
import * as anunciosActions from "../../../../store/actions/anuncios";

// Css
import classes from "./Anuncio.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";

const Anuncio = props => {
    const dispatch = useDispatch();

    const updateAnuncio = key => {
        let titulo = "";
        let inputType = "text";
        let inputValue = "";
        let inputAttributes = {};
        let inputValidator = () => true;

        switch (key) {
            case "porcentagem":
                inputType = "range";
                titulo = "Porcentagem do preço:";
                inputValue = props.anuncio.porcentagem || "";
                inputAttributes = {
                    min: 90,
                    max: 120,
                    step: 1
                };
                break;
            default:
                return;
        };

        exibirMensagem.exibir({
            title: titulo,
            input: inputType,
            inputValue: inputValue,
            inputAttributes: inputAttributes,
            showCancelButton: true,
            confirmButtonText: "Salvar",
            inputValidator: value => {
                return new Promise(resolve => {
                    if (inputValidator(value))
                        resolve();
                    else
                        resolve("Valor informado é inválido");
                });
            }
        }).then(async result => {
            if (result.isConfirmed) {
                await dispatch(anunciosActions.updateAnuncio(props.anuncio.id, { [key]: result.value }));
                exibirMensagem.success("Edição realizada com sucesso!");
            };
        });
    };

    return (
        <tr onContextMenu={event => props.onContextMenu(event)}>
            <td
                title="Ir para o anúncio"
                className={classes.Hoverble}
                onClick={() => window.open(props.anuncio.link, '_blank')}>
                <img className={classes.Logo} src={props.anuncio.plataforma.imagem} alt="Logo" />
            </td>

            <td>
                {props.anuncio.tipo.nome}
            </td>

            <td
                title="Ir para o anúncio"
                className={classes.Hoverble}
                onClick={() => window.open(props.anuncio.link, '_blank')}>
                {props.anuncio.titulo}
            </td>

            <td>
                {props.anuncio.status.nome}
            </td>

            <td
                title="Editar o preço"
                className={classes.Hoverble}
                onClick={() => updateAnuncio("porcentagem")}>
                {props.anuncio.porcentagem}%
            </td>

            <td
                title="Editar o preço"
                className={classes.Hoverble}
                onClick={() => updateAnuncio("porcentagem")}>
                {formatar.dinheiro(props.anuncio.preco)}
            </td>
        </tr>
    );
};

export default Anuncio;