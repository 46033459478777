// Dependências
import React, { useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";

// Components
import Input from "../../../UI/Input/Input";
import Dropdown from "../../../UI/BeautyDropdown/BeautyDropdown";

// Css
import classes from "./Peca.module.css";

const Peca = props => {
    const [hasSelected, setHasSelected] = useState();

    const isPecaNaoListada = props.identificacao.arquetipo.id === 350 ? true : false;

    const getStateIconColor = () => {
        if (props.peca.arquetipos.length === 1 || hasSelected)
            return "#79cd56";
        else if (isPecaNaoListada)
            return "#3b3e51";
        else
            return "#ecc34d";
    };

    return (
        <tr>
            <td style={{ textAlign: "left", position: "relative" }}>
                <div style={{ display: "inline-block", width: "3rem" }} />
                <BsFillTrashFill
                    className={classes.DeleteIcon}
                    title="Apagar peça"
                    onClick={props.onDelete}
                />
                {props.peca.nomeDetran}

            </td>
            <td>
                <div className={classes.StateIcon} style={{ backgroundColor: getStateIconColor() }} />
            </td>
            <td colSpan="2" style={{ textAlign: "left" }}>
                {isPecaNaoListada ? (
                    <Input
                        light
                        invalid={!props.identificacao.isValid}
                        placeholder="Identifique a peça"
                        value={props.identificacao.nome}
                        onBlur={event => props.onChange({ ...props.identificacao, nome: event.target.value })}
                    />
                ) : null}
            </td>
            <td colSpan="2" style={{ textAlign: "left", paddingRight: "2rem !important" }}>
                <Dropdown
                    returnObject
                    list={[...props.peca.arquetipos]}
                    invalid={!props.identificacao.isValid}
                    default={props.identificacao.arquetipo.nome}
                    onChange={novoArquetipo => {
                        setHasSelected(true);
                        props.onChange({
                            ...props.identificacao, arquetipo: { id: novoArquetipo.id, nome: novoArquetipo.nome }
                        })
                    }} />
            </td>
        </tr >
    );
};

export default Peca;