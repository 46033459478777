// Dependências
import React from "react";

// Css
import classes from "./Spinner.module.css";

const Spinner = props => (
    <div className={props.className}>
        <div
            className={`${props.inline ? classes.InlineSkFadingCircle : classes.SkFadingCircle}`}
            style={props.style}
        >
            <div className={`${classes.SkCircle1} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle2} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle3} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle4} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle5} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle6} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle7} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle8} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle9} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle10} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle11} ${classes.SkCircle}`}></div>
            <div className={`${classes.SkCircle12} ${classes.SkCircle}`}></div>
        </div>
    </div>
)

export default Spinner;
