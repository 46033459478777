// Dependências
import React from "react";

// Components
import Checkbox from "../../../UI/Checkbox/Checkbox";

// Css
import classes from "./Permissao.module.css";

const Permissao = props => {
    return (
        <div className="pl-4">
            <Checkbox
                size="small"
                defaultChecked={props.permissao.checked}
                onChange={value => props.onChange(value)}
            >{props.permissao.nome}. <span className={classes.Descricao}> ({props.permissao.descricao})</span></Checkbox>
        </div>
    );
};

export default Permissao;