// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import FotosPecas from "../../../../components/Cadastro/FotosPecas/FotosPecas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const FotosPecasScreen = props => {
    const dispatch = useDispatch();

    const pecas = useSelector(state => state.pecas.pecas);

    const idSucata = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas aguardando fotos
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 9));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [idSucata, dispatch]);

    const submitHandler = async fotos => {
        setIsLoading(true);

        try {
            if (fotos.length) {
                await dispatch(pecasActions.updateFotos(fotos));
                exibirMensagem.success("Alterações realizadas com sucesso.");
            } else
                await dispatch(sucatasActions.updateStatusSucata(idSucata));

            setIsLoading(false);
            props.history.push(`/cadastro/sucata/despesas/${idSucata}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner /> : (
        <div>
            <FotosPecas
                pecas={pecas}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={fotos => submitHandler(fotos)} />
        </div>
    );
};

export default FotosPecasScreen;