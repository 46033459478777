import axios from 'axios';

import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig"

export const CREATE_VENDA = "CREATE_VENDA";
export const DELETE_VENDA = "DELETE_VENDA";
export const FETCH_VENDAS = "FETCH_VENDAS";
export const FETCH_RESET_VENDAS = "FETCH_RESET_VENDAS";

export const createVenda = (idPeca, venda) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/${idPeca}/vendas`,
            headers: { Authorization: token },
            data: venda
        }).then(result => {
            if (result.status === 201) {
                let peca = result.data;
                dispatch({ type: CREATE_VENDA, peca: peca });
            } else
                throw new Error("Algo deu errado");

        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteVenda = (idPeca) => {
    return async (dispatch, getState) => {
        if (!idPeca)
            return;

        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/pecas/${idPeca}/vendas`, {
            method: 'DELETE',
            headers: { Authorization: token },
        });

        if (response.ok) {
            let venda = {};
            try {
                venda = await response.json();
                dispatch({ type: DELETE_VENDA, venda: venda });
            } catch (error) {
                throw new Error("Algo deu errado");
            };
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        };
    };
};

export const fetchVendas = (doravante = 1, busca, ordem, plataforma, dataInicio, dataFinal, quantidade = 50) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/vendas?quantidade=${quantidade}${dataInicio && dataFinal ? `&inicio=${dataInicio}&fim=${dataFinal}` : ""}&doravante=${doravante - 1}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}${plataforma ? `&plataforma=${plataforma}` : ""}`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let vendas = [];
            try {
                vendas = await response.json();
                dispatch({ type: doravante === 1 ? FETCH_RESET_VENDAS : FETCH_VENDAS, vendas: vendas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};