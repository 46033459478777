const barcoder = require('barcoder');

export const cambio = value => {
    if (!value)
        return true;

    if (/^([A-Z0-9]{1,25})$/.test(value))
        return true;
    else
        return false;
};

export const chassi = value => {
    if (!value)
        return true;

    if (/^([A-Z0-9]{17})$/.test(value))
        return true;
    else
        return false;
};

export const cnpj = value => {
    if (!value)
        return false;

    // Verifica se tem 14 dígitos
    if (value.length !== 14)
        return false;

    // Verifica se todos os caracteres são iguais
    if (/^(.)\1+$/.test(value))
        return false;

    // Verifica se tem 0001 na posição correta
    if (value.substring(8, 12) !== "0001")
        return false;

    const validarDv = (tamanho, charAtValue) => {
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += value.substring(0, tamanho).charAt(tamanho - i) * pos--;

            if (pos < 2)
                pos = 9;
        };

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== value.substring(tamanho).charAt(charAtValue))
            return false;
    };

    validarDv(cnpj.length - 2, 0);
    validarDv(cnpj.length + 1, 1);

    return true;
};

export const cpf = (value) => {
    if (!value) return false;

    if (!value.toString().match(/^([0-9][0-9][0-9])(\.)([0-9][0-9][0-9])(\.)([0-9][0-9][0-9])(-)[0-9][0-9]$/))
        return false;

    return true;
};

export const data = (value) => {
    if (!value) return false;

    if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value.toString())) {
        return false;
    };

    return true;
};

export const email = (value) => {
    if (!value) return false;

    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toString())) {
        return false;
    };

    return true;
}

export const medida = (value) => {
    if (!value) return true;

    if (!/^[0-9]{1,3}$/.test(value.toString())) return false;

    try {
        if (parseInt(value) === 0) return false;
    } catch {
        return false;
    };

    return true;
};

export const motor = value => {
    if (!value)
        return true;

    if (/^([A-Z0-9]{1,25})$/.test(value))
        return true;
    else
        return false;
};

export const nome = (value) => {
    if (!value) return false;

    if (!/.{4,100}$/.test(value.toString())) return false;

    return true;
};

export const peso = (value) => {
    if (!value) return true;

    if (!/^[0-9]{1,5}$/.test(value.toString())) return false;

    try {
        if (parseInt(value) === 0) return false;
    } catch {
        return false;
    };

    return true;
};

export const telefone = (value) => {
    if (!value) return true;

    /* if (!/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/.test(value.toString()) && !/^(?:[2-8]|[1-9])[0-9]{3}-[0-9]{4}$/.test(value.toString()) && !/^\([1-9]{2}\) (?:[2-8]|[1-9])[0-9]{3}-[0-9]{4}$/.test(value.toString()))
        return false; */

    return true;
};

export const placa = value => {
    if (!value)
        return true;

    if (/^([A-Z]{3})+(-[0-9]{4})$/.test(value) || /^([A-Z]{3})+(-[0-9]{1})+([A-Z]{1})+([0-9]{2})$/.test(value))
        return true;
    else
        return false;
};

export const preco = (value) => {
    if (!value) return false;

    if (!value.toString().match(/^[0-9]+((\.|,)[0-9]{1,2})?$/gm)) return false;

    try {
        if (parseInt(value) === 0) return false;
        if (parseInt(value) < 7) return false;
    } catch {
        return false;
    };

    return true;
};

export const precoMinimo = (value) => {
    if (!value) return true;

    if (!value.toString().match(/^[0-9]+((\.|,)[0-9]{1,2})?$/gm)) return false;

    try {
        if (parseInt(value) === 0) return false;
    } catch {
        return false;
    };

    return true;
};

export const renavam = value => {
    if (!value)
        return true;

    if (/^([0-9]{9})$/.test(value) || /^([0-9]{11})$/.test(value))
        return true;
    else
        return false;
};

export const senha = value => {
    if (!value)
        return false;

    if (value.replace(/ /g, '').length >= 4 && value.replace(/ /g, '').length <= 40 && !value.includes(" "))
        return true;
    else
        return false;
};

export const gitn = (value) => {
    return barcoder.validate(value) ? true : false;
}