// Dependências
import React from "react";
import { withRouter } from "react-router-dom";

// Css
import classes from "./Venda.module.css";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Vendas = props => {
    return (
        <tr onClick={() => props.history.push(`/estoque/pecas/${props.venda.idPeca}`)}>
            <td><img className={classes.Imagem} src={props.venda.plataforma.imagem} alt={props.venda.plataforma.nome} /></td>
            <td>{props.venda.cliente.nome}</td>
            <td>{props.venda.peca.arquetipo.nome || props.venda.anuncio.titulo}</td>
            <td>{props.venda.sucata.fipe.veiculo.nome || "-"}</td>
            <td style={{ whiteSpace: "nowrap" }}>{formatar.dinheiro(props.venda.valor)}</td>
            <td style={{ whiteSpace: "nowrap" }}>{formatar.data(props.venda.data)}</td>
        </tr>
    );
};

export default withRouter(Vendas);
