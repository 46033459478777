// Dependências
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import SelecaoPecas from "../../../../components/Cadastro/Sucata/SelecaoPecas/SelecaoPecas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const SelecaoFipeScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async pecas => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: "Você não poderá selecionar mais peças depois.",
            showCancelButton: true,
            confirmButtonText: "Ok",
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true);

                try {
                    await dispatch(pecasActions.createPecas(idSucata, pecas));
                    exibirMensagem.success("As peças foram selecionadas com sucesso.");

                    if (pecas.find(peca => peca.revisar))
                        props.history.push(`/cadastro/sucata/pecas-em-revisao/${idSucata}`);
                    else if (pecas.find(peca => peca.arquetipo.id === 350))
                        props.history.push(`/cadastro/sucata/categorias/${idSucata}`);
                    else
                        props.history.push(`/cadastro/sucata/upload-fotos/${idSucata}`);
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                };
            };
        });
    };

    return isLoading ? <Spinner /> : (
        <div>
            <SelecaoPecas
                pecas={cadastroSucata.pecas}
                idSucata={idSucata}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={pecas => submitHandler(pecas)}
                onChange={pecasAtualizadas => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.pecas = [...pecasAtualizadas];
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                }}
            />
        </div>
    );
};

export default SelecaoFipeScreen;