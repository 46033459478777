// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";
import * as pecasTiposActions from "../../../../store/actions/pecas-tipos";

// Components
import Button from "../../../../components/UI/Button/Button";
import Dropdown from "../../../../components/UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Textarea from "../../../../components/UI/Textarea/Textarea";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import * as validar from "../../../../helpers/validar";

const ModalCreatePeca = props => {
    const dispatch = useDispatch();

    const arquetipos = useSelector(state => state.pecasTipos.arquetipos);
    const pecaCriada = useSelector(state => state.pecas.peca);

    const [peca, setPeca] = useState({
        arquetipo: { id: null, nome: null },
        nome: "",
        titulo: "",
        preco: null,
        observacao: "",
        status: { id: 1 }
    });
    const [isValid, setIsValid] = useState({ nome: true, titulo: true, preco: true });
    const [isLoading, setIsLoading] = useState(false);
    const [isPostCompleted, setIsPostCompleted] = useState(false);

    // Requisição dos arquetipos
    useEffect(() => {
        const fetchArquetipos = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasTiposActions.fetchArquetipos(props.idSucata, true));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchArquetipos();
    }, [dispatch, props.idSucata]);

    useEffect(() => {
        if (!peca.arquetipo?.id && arquetipos.length) {
            let novoArquetipo = arquetipos.find(arquetipo => arquetipo.id === 350) || arquetipos[0];
            setPeca({ ...peca, arquetipo: { id: novoArquetipo.id, nome: novoArquetipo.nome } })
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peca.arquetipo, arquetipos]);

    useEffect(() => {
        if (pecaCriada.id && isPostCompleted)
            props.onRedirect(`/estoque/pecas/${pecaCriada.id}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pecaCriada, isPostCompleted]);

    const submitHandler = async () => {
        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (!validar.nome(peca.titulo)) {
            copiaIsValid.titulo = false;
            formIsValid = false;
        } else
            copiaIsValid.titulo = true;

        if (!validar.nome(peca.nome) && peca.arquetipo.id === 350) {
            copiaIsValid.nome = false;
            formIsValid = false;
        } else
            copiaIsValid.nome = true;

        if (!validar.preco(peca.preco)) {
            copiaIsValid.preco = false;
            formIsValid = false;
        } else
            copiaIsValid.preco = true;

        setIsValid(copiaIsValid);

        if (formIsValid) {
            try {
                setIsLoading(true);
                await dispatch(pecasActions.createPecas(props.idSucata, [{ ...peca, arquetipo: !peca.arquetipo.id ? { id: arquetipos[0].id, nome: arquetipos[0].nome } : peca.arquetipo }]));

                exibirMensagem.success("Peça criada com sucesso");
                setIsPostCompleted(true);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            };
        } else
            exibirMensagem.formInvalid();
    };

    return (
        <Modal titulo="Cadastrar Peça" onClose={props.onClose}>
            {isLoading ? <Spinner /> : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4">
                            Peça
                        </div>
                        <div className="col-8">
                            <Dropdown
                                returnObject
                                list={arquetipos}
                                default={arquetipos.find(arquetipo => arquetipo.id === 350)?.nome}
                                onChange={value => setPeca({
                                    ...peca,
                                    arquetipo: { id: value.id, nome: value.nome },
                                    titulo: ((peca.titulo === peca.arquetipo.nome) || !peca.titulo) && value.id !== 350 ? value.nome : peca.titulo
                                })} />
                        </div>

                        {peca.arquetipo.id === 350 ? (
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4">
                                        Nome da Peça
                                    </div>
                                    <div className="col-8">
                                        <Input
                                            maxLength="60"
                                            placeholder="Nome"
                                            invaid={!isValid.nome}
                                            onChange={value => setPeca({ ...peca, nome: value, titulo: value })} />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="col-4">
                            Título
                        </div>
                        <div className="col-8">
                            <Input
                                maxLength="60"
                                placeholder="Título"
                                setValue={peca.titulo}
                                invaid={!isValid.titulo}
                                onChange={value => setPeca({ ...peca, titulo: value })} />
                        </div>

                        <div className="col-4">
                            Preço (R$)
                        </div>
                        <div className="col-8">
                            <Input
                                onlyMoney
                                invaid={!isValid.preco}
                                placeholder="Preço (R$)"
                                onChange={value => setPeca({ ...peca, preco: formatar.stringToFloat(value) })} />
                        </div>

                        <div className="col-4">
                            Observações
                        </div>
                        <div className="col-8">
                            <Textarea
                                placeholder="Essas observações serão incluídas na descrição dos anúncios desta peça."
                                onChange={value => setPeca({ ...peca, observacao: value })} />
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-12 text-center">
                            <Button color="green" onClick={submitHandler}>Cadastrar</Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalCreatePeca;