// Dependências
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

// Components
import BarraPesquisa from "../../../../UI/BarraPesquisa/BarraPesquisa";
import Item from "./Item/Item";

// Css
import classes from "./Lista.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Lista = props => {
    const name = useState(uuidv4());
    const [busca, setBusca] = useState("");
    const [copiaItens, setCopiaItens] = useState([]);

    // Setando copiaItens
    useEffect(() => {
        if (props.itens)
            if (props.itens.length) {
                let copiaItensAtualizados = [...props.itens];

                if (busca)
                    copiaItensAtualizados = copiaItensAtualizados.filter(item => formatar.alphaNumeric(item.nome).includes(formatar.alphaNumeric(busca)));

                setCopiaItens([...copiaItensAtualizados]);
            };
    }, [props.itens, busca]);

    return props.itens ? props.itens.length ? (
        <div>
            <div className="row">
                <div className="col-12">
                    <BarraPesquisa onChange={value => setBusca(value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className={classes.Lista}>
                        {copiaItens.map(item =>
                            <Item
                                key={item.id}
                                name={name[0]}
                                item={item}
                                onChange={value => props.onChange(value)}
                                defaultChecked={props.defaultValue.id === item.id ? true : false} />)}
                    </div>
                </div>
            </div>
        </div>
    ) : null : null;
};

export default Lista;