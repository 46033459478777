// Dependência
import React from "react";

// Components
import Input from "../../../UI/Input/Input";

const Peca = props => {
    return (
        <tr>
            <td>
                {props.peca.arquetipo.nome} {props.showSucata ? `- ${props.peca.sucata}` : null}
            </td>

            <td>
                <Input
                    onlyNumbers
                    placeholder="Peso"
                    value={props.peca.medidas.peso}
                    onBlur={event => props.onChange({ ...props.peca, medidas: { ...props.peca.medidas, peso: parseInt(event.target.value) } })} />
            </td>
            <td>
                <Input
                    onlyNumbers
                    placeholder="Altura"
                    value={props.peca.medidas.altura}
                    onBlur={event => props.onChange({ ...props.peca, medidas: { ...props.peca.medidas, altura: parseInt(event.target.value) } })} />
            </td>
            <td>
                <Input
                    onlyNumbers
                    placeholder="Largura"
                    value={props.peca.medidas.largura}
                    onBlur={event => props.onChange({ ...props.peca, medidas: { ...props.peca.medidas, largura: parseInt(event.target.value) } })} />
            </td>
            <td>
                <Input
                    onlyNumbers
                    placeholder="Comprimento"
                    value={props.peca.medidas.comprimento}
                    onBlur={event => props.onChange({ ...props.peca, medidas: { ...props.peca.medidas, comprimento: parseInt(event.target.value) } })} />
            </td>
        </tr>
    );
};

export default Peca;