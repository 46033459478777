// Dependências
import React, { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { BsFillTrashFill, BsUpload } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

// Css
import classes from "./DropZone.module.css";

// Components
import Spinner from "../Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

const DropZone = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    const id = useState(uuidv4());
    const [src, setSrc] = useState("");
    const [posicaoSrc, setPosicaoSrc] = useState(0);
    const [isDraggingOver, setIsDragginOver] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Setando src inicial
    useEffect(() => {
        if (props.src) {
            if (props.src.length)
                setSrc(props.src[posicaoSrc]);
            else {
                setSrc("");
                setPosicaoSrc(0);
            };
        } else {
            setSrc("");
            setPosicaoSrc(0);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.src]);

    // Setando SRC
    useEffect(() => {
        if (props.src)
            setSrc(props.src[posicaoSrc]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posicaoSrc]);

    const dragEnter = event => {
        event.preventDefault();
        setIsDragginOver(true);
    };

    const dragLeave = event => {
        event.preventDefault();
        setIsDragginOver(false);
    };

    const dragOver = event => {
        event.preventDefault();
        setIsDragginOver(true);
    };

    const comprimirArquivo = imageFiles => {
        const options = {
            maxSizeMB: 0.9,
            maxWidthOrHeight: 1200,
            useWebWorker: true
        };

        return imageFiles.map(async imageFile => {
            if (imageFile.size < options.maxSizeMB * 1000)
                return imageFile;
            else {
                try {
                    let file = await imageCompression(imageFile, options);
                    let src = await imageCompression.getDataUrlFromFile(imageFile);
                    return { file: file, src: src };
                } catch (error) {
                    exibirMensagem.error();
                    console.log(error);
                };
            };
        });
    };

    const handleImageChange = files => {
        if (!files.length)
            return;

        let filesCapturados = [];
        let quantidadeFilesReceber = props.quantidade ? props.quantidade : 1;

        if (props.src?.length)
            quantidadeFilesReceber = quantidadeFilesReceber - props.src.length;

        for (let i = 0; i < quantidadeFilesReceber; i++) {
            if (files[i])
                filesCapturados.push(files[i]);
        };

        setIsDragginOver(false);

        // Verifica se o arquivo é suportado
        filesCapturados = filesCapturados.filter(file => {
            if (!file.type.match("image") && !props.notImage) {
                exibirMensagem.error("Tipo de arquivo não é suportado.");
                return false;
            } else {
                let formatosSuportados = props.accept ? [...props.accept] : ["jpg", "jpeg", "png"];
                let acceptFile = false;

                formatosSuportados.forEach(formatoSuportado => {
                    if (file.type.match(formatoSuportado))
                        acceptFile = true;
                });

                return acceptFile;
            };
        });

        if (!filesCapturados.length) {
            exibirMensagem.error("Tipo de arquivo não é suportado.");
            return;
        };

        setIsLoading(true);

        if (!props.notImage) {
            Promise.all(comprimirArquivo(filesCapturados)).then(compressedFiles => {
                if (props.onChange)
                    props.onChange(compressedFiles);
                setIsLoading(false);
            });
        } else {
            props.onChange(filesCapturados);
            setIsLoading(false);
        };
    };

    return isLoading ? <Spinner className={classes.Spinner} /> :
        <div className={`${classes.DropZone} ${darkMode ? classes.Dark : ""} ${isDraggingOver && (!src || props.quantidade > 1) ? classes.DaggingOver : ""}`}>
            <label
                htmlFor={!src ? id[0] : null}
                className={`${classes.Label} ${!src ? classes.Hoverble : ""}`}
                style={src ? { cursor: "default" } : null}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={event => {
                    event.preventDefault();
                    handleImageChange(event.dataTransfer.files)
                }} >

                {props.quantidade > 1 && !src ?
                    <div className={classes.Quantidade}>{props.quantidade}</div>
                    : null}

                {isDraggingOver && (!src || props.quantidade > 1) ? (
                    <div>
                        <div className={classes.Icon} >
                            <AiOutlineDownload size="55px" />
                        </div>
                        <div className={classes.Title}>
                            {props.title ? props.title : "Solte aqui"}
                        </div>
                    </div>
                ) : !src ? (
                    <div>
                        <div className={classes.Icon} >
                            <BsUpload size="50px" />
                        </div>

                        <div className={classes.Title}>
                            {props.title ? props.title : "Selecione os arquivos"}
                        </div>

                        <div className={classes.Subtitulo}>
                            {props.subtitulo !== undefined ? props.subtitulo : "Arraste e solte os arquivos aqui ou clique para escolher."}
                        </div>
                    </div>
                ) : (
                    <div>
                        <BsFillTrashFill
                            size="20px"
                            title="Apagar esta imagem"
                            className={classes.Delete}
                            onClick={() => {
                                let posicaoSrcAnterior = posicaoSrc;

                                if (posicaoSrc > props.src.length - 2) {
                                    if (!posicaoSrc - 1 < 0)
                                        setPosicaoSrc(posicaoSrc - 1);
                                    else
                                        setPosicaoSrc(0);
                                };

                                props.onDelete(posicaoSrcAnterior);
                            }}
                        />

                        {props.src.length > 1 && posicaoSrc !== 0 ? (
                            <div
                                className={classes.ArrowLeft}
                                onClick={() => posicaoSrc !== 0 ? setPosicaoSrc(posicaoSrc - 1) : null}>
                                <MdKeyboardArrowLeft
                                    size="35px"
                                    title="Foto anterior" />
                            </div>
                        ) : null}

                        <img
                            className={classes.Imagem}
                            src={src}
                            alt="" />

                        {props.src.length > 1 && posicaoSrc !== props.src.length - 1 ? (
                            <div
                                className={classes.ArrowRight}
                                onClick={() => posicaoSrc !== props.src.length - 1 ? setPosicaoSrc(posicaoSrc + 1) : console.log(posicaoSrc)}>
                                <MdKeyboardArrowRight
                                    size="35px"
                                    title="Próxima foto" />
                            </div>
                        ) : null}

                        {props.imageTitle ? <div className={classes.ImageTitle}>{props.imageTitle}</div> : null}
                    </div>
                )}
            </label>

            <input
                id={id[0]}
                type="file"
                className={classes.Input}
                accept={props.accept}
                multiple={props.quantidade ? props.quantidade > 1 ? true : false : false}
                onChange={event => handleImageChange(event.target.files)} />
        </div >
};

export default DropZone;