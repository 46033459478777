// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as despesasActions from "../../../store/actions/despesas";

// Css
import classes from "./DespesasScreen.module.css";

// Components
import Button from "../../../components/UI/Button/Button";
import Spinner from "../../../components/UI/Spinner/Spinner";
import DespesaMensal from "../../../components/Financeiro/Despesas/DespesaMensal/DespesaMensal";
import DespesaUnica from "../../../components/Financeiro/Despesas/DespesaUnica/DespesaUnica";
import ModalCadastroDespesa from "../../../components/Financeiro/Despesas/ModalCadastroDespesa/ModalCadastroDespesa";
import ModalEdicaoDespesa from "../../../components/Financeiro/Despesas/ModalEdicaoDespesa/ModalEdicaoDespesa";
import Table from '../../../components/UI/Table/Table';
import Titulo from '../../../components/UI/Titulo/Titulo';

const DespesaScreen = (props) => {
    const dispatch = useDispatch();

    const despesas = useSelector(state => state.despesas.despesas);
    const frequencias = useSelector(state => state.despesas.frequencias);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [showModalCadastroDespesa, setShowModalCadastroDespesa] = useState(false);

    // Despesa que vai ser editada no modal de edição
    const [despesaNoModal, setDespesaNoModal] = useState({});
    const [showModalEdicaoDespesa, setShowModalEdicaoDespesa] = useState(false);

    const [despesasUnicas, setDespesasUnicas] = useState([]);
    const [despesasMensais, setDespesasMensais] = useState([]);

    // Requisição de despesas
    useEffect(() => {
        const fetchDespesas = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchDespesas());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
            setIsRequestCompleted(true);
        };
        fetchDespesas();
    }, [dispatch]);

    // Requisição das frequências
    useEffect(() => {
        const fetchFrequencias = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchFrequencias());
            } catch (error) {
                console.log(error);
            };

            setIsLoading(false);
        };
        fetchFrequencias();
    }, [dispatch]);

    // Definindo despesasUnicas e despesasMensais
    useEffect(() => {
        if (frequencias.length) {
            let newDespesasUnicas = [];
            let newDespesasMensais = [];

            frequencias.forEach(frequencia => {
                despesas.forEach(despesa => {
                    if (frequencia.id === despesa.frequencia.id)
                        if (!frequencia.periodica)
                            newDespesasUnicas.push({ ...despesa })
                });
                despesas.forEach(despesa => {
                    if (frequencia.id === despesa.frequencia.id)
                        if (frequencia.periodica)
                            newDespesasMensais.push({ ...despesa })
                });
            });

            setDespesasUnicas(newDespesasUnicas);
            setDespesasMensais(newDespesasMensais);
        };
    }, [despesas, frequencias])

    return isLoading || !isRequestCompleted ? <Spinner /> : (
        <div>
            {showModalCadastroDespesa ?
                <ModalCadastroDespesa
                    onClose={() => setShowModalCadastroDespesa(false)}
                />
                : null}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 text-left py-1">
                        <Button
                            color="purple"
                            onClick={() => props.history.push("/financeiro/despesas/categorias")}
                        >Ver Gráficos</Button>
                    </div>

                    <div className="col-6 text-right py-1">
                        <Button
                            color="green"
                            onClick={() => setShowModalCadastroDespesa(true)}
                        >Cadastrar uma Despesa</Button>
                    </div>
                </div>
            </div>

            {despesas.length ? (
                <div>
                    {showModalEdicaoDespesa ?
                        <ModalEdicaoDespesa
                            onClose={() => setShowModalEdicaoDespesa(false)}
                            despesa={{ ...despesaNoModal }}
                        />
                        : null}

                    {despesasMensais.length ? (
                        <div className="container-fluid">
                            <Titulo>Despesas Mensais</Titulo>

                            <div className="row mb-5" style={{ height: "40vh" }}>
                                <Table
                                    header={["Código", "Despesa", "Valor parcelas", "Parcelas pagas", "Parcelas restantes", "Valor pago", "Dívida restante", "Data início", "Data final"]}
                                    bordered
                                    fixed
                                    hoverble
                                    small>
                                    {despesasMensais.map(despesa =>
                                        <DespesaMensal
                                            key={despesa.id}
                                            despesa={despesa}
                                            onClick={() => {
                                                setDespesaNoModal({ ...despesa });
                                                setShowModalEdicaoDespesa(true);
                                            }} />
                                    )}
                                </Table>
                            </div>
                        </div>
                    ) : null}

                    {despesasUnicas.length ? (
                        <div className="container-fluid">
                            <Titulo>Despesas Únicas</Titulo>

                            <div className="row" style={{ height: "40vh" }}>
                                <Table
                                    header={["Código", "Despesa", "Valor", "Data"]}
                                    bordered
                                    fixed
                                    hoverble
                                    small>
                                    {despesasUnicas.map(despesa =>
                                        <DespesaUnica
                                            key={despesa.id}
                                            despesa={despesa}
                                            onClick={() => {
                                                setDespesaNoModal({ ...despesa });
                                                setShowModalEdicaoDespesa(true);
                                            }} />
                                    )}
                                </Table>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className={classes.Parent}>
                    <div className={classes.Center}>
                        <h2>Você ainda não tem nenhuma despesa registrada!</h2>
                        <br />
                        <h4>Você pode cadastrar uma despesa clicando <span onClick={() => setShowModalCadastroDespesa(true)}>aqui.</span></h4>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DespesaScreen;
