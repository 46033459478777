// Dependências
import React from "react";

// Components
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import Input from "../../../../UI/Input/Input";
import Tooltip from "../../../../UI/Tooltip/Tooltip";

// Css
import classes from "./TipoAnuncio.module.css";

const TipoAnuncio = props => {
    return (
        <div className="row">
            <div className="col-6 pb-4">
                <div className={classes.CheckboxField}>
                    <Checkbox
                        className={classes.Checkbox}
                        checkboxColor={props.cor}
                        disabled={props.tipoAnuncio.obrigatorio}
                        defaultChecked={props.tipoAnuncio.obrigatorio ? true : props.tipoAnuncio.selecionado}
                        onChange={value => {
                            let copiaTipoAnuncio = { ...props.tipoAnuncio };
                            copiaTipoAnuncio.selecionado = value;
                            props.onChange(copiaTipoAnuncio);
                        }}
                    >
                        {props.tipoAnuncio.nome}
                        {props.tipoAnuncio.descricao ? <Tooltip className="ml-3">{props.tipoAnuncio.descricao}</Tooltip> : null}
                    </Checkbox>
                </div>
            </div>

            {props.tipoAnuncio.selecionado && props.tipoAnuncio.editavel ? (
                <div className="col-6">
                    <span className="h6">
                        Conversão de preço
                        <Tooltip className="ml-2">Ao alterar este campo, o preço das peças deste tipo de anúncio serão alterados de acordo com a porcentagem escolhida.</Tooltip>
                    </span>

                    <Input
                        type="range"
                        min="90"
                        max="120"
                        value={props.tipoAnuncio.porcentagem}
                        onBlur={event => {
                            let copiaTipoAnuncio = { ...props.tipoAnuncio };
                            copiaTipoAnuncio.porcentagem = parseInt(event.target.value);
                            props.onChange(copiaTipoAnuncio);
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default TipoAnuncio;