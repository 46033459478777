// Dependências
import React from "react";

// Components
import Spinner from "../../../../../UI/Spinner/Spinner";

const InlineSpinner = props => {
    return (
        <div style={{ position: "relative", width: "100%" }}>
            <Spinner style={{ marginLeft: "-10px" }} inline />
        </div>
    );
};

export default InlineSpinner;