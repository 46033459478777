// Dependências
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

// Components
import Input from "../../../../UI/Input/Input";
import Dropdown from "../../../../UI/BeautyDropdown/BeautyDropdown";

// Css
import classes from "./Telefone.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Telefone = props => {
    return (
        <div className="row">
            <div className="col-2">
                <Input
                    onlyNumbers
                    maxLength="2"
                    placeholder="DDD"
                    value={props.telefone.ddd}
                    onChange={value => props.onChange({ ...props.telefone, ddd: value })}
                />
            </div>
            <div className="col-6">
                <Input
                    telefone
                    maxLength="10"
                    placeholder="Telefone"
                    value={props.telefone.nome}
                    onChange={value => props.onChange({ ...props.telefone, nome: formatar.alphaNumeric(value) })}
                />
            </div>
            <div className="col-4">
                <Dropdown
                    returnObject
                    default={props.telefone.tipo.nome}
                    list={[
                        { id: 1, nome: "Fixo" },
                        { id: 2, nome: "Celular" },
                        { id: 3, nome: "WhatsApp" },
                        { id: 4, nome: "Celular & WhatsApp" }
                    ]}
                    onChange={value => props.onChange({ ...props.telefone, tipo: value })}
                />

                <AiOutlineClose
                    className={classes.CloseButton}
                    title="Apagar telefone"
                    onClick={props.onDelete}
                />
            </div>
        </div>
    );
};

export default Telefone;