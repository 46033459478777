// Dependências
import React from "react";

// Css
import classes from "./Taxa.module.css";

// Helpers
import * as formatar from "../../../../../helpers/formatar";

const Taxa = props => {
    return (
        <tr>
            <td>
                <img className={classes.Logo} src={props.taxa.tipoAnuncio.plataforma.imagem} alt="Logo" />
            </td>
            <td>
                {props.taxa.tipoAnuncio.nome}
            </td>
            <td>
                {formatar.dinheiro(props.preco)}
            </td>
            <td>
                {formatar.dinheiro(props.taxa.envio)}
            </td>
            <td>
                {formatar.dinheiro(props.taxa.venda)}
            </td>
            <td>
                {formatar.dinheiro(props.taxa.total)}
            </td>
            <td className={classes.Recebe}>
                {formatar.dinheiro(props.preco - props.taxa.total)}
            </td>
        </tr>
    );
};

export default Taxa;