// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import * as adminActions from "../../../store/actions/admin";

// Components
import Button from "../../../components/UI/Button/Button";
import Conta from "../../../components/Admin/Conta/Conta";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

const ContasScreen = () => {
    const dispatch = useDispatch();

    const contas = useSelector(state => state.admin.contas);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das contas
    useEffect(() => {
        const fetchContas = async () => {
            setIsLoading(true);
            try {
                await dispatch(adminActions.fetchContas());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchContas();
    }, [dispatch]);

    return isLoading ? <Spinner /> : (
        <div className="container-fluid">
            <Titulo>Contas do Unificar</Titulo>

            <div className="row">
                <div className="col-12 text-right">
                    <Link to="/contas/cadastro">
                        <Button color="green">Cadastrar Novo</Button>
                    </Link>
                </div>
            </div>

            <div className="row" style={{ height: "78vh" }}>
                <Table
                    bordered
                    hoverble
                    fixed
                    header={["ID", "Nome", "Email", "Tipo", "Usuários"]}
                >
                    {contas.filter(conta => conta.tipo.id !== 1).map(conta =>
                        <Conta
                            key={conta.id}
                            conta={conta}
                            onClick={() => {
                                exibirMensagem.exibir({
                                    title: "Visualizar conta?",
                                    text: `Deseja entrar em modo visualização nesta conta?`,
                                    showCancelButton: true,
                                    confirmButtonText: "Sim",
                                }).then(async result => {
                                    if (result.isConfirmed) {
                                        try {
                                            await dispatch(adminActions.switchConta(conta.id));
                                        } catch (error) {
                                            console.log(error);
                                        };
                                    } else
                                        return;
                                });
                            }}
                        />
                    )}
                </Table>
            </div>
        </div>
    );
};

export default ContasScreen;