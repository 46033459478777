import {
    FETCH_FINANCEIRO,
    FETCH_RESET_FINANCEIRO,
    FETCH_FINANCEIRO_SUCATA,
} from "../actions/financeiro";

const initialState = {
    financeiro: {},
    sucata: {},
};

const financeiroReducer = (state = initialState, action) => {
    let copiaFinanceiro = { ...state.financeiro };
    switch (action.type) {
        case FETCH_FINANCEIRO:
            action.financeiro.sucatas.forEach(sucata => copiaFinanceiro.sucatas.push({ ...sucata }));
            return { ...state, financeiro: copiaFinanceiro };
        case FETCH_RESET_FINANCEIRO:
            return { ...state, financeiro: action.financeiro };
        case FETCH_FINANCEIRO_SUCATA:
            return { ...state, sucata: action.sucata };
        default:
            return state;
    }
};

export default financeiroReducer;
