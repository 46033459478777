// Dependências
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import * as vendasActions from "../../../store/actions/vendas";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import BeautyInput from "../../../components/UI/BeautyInput/BeautyInput";
import Dropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";
import Titulo from "../../../components/UI/Titulo/Titulo";
import Venda from "../../../components/Financeiro/Venda/Venda";

// Css
import classes from "./VendasScreen.module.css";

// Helpers
import dataHoje from "../../../helpers/dataHoje";
import * as formatar from "../../../helpers/formatar";
import * as validar from "../../../helpers/validar";

const VendasScreen = props => {
    const dispatch = useDispatch();

    let plataforma = props.match.params.plataforma;
    const vendas = useSelector(state => state.vendas.vendas);

    const [busca, setBusca] = useState("");
    const [doravante, setDoravante] = useState(1);
    const [doRequest, setDoRequest] = useState(false);
    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [ordem, setOrdem] = useState("mais-recente");

    const [dataInicio, setDataInicio] = useState(dataHoje(-1));
    const [dataFinal, setDataFinal] = useState(dataHoje());

    // Requisição de vendas
    useEffect(() => {
        const fetchVendas = async () => {
            try {
                setIsLoading(true);
                await dispatch(vendasActions.fetchVendas(doravante, busca, ordem, plataforma, dataInicio, dataFinal));
                setIsLoading(false);
                setIsFirstRequestCompleted(true);
            } catch (error) {
                console.log(error);
            };
        };

        fetchVendas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, doravante, busca, doRequest, plataforma]);

    const handleOnScrollBottom = () => {
        if (!(vendas.length % 50))
            setDoravante(doravante + 1);
    };

    return isLoading && !isFirstRequestCompleted ? <Spinner /> : (
        <div>
            <Titulo subtitulo={plataforma ? plataforma === "mercado-livre" ? "Mercado Livre" : plataforma === "mundo-da-peca" ? "Mundo da Peça" : "Loja Física" : null}> Histórico de Vendas </Titulo>

            {vendas.length || isLoading ? (
                <div className="row" style={{ height: "62vh" }}>
                    <div className="col-8">
                        <BarraPesquisa onChange={value => setBusca(value)} />
                    </div>
                    <div className="col-4">
                        <Dropdown
                            list={[
                                { id: "mais-recente", nome: "Mais recentes" },
                                { id: "mais-antigo", nome: "Mais antigas" },
                            ]}
                            onChange={idOrdem => {
                                setOrdem(idOrdem);

                                if (doravante !== 1)
                                    setDoravante(1);
                                else
                                    setDoRequest(!doRequest);
                            }} />
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <BeautyInput
                                    small
                                    type="date"
                                    placeholder="A partir de:"
                                    defaultValue={dataInicio}
                                    onBlur={event => {
                                        if (validar.data(event.target.value) && new Date(event.target.value) < new Date(dataFinal)) {
                                            setDataInicio(event.target.value);

                                            if (doravante !== 1)
                                                setDoravante(1);
                                            else
                                                setDoRequest(!doRequest);
                                        } else
                                            event.target.value = dataInicio;
                                    }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <BeautyInput
                                    small
                                    type="date"
                                    placeholder="Até:"
                                    defaultValue={dataFinal}
                                    onBlur={event => {
                                        if (validar.data(event.target.value) && new Date(dataInicio) < new Date(event.target.value)) {
                                            setDataFinal(event.target.value);

                                            if (doravante !== 1)
                                                setDoravante(1);
                                            else
                                                setDoRequest(!doRequest);
                                        } else
                                            event.target.value = dataFinal;
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 text-center">
                                <h3>Total: <span style={{ color: "#79cd56" }}>{formatar.dinheiro(vendas.reduce((sum, venda) => sum += venda.valor, 0))}</span></h3>
                            </div>
                        </div>
                    </div>

                    <Table
                        fixed
                        bordered
                        hoverble
                        isLoading={isLoading}
                        onScrollBottom={handleOnScrollBottom}
                        header={["Plataforma", "Comprador", "Peça", "Modelo", "Preço", "Data"]} height="93%">
                        {vendas.map(venda =>
                            <Venda key={venda.id} venda={venda} />)}
                    </Table>
                </div>
            ) : (
                <div className="row" style={{ height: "83vh" }}>
                    <div style={{ position: "relative", width: "100%", height: "90%" }}>
                        <div className={classes.Center}>
                            <h2>Nenhuma venda encontrada!</h2>
                            <br />
                            <h4>Não encontramos nenhuma venda, mas não se preocupe...  </h4>
                            <br />
                            <h4>Assim que alguma venda for registrada, ela aparecerá aqui.</h4>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VendasScreen;
