// Dependências
import React from "react";
import { NavLink } from "react-router-dom";

// Css
import classes from "./SideDrawerButton.module.css";

const SideDrawerButton = props => (
    <NavLink
        to={props.to}
        className={classes.SideDrawerButton}
        activeClassName={classes.SideDrawerButtonActive}
    >
        <li onClick={props.onClick}>
            {props.children}
        </li>
    </NavLink>
);

export default SideDrawerButton;