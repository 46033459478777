// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import * as pecasActions from "../../../store/actions/pecas";

// Components
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Peca from "./Peca/Peca";
import Spinner from "../../UI/Spinner/Spinner";
import Table from "../../UI/Table/Table";

const Categorias = props => {
    const dispatch = useDispatch();

    const [copiaPecas, setCopiaPecas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Setando copiaPecas
    useEffect(() => {
        const newCopiaPecas = props.pecas.map(peca => {
            return {
                ...peca,
                categoria: { id: null, nome: null, temSubcategorias: true, subcategorias: [] }
            };
        });

        setCopiaPecas(newCopiaPecas);
    }, [props.pecas]);

    const submitHandler = async () => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(copiaPecas.map(peca => {
                return {
                    id: peca.id,
                    categoria: { id: peca.categoria.id, nome: peca.categoria.nome }
                };
            })));

            props.onProxClick();
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        };
    };

    return !copiaPecas.length || isLoading ? <Spinner /> : (
        <div>
            <h4>Informe as categorias dos seguintes anúncios:</h4>

            <Table header={["Peça", "Categoria", "Subcategorias"]}>
                {copiaPecas.map(peca =>
                    <Peca
                        key={peca.id}
                        peca={peca}
                        onChange={novaPeca => setCopiaPecas(prevCopiaPecas => prevCopiaPecas.map(copiaPeca => copiaPeca.id === peca.id ? novaPeca : copiaPeca))} />
                )}
            </Table>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default Categorias;