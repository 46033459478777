// Dependências
import React from "react";
import { BsPlus } from "react-icons/bs";

// Components
import Button from "../../../../UI/Button/Button";

// Css
import classes from "./ButtonCreatePeca.module.css";

const ButtonCreatePeca = props => {
    return (
        <tr className={classes.TableLine}>
            <td colSpan="3">
                <Button
                    color="green"
                    onClick={props.onClick}
                >
                    <BsPlus size="24px" /> Clique aqui para cadastrar uma peça adicional
                </Button>
            </td>
        </tr>
    );
};

export default ButtonCreatePeca;