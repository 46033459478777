// Dependências
import React from "react";

// Components
import BeautyDropdown from "../../../../UI/BeautyDropdown/BeautyDropdown";

const List = props => {
    return (
        <BeautyDropdown
            notInline
            returnObject
            titulo={props.atributo.nome}
            invalid={props.atributo.isValid === false ? true : false}
            default={props.atributo.value ? props.atributo.value.nome : null}
            list={[{ id: 0, nome: "Selecione um valor" }, ...props.atributo.valores]}
            onChange={value => {
                let copiaAtributo = { ...props.atributo };
                copiaAtributo.value = value;
                props.onChange(copiaAtributo);
            }}
            small />
    );
};

export default List;