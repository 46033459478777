// Dependências
import React from 'react';

// Css
import classes from './BotaoWhatsapp.module.css';

const botaoWhatsapp = props => {
    return (
        <div className={classes.DivBotaoWhatsapp}>
            <a
                title="Fale conosco"
                className={`${classes.BotaoWhatsapp}`}
                href={`https://wa.me/5545999463628`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src="/images/wpp.png" width="45px" alt="Botão do Whatsapp" />
            </a>
        </div>
    );
};


export default botaoWhatsapp;