// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";

// Actions
import * as gruposSucatasActions from "../../../store/actions/grupos-sucatas";

// Css
import classes from "./GruposSucatasScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../components/UI/Button/Button";
import ContextMenu from "../../../components/UI/ContextMenu/ContextMenu";
import Dropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import GrupoSucatas from "../../../components/Estoque/GrupoSucatas/GrupoSucatas";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Table from "../../../components/UI/Table/Table";

const GruposSucatasScreen = props => {
    const dispatch = useDispatch();
    const gruposSucatas = useSelector(state => state.gruposSucatas.gruposSucatas);

    const [busca, setBusca] = useState("");

    const [doravante, setDoravante] = useState(1);
    const [doRequest, setDoRequest] = useState(false);
    const [ordem, setOrdem] = useState("mais-recente");
    const [isLoading, setIsLoading] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);

    const [idGrupoSucatasContextMenu, setidGrupoSucatasContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição dos grupos de sucatas
    useEffect(() => {
        const fetchGruposSucatas = async () => {
            try {
                setIsLoading(true);
                await dispatch(gruposSucatasActions.fetchGruposSucatas(doravante, busca, ordem, 50));
                setIsRequestCompleted(true);
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchGruposSucatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, doravante, doRequest]);

    // Chama o action de apagar um grupo de sucatas
    const deleteGrupoSucatas = async idGrupoSucatas => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir estas peças? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(gruposSucatasActions.deleteGrupoSucata(idGrupoSucatas));
                    exibirMensagem.success("Peças excluídas com sucesso.");
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idPeca) => {
        event.preventDefault();

        setidGrupoSucatasContextMenu(idPeca);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const handleOnScrollBottom = () => {
        if (!(gruposSucatas.length % 50))
            setDoravante(doravante + 1);
    };

    return !isRequestCompleted ? <Spinner /> : gruposSucatas.length || isLoading || busca.length ? (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <BarraPesquisa
                        placeholder="Pesquisar no meu estoque"
                        onChange={novaBusca => {
                            if (doravante !== 1)
                                setDoravante(1);
                            else
                                setDoRequest(!doRequest);

                            setBusca(novaBusca);
                        }} />
                </div>

                <div className="col-md-4 col-sm-12">
                    <Dropdown
                        list={[
                            { id: "mais-recente", nome: "Mais recentes" },
                            { id: "mais-antigo", nome: "Mais antigas" }
                        ]}
                        onChange={idOrdem => {
                            if (doravante !== 1)
                                setDoravante(1);
                            else
                                setDoRequest(!doRequest);

                            setOrdem(idOrdem);
                        }} />
                </div>
            </div>

            <div className={`row mb-2 ${classes.HiddenMobile}`}>
                <div className="col-3">
                    <Button small color="purple" onClick={() => props.history.push("/estoque/pecas")}>
                        <AiOutlineArrowLeft /> Listar peças
                    </Button>
                </div>
            </div>

            <div className="row" style={{ height: "78vh" }}>
                <Table
                    fixed
                    isLoading={isLoading}
                    hoverble={gruposSucatas.length}
                    bordered={gruposSucatas.length}
                    onScrollBottom={handleOnScrollBottom}
                    header={["Código", "Nome", "Quantidade de Peças", "Data de Cadastro"]}>
                    {gruposSucatas.length || isLoading ? gruposSucatas.map(grupoSucatas =>
                        <GrupoSucatas
                            key={grupoSucatas.id}
                            grupoSucatas={grupoSucatas}
                            onContextMenu={event => handleContextMenuClick(event, grupoSucatas.id)} />
                    ) : <tr><td className="h5" colSpan="5">Nenhum resultado encontrado</td></tr>}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    <li onClick={() => deleteGrupoSucatas(idGrupoSucatasContextMenu)}>Excluir</li>
                </ContextMenu>
            </div>
        </div>
    ) : (
        <div className="row" style={{ height: "83vh" }}>
            <div style={{ position: "relative", width: "100%", height: "90%" }}>
                <div className={classes.Center}>
                    <h2>Você ainda não tem nenhum grupo de peças registrada!</h2>
                    <br />
                    <h4>Você pode cadastrar um clicando <Link to="/cadastro/grupo-pecas"> aqui</Link>.</h4>
                </div>
            </div>
        </div>
    );
};

export default GruposSucatasScreen;
