import {
    CREATE_USUARIO,
    CREATE_VINCULO,
    FETCH_METRICAS,
    FETCH_MEU_USUARIO,
    FETCH_TIPOS_PERMISSAO,
    UPDATE_USUARIO
} from "../actions/usuario";

import {
    FETCH_CONTA_TOKEN
} from "../actions/admin";

import {
    LOGOUT
} from "../actions/auth";


const initialState = {
    metricas: {},
    meuUsuario: {},
    tiposPermissao: [],
    usuario: {},
    usuarios: []
};

const usuarioReducer = (state = initialState, action) => {
    let copiaMeuUsuario = { ...state.meuUsuario };
    let copiaUsuario = { ...state.usuario };
    let copiaUsuarios = [...state.usuarios];

    switch (action.type) {
        case CREATE_USUARIO:
            copiaUsuarios.push(action.usuario);
            return { ...state, usuarios: copiaUsuarios }
        case CREATE_VINCULO:
            copiaMeuUsuario.conta.vinculos = [...action.vinculos];
            return { ...state, meuUsuario: copiaMeuUsuario }
        case FETCH_METRICAS:
            return { ...state, metricas: action.metricas };
        case FETCH_MEU_USUARIO:
            return { ...state, meuUsuario: action.meuUsuario };
        case FETCH_TIPOS_PERMISSAO:
            return { ...state, tiposPermissao: action.tiposPermissao };
        case UPDATE_USUARIO:
            if (copiaMeuUsuario.id === action.usuario.id)
                copiaMeuUsuario = action.usuario;
            else
                copiaUsuario = action.usuario;

            return { ...state, usuario: copiaUsuario, meuUsuario: copiaMeuUsuario };
        case FETCH_CONTA_TOKEN:
            return { ...state, meuUsuario: {} };
        case LOGOUT:
            return { ...state, meuUsuario: {} };
        default:
            return state;
    }
};

export default usuarioReducer;
