// Dependências
import React from "react";

// Helper
import * as formatar from "../../../helpers/formatar";

const Funcionario = (props) => {
    return (
        <tr onClick={props.onClick}>
            <td>{props.funcionario.id}</td>
            <td className="text-left">{props.funcionario.nome}</td>
            <td className="text-left">{props.funcionario.cargo ? props.funcionario.cargo : "-"}</td>
            <td>{props.funcionario.quantidadeVendasMes} </td>
            <td>{props.funcionario.valorVendasMes ? formatar.dinheiro(props.funcionario.valorVendasMes) : "-"}</td>
            <td>{formatar.dinheiro(props.funcionario.mediaValorVendas)}</td>
            <td>{props.funcionario.quantidadeVendas}</td>
            <td>{props.funcionario.valorVendas ? formatar.dinheiro(props.funcionario.valorVendas) : "-"}</td>
        </tr>
    );
};

export default Funcionario;
