import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig"

export const FETCH_ANOS = "FETCH_ANOS";
export const FETCH_MARCAS = "FETCH_MARCAS";
export const FETCH_MODELOS = "FETCH_MODELOS";
export const FETCH_TIPOS = "FETCH_TIPOS";
export const FETCH_VEICULOS = "FETCH_VEICULOS";

export const fetchAnos = (idTipo, idMarca) => {
    return async (dispatch, getState) => {
        if (!idMarca || !idTipo)
            return;


        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/fipe/tipos/${idTipo}/marcas/${idMarca}/anos`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let anos = [];
            try {
                anos = await response.json();
                dispatch({ type: FETCH_ANOS, anos: anos });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchMarcas = (idTipo) => {
    return async (dispatch, getState) => {
        if (!idTipo)
            return;

        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/fipe/tipos/${idTipo}/marcas`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let marcas = [];
            try {
                marcas = await response.json();
                dispatch({ type: FETCH_MARCAS, marcas: marcas });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchModelos = (idAno, idVeiculo) => {
    return async (dispatch, getState) => {
        if (!idAno || !idVeiculo)
            return;

        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/fipe/anos/${idAno}/veiculos/${idVeiculo}/modelos`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        if (response.ok) {
            let modelos = [];
            try {
                modelos = await response.json();
                dispatch({ type: FETCH_MODELOS, modelos: modelos });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchTipos = () => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/fipe/tipos`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let tipos = [];
            try {
                tipos = await response.json();
                dispatch({ type: FETCH_TIPOS, tipos: tipos });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchVeiculos = (idTipo, idMarca, idAno) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/fipe${idTipo ? `/tipos/${idTipo}` : ""}${idMarca ? `/marcas/${idMarca}` : ""}${idAno ? `/anos/${idAno}` : ""}/veiculos`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let veiculos = [];
            try {
                veiculos = await response.json();
                dispatch({ type: FETCH_VEICULOS, veiculos: veiculos });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};
