import { API_URL } from "../../constants/GlobalConfig";
import statusHandler from "../../helpers/statusHandler";

export const SET_PECA = "SET_PECA";
export const SET_PECAS = "SET_PECAS";

export const fetchMarcas = peca => {
    return async (dispatch, getState) => {
        if (!peca)
            return;

        if (!peca.fipe.tipo.id)
            return;

        const response = await fetch(
            `${API_URL}/fipe/tipos/${peca.fipe.tipo.id}/marcas`,
            { headers: { Authorization: getState().auth.token } }
        );

        if (response.ok) {
            let marcas = [];
            try {
                marcas = await response.json();

                let copiaPeca = { ...peca };

                // Setando o fipe
                copiaPeca.fipe.marca = { id: null, nome: null };
                copiaPeca.fipe.veiculo = { id: null, nome: null };


                // Setando as opções
                copiaPeca.fipeOptions.marcas = [...marcas];
                copiaPeca.fipeOptions.veiculos = [];

                await dispatch({ type: SET_PECA, peca: peca });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        };
    };
};

export const fetchVeiculos = peca => {
    return async (dispatch, getState) => {
        if (!peca)
            return;

        if (!peca.fipe.marca.id)
            return;

        const response = await fetch(
            `${API_URL}/fipe/marcas/${peca.fipe.marca.id}/veiculos`,
            { headers: { Authorization: getState().auth.token } }
        );

        if (response.ok) {
            let veiculos = [];
            try {
                veiculos = await response.json();

                let copiaPeca = { ...peca };

                // Setando o fipe
                copiaPeca.fipe.veiculo = { id: null, nome: null };

                // Setando as opções de veículos
                copiaPeca.fipeOptions.veiculos = [...veiculos];

                await dispatch({ type: SET_PECA, peca: peca });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        };
    };
};

export const setPecas = pecas => {
    return (dispatch, getState) =>
        dispatch({ type: SET_PECAS, pecas: pecas });
};