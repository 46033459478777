// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";
import * as gruposSucatasActions from "../../../../store/actions/grupos-sucatas";

// Components
import Precificacao from "../../../../components/Cadastro/Precificacao/Precificacao";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const PrecificacaoScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;
    const grupoSucatas = useSelector(state => state.gruposSucatas.grupoSucatas);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas aguardando fotos
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(gruposSucatasActions.fetchGrupoSucatas(idGrupoSucatas, 13));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [idGrupoSucatas, dispatch]);

    const updatePecas = async pecasAtualizadas => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(pecasAtualizadas.map(peca => { return { id: peca.id, preco: peca.preco, observacao: peca.observacao } })));
            exibirMensagem.success("Alterações realizadas com sucesso.");
            props.history.push(`/cadastro/grupo-pecas/selecao-tipos-anuncio/${idGrupoSucatas}`);
        } catch (error) {
            console.log(error);
        };

        setIsLoading(false);
    };

    return isLoading || !grupoSucatas.sucatas?.length ? <Spinner /> : (
        <div>
            <Precificacao
                showSucata
                pecas={[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas.map(peca => { return { ...peca, sucata: sucata?.fipe?.veiculo?.nome || "" } })))}
                onAntClick={() => props.history.push(`/cadastro/grupo-pecas`)}
                onProxClick={pecasAtualizadas => updatePecas(pecasAtualizadas)}
            />
        </div>
    );
};

export default PrecificacaoScreen;