// Dependência
import React, { useEffect, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiSubtractFill } from "react-icons/ri";

// Css
import classes from "./Arquetipo.module.css";

// Helpers
import * as exibirMensagem from "../../../../../helpers/exibirMensagem";

const Arquetipo = props => {
    // 0 é nada, 1 é anunciar, 2 é revisar
    const [state, setState] = useState(0);
    const [quantidade, setQuantidade] = useState(1);

    useEffect(() => {
        if (props.arquetipo.anunciar)
            setState(1);
        else if (props.arquetipo.revisar)
            setState(2);
        else
            setState(0);
    }, [props.arquetipo]);

    const onClickHandler = () => {
        if (state === 0) {
            setState(1);
            props.onChange({ ...props.arquetipo, anunciar: true, revisar: false });
        } else if (state === 1) {
            setState(2);
            props.onChange({ ...props.arquetipo, anunciar: false, revisar: true });
        } else {
            setState(0);
            props.onChange({ ...props.arquetipo, anunciar: false, revisar: false });
        };
    };

    return (
        <tr
            style={{ backgroundColor: state ? state === 1 ? "#00800066" : "#593196" : "", color: state === 2 ? "#fff" : "" }}
            onContextMenu={event => props.onContextMenu(event)}
        >
            <td className={classes.NomeArquetipo} onClick={onClickHandler}>
                {props.arquetipo.nomePeca || props.arquetipo.nome}
            </td>
            <td style={{ cursor: "default" }}>
                {props.arquetipo.maximoPorSucata > 1 ? (
                    <div>
                        <RiSubtractFill
                            size="25px"
                            className={quantidade - 1 === 0 ? classes.QuantidadeButtonDisabled : classes.QuantidadeButton}
                            onClick={() => {
                                if (!(quantidade - 1 === 0)) {
                                    setQuantidade(quantidade - 1);
                                    props.onChange({ ...props.arquetipo, quantidade: quantidade - 1 });
                                };
                            }} />

                        <span className={classes.Quantidade}> {quantidade} </span>

                        <MdAdd
                            size="25px"
                            className={quantidade + 1 > props.arquetipo.maximoPorSucata ? classes.QuantidadeButtonDisabled : classes.QuantidadeButton}
                            onClick={() => {
                                if (!(quantidade + 1 > props.arquetipo.maximoPorSucata)) {
                                    setQuantidade(quantidade + 1);
                                    props.onChange({ ...props.arquetipo, quantidade: quantidade + 1 });

                                    if (state === 0)
                                        onClickHandler();
                                };
                            }} />
                    </div>
                ) : null}
            </td>
            <td style={{ cursor: "default" }}>
                <BiCommentDetail
                    size="25px"
                    className={classes.ObservacaoButton}
                    title="Adicionar uma observação"
                    onClick={() => {
                        exibirMensagem.exibir({
                            title: 'Digite uma observação',
                            text: 'Esta observação será incluída na descrição desta peça.',
                            input: 'textarea',
                            inputValue: props.arquetipo.observacao,
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Salvar'
                        }).then(value => {
                            if (value.value || value.value === "")
                                props.onChange({ ...props.arquetipo, observacao: value.value });
                        });
                    }}
                />
            </td>
        </tr>
    );
};

export default Arquetipo;