// Dependências
import React, { useEffect, useState } from "react";

// Components
import BarraPesquisa from "../../UI/BarraPesquisa/BarraPesquisa";
import BottomNavbar from "../BottomNavbar/BottomNavbar";
import Dropdown from "../../UI/BeautyDropdown/BeautyDropdown";
import Peca from "./Peca/Peca";
import Titulo from "../../UI/Titulo/Titulo";

// Helpers
import * as formatar from "../../../helpers/formatar";

const FotosPecas = props => {
    const [copiaPecas, setCopiaPecas] = useState([]);
    const [pecasFiltradas, setPecasFiltradas] = useState([]);

    const [busca, setBusca] = useState("");
    const [idArea, setIdArea] = useState(0);
    const [opcoesAreas, setOpcoesAreas] = useState([]);
    const [categorias, setCategorias] = useState([]);

    // Setando copiaPecas e opcaoStatus
    useEffect(() => {
        let opcoesAreasAtualizadas = [];
        props.pecas.map(peca => peca.arquetipo.area).forEach(area => {
            if (area)
                if (!opcoesAreasAtualizadas.find(opcao => opcao.nome === area.nome))
                    opcoesAreasAtualizadas.push({ ...area });
        });
        setOpcoesAreas(opcoesAreasAtualizadas);

        setCopiaPecas([...props.pecas.map(peca => { return { ...peca, fotos: [] } })]);
    }, [props.pecas]);

    // Setando pecasFiltradas
    useEffect(() => {
        const buscar = novaPecasFiltadas => {
            if (busca) {
                novaPecasFiltadas = novaPecasFiltadas.filter(peca => {
                    return (
                        formatar.alphaNumeric(peca.arquetipo.nome).includes(formatar.alphaNumeric(busca)) ||
                        formatar.alphaNumeric(peca.arquetipo.area.nome).includes(formatar.alphaNumeric(busca))
                    );
                });
            };

            setPecasFiltradas([...novaPecasFiltadas]);
        };

        if (idArea && idArea > 0)
            buscar(copiaPecas.filter(copiaPeca => copiaPeca.arquetipo.area.id === idArea));
        if (idArea === -2)
            buscar(copiaPecas.filter(copiaPeca => copiaPeca.fotos.length));
        if (idArea === -1)
            buscar(copiaPecas.filter(copiaPeca => !copiaPeca.fotos.length));
        else if (!idArea)
            buscar([...copiaPecas]);
    }, [copiaPecas, busca, idArea]);

    // Setando categorias
    useEffect(() => {
        if (copiaPecas.length) {
            let novasCategorias = [];

            copiaPecas.forEach(peca => {
                if (!novasCategorias.find(novaCategoria => novaCategoria.id === peca.arquetipo.area?.id))
                    novasCategorias.push({ ...peca.arquetipo.area });
            });

            setCategorias(novasCategorias);
        };
    }, [copiaPecas]);

    const submitHandler = () =>
        props.onProxClick([].concat.apply([], copiaPecas.filter(peca => peca.fotos.length).map(peca => peca.fotos)));

    return (
        <div className="mb-5">
            <Titulo>Possui fotos das peças individualmente? Se sim, adicione aqui (Opcional)</Titulo>

            <div className="row">
                <div className="col-12 mb-3">
                    <i style={{ fontSize: "16px" }}>Caso opte por não inserir as fotos de cada peça, os anúncios serão criados com base nas fotos da sucata, utilizando os ângulos mais adequados para cada peça.</i>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <BarraPesquisa onChange={value => setBusca(value)} />
                </div>
                <div className="col-4 text-center">
                    <Dropdown
                        inline
                        list={[{ id: 0, nome: "Todas" }, { id: -2, nome: "Com fotos" }, { id: -1, nome: "Sem fotos" }, ...opcoesAreas]}
                        onChange={id => setIdArea(id)} />
                </div>
            </div>

            <div className="container-fluid">
                {pecasFiltradas.length ?
                    categorias.map(categoria =>
                        <div key={categoria.id} className="row">
                            <div className="col-12">
                                <Titulo>{categoria.nome}</Titulo>
                            </div>
                            {pecasFiltradas.filter(peca => peca.arquetipo.area.id === categoria.id).map(peca =>
                                <Peca
                                    key={peca.id}
                                    peca={peca}
                                    showSucata={props.showSucata}
                                    onChange={pecaAtualizada => {
                                        let copiaPecasAtualizadas = [...copiaPecas];
                                        copiaPecasAtualizadas = copiaPecasAtualizadas.map(peca => peca.id === pecaAtualizada.id ? pecaAtualizada : peca);
                                        setCopiaPecas([...copiaPecasAtualizadas]);
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="col-12 text-center pt-2 h3">
                            Nenhuma peça encontrada.
                        </div>
                    )}
            </div>

            <BottomNavbar
                onAntClick={props.onAntClick}
                onProxClick={submitHandler} />
        </div>
    );
};

export default FotosPecas;