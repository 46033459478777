import axios from 'axios';
import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const CREATE_SUCATA = "CREATE_SUCATA";
export const DELETE_SUCATA = "DELETE_SUCATA";
export const FETCH_FOTOS_AREA = "FETCH_FOTOS_AREA";
export const FETCH_SUCATA = "FETCH_SUCATA";
export const FETCH_SUCATAS = "FETCH_SUCATAS";
export const FETCH_PECAS_CADASTRAVEIS = "FETCH_PECAS_CADASTRAVEIS";
export const UPDADE_SUCATA = "UPDADE_SUCATA";

export const createFotos = (idSucata, fotos) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();
        fotos.forEach(foto => formData.append(`${foto.atrelamento ? foto.atrelamento : "null"}`, foto.file));

        await axios({
            method: 'POST',
            url: `${API_URL}/sucatas/${idSucata}/fotos`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData
        }).then(result => {
            if (result.status === 201)
                dispatch({ type: CREATE_SUCATA, sucata: result.data });
            else
                throw new Error("Algo deu errado");
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const createSucata = sucata => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();
        formData.append("sucata", JSON.stringify(sucata));

        await axios({
            method: 'POST',
            url: `${API_URL}/sucatas`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData
        }).then(result => {
            if (result.status === 201)
                dispatch({ type: CREATE_SUCATA, sucata: result.data });
            else
                throw new Error("Algo deu errado");
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deleteSucata = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(`${API_URL}/sucatas/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            await dispatch({ type: DELETE_SUCATA, id: id });
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchFotosAreas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/sucatas/fotos/areas`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let fotosAreas = [];
            try {
                fotosAreas = await response.json();
                dispatch({ type: FETCH_FOTOS_AREA, fotosAreas: fotosAreas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchSucata = id => {
    return async (dispatch, getState) => {
        if (!id)
            return;

        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/sucatas/${id}`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let sucata = {};
            try {
                sucata = await response.json();

                dispatch({ type: FETCH_SUCATA, sucata: sucata });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchSucatas = (doravante = 1, busca, ordem, quantidade = 50) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/sucatas?modelo=${true}quantidade=${quantidade}&doravante=${doravante - 1}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let sucatas = [];
            try {
                sucatas = await response.json();

                dispatch({ type: FETCH_SUCATAS, sucatas: sucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchSucatasComModelo = (doravante = 1, busca, ordem, quantidade = 50) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(
            `${API_URL}/sucatas?modelo=${true}&quantidade=${quantidade}&doravante=${doravante - 1}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let sucatas = [];
            try {
                sucatas = await response.json();

                dispatch({ type: FETCH_SUCATAS, sucatas: sucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchSucatasNaoCadastradas = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/sucatas?cadastro=${true}&grupo=${false}`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let sucatas = [];
            try {
                sucatas = await response.json();
                dispatch({ type: FETCH_SUCATAS, sucatas: sucatas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const updateSucata = (idSucata, sucata) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/sucatas/${idSucata}`,
            headers: { Authorization: token },
            data: sucata
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: UPDADE_SUCATA, sucata: result.data });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const updateStatusSucata = idSucata => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/sucatas/${idSucata}/status`,
            headers: { Authorization: token }
        }).then(result => {
            if (result.status !== 200) {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};