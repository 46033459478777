// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import * as despesasActions from "../../../../store/actions/despesas";

// Css
import classes from "./DespesasCategoriasScreen.module.css";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as formatar from "../../../../helpers/formatar";
import groupBy from "../../../../helpers/groupBy";
import * as validar from "../../../../helpers/validar";

// Components
import Button from "../../../../components/UI/Button/Button";
import GraficoLinha from "../../../../components/Financeiro/Despesas/DespesasCategorias/GraficoLinha/GraficoLinha";
import GraficoPizza from "../../../../components/Financeiro/Despesas/DespesasCategorias/GraficoPizza/GraficoPizza";
import BeautyInput from "../../../../components/UI/BeautyInput/BeautyInput";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Subcategoria from "../../../../components/Financeiro/Despesas/DespesasCategorias/Subcategoria/Subcategoria";
import Table from "../../../../components/UI/Table/Table";
import Titulo from "../../../../components/UI/Titulo/Titulo";

const DespesaScreen = props => {
    const dispatch = useDispatch();

    const dadosMensais = useSelector(state => state.despesas.dadosMensais);
    const categorias = useSelector(state => state.despesas.categorias);

    const [isLoading, setIsLoading] = useState(true);

    const [dataInicio, setDataInicio] = useState(dataHoje());
    const [dataFinal, setDataFinal] = useState(new Date().getFullYear() + '-12-31');

    // States para gerar o gráfico de pizza
    const [somasDespesas, setSomasDespesas] = useState([]);
    const [nomesDespesas, setNomesDespesas] = useState([]);
    const [coresDespesas, setCoresDespesas] = useState([]);

    // Requisição dos dados mensais
    useEffect(() => {
        const fetchDadosMensais = async () => {
            try {
                await dispatch(despesasActions.fetchDadosMensais(dataInicio, dataFinal));
            } catch (error) {
                console.log(error);
            }
        };
        fetchDadosMensais();
    }, [dispatch, dataInicio, dataFinal]);

    // Requisição de despesas e categorias
    useEffect(() => {
        const fetchDespesas = async () => {
            setIsLoading(true);

            try {
                await dispatch(despesasActions.fetchDespesas());
                await dispatch(despesasActions.fetchCategorias());
            } catch (error) {
                console.log(error);
            }

            setIsLoading(false);
        };
        fetchDespesas();
    }, [dispatch]);

    // Definindo somasDespesas, nomesDespesas e coresDespesas
    useEffect(() => {
        let copiaSomasDespesas = [];
        let copiaNomesDespesas = [];
        let copiaCoresDespesas = [];

        if (dadosMensais.categorias) {
            if (dadosMensais.categorias.length) {
                categorias.forEach(categoria => {
                    let despesasCategoria = dadosMensais.categorias.filter(dadosCategoria => dadosCategoria.id === categoria.id);

                    if (despesasCategoria.length) {
                        let somaCustos = despesasCategoria[0].dadosMeses.map(dadoMes => dadoMes.valor).reduce((a, b) => a + b, 0);

                        if (somaCustos > 0) {
                            copiaSomasDespesas.push(somaCustos);
                            copiaNomesDespesas.push(categoria.nome);
                            copiaCoresDespesas.push(categoria.cor);
                        };
                    };
                });
            };
        };

        setSomasDespesas(copiaSomasDespesas);
        setNomesDespesas(copiaNomesDespesas);
        setCoresDespesas(copiaCoresDespesas);

    }, [categorias, dadosMensais]);

    return !isLoading && dadosMensais.categorias ? (
        <div>
            <div className="container-fluid">
                <Titulo>Período de avaliação</Titulo>

                {dadosMensais.categorias.filter(dado => dado.dadosMeses.map(dadoMes => dadoMes.valor).reduce((a, b) => a + b, 0) > 0 ? true : false).length ? (
                    <div className="row mb-1">
                        <div className="col-5">
                            <div className="row">
                                <div className="col-6">
                                    <BeautyInput
                                        type="date"
                                        placeholder="Data inicial"
                                        defaultValue={dataInicio}
                                        onBlur={event => {
                                            if (validar.data(event.target.value) && new Date(event.target.value) < new Date(dataFinal))
                                                setDataInicio(event.target.value);
                                            else
                                                event.target.value = dataInicio;
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <BeautyInput
                                        type="date"
                                        placeholder="Data final"
                                        defaultValue={dataFinal}
                                        onBlur={event => {
                                            if (validar.data(event.target.value) && new Date(dataInicio) < new Date(event.target.value))
                                                setDataFinal(event.target.value);
                                            else
                                                event.target.value = dataFinal;
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GraficoPizza
                                        title="Principais despesas"
                                        labels={nomesDespesas}
                                        data={somasDespesas}
                                        colors={coresDespesas}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-7">
                            <div className="row">
                                <div className="col-12">
                                    <GraficoLinha
                                        title="Despesas por mês"
                                        dadosMeses={dadosMensais.dadosMeses}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GraficoLinha
                                        dadosCategorias={dadosMensais.categorias.filter(categoria => categoria.dadosMeses.map(dadoMes => dadoMes.valor).reduce((a, b) => a + b, 0) > 0 ? true : false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={classes.Parent}>
                        <div className={classes.Center}>
                            <h2>Nenhum resultado!</h2>
                            <br />
                            <h4>Não encontramos dados suficientes para gerar informações sobre suas despesas.</h4>
                            <br />
                            <h4>Procure alterar o período de avaliação ou cadastre despesas <Link to="/financeiro/despesas">aqui.</Link></h4>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12 mb-4 text-center">
                        <Button
                            color="green"
                            onClick={() => props.history.push("/financeiro/despesas")}>Ver despesas detalhadas</Button>
                    </div>
                </div>

                {dadosMensais.categorias.map(categoria => categoria.subcategorias.map(subcategoria => subcategoria.dadosMeses.map(dadoMes => dadoMes.valor).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) > 0 ? (
                    <div className="row mb-3">
                        <Titulo>Total</Titulo>
                        <Table
                            header={["Nome", ...dadosMensais.categorias[0].subcategorias[0].dadosMeses.map(dadoMes => formatar.data(dadoMes.data, true, true))]}
                            bordered
                            fixed
                            small
                        >
                            <Subcategoria
                                key="total"
                                nome="Total"
                                dados={dadosMensais.dadosMeses}
                                total
                            />
                        </Table>
                    </div>
                ) : null}

                {dadosMensais.categorias.map(categoria => categoria.subcategorias.map(subcategoria => subcategoria.dadosMeses.map(dadoMes => dadoMes.valor).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) <= 0 ? null : (
                    <div key={categoria.id} className="row mb-3">
                        <Titulo>{categoria.nome}</Titulo>
                        <Table
                            small
                            bordered
                            hoverble
                            header={["Subcategoria", ...categoria.subcategorias[0].dadosMeses.map(dadoMes => formatar.data(dadoMes.data, true, true))]}
                        >
                            <Subcategoria
                                total
                                nome="Total"
                                key={`total-${categoria.id}`}
                                dados={categoria.dadosMeses} />

                            {categoria.subcategorias.map(subcategoria =>
                                <Subcategoria
                                    key={subcategoria.id}
                                    nome={categoria.subcategorias[0].atrelamentos ? `${subcategoria.nome} (Somatória)` : subcategoria.nome}
                                    dados={subcategoria.dadosMeses}
                                    onClick={() => props.history.push("/financeiro/despesas")}
                                    somatoria={categoria.subcategorias[0].atrelamentos ? true : false}
                                />
                            )}

                            {categoria.subcategorias[0].atrelamentos ?
                                Object.entries(groupBy([].concat.apply([], dadosMensais.categorias[0].subcategorias.map(subcategoria => { return subcategoria.atrelamentos.map(atrelamento => { return { subcategoria: subcategoria.nome, ...atrelamento } }) })), "nome")).map(funcionario => funcionario[1]).map(dadosFuncionario =>
                                    dadosFuncionario.map(funcionario =>
                                        <Subcategoria
                                            key={`${funcionario.id} - ${funcionario.subcategoria}`}
                                            nome={`${funcionario.nome} - ${funcionario.subcategoria}`}
                                            dados={funcionario.dadosMeses}
                                            onClick={() => props.history.push("/financeiro/despesas")} />
                                    )
                                ) : null}
                        </Table>
                    </div>
                ))}
            </div>
        </div>
    ) : <Spinner />
};

/*  */
export default DespesaScreen;
