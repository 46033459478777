// Dependências
import React, { useEffect, useState } from "react";
import { BiDuplicate } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Actions
import * as grupoPecasActions from "../../../../store/actions/grupo-pecas";
import * as gruposSucatasActions from "../../../../store/actions/grupos-sucatas";
import * as pecasTiposActions from "../../../../store/actions/pecas-tipos";

// Helpers
import dataHoje from "../../../../helpers/dataHoje";
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as formatar from "../../../../helpers/formatar";
import * as validar from "../../../../helpers/validar";

// Components
import BottomNavbar from "../../../../components/Cadastro/BottomNavbar/BottomNavbar";
import Input from "../../../../components/UI/Input/Input";
import Peca from "../../../../components/Cadastro/GrupoPecas/SelecaoPecas/Peca/Peca";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Table from "../../../../components/UI/Table/Table";

const SelecaoPecas = props => {
    const dispatch = useDispatch();

    const grupoPecas = useSelector(state => state.grupoPecas);
    const arquetipos = useSelector(state => state.pecasTipos.arquetipos);
    const grupoSucatas = useSelector(state => state.gruposSucatas.grupoSucatas);

    const [isLoading, setIsLoading] = useState(false);
    const [copiaPecas, setCopiaPecas] = useState(grupoPecas.pecas);
    const [isGrupoSucatasCreated, setIsGrupoSucatasCreated] = useState(false);

    const [nomeGrupo, setNomeGrupo] = useState(`Peças do dia ${formatar.data(dataHoje())}`);

    // Verifica se o grupo foi criado e redireciona
    useEffect(() => {
        if (isGrupoSucatasCreated && grupoSucatas.id)
            props.history.push(`/cadastro/grupo-pecas/medidas/${grupoSucatas.id}`);
    }, [isGrupoSucatasCreated, grupoSucatas.id, props.history]);

    // Requisição das peças cadastráveis
    useEffect(() => {
        const fetchArquetipos = async () => {
            try {
                setIsLoading(true);
                await dispatch(pecasTiposActions.fetchArquetipos());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        fetchArquetipos();
    }, [dispatch]);

    // Verifica por peças que tem tipos e não tem marcas
    useEffect(() => {
        grupoPecas.pecas.forEach(peca => {
            if (peca.fipe.tipo.id && !peca.fipeOptions.marcas.length)
                dispatch(grupoPecasActions.fetchMarcas(peca))
        });

        setCopiaPecas([...grupoPecas.pecas]);
    }, [dispatch, grupoPecas]);

    const handleSubmit = async () => {
        let formIsValid = true;
        let pecasAtualizadas = [...grupoPecas.pecas];

        pecasAtualizadas.forEach(peca => {
            if (!peca.arquetipo.nome || !peca.arquetipo.id) {
                formIsValid = false;
                peca.isValid.arquetipo = false;
            } else peca.isValid.arquetipo = true;

            if (!validar.nome(peca.titulo)) {
                formIsValid = false;
                peca.isValid.titulo = false;
            } else peca.isValid.titulo = true;

            if (!peca.fipe.tipo.id || !peca.fipe.tipo.nome) {
                formIsValid = false;
                peca.isValid.tipo = false;
            } else {
                peca.isValid.tipo = true;

                if (!peca.fipe.marca.id || !peca.fipe.marca.nome) {
                    formIsValid = false;
                    peca.isValid.marca = false;
                } else {
                    peca.isValid.marca = true;

                    if (!peca.fipe.veiculo.id || !peca.fipe.veiculo.nome) {
                        formIsValid = false;
                        peca.isValid.veiculo = false;
                    } else peca.isValid.veiculo = true;
                };
            };
        });

        dispatch(grupoPecasActions.setPecas(pecasAtualizadas));
        if (formIsValid) {
            exibirMensagem.warning({
                title: "Tem certeza?",
                text: "Você não poderá selecionar mais peças depois.",
                showCancelButton: true,
                confirmButtonText: "Ok",
            }).then(async result => {
                if (result.isConfirmed) {
                    try {
                        let novoGrupoSucatas = pecasAtualizadas.map(peca => {
                            return {
                                fipe: peca.fipe,
                                pecas: [
                                    {
                                        arquetipo: peca.arquetipo,
                                        observacao: peca.observacao,
                                        titulo: peca.titulo
                                    }
                                ]
                            };
                        });

                        await dispatch(gruposSucatasActions.createGrupoSucatas({ nome: nomeGrupo, sucatas: novoGrupoSucatas.map(sucata => { return { ...sucata, status: { id: 6 } } }) }));
                        setIsGrupoSucatasCreated(true);
                    } catch (error) {
                        console.log(error);
                    };
                };
            });
        } else
            exibirMensagem.formInvalid();
    };

    return isLoading ? <Spinner /> : (
        <div className="mb-5">
            <div className="row">
                <div className="col-12 mb-2">
                    <strong style={{ fontSize: "18px" }}>Crie um título que te ajude a lembrar deste grupo de peças</strong>
                    <br />
                    <span style={{ fontColor: "16px" }}>Ex: Alternadores - 20/12/2020</span>
                </div>
            </div>

            <div className="row">
                <div className="col-7">
                    <Input
                        maxLength="60"
                        value={nomeGrupo}
                        placeholder="Título do grupo"
                        onChange={value => setNomeGrupo(value)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-1">
                    <strong style={{ fontSize: "18px" }}>Selecione as peças que deseja cadastrar, utilizando o botão <BiDuplicate color="#79cd56" /> para adicionar mais peças e/ou duplicar peças.</strong>
                </div>
            </div>

            <div className="row">
                <Table
                    fixed
                    header={["Peça", "Tipo", "Marca", "Modelo", "Título", "Obs."]}>
                    {copiaPecas.map((peca, index) =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            arquetipos={arquetipos}
                            onChange={pecaAtualizada => {
                                let copiaPecasAtualizadas = [...copiaPecas];
                                copiaPecasAtualizadas = copiaPecasAtualizadas.map(copiaPeca => copiaPeca.id === pecaAtualizada.id ? pecaAtualizada : copiaPeca);
                                dispatch(grupoPecasActions.setPecas(copiaPecasAtualizadas));
                            }}
                            onChangeTipo={pecaAtualizada =>
                                dispatch(grupoPecasActions.fetchMarcas(pecaAtualizada))}
                            onChangeMarca={pecaAtualizada =>
                                dispatch(grupoPecasActions.fetchVeiculos(pecaAtualizada))}
                            onDelete={() => {
                                if (copiaPecas.length <= 1)
                                    exibirMensagem.warning("Você precisa cadastrar ao menos uma peça.");
                                else
                                    dispatch(grupoPecasActions.setPecas(copiaPecas.filter(pecaFiltrada => pecaFiltrada.id !== peca.id)));
                            }}
                            onDuplicar={() => {
                                if (copiaPecas.length >= 300)
                                    exibirMensagem.warning("Você atingiu o limite de peças por cadastro.")
                                else {
                                    let pecasAtualizadas = [...copiaPecas];
                                    pecasAtualizadas.splice(index, 0, { ...JSON.parse(JSON.stringify(peca)), id: uuidv4() });
                                    dispatch(grupoPecasActions.setPecas(pecasAtualizadas));
                                }
                            }} />
                    )}
                </Table>
            </div>

            <BottomNavbar
                onAntClick={() => props.history.push("/cadastro")}
                onProxClick={handleSubmit} />
        </div>
    );
};

export default SelecaoPecas;