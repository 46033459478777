// Dependências
import React from "react";

// Components
import BeautyInput from "../../../../UI/BeautyInput/BeautyInput";

const String = props => {
    return (
        <BeautyInput
            small
            hint={props.atributo.dica}
            tooltip={props.atributo.tooltip}
            placeholder={props.atributo.nome}
            maxLength={props.atributo.maxLength}
            defaultValue={props.atributo.padrao}
            invalid={props.atributo.isValid === false ? true : false}
            datalist={props.atributo.valores ? props.atributo.valores.map(valor => valor.nome) : null}
            onBlur={event => {
                let copiaAtributo = { ...props.atributo };
                copiaAtributo.value = event.target.value;
                props.onChange(copiaAtributo);
            }}
            onNaoSeAplica={!props.atributo.obrigatorio ? value => {
                let copiaAtributo = { ...props.atributo };
                copiaAtributo.naoSeAplica = value;
                props.onChange(copiaAtributo);
            } : null} />
    );
};

export default String;