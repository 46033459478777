import {
    DELETE_PERGUNTA,
    FETCH_PERGUNTA,
    FETCH_PERGUNTAS_ABERTAS,
    SEND_RESPOSTA
} from "../actions/perguntas";

const initialState = {
    perguntas: [],
    pergunta: {},
    resposta: {}
};

const perguntasReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_PERGUNTA:
            const perguntasFiltradas = state.perguntas.filter(pergunta => pergunta.id !== action.id);
            return { ...state, perguntas: perguntasFiltradas };
        case FETCH_PERGUNTA:
            return { ...state, pergunta: action.pergunta };
        case FETCH_PERGUNTAS_ABERTAS:
            return { ...state, perguntas: action.perguntas };
        case SEND_RESPOSTA:
            const perguntasAtualizadas = state.perguntas.filter(pergunta => pergunta.id !== action.id);
            return { ...state, perguntas: perguntasAtualizadas };
        default:
            return state;
    }
};

export default perguntasReducer;
