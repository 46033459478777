// Dependências
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaUsers } from "react-icons/fa";
import { ImKey } from "react-icons/im";
import { RiLogoutBoxLine } from "react-icons/ri";

// Actions
import * as anunciosActions from "../../store/actions/anuncios";
import * as authActions from "../../store/actions/auth";
import * as colorPaletteActions from "../../store/actions/color-palette";
import * as usuarioActions from "../../store/actions/usuario";
import * as respostasProntasActions from "../../store/actions/respostas-prontas";

// Helpers
import * as exibirMensagem from "../../helpers/exibirMensagem";

// Components
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Dropdown from "../../components/UI/BeautyDropdown/BeautyDropdown";
import DropZone from "../../components/UI/DropZone/DropZone";
import Input from "../../components/UI/Input/Input";
import ModalRespostaPronta from '../../components/Perguntas/ModalRespostaPronta/ModalRespostaPronta';
import RespostaPronta from '../../components/Perguntas/Pergunta/RespostaPronta/RespostaPronta';
import Spinner from "../../components/UI/Spinner/Spinner";
import SwitchColorButton from "../../components/Configuracoes/SwitchColorButton/SwitchColorButton";
import Table from "../../components/UI/Table/Table";
import Textarea from "../../components/UI/Textarea/Textarea";
import Titulo from "../../components/UI/Titulo/Titulo";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import Vinculo from "../../components/Configuracoes/Vinculo/Vinculo";

const ConfiguracoesScreen = props => {
    const dispatch = useDispatch();

    const fretes = useSelector(state => state.anuncios.fretes);
    const usuario = useSelector(state => state.usuario.meuUsuario);
    const respostasProntas = useSelector(state => state.respostasProntas.respostasProntas);

    const [isLoading, setIsLoading] = useState(false);
    const [isImagemLoading, setIsImagemLoading] = useState(false);
    const [isDescricaoLoading, setIsDescricaoLoading] = useState(false);
    const [isModoDemonstracaoLoading, setIsModoDemonstracaoLoading] = useState(false);
    const [showModalRespostaPronta, setShowModalRespostaPronta] = useState(false);
    const [respostaPronta, setRespostaPronta] = useState({ id: "", titulo: "", reposta: "" })
    const [isarantiaLoading, setIsGarantiaLoading] = useState(false);
    const [isFreteLoading, setIsFreteLoading] = useState(false);

    // Requisição das respostas prontas
    useEffect(() => {
        const fetchRespostasProntas = async () => {
            try {
                setIsLoading(true);
                await dispatch(respostasProntasActions.fetchRespostasProntas());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };
        fetchRespostasProntas();
    }, [dispatch]);

    // Requisição dos fretes
    useEffect(() => {
        const fetchFretes = async () => {
            try {
                setIsLoading(true);
                await dispatch(anunciosActions.fetchFretes());
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        fetchFretes();
    }, [dispatch]);

    const handleModalRespostaPronta = (dadosRespostaPronta) => {
        setRespostaPronta(dadosRespostaPronta);
        setShowModalRespostaPronta(true);
    };

    return isLoading || !usuario.id ? <Spinner /> : (
        <div className="container-fluid">
            {showModalRespostaPronta ? (
                <ModalRespostaPronta respostaPronta={respostaPronta} onClose={() => setShowModalRespostaPronta(false)} />
            ) : null}

            <div className="row mb-5">
                <Titulo>Vínculos</Titulo>

                <Table fixed header={["Plataforma", "Conta", "Data"]}>
                    {usuario.conta.vinculos.length ? usuario.conta.vinculos.map(vinculo => (
                        <Vinculo key={vinculo.id} vinculo={vinculo} />)
                    ) : (
                        <tr><td colSpan="3"><h4>Nenhum vínculo encontrado.</h4></td></tr>
                    )}
                </Table>

                {usuario.conta.vinculos.find(vinculo => vinculo.plataforma.id === 2) ? null : (
                    <div className="col-12 text-right">
                        <a href={`https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=5330184019012924&redirect_uri=https://sistemaunificar.com.br/configuracoes/vinculos/mercado-livre`}>
                            <Button color="green">Vincular nova conta</Button>
                        </a>
                    </div>
                )}
            </div>

            {usuario.conta.tipo.id === 4 ? (
                <div className="row mb-5 h5">
                    <Titulo>Dados fictícios</Titulo>

                    <div className="col-5 text-center">
                        Exibição de dados fictícios para exemplificação:
                    </div>

                    <div className="col-7 text-center">
                        {isModoDemonstracaoLoading ? <Spinner inline /> : (
                            <div className="row">
                                <div className="col-12">
                                    <Checkbox
                                        defaultChecked={!usuario.conta.configuracoes.demonstracao}
                                        disabled={!usuario.conta.configuracoes.demonstracao}
                                        onChange={async () => {
                                            setIsModoDemonstracaoLoading(true);
                                            await dispatch(usuarioActions.updateUsuario({ demonstracao: false }));
                                            setIsModoDemonstracaoLoading(false);
                                        }}
                                    >Exibir dados da minha empresa.</Checkbox>
                                </div>
                                <div className="col-12">
                                    <Checkbox
                                        defaultChecked={usuario.conta.configuracoes.demonstracao}
                                        disabled={usuario.conta.configuracoes.demonstracao}
                                        onChange={async () => {
                                            setIsModoDemonstracaoLoading(true);
                                            await dispatch(usuarioActions.updateUsuario({ demonstracao: true }));
                                            setIsModoDemonstracaoLoading(false);
                                        }}
                                    >Exibir dados fictícios para exemplificação.</Checkbox>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}

            <div className="row mb-5 h5">
                <Titulo>Configurações da Loja</Titulo>

                <div className="col-4 mb-2" style={{ height: "300px" }}>
                    <div className="row">
                        <div className="col-12">
                            Imagem da Loja

                            <Tooltip className="ml-2">
                                Esta imagem será incluida como a última imagem de todos os seus anúncios.
                            </Tooltip>
                        </div>
                    </div>

                    {isImagemLoading ? <Spinner /> : (
                        <DropZone
                            src={usuario.conta.configuracoes.imagem ? [usuario.conta.configuracoes.imagem] : null}
                            onDelete={async () => {
                                try {
                                    setIsImagemLoading(true);
                                    await dispatch(usuarioActions.deleteImagemUsuario());
                                } catch (error) {
                                    console.log(error);
                                };

                                setIsImagemLoading(false);
                            }}
                            onChange={novaImagem => {
                                const updateUsuario = async () => {
                                    try {
                                        setIsImagemLoading(true);
                                        await dispatch(usuarioActions.updateUsuario({ imagem: novaImagem[0]?.file }));
                                    } catch (error) {
                                        console.log(error);
                                    };
                                    setIsImagemLoading(false);
                                };

                                updateUsuario();
                            }}
                        />
                    )}
                </div>

                <div className="col-8">
                    <div className="row mb-3">
                        Descrição da Loja

                        <Tooltip className="ml-2">
                            Este texto será incluido no final da descrição de todos os seus anúncios.
                        </Tooltip>
                    </div>
                    {isDescricaoLoading ? <Spinner /> : (
                        <Textarea
                            rows="5"
                            noResize
                            maxLength="500"
                            value={usuario.conta.configuracoes.descricao}
                            placeholder={`Descrição padrão \nNÃO INSIRA DADOS DE CONTATO, ENDEREÇO OU CNPJ NA DESCRIÇÃO. O MERCADO LIVRE BLOQUEIA CONTAS QUE INFRINGEM ESTA REGRA.`}
                            onSave={async descricao => {
                                try {
                                    setIsDescricaoLoading(true);
                                    await dispatch(usuarioActions.updateUsuario({ descricao: descricao }));
                                } catch (error) {
                                    console.log(error);
                                };

                                setIsDescricaoLoading(false);
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="row mb-5 h5">
                <Titulo>Configurações dos Anúncios</Titulo>

                <div className="col-6 text-center">
                    Frete padrão:
                    <Tooltip>Frete incluído automaticamente na criação dos seus anúncios.</Tooltip>
                </div>

                <div className="col-6 text-center">
                    {isFreteLoading && fretes.length ? <Spinner inline /> : (
                        <Dropdown
                            size="50%"
                            list={fretes}
                            default={usuario.conta.configuracoes.frete?.nome}
                            onChange={async idFrete => {
                                try {
                                    setIsFreteLoading(true);
                                    await dispatch(usuarioActions.updateUsuario({ frete: { id: idFrete } }));
                                } catch (error) {
                                    console.log(error);
                                };

                                setIsFreteLoading(false);
                            }}
                        />
                    )}
                </div>

                <div className="col-6 text-center">Dias de garantia:</div>
                <div className="col-6 text-center">
                    {isarantiaLoading ? <Spinner inline /> : (
                        <Input
                            onlyNumbers
                            size="200px"
                            placeholder="Dias de garantia"
                            value={usuario.conta.configuracoes.garantia}
                            onBlur={async event => {
                                if (!event.target.value && event.target.value !== 0)
                                    return;

                                try {
                                    setIsGarantiaLoading(true);
                                    await dispatch(usuarioActions.updateUsuario({ garantia: parseInt(event.target.value) }));
                                } catch (error) {
                                    console.log(error);
                                };

                                setIsGarantiaLoading(false);
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="row mb-5 h5">
                <Titulo> Configurações de Respostas Prontas </Titulo>

                <div className="col-12 text-center pl-5 pr-5">
                    {respostasProntas.length ? (
                        respostasProntas.map(respostaPronta => (
                            <RespostaPronta
                                key={respostaPronta.id}
                                titulo={respostaPronta.titulo}
                                onClick={!respostaPronta.mostrarParaTodos ? () => handleModalRespostaPronta(respostaPronta) : () => exibirMensagem.error("Essa é uma mensagem padrão e não pode ser editada ou excluída.")}
                                padrao={respostaPronta.mostrarParaTodos}
                                bigger={true}
                            />
                        ))
                    ) : null}
                </div>

                <div className="col-12 text-center pt-5">
                    <Button
                        color="green"
                        onClick={() => handleModalRespostaPronta(null)}
                    >Nova resposta</Button>
                </div>
            </div>

            <div className="row mb-5 h5">
                <Titulo>Configurações de Visualização</Titulo>

                <div className="col-6 text-center"> Paleta de Cores </div>
                <div className="col-6 text-center">
                    <SwitchColorButton onChange={() => dispatch(colorPaletteActions.switchColorMode())} />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-6 text-left">
                    <Button color="blue" onClick={() => props.history.push("/configuracoes/alterar-senha")}>
                        <ImKey /> Alterar minha senha
                    </Button>
                </div>

                <div className="col-6 text-right">
                    {usuario.permissoes.find(permissao => permissao.id === 2) ? (
                        <Button color="green" onClick={() => props.history.push("/configuracoes/usuarios/cadastro")}>
                            <FaUsers /> Gerenciar usuários
                        </Button>
                    ) : null}
                </div>

                <div className="col-12 text-center">
                    <Button color="red" onClick={() => dispatch(authActions.logout())}>
                        <RiLogoutBoxLine /> Logout
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfiguracoesScreen;