// Dependências
import React from "react";

// Components
import BeautyInput from "../../../../UI/BeautyInput/BeautyInput";

const Number = props => {
    return (
        <BeautyInput
            onlyNumbers
            placeholder={props.atributo.nome}
            defaultValue={props.atributo.value}
            maxLength={props.atributo.maxLength}
            hint={props.atributo.dica}
            tooltip={props.atributo.tooltip}
            invalid={props.atributo.isValid === false ? true : false}
            datalist={props.atributo.valores ? props.atributo.valores.map(value => value.nome) : null}
            onBlur={event => {
                let copiaAtributo = { ...props.atributo };
                copiaAtributo.value = event.target.value;
                props.onChange(copiaAtributo);
            }}
            small />
    );
};

export default Number;