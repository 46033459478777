import { v4 as uuidv4 } from 'uuid';

import { SET_PECA, SET_PECAS } from "../actions/grupo-pecas";

import { CREATE_GRUPO_SUCATAS } from "../actions/grupos-sucatas";

const getGrupoPecasInitialState = () => {
    let initialState = {};
    initialState.pecas = JSON.parse(sessionStorage.getItem("grupo-pecas"));

    if (!initialState.pecas) {
        initialState = {
            pecas: [{
                id: uuidv4(),
                titulo: "",
                arquetipo: { id: null, nome: null },
                nome: null,
                fotos: [],
                fipe: {
                    tipo: { id: 1, nome: "Veículos leves" },
                    marca: { id: null, nome: null },
                    veiculo: { id: null, nome: null },
                },
                observacao: "",

                isValid: {
                    arquetipo: true,
                    tipo: true,
                    marca: true,
                    veiculo: true,
                    titulo: true,
                },

                fipeOptions: {
                    tipos: [
                        { id: 1, nome: "Veículos Leves" },
                        { id: 2, nome: "Motocicletas" },
                        { id: 3, nome: "Caminhões e Ônibus" }
                    ],
                    marcas: [],
                    veiculos: [],
                }
            }]
        };

        sessionStorage.setItem("grupo-pecas", JSON.stringify(initialState.pecas));
    };

    return initialState;
};

const initialState = getGrupoPecasInitialState();

const grupoPecas = (state = initialState, action) => {
    switch (action.type) {
        case SET_PECA:
            let copiaPecas = [...state.pecas];
            copiaPecas.map(peca => peca.id === action.peca.id ? action.peca : peca);

            sessionStorage.setItem("grupo-pecas", JSON.stringify(copiaPecas));
            return { ...state, pecas: copiaPecas };
        case SET_PECAS:
            sessionStorage.setItem("grupo-pecas", JSON.stringify(action.pecas));
            return { ...state, pecas: action.pecas };
        case CREATE_GRUPO_SUCATAS:
            sessionStorage.removeItem("grupo-pecas");
            return { ...state, pecas: getGrupoPecasInitialState().pecas };
        default:
            return state;
    };
};

export default grupoPecas;
