// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

// Css
import classes from "./App.module.css";

// Actions
import * as adminActions from "./store/actions/admin";
import * as configActions from "./store/actions/config";
import * as usuarioActions from "./store/actions/usuario";

// Helpers
import * as exibirMensagem from "./helpers/exibirMensagem";

// Components
import Button from "./components/UI/Button/Button";
import Navbar from "./components/Navbar/Navbar";
import Navigator from "./navigation/Navigator";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Spinner from "./components/UI/Spinner/Spinner";

// Screens
import ManutencaoScreen from "./screens/Manutencao/ManutencaoScreen";

const App = props => {
    const dispatch = useDispatch();
    const location = useLocation();

    const config = useSelector(state => state.config.config);
    const darkMode = useSelector(state => state.colorPalette.darkMode);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const usuario = useSelector(state => state.usuario.meuUsuario);
    const token = useSelector(state => state.auth.token);
    const tokenReserva = useSelector(state => state.auth.tokenReserva);

    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [isConfigRequested, setIsConfigRequested] = useState(false);
    const [isUserRequested, setIsUserRequested] = useState(false);

    ReactGA.initialize('UA-145413015-1');
    ReactGA.initialize('AW-718115846');

    ReactPixel.init('2231863916852127')

    const paginasEspeciais = ["/conhecer", "/obrigado", "/conhecer2", "/anuncios-automaticos", "/login", "/registrar", "/utilitarias/pesquisa-mercado-livre"];

    // Requisição das configurações
    useEffect(() => {
        const fetchConfig = async () => {
            try {
                setIsConfigRequested(true);
                await dispatch(configActions.fetchConfig());
            } catch (error) {
                console.log(error);
            };

            setIsConfigRequested(true);
        };

        fetchConfig();
    }, [dispatch]);

    // Requisição do usuário
    useEffect(() => {
        const fetchMeuUsuario = async () => {
            try {
                await dispatch(usuarioActions.fetchMeuUsuario());
                setIsUserRequested(true);
            } catch (error) {
                console.log(error);
            }
        };

        if (token)
            fetchMeuUsuario();
        else
            setIsUserRequested(true);
    }, [dispatch, token]);

    // Envia as páginas que o usuário navega para a API
    useEffect(() => {
        ReactGA.pageview(location.pathname);
        ReactPixel.pageView();

        const registrarAcesso = async () => {
            try {
                await dispatch(usuarioActions.createRegistroAcesso(location.pathname, darkMode));
            } catch (error) {
                console.log(error);
            };
        };

        if (!paginasEspeciais.includes(location.pathname))
            registrarAcesso();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location]);

    // Exibe mensagem quando o usuário troca de página
    useEffect(() => {
        if (usuario.id) {
            const mensagem = usuario.conta.mensagens.find(mensagem => mensagem.caminho === location.pathname);

            if ((mensagem?.quantidade) || mensagem?.quantidade === null) {
                exibirMensagem.exibir({
                    icon: mensagem.icone,
                    title: mensagem.titulo,
                    text: mensagem.corpo,
                    allowOutsideClick: false,
                    confirmButtonText: mensagem.textoBotao || "Ok"
                });

                dispatch(usuarioActions.subtractMensagem(mensagem.id));
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location, usuario.id]);

    // Exibe mensagem se o usuário está em modo demonstração
    useEffect(() => {
        if (usuario.conta?.configuracoes?.demonstracao)
            exibirMensagem.warning({
                backdrop: false,
                allowOutsideClick: false,
                title: "Recado Importante:",
                text: `Você está no modo DADOS FICTÍCIOS.<br>
                Como sua conta ainda não possui peças ou sucatas cadastradas, vamos exibir os dados fictícios, para que você possa visualizar todos os recursos disponíveis em uma conta ativa! Para desativar, acesse a aba Configurações.`
            });
    }, [usuario.conta]);

    // Exibe mensagem se o usuário está no mobile
    useEffect(() => {
        if (isMobile && !paginasEspeciais.includes(location.pathname)) {
            exibirMensagem.warning({
                backdrop: false,
                allowOutsideClick: false,
                title: "ATENÇÃO!",
                text: `Recomendamos o uso em COMPUTADORES. <br /> A grande quantidade de informações inviabiliza a utilização em telas pequenas sem a perda de funções.`
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isConfigRequested && isUserRequested ? (
        config.emManutencao ? (
            <div className={`${classes.App} ${classes.NotAuthenticated} ${darkMode ? classes.DarkMode : ""}`}>
                <ManutencaoScreen />
            </div>
        ) : (
            isAuthenticated && paginasEspeciais.includes(location.pathname) && location.pathname !== "/utilitarias/pesquisa-mercado-livre" ? (
                <Redirect to="/inicio" />
            ) : (
                isAuthenticated ? !usuario.id ? <Spinner /> : (
                    <div className={`${classes.App} ${darkMode ? classes.DarkMode : ""}`}>
                        <div className={`${usuario.conta.configuracoes.demonstracao ? classes.ModoDemonstracao : ""} ${tokenReserva ? classes.ModoVisualizacao : ""}`}>
                            {tokenReserva ? (
                                <Button
                                    className={classes.ButtonModoVisualizacao}
                                    onClick={() => dispatch(adminActions.switchConta())}
                                >
                                    Sair da Visualização
                                </Button>
                            ) : null}
                            <Navbar onDrawerClick={() => setShowSideDrawer(!showSideDrawer)} />
                            <SideDrawer show={showSideDrawer} onClick={() => setShowSideDrawer(false)} />
                            <Navigator />
                        </div>
                    </div>
                ) : paginasEspeciais.includes(location.pathname) ? (
                    <div className={`${classes.App} ${classes.NotAuthenticated} ${darkMode ? classes.DarkMode : ""}`}>
                        <Navigator />
                    </div>
                ) : <Redirect to="/login" />
            )
        )
    ) : (
        <div className={`${classes.App} ${classes.NotAuthenticated} ${darkMode ? classes.DarkMode : ""}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Spinner />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
