// Dependências
import React from "react";
import { BsFillLockFill } from "react-icons/bs";
import { withRouter } from "react-router-dom";

// Components
import ColLink from "../../../UI/ColLink/ColLink";

// Helpers
import * as formatar from "../../../../helpers/formatar";

const Peca = (props) => {
    const pecaScreenLocation = `/estoque/pecas/${props.peca.id}`;

    return (
        <tr onContextMenu={event => props.onContextMenu(event)}>
            <ColLink to={pecaScreenLocation}>{props.peca.id}</ColLink>

            <ColLink to={pecaScreenLocation}>{props.peca.arquetipo.nome}</ColLink>
            
            <ColLink to={pecaScreenLocation}>{props.peca.venda?.id ? props.accessVendas ? formatar.dinheiro(props.peca.venda.valor) : <BsFillLockFill /> : props.peca.preco ? formatar.dinheiro(props.peca.preco) : "-"}</ColLink>
            
            <ColLink to={pecaScreenLocation}>{props.peca.status.nome}</ColLink>
        </tr>
    );
};

export default withRouter(Peca);