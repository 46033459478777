// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Components
import Titulo from "../../components/UI/Titulo/Titulo";

// Css
import classes from "./LocalizacaoScreen.module.css";
import { FiCamera } from "react-icons/fi";
import { FaBoxOpen } from "react-icons/fa";

const LocalizacaoScreen = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    return (
        <div className="container-fluid">
            <Titulo>Localizações</Titulo>

            <div className="row pt-1 mb-3">
                <div className="col-6 text-center">
                    <div className={darkMode ? classes.OptionDark : classes.OptionLight}>
                        <div onClick={() => props.history.push("/localizacao/peca")}>
                            <h3>Cadastrar Localização em uma peça</h3>
                            <div className={classes.Icon}>
                                <FiCamera size="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 text-center">
                    <div className={darkMode ? classes.OptionDark : classes.OptionLight}>
                        <div onClick={() => props.history.push("/localizacao/gerenciar")}>
                            <h3>Gerenciar Localizações</h3>
                            <div className={classes.Icon}>
                                <FaBoxOpen size="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalizacaoScreen;