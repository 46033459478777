// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as anunciosActions from "../../../../store/actions/anuncios";

// Components
import Button from "../../../../components/UI/Button/Button";
import Dropdown from "../../../../components/UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../../components/UI/Input/Input";
import Modal from "../../../../components/UI/Modal/Modal";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const ModalCreateAnuncio = props => {
    const dispatch = useDispatch();

    const plataformas = useSelector(state => state.anuncios.plataformas);
    const fretes = useSelector(state => state.anuncios.fretes);

    const [anuncio, setAnuncio] = useState({ categoria: {}, frete: {}, porcentagem: 100 });
    const [isLoading, setIsLoading] = useState(false);
    const [copiaPlataformas, setCopiaPlataformas] = useState([]);

    // Requisição das plataformas
    useEffect(() => {
        const fetchPlataformas = async () => {
            setIsLoading(true);
            try {
                await dispatch(anunciosActions.fetchPlataformas());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        if (!plataformas.length)
            fetchPlataformas();
    }, [dispatch, plataformas]);

    // Requisição dos fretes
    useEffect(() => {
        const fetchFretesPeca = async () => {
            setIsLoading(true);
            try {
                await dispatch(anunciosActions.fetchFretesPecas([{ id: props.peca.id }]));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchFretesPeca();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, props.pecas]);

    // Setando copiaPlataformas
    useEffect(() => {
        if (plataformas.length) {
            let copiaPlataformasAtualizada = JSON.parse(JSON.stringify([...plataformas]));
            copiaPlataformasAtualizada.forEach(plataforma => plataforma.tiposAnuncio = plataforma.tiposAnuncio.filter(tipoAnuncio => {
                let encontrado = false;
                props.peca.anuncios.forEach(anuncio => {
                    if (anuncio.tipo.nome === tipoAnuncio.nome)
                        encontrado = true;
                });
                return !encontrado;
            }));

            copiaPlataformasAtualizada = copiaPlataformasAtualizada.filter(plataforma => plataforma.tiposAnuncio.length);

            setCopiaPlataformas(copiaPlataformasAtualizada);
        };
    }, [plataformas, props.peca]);

    const createAnunciosPeca = async () => {
        setIsLoading(true);

        try {
            await dispatch(anunciosActions.createAnunciosPeca(props.peca.id, [
                {
                    id: anuncio.categoria.id || [].concat.apply([], copiaPlataformas.map(plataforma => plataforma.tiposAnuncio))[0].id,
                    porcentagem: anuncio.porcentagem || 100,
                    frete: { id: anuncio.frete.id || fretes[0]?.fretes[0]?.id }
                },
            ]));

            setIsLoading(false);
            exibirMensagem.success("Anúncio gerado com sucesso!");
            props.onClose();
        } catch (error) {
            console.log(error);
        };
    };

    return (
        <Modal titulo="Cadastrar anúncio" onClose={props.onClose}>
            {isLoading ? <Spinner /> : (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4">
                            Categoria:
                        </div>
                        <div className="col-8">
                            <Dropdown
                                returnObject
                                list={[].concat.apply([], copiaPlataformas.map(plataforma => plataforma.tiposAnuncio.map(tipoAnuncio => { return { ...tipoAnuncio, nome: `${plataforma.nome} - ${tipoAnuncio.nome}` } })))}
                                onChange={value => setAnuncio({ ...anuncio, categoria: value })}
                            />
                        </div>

                        <div className="col-4">
                            Frete:
                        </div>
                        <div className="col-8">
                            <Dropdown
                                returnObject
                                list={fretes[0]?.fretes}
                                onChange={value => setAnuncio({ ...anuncio, frete: value })}
                            />
                        </div>

                        <div className="col-4">
                            Porcentagem:
                        </div>
                        <div className="col-8">
                            <Input
                                type="range"
                                min={90}
                                max={120}
                                value={100}
                                onChange={value => setAnuncio({ ...anuncio, porcentagem: parseInt(value) })}
                            />
                        </div>
                    </div>

                    <div className="row pt-5">
                        <div className="col-12 text-center">
                            <Button color="green" onClick={createAnunciosPeca}>Cadastrar</Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalCreateAnuncio;