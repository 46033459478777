// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../../store/actions/cadastro";
import * as pecasActions from "../../../../../store/actions/pecas";

// Components
import SelecaoFreteGarantia from "../../../../../components/Cadastro/SelecaoFreteGarantia/SelecaoFreteGarantia";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

const SelecaoFreteGarantiaScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const pecas = useSelector(state => state.pecas.pecas);
    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    const [isLoading, setIsLoading] = useState(true);
    const [isRequestComplete, setIsRequestComplete] = useState(false);

    // Requisição das peças
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(pecasActions.fetchPecas(idSucata, 11, "tipo"));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
            setIsRequestComplete(true);
        };

        if (!cadastroSucata.pecas.length)
            fetchPecas();
        else {
            setIsLoading(false);
            setIsRequestComplete(true);
        };
    }, [dispatch, idSucata, cadastroSucata.pecas.length]);

    return isLoading ? <Spinner /> : (
        <div>
            {isRequestComplete ?
                <SelecaoFreteGarantia
                    pecas={cadastroSucata.pecas.length ? [...cadastroSucata.pecas] : [...pecas]}
                    onAntClick={() => props.history.push(`/cadastro/sucata/selecao-tipos-anuncio/${idSucata}`)}
                    onProxClick={pecasAtualizadas => {
                        let cadastroSucataAtualizado = { ...cadastroSucata };
                        cadastroSucataAtualizado.pecas = [...pecasAtualizadas];
                        dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                        props.history.push(`/cadastro/sucata/resumo/${idSucata}`);
                    }} />
                : null}
        </div>
    );
};

export default SelecaoFreteGarantiaScreen;