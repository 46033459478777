// Dependências
import React from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./Menu.module.css";

const Menu = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    return (
        <div className={classes.MenuBackground}>
            <div className={darkMode ? classes.MenuDark : classes.MenuLight}>
                <div className={classes.Body}>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={props.passo > 1 ? classes.ItemSelecionado : classes.ItemAtivo}
                                onClick={() => {
                                    if (props.passo > 1)
                                        props.selecaoTipo();
                                }}>
                                {props.tipo ? `Tipo: ${props.tipo}` : "Seleção do tipo"}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={props.passo > 2 ? classes.ItemSelecionado : props.passo === 2 ? classes.ItemAtivo : classes.Item}
                                onClick={() => {
                                    if (props.passo > 2)
                                        props.selecaoMarca();
                                }}>
                                {props.marca ? `Marca: ${props.marca}` : "Seleção da marca"}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={props.passo > 3 ? classes.ItemSelecionado : props.passo === 3 ? classes.ItemAtivo : classes.Item}
                                onClick={() => {
                                    if (props.passo > 3)
                                        props.selecaoAno();
                                }}>
                                {props.ano ? `Ano: ${props.ano}` : "Seleção do ano"}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={props.passo > 4 ? classes.ItemSelecionado : props.passo === 4 ? classes.ItemAtivo : classes.Item}
                                onClick={() => {
                                    if (props.passo > 4)
                                        props.selecaoVeiculo();
                                }}>
                                {props.veiculo ? `Veiculo: ${props.veiculo}` : "Seleção do veiculo"}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={props.passo > 5 ? classes.ItemSelecionado : props.passo === 5 ? classes.ItemAtivo : classes.Item}
                                onClick={() => {
                                    if (props.passo > 5)
                                        props.selecaoModelo();
                                }}>
                                {props.modelo ? `Modelo: ${props.modelo}` : "Seleção do modelo"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;