// Dependências
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

// Css
import classes from "./Tooltip.module.css";

const Tooltip = (props) => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);

    let tipPosition = classes.Left;
    switch (props.tipPosition) {
        case "left":
            tipPosition = classes.Left;
            break;
        case "right":
            tipPosition = classes.Right;
            break;
        case "bottom":
            tipPosition = classes.Bottom;
            break;
        default:
            tipPosition = classes.Bottom;
    }

    return (
        <div
            className={`${classes.ConTooltip} ${tipPosition} ${props.className}`}
            style={props.style}
        >
            <p>
                <AiOutlineQuestionCircle style={props.light ? { color: "#fff" } : { color: "#6f37d6" }} />
            </p>
            <div className={darkMode ? classes.TooltipDark : classes.TooltipLight}>
                <p>{props.children}</p>
            </div>
        </div>
    );
};

export default Tooltip;
