// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import * as funcionariosActions from "../../../../store/actions/funcionarios";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";
import * as validar from "../../../../helpers/validar";

// Components
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";

const ModalFuncionario = props => {
    const dispatch = useDispatch();

    const [nome, setNome] = useState("");
    const [cargo, setCargo] = useState("");

    const [isNomeValid, setIsNomeValid] = useState(true);
    const [isCargoValid, setIsCargoValid] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = true;

        if (!validar.nome(nome)) {
            formIsValid = false;
            setIsNomeValid(false);
        } else setIsNomeValid(true);

        if (!validar.nome(cargo)) {
            formIsValid = false;
            setIsCargoValid(false);
        } else setIsCargoValid(true);

        if (formIsValid) {
            try {
                if (props.funcionario) {
                    await dispatch(funcionariosActions.updateFuncionario(props.funcionario.id, { nome: nome, cargo: cargo }));
                    exibirMensagem.success("O funcionário foi editado com sucesso");
                } else {
                    await dispatch(funcionariosActions.createFuncionario({ nome: nome, cargo: cargo }));
                    exibirMensagem.success("O funcionário foi cadastrado com sucesso");
                };

                props.onClose();
            } catch (error) {
                console.log(error);
            }
        } else exibirMensagem.formInvalid();
    };

    const handleDelete = async () => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: `Você tem certeza que deseja excluir o funcionário ${props.funcionario.nome}? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(funcionariosActions.deleteFuncionario(props.funcionario.id));
                    exibirMensagem.success("O funcionário foi deletado com sucesso");

                    props.onClose();
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            }
        });
    };

    useEffect(() => {
        if (props.funcionario) {
            setNome(props.funcionario.nome);
            setCargo(props.funcionario.cargo);
        };
    }, [props.funcionario])

    return (
        <Modal
            onClose={props.onClose}
            titulo={props.funcionario ? "Editar funcionário" : "Cadastrar um novo funcionário"}
        >
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-12">
                            <h5>Nome:</h5>
                            <Input
                                value={props.funcionario ? props.funcionario.nome : ""}
                                onChange={(value) => setNome(value)}
                                invalid={!isNomeValid}
                                size="100%"
                                placeholder="Nome do novo funcionário" />
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-12">
                            <h5>Cargo:</h5>
                            <Input
                                value={props.funcionario ? props.funcionario.cargo : ""}
                                onChange={(value) => setCargo(value)}
                                invalid={!isCargoValid}
                                size="100%"
                                placeholder="Cargo do novo funcionário" />
                        </div>
                    </div>

                    {props.funcionario ? (
                        <div className="row">
                            <div className="col-6 text-center">
                                <Button color="red" type="button" onClick={handleDelete}>Excluir</Button>
                            </div>
                            <div className="col-6 text-center">
                                <Button color="green" type="submit">Salvar</Button>
                            </div>
                        </div>
                    ) : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <Button color="green" type="submit">Cadastrar</Button>
                                </div>
                            </div>
                        )}
                </div>
            </form>
        </Modal >
    );
};

export default ModalFuncionario;