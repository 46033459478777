// Dependências
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";

// Css
import classes from "./BarraPesquisa.module.css";

// Components
import Input from "../Input/Input";

const BarraPesquisa = props => {
    const [text, setText] = useState("");

    return (
        <div className={classes.BarraPesquisa}>
            <Input
                light
                enterHandler
                type="text"
                size="100%"
                padding="0 50px 0 10px"
                name={props.name}
                placeholder={props.placeholder ? props.placeholder : "Buscar por um Item"}
                datalist={props.datalist ? props.datalist : false}
                onChange={value => setText(value)}
                setValue={text}
                onBlur={event => props.onChange(event.target.value)}
                onKeyUp={event => {
                    if (event.key === "Enter") {
                        event.target.blur();
                        props.onChange(event.target.value);
                    };
                }}
            />

            {props.cleanOption && text.length ? (
                <AiOutlineCloseCircle
                    className={classes.CleanButton}
                    onClick={() => { setText(""); props.onChange("") }}
                    title="Limpar pesquisa"
                    size="20px" />
            ) : null}

            <button
                type="button"
                className={`${classes.BotaoPesquisa} btn btn-outline-secondary`}
                onClick={() => { if (props.onBuscar) props.onBuscar() }}
            > <BsSearch /> </button>
        </div>
    );
};

export default BarraPesquisa;
