// Dependências
import React from "react";

// Css
import classes from "./Button.module.css";

const Button = props => {
    return (
        <button
            className={`
                ${classes.Button} 
                ${props.className} 
                ${props.invert ? classes.Invert : ""}
                ${props.small ? classes.Small : ""}
            `}
            type={props.type ? props.type : 'button'}
        >
            {props.children}
        </button>
    );
};

export default Button;