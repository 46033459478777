// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";

// Actions
import * as pecasActions from "../../../store/actions/pecas";

// Css
import classes from "./PecasScreen.module.css";

// Helpers
import * as exibirMensagem from "../../../helpers/exibirMensagem";
import * as validar from "../../../helpers/validar";

// Components
import BarraPesquisa from "../../../components/UI/BarraPesquisa/BarraPesquisa";
import Button from "../../../components/UI/Button/Button";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ContextMenu from "../../../components/UI/ContextMenu/ContextMenu";
import BeautyDropdown from "../../../components/UI/BeautyDropdown/BeautyDropdown";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Peca from "../../../components/Estoque/Peca/Peca";
import Table from "../../../components/UI/Table/Table";

const PecasScreen = props => {
    const dispatch = useDispatch();

    const localizacoes = useSelector(state => state.pecas.localizacoes);
    const pecas = useSelector(state => state.pecas.pecas);

    const [busca, setBusca] = useState("");

    const [isSelecting, setIsSelecting] = useState(false);
    const [showLocalizacao, setShowLocalizacao] = useState(false);

    const [pecasSelecionadas, setPecasSelecionadas] = useState([]);

    const [doravante, setDoravante] = useState(1);
    const [doRequest, setDoRequest] = useState(false);
    const [ordem, setOrdem] = useState("mais-recente");
    const [isLoading, setIsLoading] = useState(true);
    const [displayPecasCadastradas, setDisplayPecasCadastradas] = useState(true);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [idLastAnuncio, setIdLastAnuncio] = useState("");

    const [idPecaContextMenu, setidPecaContextMenu] = useState(null);
    const [displayContextMenu, setDisplayContextMenu] = useState(false);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    // Requisição das Localizações
    useEffect(() => {
        const fetchLocalizacoes = async () => {
            try {
                setIsLoading(true);
                await dispatch(pecasActions.fetchLocalizacoes());
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchLocalizacoes();
    }, [dispatch]);

    // Requisição das pecas
    useEffect(() => {
        const fetchPecas = async () => {
            try {
                setIsLoading(true);
                await dispatch(pecasActions.fetchTodasPecas(
                    doravante,
                    busca,
                    ordem,
                    50,
                    displayPecasCadastradas ? 1 : null,
                    idLastAnuncio
                ));
                setIsRequestCompleted(true);
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, doravante, doRequest]);

    // Setando showLocalizacao
    useEffect(() => {
        let newShowLocalizacao = false;

        pecas.forEach(peca => peca.localizacao?.id ? newShowLocalizacao = true : null);

        setShowLocalizacao(newShowLocalizacao);
    }, [pecas]);

    // Setando idLastAnuncio
    useEffect(() => {
        const anuncios = pecas.filter(peca => peca.link);
        if (anuncios.length) setIdLastAnuncio(anuncios[anuncios.length - 1].id);
        else setIdLastAnuncio("");
    }, [pecas]);

    // Escolhe se usuário vai criar uma localização um cadastrar uma existente
    const armazenarPecas = () => {
        const armazenar = () => {
            if (localizacoes.length)
                exibirMensagem.exibir({
                    title: isSelecting ? "Como deseja armazenar as peças?" : "Como deseja armazenar a peça?",
                    showCancelButton: true,
                    cancelButtonText: "Localização existente",
                    confirmButtonText: "Nova localização",
                    confirmButtonColor: "#404242",
                    cancelButtonColor: "#404242",
                }).then(async result => {
                    if (result.isConfirmed)
                        createLocalizacao();
                    else
                        exibirMensagem.exibir({
                            title: "Selecione a localização:",
                            input: "select",
                            inputOptions: localizacoes.map(localizacao => localizacao.nome)
                        }).then(async result => {
                            if (result.value) {
                                if (isSelecting)
                                    await dispatch(pecasActions.updatePecas(pecasSelecionadas.map(pecaSelecionada => { return { id: pecaSelecionada.id, localizacao: localizacoes[result.value] } })))
                                else
                                    await dispatch(pecasActions.updatePecas([{ id: idPecaContextMenu, localizacao: localizacoes[result.value] }]));

                                setIsSelecting(false);
                                setPecasSelecionadas([]);
                                exibirMensagem.success("Peças armazenadas com sucesso.");
                            };
                        });
                });
            else
                createLocalizacao();
        };

        if (isSelecting && pecasSelecionadas.find(pecaSelecionada => pecaSelecionada.localizacao?.id))
            exibirMensagem.exibir({
                title: "Algumas peças selecionadas já possuem localização, deseja prosseguir?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim"
            }).then(async result => {
                if (result.isConfirmed)
                    armazenar();
            });
        else
            armazenar();
    };

    // Cria uma localização
    const createLocalizacao = () => {
        exibirMensagem.exibir({
            title: "Digite o nome da Localização:",
            input: "text",
            showCancelButton: true,
            inputValidator: value => {
                if (!validar.nome(value) || localizacoes.find(localizacao => localizacao.nome === value))
                    return "A localização informada não é válida";
            },
        }).then(async result => {
            if (result.value) {
                if (isSelecting)
                    await dispatch(pecasActions.createLocalizacao(result.value, pecasSelecionadas.map(pecaSelecionada => { return { id: pecaSelecionada.id } })));
                else
                    await dispatch(pecasActions.createLocalizacao(result.value, [{ id: idPecaContextMenu }]));


                setIsSelecting(false);
                setPecasSelecionadas([]);
                exibirMensagem.success("Peças armazenadas com sucesso.");
            };
        });
    };

    // Chama o action de apagar uma peca
    const deletePecas = async () => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: isSelecting ? `Você tem certeza que deseja excluir ${pecasSelecionadas.length} peça(s)? Essa ação não poderá ser revertida!` : `Você tem certeza que deseja excluir esta peça? Essa ação não poderá ser revertida!`,
            showCancelButton: true,
            confirmButtonText: "Sim, pode excluir",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (isSelecting) {
                        await dispatch(pecasActions.deletePecas(pecasSelecionadas.map(pecaSelecionada => { return { id: pecaSelecionada.id } })));
                        exibirMensagem.success("Peças excluídas com sucesso.");
                    } else {
                        await dispatch(pecasActions.deletePeca(idPecaContextMenu));
                        exibirMensagem.success("Peça excluída com sucesso.");
                    };
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    const deleteLocalizacaoPeca = () => {
        exibirMensagem.warning({
            title: "Tem certeza?",
            text: "Deseja desassociar essa peça dos seus locais de armazenamento?",
            showCancelButton: true,
            confirmButtonText: "Sim",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(pecasActions.updatePecas([{ id: idPecaContextMenu, localizacao: { id: null } }]));
                    exibirMensagem.success("Peça atualizada com sucesso.");
                } catch (error) {
                    console.log(error);
                };
            } else {
                return;
            };
        });
    };

    const getHeaderTable = () => {
        const header = ["Código", "Peça", "Modelo", "Data de Cadastro"];

        if (showLocalizacao)
            header.push("Localização");

        if (!displayPecasCadastradas)
            header.push("Status");

        return header;
    };

    // Função de quando clica com o botão direito numa linha da tabela.
    const handleContextMenuClick = (event, idPeca) => {
        event.preventDefault();

        setidPecaContextMenu(idPeca);
        setPositionX(event.pageX);
        setPositionY(event.pageY);
        setDisplayContextMenu(!displayContextMenu);
    };

    const handleOnScrollBottom = () => {
        if (pecas.length || !isRequestCompleted)
            setDoravante(doravante + 1);
    };

    return !isRequestCompleted ? <Spinner /> : pecas.length || isLoading || busca.length ? (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <BarraPesquisa
                        cleanOption
                        placeholder="Pesquisar no meu estoque"
                        onChange={novaBusca => {
                            if (doravante !== 1)
                                setDoravante(1);
                            else
                                setDoRequest(!doRequest);

                            setBusca(novaBusca);
                        }} />
                </div>

                <div className="col-md-4 col-sm-12">
                    <BeautyDropdown
                        list={[
                            { id: "mais-recente", nome: "Mais recentes" },
                            { id: "mais-antigo", nome: "Mais antigas" }
                        ]}
                        onChange={idOrdem => {
                            if (doravante !== 1)
                                setDoravante(1);
                            else
                                setDoRequest(!doRequest);

                            setOrdem(idOrdem);
                        }} />
                </div>
            </div>

            <div className="row mb-2">
                <div className={`col-3 ${classes.HiddenMobile}`}>
                    <Button
                        small
                        color="purple"
                        onClick={() => props.history.push("/estoque/grupo-sucatas")}>
                        <AiOutlineArrowLeft /> Listar grupos
                    </Button>
                </div>

                {isSelecting ? (
                    <>
                        <div className={`col-3 ${classes.HiddenMobile}`}>
                            <Button
                                small
                                color="gray"
                                onClick={() => {
                                    setIsSelecting(false);
                                    setPecasSelecionadas([]);
                                }}>
                                Cancelar seleção
                            </Button>
                        </div>

                        {pecasSelecionadas.length ? (
                            <div className={`col-2 ${classes.HiddenMobile}`}>
                                <Button
                                    small
                                    color="purple"
                                    onClick={() => props.history.push(`/estoque/pecas/fotos?pecas=${pecasSelecionadas.map(peca => peca.id)}`)}
                                >
                                    Editar fotos
                                </Button>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className={`col-3 ${classes.HiddenMobile}`}>
                        <Button small color="green" onClick={() => setIsSelecting(true)}>
                            Selecionar peças
                        </Button>
                    </div>
                )}

                <div className={`${pecasSelecionadas.length ? "col-md-4" : "col-md-6"} col-sm-12 text-right`}>
                    <Checkbox
                        size="small"
                        defaultChecked={displayPecasCadastradas}
                        onChange={value => {
                            if (doravante !== 1)
                                setDoravante(1);
                            else
                                setDoRequest(!doRequest);

                            setDisplayPecasCadastradas(value)
                        }}>
                        Peças cadastradas
                    </Checkbox>
                </div>
            </div>

            <div className="row" style={{ height: "78vh" }}>
                <Table
                    fixed
                    isLoading={isLoading}
                    hoverble={pecas.length}
                    header={getHeaderTable()}
                    bordered={pecas.length && !isSelecting}
                    onScrollBottom={handleOnScrollBottom}>
                    {pecas.length || isLoading ? pecas.map(peca =>
                        <Peca
                            key={peca.id}
                            peca={peca}
                            showLocalizacao={showLocalizacao}
                            showStatus={!displayPecasCadastradas}
                            isSelected={pecasSelecionadas.find(pecaSelecionada => pecaSelecionada.id === peca.id) ? true : false}
                            onContextMenu={event => {
                                if (isSelecting && !pecasSelecionadas.find(pecaSelecionada => pecaSelecionada.id === peca.id))
                                    setPecasSelecionadas([...pecasSelecionadas, { ...peca }]);

                                handleContextMenuClick(event, peca.id);
                            }}
                            onClick={() => {
                                if (!isSelecting)
                                    props.history.push(`/estoque/pecas/${peca.id}`);
                                else {
                                    if (pecasSelecionadas.find(pecaSelecionada => pecaSelecionada.id === peca.id))
                                        setPecasSelecionadas(pecasSelecionadas.filter(pecaSelecionada => pecaSelecionada.id !== peca.id));
                                    else
                                        setPecasSelecionadas([...pecasSelecionadas, { ...peca }]);
                                };
                            }} />
                    ) : <tr><td className="h5" colSpan="5">Nenhum resultado encontrado</td></tr>}
                </Table>

                {/* Menu que aparece ao clicar com o botão direito */}
                <ContextMenu
                    display={displayContextMenu}
                    onClickOutside={() => setDisplayContextMenu(false)}
                    x={positionX}
                    y={positionY}>

                    {isSelecting ? (
                        <div>
                            <li onClick={() => armazenarPecas()}>Armazenar {pecasSelecionadas.length} peça(s)</li>
                            <li className={classes.DeleteButton} onClick={() => deletePecas(idPecaContextMenu)}>Excluir {pecasSelecionadas.length} peça(s)</li>
                        </div>
                    ) : (
                        <div>
                            <li onClick={() => armazenarPecas()}>{pecas.find(peca => idPecaContextMenu === peca.id)?.localizacao?.id ? "Editar Localização" : "Armazenar"}</li>
                            {pecas.find(peca => idPecaContextMenu === peca.id)?.localizacao?.id ? <li onClick={deleteLocalizacaoPeca}>Apagar Localização</li> : null}
                            <li className={classes.DeleteButton} onClick={deletePecas}>Excluir</li>
                        </div>
                    )}
                </ContextMenu>
            </div>
        </div>
    ) : (
        <div className="row" style={{ height: "83vh" }}>
            <div style={{ position: "relative", width: "100%", height: "90%" }}>
                <div className={classes.Center}>
                    <h2>Você ainda não tem nenhuma peça em estoque!</h2>
                    <br />
                    <h4>Você pode cadastrar uma peça clicando <Link to="/cadastro"> aqui</Link>.</h4>
                </div>
            </div>
        </div>
    );
};

export default PecasScreen;
