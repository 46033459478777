// Dependências
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineSave } from "react-icons/ai";

// Css
import classes from "./Textarea.module.css";

// Components
import Spinner from "../Spinner/Spinner";

const Textarea = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);
    const [atualizado, setAtualizado] = useState(true);
    const [value, setValue] = useState(props.value);

    // Setando valor igual props.value
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div className="col-12 p-0">
            <textarea
                id={props.id}
                className={`${darkMode ? classes.TextareaDark : classes.TextareaLight} ${props.invalid ? classes.IsNotValid : ""} ${props.noResize ? classes.NoResize : ""} ${props.className} form-control`}
                onBlur={event => props.onBlur ? props.onBlur(event) : null}
                onChange={(event) => {
                    setValue(event.target.value);
                    if (props.onChange)
                        props.onChange(event.target.value);
                    event.target.value !== props.value ? setAtualizado(false) : setAtualizado(true);
                }}
                placeholder={props.placeholder}
                value={props.setValue}
                defaultValue={props.value}
                maxLength={props.maxLength}
                rows={props.rows ? props.rows : "2"}
            />
            {props.isLoading ? <Spinner className={classes.Spinner} inline /> :
                props.onSave && !atualizado ? (
                    <button
                        className={`${classes.SaveButton}`}
                        title="Salvar"
                        onClick={() => {
                            props.onSave(value);
                            setAtualizado(true);
                        }}
                    >
                        <AiOutlineSave />
                    </button>
                ) : null
            }

        </div>
    );
};


export default Textarea;