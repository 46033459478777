// Dependências
import React from "react";

// Components
import Permissao from "./Permissao/Permissao";

// Css
import classes from "./TipoPermissao.module.css";

const TipoPermissao = props => {
    return (
        <div className="mb-3">
            <div className={classes.Titulo}>{props.tipoPermissao.nome}.</div>

            {props.tipoPermissao.permissoes.map(permissao =>
                <Permissao
                    key={permissao.id}
                    permissao={permissao}
                    onChange={value => {
                        let copiaPermissoes = props.tipoPermissao.permissoes;

                        copiaPermissoes.forEach(copiaPermissao => {
                            if (copiaPermissao.id === permissao.id)
                                copiaPermissao.checked = value;
                        });

                        if (value)
                            copiaPermissoes.forEach(copiaPermissao => {
                                if (copiaPermissao.nivel < permissao.nivel)
                                    copiaPermissao.checked = true;
                            });

                        props.onChange(copiaPermissoes);
                    }}
                />
            )}
        </div>
    );
};

export default TipoPermissao;