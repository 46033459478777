// Depedências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as sucatasActions from "../../../../store/actions/sucatas";

// Components
import BottomNavbar from "../../../../components/Cadastro/BottomNavbar/BottomNavbar";
import Input from "../../../../components/UI/Input/Input";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Titulo from "../../../../components/UI/Titulo/Titulo";

// Css
import classes from "./TituloSucataScreen.module.css";

const TituloSucata = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const sucata = useSelector(store => store.sucatas.sucata);

    const [titulo, setTitulo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    // Requisição da sucata
    useEffect(() => {
        const fetchSucata = async () => {
            setIsLoading(true);
            try {
                await dispatch(sucatasActions.fetchSucata(idSucata));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchSucata();
    }, [dispatch, idSucata]);

    // Seta o título inicial como o título da sucata
    useEffect(() => {
        if (sucata.fipe)
            setTitulo(sucata.titulo ? sucata.titulo : `- ${sucata.fipe.modelo.nome}`);
    }, [sucata]);

    const submitHandler = async () => {
        setIsLoading(true);
        try {
            await dispatch(sucatasActions.updateSucata(idSucata, { titulo: titulo }));
            props.history.push(`/cadastro/sucata/titulo-pecas/${idSucata}`);
        } catch (error) {
            console.log(error);
        };
        setIsLoading(false);
    };

    return isLoading ? <Spinner /> : (
        <div className="container-fluid pb-5">
            <Titulo>Título dos anúncios – Edição em massa</Titulo>

            Digite abaixo o modelo do carro, da maneira mais otimizada para o título dos anúncios. Montaremos um título sugerido para todas as peças com base nisso, e você poderá editá-los individualmente na próxima tela.

            <div className="row">
                <div className="col-12 mt-4">
                    Ex:<br />
                    <strong>FIPE:</strong> Fiat Palio ESSENCE 1.6 Flex 16V 5p 2019<br />
                    <strong>Modelo para anúncios:</strong> Fiat Palio 2019<br />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-4">
                    <span style={{ fontSize: "12px" }}>
                        DICA: Informações como cilindrada do motor, combustível, 8v /16v, 2p/4p são relevantes apenas para algumas peças (alternador, cabeçote, motor, ou então frisos, portas, máquina de vidro, etc).
                        Portanto, <strong>RECOMENDAMOS NÃO INSERIR</strong> essas informações aqui, pois irão consumir caracteres no título de anúncios de peças com informações que não são relevantes.
                        Na próxima tela, você poderá visualizar e editar o título de cada peça individualmente. Este é o momento ideal de colocar tais informações.
                    </span>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <Input
                        size="80%"
                        placeholder="Título da sucata"
                        maxLength="55"
                        value={sucata.titulo || sucata.fipe.modelo.nome || `${sucata.fipe.marca.nome} ${sucata.fipe.veiculo.nome} ${sucata.fipe.ano.nome}`}
                        onChange={value => setTitulo(value)}
                    />
                </div>
            </div>

            <div className="mt-4">
                <h6><strong>Como ficará o título sugerido para suas peças, com base no modelo informado:</strong></h6>

                <div className="col-12 h6">
                    <p>Kit Airbag <span className={classes.Titulo}>{titulo}</span></p>
                    <p>Compressor Ar Condicionado <span className={classes.Titulo}>{titulo}</span></p>
                    <p>Motor Parcial com Cabeçote <span className={classes.Titulo}>{titulo}</span></p>
                    <p>Tampa Traseira Porta-Malas <span className={classes.Titulo}>{titulo}</span></p>
                </div>
            </div>

            <BottomNavbar
                onAntClick={() => props.history.push("/cadastro/sucata")}
                onProxClick={submitHandler} />
        </div >
    );
};

export default TituloSucata;

