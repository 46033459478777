import * as exibirMensagem from "./exibirMensagem";

const statusHandler = (statusCode) => {
    switch (statusCode) {
        case 400:
            exibirMensagem.error({
                title: "400: Bad Request",
                text: "Houve um erro interno ao tentar fazer a solicitação pro servidor, tente novamente. Se o problema persistir, por favor contate-nos"
            });
            throw statusCode;
        case 401:
            localStorage.setItem("bearerToken", JSON.stringify(""));
            sessionStorage.setItem("bearerToken", JSON.stringify(""));
            document.location.reload(true);

            break;
        case 403:
            exibirMensagem.error({
                title: "403: Forbidden",
                text: "Você não tem permissão para realizar o procedimento!"
            });
            throw statusCode;
        case 404:
            exibirMensagem.error({
                title: "404: Not Found",
                text: "Não foi possível encontrar a rota solicitada, tente novamente. Se o problema persistir, por favor contate-nos"
            });
            throw statusCode;
        case 409:
            exibirMensagem.error({
                title: "409: Conflict",
                text: "Houve algum conflito inesperado com os dados, tente novamente. Se o problema persistir, por favor contate-nos"
            });
            throw statusCode;
        case 500:
            exibirMensagem.error({
                title: "500: Internal Server Error",
                text: "Houve um erro interno, tente novamente. Se o problema persistir, por favor contate-nos"
            });
            throw statusCode;
        default:
            exibirMensagem.error();
            console.log(statusCode)
    }
};

export default statusHandler;