import axios from 'axios';

import { API_URL } from "../../constants/GlobalConfig";
import statusHandler from "../../helpers/statusHandler";

export const DELETE_PERGUNTA = "DELETE_PERGUNTA";
export const FETCH_PERGUNTAS_ABERTAS = "FETCH_PERGUNTAS_ABERTAS";
export const FETCH_PERGUNTA = "FETCH_PERGUNTA";
export const SEND_RESPOSTA = "SEND_RESPOSTA";

export const deletePergunta = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/perguntas/${id}`,
            headers: { Authorization: token },
        }).then((result) => {
            if (result.status) {
                dispatch({ type: DELETE_PERGUNTA, id: id });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};


export const fetchPerguntasEmAberto = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/perguntas-em-aberto`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let perguntas = [];
            try {
                perguntas = await response.json();
                dispatch({ type: FETCH_PERGUNTAS_ABERTAS, perguntas: perguntas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const enviarResposta = (idPergunta, marketplace, resposta) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        await axios({
            method: 'POST',
            url: `${API_URL}/perguntas/${marketplace}/${idPergunta}`,
            headers: {
                Authorization: token
            },
            data: { resposta: resposta }
        }).then((result) => {
            if (result.status) {
                dispatch({ type: SEND_RESPOSTA, id: idPergunta });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};