import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";

import adminReducer from "./reducers/admin";
import anunciosReducer from "./reducers/anuncios";
import authReducer from "./reducers/auth";
import cadastroReducer from "./reducers/cadastro";
import clientesReducer from "./reducers/clientes";
import colorPaletteReducer from "./reducers/color-palette";
import configReducer from "./reducers/config";
import despesasReducer from './reducers/despesas';
import financeiroReducer from "./reducers/financeiro";
import fipeReducer from "./reducers/fipe";
import funcionariosReducer from "./reducers/funcionarios";
import grupoPecasReducer from "./reducers/grupo-pecas";
import gruposSucatasReducer from "./reducers/grupos-sucatas";
import mercadoLivreReducer from "./reducers/mercado-livre";
import pecasReducer from "./reducers/pecas";
import sucatasReducer from "./reducers/sucatas";
import usuarioReducer from "./reducers/usuario";
import vendasReducer from "./reducers/vendas";
import perguntasReducer from './reducers/perguntas';
import respostasProntasReducer from './reducers/respostas-prontas';
import pecasTiposReducer from "./reducers/pecas-tipos";

const rootReducer = combineReducers({
    admin: adminReducer,
    anuncios: anunciosReducer,
    auth: authReducer,
    cadastro: cadastroReducer,
    clientes: clientesReducer,
    colorPalette: colorPaletteReducer,
    config: configReducer,
    despesas: despesasReducer,
    financeiro: financeiroReducer,
    fipe: fipeReducer,
    funcionarios: funcionariosReducer,
    grupoPecas: grupoPecasReducer,
    gruposSucatas: gruposSucatasReducer,
    mercadoLivre: mercadoLivreReducer,
    pecas: pecasReducer,
    sucatas: sucatasReducer,
    usuario: usuarioReducer,
    vendas: vendasReducer,
    perguntas: perguntasReducer,
    respostasProntas: respostasProntasReducer,
    pecasTipos: pecasTiposReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;
