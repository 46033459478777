// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as grupoSucatasActions from "../../../../store/actions/grupos-sucatas";
import * as pecasActions from "../../../../store/actions/pecas";

// Components
import Medidas from "../../../../components/Cadastro/Medidas/Medidas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const MedidasScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;

    const grupoSucatas = useSelector(state => state.gruposSucatas.grupoSucatas);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas em revisão
    useEffect(() => {
        const fetchGrupoSucatas = async () => {
            setIsLoading(true);
            try {
                await dispatch(grupoSucatasActions.fetchGrupoSucatas(idGrupoSucatas));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchGrupoSucatas();
    }, [dispatch, idGrupoSucatas]);

    const submitHandler = async pecas => {
        setIsLoading(true);

        try {
            await dispatch(pecasActions.updatePecas(pecas));
            setIsLoading(false);
            exibirMensagem.success("Alterações realizadas com sucesso.");
            props.history.push(`/cadastro/grupo-pecas/ficha-tecnica/${idGrupoSucatas}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading || !grupoSucatas.id ? <Spinner /> : (
        <div>
            <h4 className="mb-3">Informe as medidas das seguintes peças:</h4>

            <Medidas
                showSucata
                pecas={[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas.map(peca => { return { ...peca, sucata: sucata.fipe.veiculo.nome } })))}
                onAntClick={() => props.history.push('/cadastro/grupo-pecas')}
                onProxClick={pecas => submitHandler(pecas)} />
        </div>
    );
};

export default MedidasScreen;