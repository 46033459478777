// Dependências
import React from "react";
import { FaTrash } from "react-icons/fa";

// Css
import classes from "./Despesa.module.css";

// Helpers
import * as formatar from "../../../../helpers/formatar";

const Despesa = props => {
    return (
        <tr className="text-center">
            <td>
                <div className={classes.ExcluirDespesa} onClick={props.onDelete}>
                    <FaTrash size="17px" title="Apagar despesa" />
                </div>
                {props.despesa.categoria.subcategoria.nome}
            </td>
            <td>{formatar.data(props.despesa.dataInicio)}</td>
            <td>{formatar.dinheiro(props.despesa.valor)}</td>
        </tr>
    );
};

export default Despesa;