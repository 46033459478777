import {
    FETCH_AREAS,
    FETCH_PECAS_TIPOS_ARQUETIPOS
} from "../actions/pecas-tipos";

const initialState = {
    areas: [],
    arquetipos: []
};

const tipoPecasReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AREAS:
            return { ...state, areas: action.areas };
        case FETCH_PECAS_TIPOS_ARQUETIPOS:
            return { ...state, arquetipos: action.arquetipos };
        default:
            return state;
    }
};

export default tipoPecasReducer;
