import {
    DELETE_ARQUETIPOS_DETRAN,
    DELETE_MENSAGEM,
    FETCH_ARQUETIPOS_DETRAN,
    FETCH_CONTAS,
    FETCH_MENSAGENS
} from "../actions/admin";

const initialState = {
    arquetiposDetran: [],
    contas: [],
    mensagens: []
};

const adminReducer = (state = initialState, action) => {
    let copiaArquetiposDetran = [...state.arquetiposDetran];
    let copiaMensagens = [...state.mensagens];

    switch (action.type) {
        case DELETE_ARQUETIPOS_DETRAN:
            if (!action.idArquetipo)
                copiaArquetiposDetran = copiaArquetiposDetran.filter(arquetipo => arquetipo.nome !== action.nomeArquetipoDetran);
            else {
                try {
                    copiaArquetiposDetran = copiaArquetiposDetran.map(arquetipoDetran => arquetipoDetran.nome === action.nomeArquetipoDetran ? { ...arquetipoDetran, arquetipos: arquetipoDetran.arquetipos.filter(arquetipo => arquetipo.id !== action.idArquetipo) } : arquetipoDetran)
                } catch (error) {
                    console.log(error);
                };
            };

            return { ...state, arquetiposDetran: [...copiaArquetiposDetran] };
        case DELETE_MENSAGEM:
            copiaMensagens = copiaMensagens.filter(mensagem => mensagem.id !== action.idMensagem);
            return { ...state, mensagens: [...copiaMensagens] };
        case FETCH_ARQUETIPOS_DETRAN:
            return { ...state, arquetiposDetran: [...action.arquetiposDetran] };
        case FETCH_CONTAS:
            return { ...state, contas: [...action.contas] };
        case FETCH_MENSAGENS:
            return { ...state, mensagens: [...action.mensagens] };
        default:
            return state;
    }
};

export default adminReducer;
