// Dependências
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import * as respostaProntaActions from '../../../store/actions/respostas-prontas';

// Helpers
import * as exibirMensagem from '../../../helpers/exibirMensagem';

// Componentes
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Modal from '../../UI/Modal/Modal';
import Spinner from '../../UI/Spinner/Spinner'
import Textarea from '../../UI/Textarea/Textarea';

const ModalRespostaPronta = props => {
    const dispatch = useDispatch();

    const [dadosRespostaPronta, setDadosRespostaPronta] = useState(props.respostaPronta ? props.respostaPronta : {
        id: "",
        titulo: "",
        texto: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [tituloInvalid, setTituloInvalid] = useState(false);
    const [textoInvalid, setTextoInvalid] = useState(false);

    const updateDadosRespostaPronta = (key, value) => {
        let copiaDadosRespostaPronta = { ...dadosRespostaPronta };

        switch (key) {
            case 'titulo':
                copiaDadosRespostaPronta.titulo = value;
                break;
            case 'texto':
                copiaDadosRespostaPronta.texto = value;
                break;
            default:
                return;
        }
        setDadosRespostaPronta(copiaDadosRespostaPronta);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = true;

        if (!dadosRespostaPronta.titulo) {
            setTituloInvalid(true);
            formIsValid = false;
        } else setTituloInvalid(false);

        if (!dadosRespostaPronta.texto) {
            setTextoInvalid(true);
            formIsValid = false;
        } else setTextoInvalid(false);

        if (formIsValid) {
            setIsLoading(true);
            try {
                if (dadosRespostaPronta.id) {
                    await dispatch(respostaProntaActions.atualizarRespostaPronta(dadosRespostaPronta.id, {
                        titulo: dadosRespostaPronta.titulo,
                        texto: dadosRespostaPronta.texto
                    }))
                    props.onClose()
                    exibirMensagem.success("Modelo de Resposta atualizado!")
                } else {
                    await dispatch(respostaProntaActions.criarRespostaPronta({
                        titulo: dadosRespostaPronta.titulo,
                        texto: dadosRespostaPronta.texto
                    }))
                    props.onClose()
                    exibirMensagem.success("Modelo de Resposta criado!")
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        } else {
            exibirMensagem.formInvalid();
        }
    }

    const handleDelete = async () => {
        setIsLoading(true);

        try {
            if (dadosRespostaPronta.id) {
                await dispatch(respostaProntaActions.removerRespostaPronta(dadosRespostaPronta.id))
                props.onClose()
                exibirMensagem.success("Modelo de Resposta excluído")
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false);
    }

    return (
        <Modal titulo="Resposta Pronta" onClose={() => props.onClose()}>
            <form onSubmit={handleSubmit}>
                {isLoading ? (
                    <Spinner />
                ) : (
                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-12">
                                    <h5>Titulo</h5>
                                    <Input
                                        value={dadosRespostaPronta ? dadosRespostaPronta.titulo : null}
                                        onChange={(value) => updateDadosRespostaPronta('titulo', value)}
                                        type="text"
                                        invalid={tituloInvalid}
                                        placeholder="Título da resposta"
                                        maxLength="55"
                                        size="100%"
                                        tag
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5>Texto</h5>
                                    <Textarea
                                        value={dadosRespostaPronta ? dadosRespostaPronta.texto : null}
                                        onChange={(value) => updateDadosRespostaPronta('texto', value)}
                                        invalid={textoInvalid}
                                        rows="3"
                                        placeholder="Texto de resposta"
                                        maxLength="255"
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                {props.respostaPronta ? (
                                    <div className="col-6 text-center">
                                        <Button
                                            color="red"
                                            onClick={() => handleDelete()}>Excluir</Button>
                                    </div>
                                ) : null}
                                <div className={`${props.respostaPronta ? "col-6" : "col-12"} text-center`}>
                                    <Button
                                        color="green"
                                        type="submit">Salvar</Button>
                                </div>
                            </div>
                        </div>
                    )}
            </form>
        </Modal>
    );
}

export default ModalRespostaPronta;