import {
    CREATE_SUCATA,
    FETCH_FOTOS_AREA,
    FETCH_SUCATA,
    FETCH_SUCATAS,
    FETCH_PECAS_CADASTRAVEIS,
} from "../actions/sucatas";

import {
    CREATE_DESPESAS_SUCATA,
    DELETE_DESPESA
} from "../actions/despesas";

import {
    CREATE_PECAS,
    DELETE_PECA
} from "../actions/pecas";

const initialState = {
    sucatas: [],
    sucata: {},
    fotosAreas: [],
    pecasCadastraveis: []
};

const sucatasReducer = (state = initialState, action) => {
    let copiaSucata = { ...state.sucata };

    switch (action.type) {
        case CREATE_SUCATA:
            return { ...state, sucata: action.sucata };
        case FETCH_FOTOS_AREA:
            return { ...state, fotosAreas: action.fotosAreas };
        case FETCH_SUCATA:
            return { ...state, sucata: action.sucata };
        case FETCH_SUCATAS:
            return { ...state, sucatas: action.sucatas };
        case FETCH_PECAS_CADASTRAVEIS:
            return { ...state, pecasCadastraveis: action.pecasCadastraveis }

        case CREATE_DESPESAS_SUCATA: // De outras actions
            return { ...state, sucata: action.sucata };
        case DELETE_DESPESA:
            if (!copiaSucata.despesas)
                return { ...state };

            copiaSucata.despesas = copiaSucata.despesas.filter(despesa => despesa.id !== parseInt(action.id));
            return { ...state, sucata: copiaSucata };
        case CREATE_PECAS:
            if (copiaSucata.id === action.idSucata)
                copiaSucata.pecas = [...action.pecas, ...copiaSucata.pecas];

            return { ...state, sucata: copiaSucata };
        case DELETE_PECA:
            if (!copiaSucata.pecas)
                return { ...state };

            copiaSucata.pecas = copiaSucata.pecas.filter(peca => peca.id !== parseInt(action.id));
            return { ...state, sucata: copiaSucata };
        default:
            return state;
    };
};

export default sucatasReducer;
