// Dependências
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiFileWarningFill } from "react-icons/ri";

// Css
import classes from "./FilePreview.module.css";


const FilesPreview = ({ accept, file, onDelete, onDragEnd, onDragEnter, onDragStart, type }) => {
    const [fileURL, setFileURL] = useState("");
    const [isValid, setIsValid] = useState(true);

    const [isDragging, setIsDragging] = useState(false);

    // Valida e lê o arquivo
    useEffect(() => {
        if (type === 'default') setFileURL(file);
        else {
            if (!file.type.match("image")) setIsValid(false);
            else {
                let fileFormatIsValid = false;

                accept.forEach(format => {
                    if (file.type.match(format)) fileFormatIsValid = true;
                });

                if (!fileFormatIsValid) {
                    setIsValid(false);
                    return;
                } else setIsValid(true);
            };

            const reader = new FileReader();
            reader.onloadend = () => setFileURL(reader.result);

            reader.readAsDataURL(file);
        };
    }, [accept, file, type]);

    return (
        <li
            className={`${classes.FilePreview} ${isDragging ? classes.Dragging : ""} ${isValid ? "" : classes.InvalidFile}`}
            draggable
            onDragEnd={() => { setIsDragging(false); onDragEnd(); }}
            onDragEnter={onDragEnter}
            onDragStart={() => { setIsDragging(true); onDragStart(); }}
        >
            <div className={classes.ImageWrapper} title={isValid ? "" : "Arquivo não suportado"}>
                {isValid ? (
                    <img className={classes.Image} src={fileURL} alt="" />
                ) : (
                    <RiFileWarningFill className={classes.WarningIcon} />
                )}
            </div>

            <button
                className={classes.DeleteButton}
                onClick={onDelete}
                title="Apagar"
            >
                <AiOutlineClose />
            </button>

            {isValid ? null : <legend className={classes.InvalidLegend}>Formato não suportado!</legend>}
        </li>
    );
};

export default FilesPreview;