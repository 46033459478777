// Dependências
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../../store/actions/cadastro";

// Components
import SelecaoTiposAnuncio from "../../../../../components/Cadastro/SelecaoTiposAnuncio/SelecaoTiposAnuncio";

const SelecaoTiposAnuncioScreen = props => {
    const dispatch = useDispatch();

    const idSucata = props.match.params.id;
    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    return (
        <div>
            <SelecaoTiposAnuncio
                plataformas={cadastroSucata.plataformas}
                onAntClick={() => props.history.push("/cadastro/sucata")}
                onProxClick={plataformasAtualizadss => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.plataformas = [...plataformasAtualizadss];
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                    props.history.push(`/cadastro/sucata/fretes-e-garantias/${idSucata}`);
                }} />
        </div>
    );
};

export default SelecaoTiposAnuncioScreen;