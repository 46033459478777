import {
    CREATE_GRUPO_SUCATAS,
    DELETE_GRUPO_SUCATA,
    FETCH_GRUPO_SUCATAS,
    FETCH_GRUPOS_SUCATAS,
} from "../actions/grupos-sucatas";

const initialState = {
    grupoSucatas: {},
    gruposSucatas: []
};

const gruposSucatasReducer = (state = initialState, action) => {
    let copiaGruposSucatas = [...state.gruposSucatas];

    switch (action.type) {
        case CREATE_GRUPO_SUCATAS:
            return { ...state, grupoSucatas: action.grupoSucatas };
        case DELETE_GRUPO_SUCATA:
            copiaGruposSucatas = copiaGruposSucatas.filter(grupoSucatas => grupoSucatas.id !== action.id);
            return { ...state, gruposSucatas: copiaGruposSucatas };
        case FETCH_GRUPO_SUCATAS:
            return { ...state, grupoSucatas: action.grupoSucatas };
        case FETCH_GRUPOS_SUCATAS:
            return { ...state, gruposSucatas: action.gruposSucatas };
        default:
            return state;
    }
};

export default gruposSucatasReducer;
