// Dependências
import React, { useEffect, useState } from "react";
import { BiCommentDetail, BiDuplicate } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";

// Css
import classes from "./Peca.module.css";

// Components
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import Input from "../../../../UI/Input/Input";
import Spinner from "./InlineSpinner/InlineSpinner";

// Helpers
import * as exibirMensagem from "../../../../../helpers/exibirMensagem";

const Peca = props => {

    // Setando titulo
    useEffect(() => {
        if (props.peca.titulo !== titulo)
            setTitulo(props.peca.titulo);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.peca.titulo]);

    const [titulo, setTitulo] = useState(props.peca.titulo);

    return (
        <tr>
            {/* Peças */}
            <td style={{ position: "relative" }}>
                <BiDuplicate className={classes.Duplicar} onClick={props.onDuplicar} size="22px" title="Duplicar Linha" />

                <Dropdown
                    small
                    busca
                    buttonSmall
                    returnObject
                    textAlignLeft
                    padrao={props.peca.arquetipo.nome ? props.peca.arquetipo.nome : "Selecione uma peça:"}
                    onChange={arquetipo => props.onChange({ ...props.peca, arquetipo: arquetipo, titulo: props.peca.fipe.veiculo.id && props.peca.fipe.marca.id ? `${arquetipo.nome} ${props.peca.fipe.marca.nome} ${props.peca.fipe.veiculo.nome} ` : "" })}
                    invalid={!props.peca.isValid.arquetipo}
                    list={props.arquetipos.filter(arquetipo => arquetipo.id !== 350)} />
            </td>

            {/* Tipos */}
            <td>
                <Dropdown
                    small
                    buttonSmall
                    returnObject
                    padrao={props.peca.fipe.tipo.nome ? props.peca.fipe.tipo.nome : "Selecione um tipo:"}
                    list={props.peca.fipeOptions.tipos}
                    invalid={!props.peca.isValid.tipo}
                    onChange={tipo => {
                        let copiaPeca = { ...props.peca };
                        copiaPeca.fipe.tipo = { ...tipo };
                        props.onChangeTipo(copiaPeca);
                    }} />
            </td>

            {/* Marcas */}
            <td>
                {props.peca.fipeOptions.marcas.length ?
                    <Dropdown
                        small
                        busca
                        buttonSmall
                        returnObject
                        padrao={props.peca.fipe.marca.nome ? props.peca.fipe.marca.nome : "Selecione uma marca:"}
                        list={props.peca.fipeOptions.marcas}
                        invalid={!props.peca.isValid.marca}
                        onChange={marca => {
                            let copiaPeca = { ...props.peca };
                            copiaPeca.fipe.marca = { ...marca };

                            if (props.peca.arquetipo.id && props.peca.fipe.veiculo.id)
                                copiaPeca.titulo = `${props.peca.arquetipo.nome} ${marca.nome} ${props.peca.fipe.veiculo.nome} `;

                            props.onChangeMarca(copiaPeca);
                        }} />
                    : props.peca.fipe.tipo.id ? <Spinner /> : "-"}
            </td>

            {/* Veiculos */}
            <td>
                {props.peca.fipeOptions.veiculos.length ?
                    <Dropdown
                        small
                        busca
                        buttonSmall
                        returnObject
                        padrao={props.peca.fipe.veiculo.nome ? props.peca.fipe.veiculo.nome : "Selecione um modelo:"}
                        list={props.peca.fipeOptions.veiculos}
                        invalid={!props.peca.isValid.veiculo}
                        onChange={veiculo => {
                            let copiaPeca = { ...props.peca };
                            copiaPeca.fipe.veiculo = { ...veiculo };
                            if (props.peca.arquetipo.id && props.peca.fipe.marca.id)
                                copiaPeca.titulo = `${props.peca.arquetipo.nome} ${props.peca.fipe.marca.nome} ${veiculo.nome} `;
                            props.onChange(copiaPeca);
                        }} />
                    : props.peca.fipe.marca.id ? <Spinner /> : "-"}
            </td>

            {/* Título */}
            <td>
                <Input
                    size="320px"
                    maxLength="60"
                    placeholder="Título dos anúncios"
                    setValue={titulo}
                    invalid={!props.peca.isValid.titulo}
                    onChange={value => setTitulo(value)}
                    onBlur={() => props.onChange({ ...props.peca, titulo: titulo })}
                />
            </td>

            <td style={{ position: "relative" }}>
                <BiCommentDetail
                    size="28px"
                    className={classes.Comentario}
                    title="Alterar descrição dos anúncios"
                    onClick={() => {
                        exibirMensagem.exibir({
                            title: 'Digite uma observação',
                            text: 'Esta observação será incluída na descrição desta peça.',
                            input: 'textarea',
                            inputValue: props.peca.observacao ? props.peca.observacao : "",
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Salvar'
                        }).then(value => {
                            if (value.value || value.value === "")
                                props.onChange({ ...props.peca, observacao: value.value });
                        });
                    }} />

                <FaTrash className={classes.Delete} size="17px" title="Apagar linha" onClick={props.onDelete} />
            </td>
        </tr>
    );
};

export default Peca;