// Dependências
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";

// Css
import classes from "./BeautyDropdown.module.css";

const BeautyDropdown = props => {
    const darkMode = useSelector((state) => state.colorPalette.darkMode);
    const node = useRef();

    const [showDropdown, setShowDropdown] = useState(false);
    const [value, setValue] = useState("");

    const handleClickOutside = e => {
        if (node.current.contains(e.target))
            return;

        setShowDropdown(false);
    };

    // Verificando value inicial
    useEffect(() => {
        if (!value && props.list)
            if (props.default)
                setValue(props.default)
            else
                setValue(props.list[0]?.nome);
    }, [value, props.default, props.list]);

    useEffect(() => {
        if (props.default)
            setValue(props.default)
    }, [props.default])

    // Verifica se está aberto ou não
    useEffect(() => {
        if (showDropdown)
            document.addEventListener("mousedown", handleClickOutside);
        else
            document.removeEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown]);

    return props.list ? (
        <div
            ref={node}
            className={`${classes.FormGroup} ${props.small ? classes.Small : ""} ${!props.notInline ? classes.Inline : ""}`}
        >
            <label className={classes.Titulo}>{props.titulo}</label>

            <button
                className={`${classes.Button} ${props.invalid ? classes.Invalid : ""}`}
                style={{ textAlign: props.alignCenter ? "center" : "" }}
                onClick={() => { setShowDropdown(!showDropdown) }}
                type="button"
            >{value} <IoMdArrowDropdown className={classes.Icon} /> </button>

            {showDropdown ? (
                <div>
                    <ul className={`${darkMode ? classes.DropdownMenuDark : classes.DropdownMenuLight}`}>
                        {props.list.map(item => (
                            <li
                                key={item.id}
                                className={classes.DropdownItem}
                                onClick={() => {
                                    setShowDropdown(false);
                                    setValue(item.nome);
                                    if (props.onChange) {
                                        if (props.returnObject) props.onChange(item);
                                        else props.onChange(item.id);
                                    };
                                }}
                            > {item.nome} </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div >
    ) : null;
};

export default BeautyDropdown;