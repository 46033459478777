// Dependências
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as cadastroActions from "../../../../store/actions/cadastro";

// Components
import SelecaoFipe from "../../../../components/Cadastro/Sucata/SelecaoFipe/SelecaoFipe";

const SelecaoFipeScreen = props => {
    const dispatch = useDispatch();

    const cadastroSucata = useSelector(state => state.cadastro.cadastroSucata);

    return (
        <div className="container-fluid">
            <SelecaoFipe
                fipe={cadastroSucata.sucata.fipe}
                onAntClick={() => props.history.push('/cadastro/sucata')}
                onProxClick={() => props.history.push('/cadastro/sucata/dados-sucata')}
                onChange={fipeAtualizado => {
                    let cadastroSucataAtualizado = { ...cadastroSucata };
                    cadastroSucataAtualizado.sucata.fipe = { ...fipeAtualizado };
                    dispatch(cadastroActions.setCadastroSucata(cadastroSucataAtualizado));
                }}
            />
        </div>
    );
};

export default SelecaoFipeScreen;