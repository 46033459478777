// Dependências
import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

// Components
import Spinner from "../Spinner/Spinner";

// Css
import classes from "./Table.module.css";

const Table = props => {
    const darkMode = useSelector(state => state.colorPalette.darkMode);

    return (
        <div className={`col-12 ${classes.Wrapper} ${props.fixed ? classes.Fixed : ""} ${props.small ? classes.Small : ""} ${darkMode ? classes.Dark : ""}`}
            onScroll={event => {
                if (props.onScrollBottom) {
                    if (parseInt(event.target.scrollHeight) - parseInt(event.target.scrollTop) <= parseInt(event.target.clientHeight))
                        props.onScrollBottom(event);
                } else if (props.onScroll)
                    props.onScroll(event);
            }}>

            <table
                className={`table 
                ${classes.Table} 
                ${props.bordered ? classes.Bordered : ""} 
                ${props.hoverble ? classes.Hoverble : ""}
                ${props.bodyStriped ? classes.Spriped : ""}
            `}>
                <thead>
                    <tr>
                        {props.header?.map(col => <th scope="col" key={uuidv4()}>{col}</th>)}
                    </tr>
                </thead>

                {props.tbody === false ? props.children : (
                    <tbody>
                        {props.children}

                        {props.isLoading ? (
                            <tr>
                                <td colSpan={props.header.length} style={{ height: "50px" }}>
                                    <Spinner inline />
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                )}
            </table>
        </div >
    );
};

export default Table;