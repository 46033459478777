import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const FETCH_CLIENTES = "FETCH_CLIENTES";

export const fetchClientes = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(`${API_URL}/clientes`, {
            headers: {
                Authorization: token,
            },
        });

        if (response.ok) {
            let clientes = [];
            try {
                clientes = await response.json();
                dispatch({ type: FETCH_CLIENTES, clientes: clientes });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};
