// Dependências
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as pecasActions from "../../../../store/actions/pecas";
import * as gruposSucatasActions from "../../../../store/actions/grupos-sucatas";

// Components
import FotosPecas from "../../../../components/Cadastro/FotosPecas/FotosPecas";
import Spinner from "../../../../components/UI/Spinner/Spinner";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const FotosPecasScreen = props => {
    const dispatch = useDispatch();

    const idGrupoSucatas = props.match.params.id;
    const grupoSucatas = useSelector(state => state.gruposSucatas.grupoSucatas);

    const [isLoading, setIsLoading] = useState(false);

    // Requisição das pecas aguardando fotos
    useEffect(() => {
        const fetchPecas = async () => {
            setIsLoading(true);
            try {
                await dispatch(gruposSucatasActions.fetchGrupoSucatas(idGrupoSucatas, 10));
            } catch (error) {
                console.log(error);
            };
            setIsLoading(false);
        };

        fetchPecas();
    }, [idGrupoSucatas, dispatch]);

    const submitHandler = async fotos => {
        setIsLoading(true);

        try {
            if (fotos.length) {
                await dispatch(pecasActions.updateFotos(fotos));
                exibirMensagem.success("Fotos cadastradas com sucesso.");
            };

            await dispatch(gruposSucatasActions.updateStatus(idGrupoSucatas, 13));

            setIsLoading(false);
            props.history.push(`/cadastro/grupo-pecas/precificacao/${idGrupoSucatas}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        };
    };

    return isLoading || !grupoSucatas.id ? <Spinner /> : (
        <div>
            <FotosPecas
                showSucata
                pecas={[].concat.apply([], grupoSucatas.sucatas.map(sucata => sucata.pecas.map(peca => { return { ...peca, sucata: sucata.fipe.veiculo.nome } })))}
                onAntClick={() => props.history.push('/cadastro/grupo-pecas')}
                onProxClick={fotos => submitHandler(fotos)} />
        </div>
    );
};

export default FotosPecasScreen;