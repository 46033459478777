// Dependências
import React from "react";
import { Link } from 'react-router-dom';
// Css
import classes from "./NavbarButton.module.css";

const NavbarButton = props => {
    return (
        <div className={`${classes.NavItem} ${props.className}`}>
            {props.link ? (
                <Link to={props.link}>
                    <span className={classes.NavItemNavLink}> {props.children} </span>
                </Link>
            ) : (
                <span className={classes.NavItemNavLink}> {props.children} </span>
            )}
        </div>
    );
};

export default NavbarButton;