import axios from 'axios';

import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig";

export const CREATE_FOTOS_PECA = "CREATE_FOTOS_PECA";
export const CRETE_LOCALIZACAO = "CRETE_LOCALIZACAO";
export const UPDATE_LOCALIZACAO = "UPDATE_LOCALIZACAO";
export const DELETE_LOCALIZACAO = "DELETE_LOCALIZACAO";
export const CREATE_PECAS = "CREATE_PECAS";
export const DELETE_PECA = "DELETE_PECA";
export const DELETE_PECAS = "DELETE_PECAS";
export const FETCH_FOTOS_PECAS = "FETCH_FOTOS_PECAS";
export const FETCH_LOCALIZACOES = "FETCH_LOCALIZACOES";
export const FETCH_PECA = "FETCH_PECA";
export const FETCH_PECAS = "FETCH_PECAS";
export const FETCH_NEW_PECAS = "FETCH_NEW_PECAS";
export const UPDATE_PECA = "UPDATE_PECA";
export const UPDATE_PECAS = "UPDATE_PECAS";

export const createFichaTecnica = fichaTecnica => {
    if (!fichaTecnica) return;

    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/ficha-tecnica`,
            headers: { Authorization: token },
            data: fichaTecnica
        }).then(result => {
            if (result.status === 201) {
                dispatch({ type: FETCH_PECAS, pecas: [] });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const createFotos = fotos => {
    if (!fotos) return;
    if (!fotos.length) return;

    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();
        fotos.forEach(foto =>
            formData.append(JSON.stringify(foto.atrelamento), foto.file));

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/fotos`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then((result) => {
            let peca = {}
            if (result.status) {
                peca = result.data;
                dispatch({ type: CREATE_FOTOS_PECA, peca: peca });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};

export const createFotosPeca = (idPeca, fotos) => {
    if (!fotos?.length) return;

    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();
        fotos.forEach((foto, index) =>
            formData.append(JSON.stringify(foto.atrelamento), foto.file));

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/${idPeca}/fotos`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then(result => {
            let peca = {}
            if (result.status) {
                peca = result.data;
                dispatch({ type: FETCH_PECA, peca: peca });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};

export const createLocalizacao = (nome, pecas) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/pecas/localizacoes`,
            headers: { Authorization: token },
            data: {
                nome: nome,
                pecas: pecas
            }
        }).then(result => {
            if (result.status === 201) {
                let pecas = result.data?.pecas || [];
                let localizacao = result.data?.localizacao || [];
                dispatch({ type: CRETE_LOCALIZACAO, pecas: pecas, localizacao: localizacao });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const updateLocalizacao = ({nome, id}) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/pecas/localizacoes/${id}`,	
            headers: { Authorization: token },
            data: {
                nome,
                id,
            }
        }).then(result => {
            if (result.status === 201) {
                let localizacao = {id, nome};
                dispatch({ type: UPDATE_LOCALIZACAO, localizacao });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deleteLocalizacao = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/pecas/localizacoes/${id}`,
            headers: { Authorization: token },
        }).then(result => {
            if (result.status === 201) {
                dispatch({ type: DELETE_LOCALIZACAO, id });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const createPecas = (idSucata, pecas) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'POST',
            url: `${API_URL}/sucatas/${idSucata}/pecas`,
            headers: { Authorization: token },
            data: pecas
        }).then((result) => {
            if (result.status === 201) {
                dispatch({ type: CREATE_PECAS, pecas: result.data, idSucata: idSucata });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deleteFoto = (idPeca, idFoto, tipoFoto) => {
    if (!idPeca || !idPeca || !tipoFoto)
        return;

    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/pecas/${idPeca}/fotos/${idFoto}`,
            headers: { Authorization: token },
            data: { tipo: tipoFoto }
        }).then(result => {
            let peca = {}
            if (result.status === 200) {
                peca = result.data;
                dispatch({ type: FETCH_PECA, peca: peca });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const deletePeca = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const response = await fetch(`${API_URL}/pecas/${id}`, {
            method: "DELETE",
            headers: { Authorization: token },
        });

        if (response.ok) {
            await dispatch({ type: DELETE_PECA, id: id });
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const deletePecas = pecas => {
    return async (dispatch, getState) => {
        if (!pecas)
            return;
        else if (!pecas.length)
            return;

        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/pecas`,
            headers: {
                Authorization: token
            },
            data: pecas
        }).then(result => {
            let idPecasExcluidas = [];
            if (result.status === 200) {
                idPecasExcluidas = result.data;
                dispatch({ type: DELETE_PECAS, idPecasExcluidas: idPecasExcluidas });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
            } else
                throw new Error(error);
        });
    };
};

export const fetchLocalizacoes = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/pecas/localizacoes`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let localizacoes = [];
            try {
                localizacoes = await response.json();
                dispatch({ type: FETCH_LOCALIZACOES, localizacoes: localizacoes });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchFotosPecas = (idSucata, idPecas) => {
    return async (dispatch, getState) => {
        if (!idSucata && !idPecas?.length) return;

        const token = getState().auth.token;
        const response = await fetch(idSucata ? `${API_URL}/sucatas/${idSucata}/pecas/fotos` : `${API_URL}/pecas/fotos?pecas=${idPecas}`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let pecas = [];
            try {
                pecas = await response.json();

                dispatch({ type: FETCH_FOTOS_PECAS, pecas: pecas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchPeca = id => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/pecas/${id}`, {
            headers: { Authorization: token }
        });

        if (response.ok) {
            let peca = {};
            try {
                peca = await response.json();
                dispatch({ type: FETCH_PECA, peca: peca });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchPecas = (idSucata, idStatus, ordem) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/${idSucata ? `sucatas/${idSucata}/` : ""}pecas${idStatus ? `?status=${idStatus}${ordem ? `?ordem=${ordem}` : ""}` : ""}`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let pecas = [];
            try {
                pecas = await response.json();
                dispatch({ type: FETCH_PECAS, pecas: pecas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchTodasPecas = (doravante = 1, busca, ordem, quantidade = 50, idStatus, idLastAnuncio) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const response = await fetch(
            `${API_URL}/pecas?quantidade=${quantidade}${idStatus ? `&status=${idStatus}` : ""}&doravante=${doravante - 1}${busca ? `&busca=${busca}` : ""}${ordem ? `&ordem=${ordem}` : ""}${idLastAnuncio ? `&ultimo_anuncio=${idLastAnuncio}` : ""}`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let pecas = [];
            try {
                pecas = await response.json();

                dispatch({ type: doravante === 1 ? FETCH_PECAS : FETCH_NEW_PECAS, pecas: pecas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchPecasAtributos = (idSucata, idGrupoSucatas) => {
    return async (dispatch, getState) => {
        if (!idSucata && !idGrupoSucatas)
            return;
        if (idSucata && idGrupoSucatas)
            return;

        const token = getState().auth.token;
        const response = await fetch(
            idSucata ? `${API_URL}/sucatas/${idSucata}/pecas/atributos` : `${API_URL}/grupos-sucatas/${idGrupoSucatas}/sucatas/pecas/atributos`,
            { headers: { Authorization: token } }
        );

        if (response.ok) {
            let pecas = [];
            try {
                pecas = await response.json();
                dispatch({ type: FETCH_PECAS, pecas: pecas });
            } catch (error) {
                throw new Error("Algo deu errado");
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const updatePeca = (id, value) => {
    return async (dispatch, getState) => {
        if (!id || !value)
            return;

        let copiaValue = {};

        Object.keys(value).forEach(key => {
            if (key === "altura" || key === "comprimento" || key === "largura" || key === "peso") {
                copiaValue.medidas = {};
                copiaValue.medidas[key] = value[key] ? parseFloat(value[key]) : null;
            } else if (key === "preco")
                copiaValue[key] = parseFloat(value[key]);
            else
                copiaValue[key] = value[key] ? value[key] : null;

        });

        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/pecas/${id}`,
            headers: { Authorization: token },
            data: copiaValue
        }).then(result => {
            if (result.status) {
                dispatch({ type: UPDATE_PECA, peca: { ...result.data } });
            } else
                throw new Error("Algo deu errado");
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};

export const updateLocPeca = (id, value) => {
    return async (dispatch, getState) => {
        if (!id || !value)
            return;

        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/pecas/${value}/${id}`,
            headers: { Authorization: token },
        }).then(result => {
            if (result.status) {
                dispatch({ type: UPDATE_PECA, peca: { ...result.data } });
            } else
                throw new Error("Algo deu errado");
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};

export const updatePecas = pecas => {
    return async (dispatch, getState) => {
        if (!pecas)
            return;
        else if (!pecas.length)
            return;

        const token = getState().auth.token;

        await axios({
            method: 'PUT',
            url: `${API_URL}/pecas`,
            headers: { Authorization: token },
            data: pecas
        }).then(result => {
            if (result.status) {
                dispatch({ type: UPDATE_PECAS, pecas: [...result.data] });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};

export const updateFotos = fotos => {
    if (!fotos?.length) return;

    return async (dispatch, getState) => {
        const token = getState().auth.token;

        let formData = new FormData();
        fotos.forEach(foto =>
            formData.append(JSON.stringify(foto.atrelamento), foto.file));

        await axios({
            method: 'PUT',
            url: `${API_URL}/pecas/fotos/`,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                Authorization: token
            },
            data: formData,
        }).then((result) => {
            let pecas = []
            if (result.status) {
                pecas = result.data;
                dispatch({ type: FETCH_PECAS, pecas: pecas });
            } else {
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response) {
                statusHandler(error.response.status);
                throw error;
            } else
                throw new Error(error);
        });
    };
};