// Dependências
import React from "react";
import { useDispatch } from "react-redux";

// Actions
import * as adminActions from "../../../../store/actions/admin";

// Css
import classes from "./ArquetipoDetran.module.css";

// Helpers
import * as exibirMensagem from "../../../../helpers/exibirMensagem";

const ArquetipoDetran = props => {
    const dispatch = useDispatch();

    const deleteArquetipo = async id => {
        let novosArquetipos = props.arquetipoDetran.arquetipos.filter(arquetipo => arquetipo.id !== id);
        if (novosArquetipos.length) {
            await dispatch(adminActions.deleteArquetipoDetran(props.arquetipoDetran.nome, id));
            exibirMensagem.success("Ligação apagada com sucesso.");
        } else {
            exibirMensagem.exibir({
                title: "Tem certeza?",
                text: `Ao deletar esse arquétipo essa peça do Detran será excluída. Deseja continuar?`,
                showCancelButton: true,
                confirmButtonText: "Sim",
            }).then(async result => {
                if (result.isConfirmed) {
                    try {
                        await dispatch(adminActions.deleteArquetipoDetran(props.arquetipoDetran.nome));
                        exibirMensagem.success("Peça do Detran apagada com sucesso.");
                    } catch (error) {
                        console.log(error);
                    };
                } else {
                    return;
                };
            });
        }
    };

    return (
        <tr>
            <td>{props.arquetipoDetran.nome}</td>
            <td>
                {props.arquetipoDetran.arquetipos.map(arquetipo =>
                    <span
                        key={arquetipo.id}
                        className={classes.Arquetipo}
                        title="Excluir arquétipo"
                        onClick={() => deleteArquetipo(arquetipo.id)}
                    >
                        {arquetipo.nome}
                    </span>
                )}
            </td>
        </tr >
    );
};

export default ArquetipoDetran;