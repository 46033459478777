import axios from 'axios';
import * as exibirMensagem from "../../helpers/exibirMensagem";
import statusHandler from "../../helpers/statusHandler";
import { API_URL } from "../../constants/GlobalConfig"

export const FETCH_ANUNCIOS_MERCADO_LIVRE = "FETCH_ANUNCIOS_MERCADO_LIVRE";
export const FETCH_ANUNCIOS_REVISAO_MERCADO_LIVRE = "FETCH_ANUNCIOS_REVISAO_MERCADO_LIVRE";
export const FETCH_STATUS_MERCADO_LIVRE_CREATE_ANUNCIOS = "FETCH_STATUS_MERCADO_LIVRE_CREATE_ANUNCIOS";
export const FETCH_STATUS_MERCADO_LIVRE_CREATE_PECAS = "FETCH_STATUS_MERCADO_LIVRE_CREATE_PECAS";
export const CREATE_PECA_MERCADO_LIVRE = "CREATE_PECA_MERCADO_LIVRE";
export const DELETE_ANUNCIOS_MERCADO_LIVRE = "DELETE_ANUNCIOS_MERCADO_LIVRE";

const requestFecthStatusCreateAnuncios = async (idUsuario, dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
        `${API_URL}/mercado-livre/importar/status/${idUsuario}`,
        { headers: { Authorization: token } }
    );

    if (response.ok) {
        let status = [];
        try {
            status = await response.json();
            dispatch({ type: FETCH_STATUS_MERCADO_LIVRE_CREATE_ANUNCIOS, status: status });
        } catch (error) {
            throw error;
        }
    } else {
        if (response.status) {
            statusHandler(response.status);
        } else {
            statusHandler();
            throw new Error("Algo deu errado");
        };
    }
};

const requestFecthStatusCreatePecas = async (idUsuario, dispatch, getState) => {
    const token = getState().auth.token;

    const response = await fetch(
        `${API_URL}/mercado-livre/importar/salvar-como-peca/status/${idUsuario}`,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.ok) {
        let status = [];
        try {
            status = await response.json();
            dispatch({ type: FETCH_STATUS_MERCADO_LIVRE_CREATE_PECAS, status: status });
        } catch (error) {
            throw error;
        }
    } else {
        if (response.status) {
            statusHandler(response.status);
        } else {
            statusHandler();
            throw new Error("Algo deu errado");
        };
    }
};

export const createAnuncios = (idUsuario) => {
    return async (dispatch, getState) => {
        const statusCreateAnucios = getState().mercadoLivre.statusCreateAnucios;
        const token = getState().auth.token;

        if (statusCreateAnucios.emAndamento) {
            exibirMensagem.warning("Há um processo de importação em andamento, não é possível realizar outra.");
        } else {
            await axios({
                method: 'POST',
                url: `${API_URL}/mercado-livre/importar/${idUsuario}`,
                headers: {
                    Authorization: token
                }
            }).then((result) => {
                if (result.status === 202)
                    requestFecthStatusCreateAnuncios(idUsuario, dispatch, getState);
            }).catch(error => {
                if (error.response)
                    statusHandler(error.response.status);
                else
                    throw new Error(error);
            });
        };
    };
};

export const createPecas = (idUsuario, pecas) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        axios({
            method: 'POST',
            url: `${API_URL}/mercado-livre/importar/salvar-como-peca/${idUsuario}`,
            headers: {
                Authorization: token
            },
            data: pecas
        }).then((result) => {
            if (result.status === 202)
                requestFecthStatusCreatePecas(idUsuario, dispatch, getState);
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const deleteAnuncios = (idUsuario) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        await axios({
            method: 'DELETE',
            url: `${API_URL}/mercado-livre/importar/${idUsuario}`,
            headers: {
                Authorization: token
            }
        }).then((result) => {
            if (result.status === 200) {
                dispatch({ type: DELETE_ANUNCIOS_MERCADO_LIVRE });
            } else {
                statusHandler(result.status);
                throw new Error("Algo deu errado");
            };
        }).catch(error => {
            if (error.response)
                statusHandler(error.response.status);
            else
                throw new Error(error);
        });
    };
};

export const fetchAnuncios = (idUsuario, quantidadeAnuncios) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        if (!quantidadeAnuncios)
            quantidadeAnuncios = 100;

        const response = await fetch(
            `${API_URL}/mercado-livre/importar/${idUsuario}/${quantidadeAnuncios}`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let anuncios = [];
            try {
                anuncios = await response.json();

                anuncios.forEach(anuncio => {
                    if (anuncio.fipe.length) {
                        anuncio.values = {
                            importar: true,
                            fipe: {
                                marca: { id: anuncio.fipe[0].id, nome: anuncio.fipe[0].nome },
                                modelo: { id: anuncio.fipe[0].modelos[0].id, nome: anuncio.fipe[0].modelos[0].nome }
                            },
                            peca: { id: anuncio.idSucatapeca ? anuncio.idSucatapeca : "", nome: "" }
                        };
                    } else {
                        anuncio.values = {
                            importar: false,
                            fipe: {
                                marca: { id: "", nome: "" },
                                modelo: { id: "", nome: "" }
                            },
                            peca: { id: anuncio.idSucatapeca ? anuncio.idSucatapeca : "", nome: "" }
                        };
                    };
                });

                dispatch({ type: FETCH_ANUNCIOS_MERCADO_LIVRE, anuncios: anuncios });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchAnunciosParaRevisao = (idUsuario, quantidadeAnuncios) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        if (!quantidadeAnuncios)
            quantidadeAnuncios = 100;

        const response = await fetch(
            `${API_URL}/mercado-livre/importar/revisao/${idUsuario}/${quantidadeAnuncios}`,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        if (response.ok) {
            let anunciosRevisao = [];
            try {
                anunciosRevisao = await response.json();

                anunciosRevisao.forEach(anuncio => {
                    if (anuncio.fipe.length) {
                        anuncio.values = {
                            importar: true,
                            fipe: {
                                marca: { id: anuncio.fipe[0].id, nome: anuncio.fipe[0].nome },
                                modelo: { id: anuncio.fipe[0].modelos[0].id, nome: anuncio.fipe[0].modelos[0].nome }
                            },
                            peca: { id: anuncio.idSucatapeca ? anuncio.idSucatapeca : "", nome: "" }
                        };
                    } else {
                        anuncio.values = {
                            importar: false,
                            fipe: {
                                marca: { id: "", nome: "" },
                                modelo: { id: "", nome: "" }
                            },
                            peca: { id: anuncio.idSucatapeca ? anuncio.idSucatapeca : "", nome: "" }
                        };
                    };

                    anuncio.isValid = { marca: true, peca: true };
                });

                dispatch({ type: FETCH_ANUNCIOS_REVISAO_MERCADO_LIVRE, anunciosRevisao: anunciosRevisao });
            } catch (error) {
                throw error;
            }
        } else {
            if (response.status) {
                statusHandler(response.status);
            } else {
                statusHandler();
                throw new Error("Algo deu errado");
            };
        }
    };
};

export const fetchStatusCreateAnuncios = (idUsuario) => {
    return (dispatch, getState) => requestFecthStatusCreateAnuncios(idUsuario, dispatch, getState);
};

export const fetchStatusCreatePecas = (idUsuario) => {
    return (dispatch, getState) => requestFecthStatusCreatePecas(idUsuario, dispatch, getState);
};