// Dependência
import React from "react";
import { BiCommentDetail } from "react-icons/bi";

// Css
import classes from "./Peca.module.css";

// Helpers
import * as exibirMensagem from "../../../../../helpers/exibirMensagem";

const Peca = props => {
    const onClickHandler = () => {
        props.onChange({ ...props.peca, anunciar: !props.peca.anunciar });
    };

    return (
        <tr style={{ backgroundColor: props.peca.anunciar ? "#00800066" : "" }}>
            <td onClick={onClickHandler}>
                {props.peca.arquetipo.nome}
            </td>
            <td style={{ cursor: "default" }}>
                <BiCommentDetail
                    size="25px"
                    className={classes.ObservacaoButton}
                    title="Adicionar uma observação"
                    onClick={() => {
                        exibirMensagem.exibir({
                            title: 'Digite uma observação',
                            text: 'Esta observação será incluída na descrição desta peça.',
                            input: 'textarea',
                            inputValue: props.peca.observacao ? props.peca.observacao : "",
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Salvar'
                        }).then(value => {
                            if (value.value || value.value === "")
                                props.onChange({ ...props.peca, observacao: value.value });
                        });
                    }}
                />
            </td>
        </tr>
    );
};

export default Peca;