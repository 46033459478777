// Dependências
import React from "react";

// Components
import Dropdown from "../../../UI/BeautyDropdown/BeautyDropdown";
import Input from "../../../UI/Input/Input";
//import Tooltip from "../../../UI/Tooltip/Tooltip";

const Peca = props => {
    return (
        <tr>
            <td>
                {props.peca.arquetipo.nome} {props.showSucata ? `- ${props.peca.sucata}` : null}
            </td>
            <td style={{ width: "320px" }}>
                {/* props.peca.frete.descricao ? <Tooltip>{props.peca.frete.descricao}</Tooltip> : null */}
                <Dropdown
                    returnObject
                    list={[...props.options]}
                    default={props.peca.frete.nome}
                    onChange={novoFrete => {
                        let pecaAtualizada = { ...props.peca };
                        pecaAtualizada.frete = novoFrete;
                        props.onChange(pecaAtualizada);
                    }}
                />
            </td>
            <td>
                <Input
                    light
                    onlyNumbers
                    size="150px"
                    maxLength="4"
                    placeholder="Garantia (dias)"
                    value={props.peca.garantia}
                    onBlur={event => {
                        let pecaAtualizada = { ...props.peca };
                        pecaAtualizada.garantia = parseInt(event.target.value);
                        props.onChange(pecaAtualizada);
                    }}
                />
            </td>
        </tr>
    );
};

export default Peca;