// Dependências
import React from "react";

// Helpers
import * as formatar from "../../../helpers/formatar";

const Vinculo = props => {
    return (
        <tr>
            <td>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.vinculo.plataforma.url}
                >
                    <img
                        style={{ width: "50px" }}
                        alt={props.vinculo.plataforma.nome}
                        src={props.vinculo.plataforma.imagem} />
                </a>
            </td>
            <td>{props.vinculo.nome}</td>
            <td>{formatar.data(props.vinculo.data)}</td>
        </tr>
    );
};

export default Vinculo;