import React from "react";
import { isMobile } from "react-device-detect";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// Início
import InicioScreen from '../screens/Inicio/InicioScreen';

// Estoque
import FotosPecasScreen from "../screens/Estoque/Sucatas/Sucata/FotosPecas/FotosPecasScreen";
import GruposSucatasScreen from "../screens/Estoque/GruposSucatas/GruposSucatasScreen";
import GrupoSucatasScreen from "../screens/Estoque/GruposSucatas/GrupoSucatas/GrupoSucatasScreen";
import SucataScreen from "../screens/Estoque/Sucatas/Sucata/SucataScreen";
import SucatasScreen from "../screens/Estoque/Sucatas/SucatasScreen";
import PecaScreen from "../screens/Estoque/Pecas/Peca/PecaScreen";
import PecasScreen from "../screens/Estoque/Pecas/PecasScreen";

// Financeiro
import FinanceiroScreen from "../screens/Financeiro/FinanceiroScreen";
import VendasScreen from "../screens/Financeiro/Vendas/VendasScreen";
import DespesasScreen from '../screens/Financeiro/Despesas/DespesasScreen';
import DespesasCategoriasScreen from '../screens/Financeiro/Despesas/DespesasCategorias/DespesasCategoriasScreen';

// Perguntas
import PerguntasScreen from '../screens/Perguntas/PerguntasScreen';

// Cadastro
import CadastroScreen from "../screens/Cadastro/CadastroScreen";

// Cadastro sucata
import CadastroSucataScreen from "../screens/Cadastro/Sucata/CadastroSucataScreen";
import DadosSucataScreen from "../screens/Cadastro/Sucata/DadosSucata/DadosSucataScreen";
import SelecaoFipeScreen from "../screens/Cadastro/Sucata/SelecaoFipe/SelecaoFipeScreen";
import FotosSucataScreen from "../screens/Cadastro/Sucata/FotosSucata/FotosSucataScreen";
import SelecaoPecasCadastroSucataScreen from "../screens/Cadastro/Sucata/SelecaoPecas/SelecaoPecasScreen";
import PecasEmRevisaoScreen from "../screens/Cadastro/Sucata/PecasEmRevisao/PecasEmRevisaoScreen";
import CategoriasScreen from "../screens/Cadastro/Sucata/Categorias/CategoriasScreen";
import MedidasScreen from "../screens/Cadastro/Sucata/Medidas/MedidasScreen";
import FichaTecnicaScreen from "../screens/Cadastro/Sucata/FichaTecnica/FichaTecnicaScreen";
import TituloSucataScreen from "../screens/Cadastro/Sucata/TituloSucata/TituloSucataScreen";
import TituloPecasScreen from "../screens/Cadastro/Sucata/TituloPecas/TituloPecasScreen";
import FotoPecasScreen from "../screens/Cadastro/Sucata/FotosPecas/FotosPecasScreen";
import CadastroDespesasSucataScreen from "../screens/Cadastro/Sucata/Despesas/DespesasScreen";
import PrecificacaoScreeen from "../screens/Cadastro/Sucata/Precificacao/PrecificacaoScreen";
import SelecaoTiposAnuncioScreen from "../screens/Cadastro/Sucata/DadosAnuncios/SelecaoTiposAnuncio/SelecaoTiposAnuncioScreen";
import SelecaoFreteGarantiaScreen from "../screens/Cadastro/Sucata/DadosAnuncios/SelecaoFreteGarantia/SelecaoFreteGarantiaScreen";
import ResumoScreen from "../screens/Cadastro/Sucata/DadosAnuncios/Resumo/ResumoScreen";

// Cadastro de GrupoPecas
import CadastroGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/CadastroGrupoPecasScreen";
import SelecaoPecasGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/SelecaoPecas/SelecaoPecasScreen";
import MedidasPecasGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/MedidasScreen/MedidasScreen";
import FichaTecnicaGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/FichaTecnica/FichaTecnicaScreen";
import FotosPecasGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/FotosPecas/FotosPecasScreen";
import PrecificacaoGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/Precificacao/Precificacao";
import SelecaoTiposAnunciosGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/SelecaoTiposAnuncios/SelecaoTiposAnunciosScreen";
import SelecaoFreteGarantiaGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/SelecaoFreteGarantia/SelecaoFreteGarantiaScreen";
import ResumoGrupoPecasScreen from "../screens/Cadastro/GrupoPecas/Resumo/Resumo";

// Cadastro importação DETRAN
import ImportarDetranScreen from "../screens/Cadastro/ImportarDetran/ImportarDetranScreen";

// Funcionários
import FuncionariosScreen from "../screens/Funcionarios/FuncionariosScreen";

// Tutorias
import TutoriaisScreen from '../screens/Tutoriais/TutoriaisScreen';

// Configurações
import CadastroUsuarioScreen from "../screens/Configuracoes/Usuarios/Cadastro/CadastroUsuarioScreen";
import ConfiguracoesScreen from "../screens/Configuracoes/ConfiguracoesScreen";
import SenhaScreen from "../screens/Configuracoes/Senha/SenhaScreen";
import VinculoMercadoLivreScreen from "../screens/Configuracoes/Vinculos/MercadoLivreScreen";

// Telas sem autenticação
import RegistrarScreen from "../screens/Registrar/RegistrarScreen";
import ConhecerScreen from "../screens/Conhecer/ConhecerScreen";
import ConhecerDoisScreen from "../screens/Conhecer/ConhecerDoisScreen";
import TutorialScreen from '../screens/Tutorial/TutorialScreen';
import LoginScreen from "../screens/Login/LoginScreen";
import PesquisaMercadoLivreScreen from '../screens/Admin/PesquisaMercadoLivre/PesquisaMercadoLivreScreen';

// Telas de Adm
import ArquetiposDetranScreen from "../screens/Admin/ArquetiposDetran/ArquetiposDetranScreen";
import CadastroContaScreen from "../screens/Admin/Contas/CadastroConta/CadastroConta";
import ContasScreen from "../screens/Admin/Contas/ContasScreen";
import MensagensScreen from "../screens/Admin/Mensagens/MensagensScreen";
import CadastroMensagensScreen from "../screens/Admin/Mensagens/CadastroMensagens/CadastroMensagensScreen";
import LocalizacaoScreen from "../screens/Localizacao/LocalizacaoScreen";
import GerenciarScreen from "../screens/Localizacao/Gerenciar/GerenciarScreen";
import PecaLocalizacaoScreen from "../screens/Localizacao/Peca/PecaLocalizacaoScreen";

const EstoqueNavigator = () => {
    return (
        <Switch>
            <Route path="/estoque/grupo-sucatas/:id" component={GrupoSucatasScreen} />
            <Route path="/estoque/pecas/fotos" component={FotosPecasScreen} />
            <Route path="/estoque/pecas/:id" component={PecaScreen} />
            <Route path="/estoque/sucatas/:id/fotos" component={FotosPecasScreen} />
            <Route path="/estoque/sucatas/:id" component={SucataScreen} />
            <Route path="/estoque/grupo-sucatas" component={GruposSucatasScreen} />
            <Route path="/estoque/pecas" component={PecasScreen} />
            <Route path="/estoque/sucatas" component={SucatasScreen} />

            <Redirect to="/estoque/sucatas" />
        </Switch>
    );
};

const FinanceiroNavigator = () => {
    const usuario = useSelector(state => state.usuario.meuUsuario);

    const VendasNavigator = () => {
        return usuario.permissoes.find(permissao => permissao.id === 5) ? (
            <Switch>
                <Route path="/financeiro/vendas/:plataforma" component={VendasScreen} />
                <Route path="/financeiro/vendas" component={VendasScreen} />
            </Switch>
        ) : <Redirect to="/inicio" />;
    };

    return usuario.permissoes.find(permissao => permissao.id === 3 || 5) ? (
        <Switch>
            <Route path="/financeiro/vendas" component={VendasNavigator} />

            {usuario.permissoes.find(permissao => permissao.id === 3) ? (
                <Switch>
                    <Route path="/financeiro/despesas/categorias" component={DespesasCategoriasScreen} />
                    <Route path="/financeiro/despesas" component={DespesasScreen} />
                    <Route path="/financeiro" component={FinanceiroScreen} />
                </Switch>
            ) : <Redirect to="/inicio" />}
        </Switch>
    ) : <Redirect to="/inicio" />;
};

// Cadastro novo
const CadastroNavigator = () => {
    const CadastroGrupoPecasNavigator = () => (
        <Switch>
            <Route path="/cadastro/grupo-pecas/selecao-pecas" component={SelecaoPecasGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/medidas/:id" component={MedidasPecasGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/ficha-tecnica/:id" component={FichaTecnicaGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/fotos-pecas/:id" component={FotosPecasGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/precificacao/:id" component={PrecificacaoGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/selecao-tipos-anuncio/:id" component={SelecaoTiposAnunciosGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/fretes-e-garantias/:id" component={SelecaoFreteGarantiaGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas/resumo/:id" component={ResumoGrupoPecasScreen} />
            <Route path="/cadastro/grupo-pecas" component={CadastroGrupoPecasScreen} />

            <Redirect to="/cadastro/grupo-pecas" />
        </Switch>
    );

    const CadastroSucataNavigator = () => (
        <Switch>
            <Route path="/cadastro/sucata/selecao-fipe" component={SelecaoFipeScreen} />
            <Route path="/cadastro/sucata/dados-sucata" component={DadosSucataScreen} />
            <Route path="/cadastro/sucata/upload-fotos/:id" component={FotosSucataScreen} />
            <Route path="/cadastro/sucata/selecao-pecas/:id" component={SelecaoPecasCadastroSucataScreen} />
            <Route path="/cadastro/sucata/pecas-em-revisao/:id" component={PecasEmRevisaoScreen} />
            <Route path="/cadastro/sucata/categorias/:id" component={CategoriasScreen} />
            <Route path="/cadastro/sucata/medidas-pecas/:id" component={MedidasScreen} />
            <Route path="/cadastro/sucata/ficha-tecnica/:id" component={FichaTecnicaScreen} />
            <Route path="/cadastro/sucata/titulo-sucata/:id" component={TituloSucataScreen} />
            <Route path="/cadastro/sucata/titulo-pecas/:id" component={TituloPecasScreen} />
            <Route path="/cadastro/sucata/fotos-pecas/:id" component={FotoPecasScreen} />
            <Route path="/cadastro/sucata/despesas/:id" component={CadastroDespesasSucataScreen} />
            <Route path="/cadastro/sucata/precificacao/:id" component={PrecificacaoScreeen} />
            <Route path="/cadastro/sucata/selecao-tipos-anuncio/:id" component={SelecaoTiposAnuncioScreen} />
            <Route path="/cadastro/sucata/fretes-e-garantias/:id" component={SelecaoFreteGarantiaScreen} />
            <Route path="/cadastro/sucata/resumo/:id" component={ResumoScreen} />
            <Route path="/cadastro/sucata" component={CadastroSucataScreen} />

            <Redirect to="/cadastro/sucata" />
        </Switch>
    );

    const ImportarDetranNavigator = () => (
        <Switch>
            <Route path="/cadastro/arquivo-detran" component={ImportarDetranScreen} />
        </Switch>
    );

    return (
        <Switch>
            <Route path="/cadastro/arquivo-detran" component={ImportarDetranNavigator} />
            <Route path="/cadastro/grupo-pecas" component={CadastroGrupoPecasNavigator} />
            <Route path="/cadastro/sucata" component={CadastroSucataNavigator} />

            <Route path="/cadastro" component={CadastroScreen} />
            <Redirect to="/cadastro" />
        </Switch>
    );
};

const LocalizacaoNavigator = () => {

    
    const PecaNavigator = () => (
        <Switch>
            <Route path="/localizacao/peca" component={PecaLocalizacaoScreen} />
        </Switch>
    );
    const GerenciarNavigator = () => (
        <Switch>
            <Route path="/localizacao/gerenciar" component={GerenciarScreen} />
        </Switch>
    );

    return (
        <Switch>
            <Route path="/localizacao/peca" component={PecaNavigator} />
            <Route path="/localizacao/gerenciar" component={GerenciarNavigator} />
            <Route path="/localizacao" component={LocalizacaoScreen} />
            <Redirect to="/localizacao" />
        </Switch>
    );
};

const FuncionariosNavigator = () => {
    const usuario = useSelector(state => state.usuario.meuUsuario);

    return usuario.permissoes.find(permissao => permissao.id === 4) ? (
        <Switch>
            <Route path="/funcionarios" component={FuncionariosScreen} />
        </Switch>
    ) : <Redirect to="/inicio" />;
};

const ConfiguracoesNavigator = () => {
    const usuario = useSelector(state => state.usuario.meuUsuario);

    const UsuariosNavigator = () => {
        return usuario.permissoes.find(permissao => permissao.id === 2) ? (
            <Switch>
                <Route path="/configuracoes/usuarios/cadastro" component={CadastroUsuarioScreen} />
            </Switch>
        ) : <Redirect to="/inicio" />;
    };

    return (
        <Switch>
            <Route path="/configuracoes/usuarios" component={UsuariosNavigator} />
            <Route path="/configuracoes/vinculos/mercado-livre" component={VinculoMercadoLivreScreen} />
            <Route path="/configuracoes/alterar-senha" component={SenhaScreen} />
            <Route path="/configuracoes" component={ConfiguracoesScreen} />
        </Switch>
    );
};
const CadastroSucataMobileNavigator = () => (
    <Switch>
        <Route path="/cadastro/sucata/selecao-fipe" component={SelecaoFipeScreen} />
        <Route path="/cadastro/sucata/categorias/:id" component={CategoriasScreen} />
        <Route path="/cadastro/sucata/dados-sucata" component={DadosSucataScreen} />
        <Route path="/cadastro/sucata/selecao-pecas/:id" component={SelecaoPecasCadastroSucataScreen} />
        <Route path="/cadastro/sucata/pecas-em-revisao/:id" component={PecasEmRevisaoScreen} />
        <Route path="/cadastro/sucata" component={CadastroSucataScreen} />

        <Redirect to="/cadastro/sucata" />
    </Switch>
);

const EstoqueMobileNavigator = () => (
    <Switch>
        <Route path="/estoque/pecas" component={PecasScreen} />
        <Route path="/estoque/grupo-sucatas" component={GruposSucatasScreen} />

        <Redirect to="/estoque/pecas" />
    </Switch>
);

const Navigator = () => {
    const usuario = useSelector(state => state.usuario.meuUsuario);

    return usuario?.conta?.tipo?.id === 1 ? (
        <Switch>
            <Route path="/mensagens/cadastro" component={CadastroMensagensScreen} />
            <Route path="/mensagens" component={MensagensScreen} />
            <Route path="/contas/cadastro" component={CadastroContaScreen} />
            <Route path="/contas" component={ContasScreen} />
            <Route path="/arquetipos-detran" component={ArquetiposDetranScreen} />

            <Redirect to="/contas" />
        </Switch>
    ) : true ? (
        <Switch>
            <Route path="/utilitarias/pesquisa-mercado-livre" component={PesquisaMercadoLivreScreen} />

            <Route path="/inicio" component={InicioScreen} />
            <Route path="/estoque" component={EstoqueNavigator} />
            <Route path="/financeiro" component={FinanceiroNavigator} />
            <Route path="/perguntas" component={PerguntasScreen} />
            <Route path="/cadastro" component={CadastroNavigator} />
            <Route path="/localizacao" component={LocalizacaoNavigator} />
            <Route path="/funcionarios" component={FuncionariosNavigator} />
            <Route path="/tutoriais" component={TutoriaisScreen} />
            <Route path="/configuracoes" component={ConfiguracoesNavigator} />

            <Route path="/login" component={LoginScreen} />
            <Route path="/registrar" component={RegistrarScreen} />
            <Route path="/conhecer" component={ConhecerScreen} />
            <Route path="/conhecer2" component={ConhecerDoisScreen} />
            <Route path="/anuncios-automaticos" component={ConhecerDoisScreen} />
            <Route path="/obrigado" component={TutorialScreen} />
        </Switch>
    ) : (
        <Switch>
            <Route path="/login" component={LoginScreen} />
            <Route path="/inicio" component={InicioScreen} />
            <Route path="/perguntas" component={PerguntasScreen} />

            <Route path="/cadastro" component={CadastroSucataMobileNavigator} />
            <Route path="/estoque" component={EstoqueMobileNavigator} />

            <Route path="/utilitarias/pesquisa-mercado-livre" component={PesquisaMercadoLivreScreen} />
        </Switch>
    );
};

export default Navigator;
