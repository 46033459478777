// Dependências
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowReturnLeft } from "react-icons/bs";
import { API_URL } from "../../../../constants/GlobalConfig";

// Components
import Dropdown from "../../../UI/Dropdown/Dropdown";
import Spinner from "../../../UI/Spinner/Spinner";

// Css
import classes from "./Peca.module.css";

// Helpers
import statusHandler from "../../../../helpers/statusHandler";

const Peca = props => {
    const [categorias, setCategorias] = useState([]); // Estas são o histórico de categorias selecionadas e suas subcategorias (quando uma subcategoria é selecionada, ela é adicionada aqui)
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector(state => state.auth.token);

    const fetchSubcategorias = async idCategoria => {
        try {
            setIsLoading(true);
            const response = await fetch(
                `${API_URL}/pecas/${props.peca.id}/categorias${idCategoria ? `/${idCategoria}/subcategorias` : ""}`, {
                headers: { Authorization: token },
            });

            if (response.ok) {
                let subcategorias = [];

                try {
                    subcategorias = await response.json();
                    setIsLoading(false);
                    return subcategorias;
                } catch (error) {
                    throw new Error("Algo deu errado");
                };
            } else {
                if (response.status) {
                    statusHandler(response.status);
                } else {
                    statusHandler();
                    throw new Error("Algo deu errado");
                };
            };
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        };
    };

    // Torna a categoria selecionada uma categoria, requisitando as subcategorias dela (se houver)
    const selectSubcategoriaHandler = subcategoria => {
        if (subcategoria.temSubcategorias) {
            fetchSubcategorias(subcategoria.id).then(subcategorias =>
                setCategorias(prevCategorias => [...prevCategorias, { ...subcategoria, subcategorias }]));
        } else setCategorias(prevCategorias => [...prevCategorias, { ...subcategoria }]);
    };

    // Faz o request das primeiras subcategorias, baseada em uma categoria padrão
    useEffect(() => {
        if (!categorias.length) {
            const defaultCategoria = { id: "MLB5672", nome: "Acessórios para Veículos", cadastravel: false, temSubcategorias: true };

            fetchSubcategorias(defaultCategoria.id).then(subcategorias => {
                setCategorias([{ ...defaultCategoria, subcategorias: subcategorias }]);
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Muda a categoria selecionada ao mudar as categorias
    useEffect(() => {
        if (categorias.length)
            props.onChange({ ...props.peca, categoria: { ...categorias[categorias.length - 1] } })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorias])

    return (
        <tr className={!props.peca.categoria.temSubcategorias ? classes.Success : ""}>
            <td>
                {props.peca.arquetipo.nome}
            </td>
            <td style={{ whiteSpace: "none" }}>
                <strong>{props.peca.categoria?.nome || "-"}</strong>
            </td>
            <td className={classes.Subcategorias}>
                {isLoading ? <Spinner inline /> : (
                    <>
                        {props.peca.categoria?.temSubcategorias ? (
                            <Dropdown
                                textAlignLeft
                                returnObject
                                padrao="Selecione"
                                list={[...props.peca.categoria.subcategorias]}
                                onChange={selectedSubcategoria => selectSubcategoriaHandler(selectedSubcategoria)}
                            />
                        ) : "-"}
                        {categorias.length > 1 ? (
                            <button
                                title="Voltar"
                                className={classes.ReturnButton}
                                onClick={() => {
                                    let copiaCategorias = [...categorias];
                                    copiaCategorias.splice(-1);
                                    setCategorias(copiaCategorias);
                                }}>
                                <BsArrowReturnLeft size="24px" />
                            </button>
                        ) : null}
                    </>
                )}
            </td>
        </tr>
    );
};

export default Peca;