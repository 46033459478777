import {
    FETCH_ANOS,
    FETCH_MARCAS,
    FETCH_MODELOS,
    FETCH_TIPOS,
    FETCH_VEICULOS
} from "../actions/fipe";

const initialState = {
    anos: [],
    marcas: [],
    modelos: [],
    tipos: [],
    veiculos: []
};

const fipeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANOS:
            return { ...state, anos: action.anos };
        case FETCH_MARCAS:
            return { ...state, marcas: action.marcas };
        case FETCH_MODELOS:
            return { ...state, modelos: action.modelos };
        case FETCH_TIPOS:
            return { ...state, tipos: action.tipos };
        case FETCH_VEICULOS:
            return { ...state, veiculos: action.veiculos };
        default:
            return state;
    }
};

export default fipeReducer;
